import * as React from 'react';

import { Fab, type FabProps, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export type DeleteButtonProps = {
    tooltip?: string;
    size?: FabProps['size'];
    color?: FabProps['color'];
    onClick?: FabProps['onClick'];
    disabled?: FabProps['disabled'];
};

const DeleteButton: React.FunctionComponent<DeleteButtonProps> = function ({
    tooltip,
    ...props
}: DeleteButtonProps) {
    return (
        <Tooltip title={tooltip}>
            <Fab {...props}>
                <DeleteIcon />
            </Fab>
        </Tooltip>
    );
};

DeleteButton.defaultProps = {
    size: 'medium',
    color: 'error',
    tooltip: 'Supprimer',
    onClick: undefined,
    disabled: false,
};

export default DeleteButton;
