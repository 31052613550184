import React, { useEffect, useMemo, useState } from 'react';

import {
    CenterCircularProgress,
    footerHeight,
    TopbarHeight,
} from '@europrocurement/flexy-components';
import { Card, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { MediaObjectSelector, selectMediaObject, mediaObjectApi } from '../../redux/RootStore';
import { MediaObject } from '@europrocurement/l2d-domain';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import MediaObjectReader from './components/widgets/MediaObjectReader';

export type SaisieFacturesProps = {};

export type MultiAnnonceContextType = { mediaObject: MediaObject };

export function useMultiAnnonceContext() {
    return useOutletContext<MultiAnnonceContextType>();
}

const SaisieFacturesMultiple: React.FunctionComponent<SaisieFacturesProps> = () => {
    const { mediaobjectid } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;
    const [moStatus, setMoStatus] = useState<EuroprocApiResponseStatus>('idle');

    useEffect(() => {
        if (!mediaobjectid) {
            enqueueSnackbar(<Typography>Il n'y a pas de PDF correspondant ! 💥</Typography>);
        } else if (moStatus !== 'succeeded') {
            mediaObjectApi.patchMediaObjectItem(mediaobjectid, {
                dateDebutTraitement: new Date().toISOString(),
            });
            setMoStatus('succeeded');
        }
    }, [enqueueSnackbar, mediaobjectid, moStatus]);

    useEffect(() => {
        if (
            mediaObjectDataSource.status !== 'loading' &&
            mediaobjectid &&
            (!mediaObjectDataSource.selected ||
                `${mediaObjectDataSource.selected.id}` !== mediaobjectid)
        ) {
            dispatch(selectMediaObject({ id: +mediaobjectid })); // A aller chercher dans le match.params (react-router-dom)
        }
    }, [dispatch, mediaObjectDataSource.selected, mediaObjectDataSource.status, mediaobjectid]);

    // const mediaObject = ;
    const mediaObject = useMemo(
        () => mediaObjectDataSource.selected,
        [mediaObjectDataSource.selected],
    );

    return (
        <>
            {!mediaObject || mediaObjectDataSource.status === 'loading' ? (
                <CenterCircularProgress sx={{ height: '500px' }} />
            ) : (
                <Grid container>
                    <Grid item lg={6} sm={12}>
                        <MediaObjectReader
                            mediaObject={mediaObject}
                            sx={{
                                height: `calc(100vh -${footerHeight} - ${TopbarHeight}  )`,
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} sm={12}>
                        <Card>
                            <Outlet context={{ mediaObject }} />
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SaisieFacturesMultiple;
