import React, { useEffect, useState } from 'react';
import { Control, Controller, UseControllerProps } from 'react-hook-form';

import { AutocompleteStore, AutocompleteStoreProps } from './AutocompleteStore';

export type ControlledAutocompleteProps = UseControllerProps &
    AutocompleteStoreProps<Record<string, unknown>>;

export const ControlledAutocomplete: React.FunctionComponent<ControlledAutocompleteProps> =
    function (props) {
        const { name, rules, shouldUnregister, defaultValue, control, ...acstoreprops } = props;

        const controllerProps: UseControllerProps = {
            name,
            rules,
            shouldUnregister,
            defaultValue,
            control,
        };

        // console.log("rerender controled AC",acstoreprops );

        return (
            <Controller
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => {
                    if (!acstoreprops.inputProps) {
                        acstoreprops.inputProps = {
                            variant: 'outlined',
                        };
                    }
                    acstoreprops.inputProps.onBlur = (e) => {
                        onBlur();
                    };
                    return (
                        <AutocompleteStore
                            {...acstoreprops}
                            onChange={(e, data) => {
                                onChange(data);
                            }}
                            required={rules?.required ? true : undefined}
                        />
                    );
                }}
                {...controllerProps}
            />
        );
    };
