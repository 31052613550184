import * as React from 'react';

import { ActionsBar, AddressCard, CustomAvatar } from '@europrocurement/flexy-components';
import AddressForm from '../../../forms/AddressForm';

import { Address } from '@europrocurement/l2d-domain';
import { Prescripteur } from '@europrocurement/l2d-domain';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    useColorScheme,
    useMediaQuery,
} from '@mui/material';
import { Link } from '@mui/material';

import ApartmentIcon from '@mui/icons-material/Apartment';
import { useTheme } from '@emotion/react';
import MainInformationForm from '../forms/MainInformationForm';

type PrescriberInformationsProps = {
    prescriber: Prescripteur;
    afterSubmit: () => void;
    callModal: (modalContent: React.ReactElement) => void;
};

const PrescriberInformations: React.FC<PrescriberInformationsProps> = (props) => {
    const { prescriber, callModal, afterSubmit } = props;
    const {
        adresses,
        siret,
        siren,
        urlSocieteDotCom,
        tvaIntracommunautaire,
        regimeTva,
        nomDentreprise,
    } = prescriber;

    const theme: any = useTheme();
    const lgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const mainAddress = adresses.find((address) => address.defaut);

    const handleEditAddress = (address: any) => {
        //Todo type params
        if (prescriber.id) {
            callModal(
                <AddressForm entity={address} prescriber={prescriber} afterSubmit={afterSubmit} />,
            );
        }
    };

    const handleEditMainInformation = () => {
        //Todo type params
        if (prescriber.id) {
            callModal(<MainInformationForm prescriber={prescriber} afterSubmit={afterSubmit} />);
        }
    };

    const informations = [
        {
            label: 'SIRET',
            value: siret,
        },
        {
            label: 'SIREN',
            value: siren,
        },
        {
            label: 'TVA Intracommunautaire',
            value: tvaIntracommunautaire,
        },
        {
            label: 'Régime TVA',
            value: regimeTva,
        },
    ];

    const Information = ({ label, value }: { label: string; value: string | undefined }) => {
        const displayedValue = value ? value : '- - -';
        return (
            <p>
                <b>{label} : </b>
                {displayedValue}
            </p>
        );
    };

    const CustomDivider = () => {
        return <hr style={{ opacity: '0.2' }} />;
    };

    const avatarSize = 100;

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Card
                    sx={{
                        height: '90%',
                        boxShadow: 'none',
                        // border: '1px solid lightgray',
                    }}
                >
                    <CardHeader
                        title={
                            <Box display={'flex'} sx={{ marginBottom: '20px' }}>
                                <Typography fontWeight="bold">{'Informations société'}</Typography>
                            </Box>
                        }
                        action={<ActionsBar editAction={() => handleEditMainInformation()} />}
                    />
                    <CardContent>
                        <Box display={'flex'}>
                            <Box width={`${lgBreakpoint ? '66%' : '100%'}`}>
                                <CustomDivider />
                                {informations.map(({ label, value }) => (
                                    <Typography sx={{ marginBottom: '10px' }}>
                                        <Information label={label} value={value} />
                                        <CustomDivider />
                                    </Typography>
                                ))}
                            </Box>
                            <Box
                                width={'30%'}
                                display={`${lgBreakpoint ? 'flex' : 'none'}`}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{ marginLeft: '22px' }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: 'primary.main',
                                        width: avatarSize,
                                        height: avatarSize,
                                        marginBottom: '15px',
                                    }}
                                >
                                    <ApartmentIcon fontSize={'large'} />
                                </Avatar>
                                {urlSocieteDotCom && (
                                    <Box display={'flex'} marginTop={'15px'}>
                                        <Link href={urlSocieteDotCom} target="_blank">
                                            <Button variant={'outlined'}>Fiche société.com</Button>
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {!lgBreakpoint && urlSocieteDotCom && (
                            <Box display={'flex'} marginTop={'15px'}>
                                <Link href={urlSocieteDotCom} target="_blank">
                                    <Button variant={'outlined'}>Fiche société.com</Button>
                                </Link>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={6}>
                <AddressCard
                    address={mainAddress as Address}
                    onEdit={() => handleEditAddress(mainAddress)}
                />
            </Grid>
        </Grid>
    );
};

export default PrescriberInformations;
