import React from 'react';

import { FlexyMenu } from '@europrocurement/flexy-components';
import { FactureAchat, Fournisseur } from '@europrocurement/l2d-domain';
import { Box, Typography, Button } from '@mui/material';
import { groupLinesByDossiers } from '../functions/dossierFonctions';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import useToaster from '@b2d/hooks/useToaster';
import { useNavigate } from 'react-router';
import { FactureAchatFactureAchatReadDossierDossiersInner } from '@europrocurement/l2d-domain/openApi/ApiAchats';

export type EnteteFactureType = {
    factureAchat: FactureAchat;
    fournisseur?: Fournisseur;
    showListeDossier?: boolean;
    dossiers?: FactureAchatFactureAchatReadDossierDossiersInner[];
};

export const EnteteFacture = function ({
    factureAchat,
    fournisseur,
    showListeDossier = true,
    dossiers,
}: EnteteFactureType) {
    const { handleRouterResponse } = useToaster();
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography color="secondary" typography="h1">
                    Facture N°{factureAchat.numeroFacture} { factureAchat.avoir === true ? "(Facture d'avoir)" : '' }
                </Typography>
                {fournisseur ? (
                    <Typography typography="subtitle2">
                        <b>Date de facturation</b> {factureAchat.dateFacture?.split('T')[0]} <br />
                        <b>Fournisseur :</b> {fournisseur.libelle} <br />
                        <b>Type remise :</b> {fournisseur.typeRemise?.code}
                    </Typography>
                ) : null}
                {factureAchat && factureAchat.createdAt ? (
                    <>
                        <Typography variant="subtitle2">
                            <b>Facture saisie le :</b>
                            {`${new Date(factureAchat.createdAt).toLocaleDateString()}`}
                        </Typography>
                    </>
                ) : null}
                {factureAchat && factureAchat.createdBy ? (
                    <>
                        <Typography variant="subtitle2">
                            <b>Facture saisie par :</b>
                            {`${factureAchat.createdBy}`}
                        </Typography>
                    </>
                ) : null}
            </Box>

            {showListeDossier && dossiers ? (
                <FlexyMenu
                    key="liste-dossiers-facture-achat"
                    title="Liste des dossiers"
                    items={dossiers.map(
                        (item: FactureAchatFactureAchatReadDossierDossiersInner) => {
                            return `${item.numeroDossier} - ${item.libelleAnnonceur}`;
                        },
                    )}
                    onClick={(clickedDossier: string) => {
                        const res = generateB2DPath('nextEditDossierMultiFacturePath', {
                            factureAchat: factureAchat,
                            dossiernum: clickedDossier.split('-')[0].trim(),
                        });

                        handleRouterResponse(
                            res,
                            () => {
                                if (res.status === 'OK') {
                                    navigate(res.path);
                                }
                            },
                            true,
                        );
                    }}
                />
            ) : null}
        </Box>
    );
};
export default EnteteFacture;
