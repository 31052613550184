import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useMultiAnnonceContext } from '../../../SaisieFacturesMultiple';

export const NextDossierSaisieMultiple = function () {
    const { factureachatid } = useParams();
    const { mediaObject } = useMultiAnnonceContext();
    const navigate = useNavigate();

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    useEffect(() => {
        const res = generateB2DPath('formmultiadddossier', {
            factureAchat: { id: Number(factureachatid) },
            mediaObject,
        });

        if (res.status === 'OK') {
            navigate(res.path);
        } else {
            // TODO
        }
    }, [factureachatid, mediaObject, navigate]);

    return null;
};

export default NextDossierSaisieMultiple;
