import * as React from 'react';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { MenuEntry, BreadcrumbsOpts, LazyBreadcrumb } from '@europrocurement/flexy-components';
import PrescripteurFiche from './views/detail/PrescripteurDetail';
import { Outlet } from 'react-router';
import PrescripteursListing from './views/listing/PrescripteursListing';
import PrescripteurAdd from './views/create/PrescripteurCreation';

export const prescripteursBasePath = 'prescripteurs';
export const PRESCRIBER_LIST = '/prescripteurs/liste';
export const PRESCRIBER_NEW = '/prescripteurs/new';

export const PrescipteurRouter = {
    path: prescripteursBasePath,
    element: (
        <KeycloakRoute roles="prescripteur">
            <Outlet />
        </KeycloakRoute>
    ),
    children: [
        {
            path: 'liste',
            children: [
                {
                    path: '',
                    index: true,
                    element: <PrescripteursListing />,
                },
                {
                    path: ':idPrescripteur',
                    element: <PrescripteurFiche />,
                },
            ],
        },
        {
            path: 'new',
            element: <PrescripteurAdd />,
        },
    ],
};

export const PrescripteurBreadcrumbMapper: Array<BreadcrumbsOpts> = [
    {
        path: '^/prescripteurs$',
        displayPath: [{ path: 'Prescripteurs', link: PRESCRIBER_LIST }],
    },
    {
        path: '^/prescripteurs/new$',
        displayPath: [{ path: 'Prescripteurs', link: PRESCRIBER_LIST }, 'Nouveau prescripteur'],
    },
    {
        path: '^/prescripteurs/liste/\\d+$',
        bypassTitle: true,
        displayPath: [
            { path: 'Prescripteurs', link: PRESCRIBER_LIST },
            "Details d'un prescripteur",
        ],
    },
    {
        path: '^/prescripteurs/liste$',
        displayPath: [
            { path: 'Prescripteurs', link: PRESCRIBER_LIST },
            'Recherche des prescripteurs',
        ],
    },
];

export const PrescripteurMenuEntries: MenuEntry[] = [
    {
        navlabel: true,
        subheader: 'Prescripteurs',
        href: '/prescripteurs',
        role: 'prescripteur',
    },
    {
        title: 'Liste des prescripteurs',
        icon: <SupervisedUserCircleIcon />,
        href: PRESCRIBER_LIST,
        role: 'prescripteur',
    },
    {
        title: 'Nouveau prescripteur',
        icon: <PersonAddIcon />,
        href: PRESCRIBER_NEW,
        role: 'prescripteur',
    },
];

export default PrescipteurRouter;
