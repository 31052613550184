import * as React from 'react';
import { Box, Typography } from '@mui/material';

export type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
};

const TabPanel: React.FunctionComponent<TabPanelProps> = function ({
    children,
    value,
    index,
    ...props
}: TabPanelProps) {
    return (
        <div role="tabpanel" hidden={value !== index} style={{ width: '100%' }} {...props}>
            {value === index ? (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            ) : null}
        </div>
    );
};

export default TabPanel;
