import * as React from 'react';
import VerticalTabs from '../../../../../components/VerticalTabs';
import AccountingSettings from './billing/AccountingSettings';

const Accounting: React.FunctionComponent = function () {
    const SETTINGS_TAB_INDEX = 0;

    const TABS = [
        {
            index: SETTINGS_TAB_INDEX,
            label: 'Paramétrage',
            tabContent: <AccountingSettings />,
        },
    ];

    return <VerticalTabs tabs={TABS} />;
};

export default Accounting;
