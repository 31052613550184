import * as React from 'react';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import Person from '@mui/icons-material/Person';
import { prescripteursApi } from '../../../../../redux/RootStore';
import { PrescriberFormProps } from '../../../forms/common';
import useToaster from '../../../../../hooks/useToaster';
import { TiersPrescripteurJsonldPrescripteurUpdate } from '@europrocurement/l2d-domain/openApi/ApiTiers';

export type MainInformationFormProps = PrescriberFormProps & {
    entity?: any;
};

const MainInformationForm: React.FC<MainInformationFormProps> = (props) => {
    const { afterSubmit, prescriber } = props;

    const { handleApiResponse } = useToaster();

    const MainInformationFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Configuration
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'boolean',
            name: 'personnePhysique',
            inputlabel: 'Personne physique',
            xs: 12,
        },
        {
            type: 'boolean',
            name: 'clientSuivi',
            inputlabel: 'Client Suivi',
            xs: 12,
        },
        {
            type: 'boolean',
            name: 'venteIndirecte',
            inputlabel: 'Vente Indirecte',
            xs: 12,
        },
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Informations société
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'text',
            name: 'raisonSociale',
            inputlabel: 'Raison sociale',
            xs: 12,
        },
        {
            type: 'text',
            name: 'siret',
            inputlabel: 'SIRET',
            xs: 12,
        },
        {
            type: 'text',
            name: 'siren',
            inputlabel: 'SIREN',
            xs: 12,
        },
        {
            type: 'text',
            name: 'tvaIntracommunautaire',
            inputlabel: 'TVA Intracommunautaire',
            xs: 12,
        },
    ];
    const updatePrescriber = async (formData: FormObject) => {
        if (prescriber.id) {
            const updateRequest = prescripteursApi.updatePrescripteurTiersItem(
                prescriber.id?.toString(),
                formData as unknown as TiersPrescripteurJsonldPrescripteurUpdate,
            );
            await handleApiResponse(updateRequest, afterSubmit);
        }
    };

    return (
        <FlexyForm
            formObject={prescriber}
            formStructure={MainInformationFormStructure}
            onSubmit={(formData) => updatePrescriber(formData)}
        />
    );
};

export default MainInformationForm;
