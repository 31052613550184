import { BASE_PATH as BASE_PATH_ACHATS } from '../openApi/ApiAchats/base';
import { BASE_PATH as BASE_PATH_DOSSIERS } from '../openApi/ApiDossiers/base';
import { BASE_PATH as BASE_PATH_TIERS } from '../openApi/ApiTiers/base';
import Keycloak from 'keycloak-js';

export type ApiEnvType = {
    local?: string;
    dev?: string;
    qual: string;
    prod?: string;
    preprod?: string;
};

// export type ENV = 'dev' | 'qual' | 'preprod' | 'prod';
export type ENV_API = 'local' | ENV;

export enum ENV {
    'DEV' = 'dev',
    'QUAL' = 'qual',
    'PREPROD' = 'preprod',
    'PROD' = 'prod',
}

export enum APIS_ENUM {
    'ACHATS' = 'achats',
    'TIERS' = 'tiers',
    'DOSSIERS' = 'dossiers',
}

export type APISENV = {
    [x in APIS_ENUM]: ApiEnvType;
};

export const APIENV: {
    apis: APISENV;
    keycloak: {
        [x in ENV]: Keycloak.KeycloakConfig;
    };
} = {
    apis: {
        achats: {
            local: 'http://localhost:8086',
            qual: BASE_PATH_ACHATS,
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        tiers: {
            local: 'http://localhost:8080',
            qual: BASE_PATH_ACHATS,
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        dossiers: {
            local: 'http://localhost:8087',
            qual: BASE_PATH_ACHATS,
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
    },
    keycloak: {
        dev: {
            realm: 'dev',
            url: 'https://sso.preprod.legal2digital.net',
            clientId: 'back-to-digital',
        },
        qual: {
            realm: 'qual',
            url: 'https://sso.preprod.legal2digital.net',
            clientId: 'back-to-digital',
        },
        preprod: {
            realm: 'preprod',
            url: 'https://sso.preprod.legal2digital.net',
            clientId: 'back-to-digital',
        },
        prod: {
            realm: 'prod',
            url: 'https://sso.legal2digital.net',
            clientId: 'back-to-digital',
        },
    },
};
