import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';

/** layouts */
import {
    FullLayout,
    BlankLayout,
    NotFound,
    Forbidden,
    Unauthorized,
    NavigateNotFound,
    BreadcrumbsOpts,
    FullLayoutProps,
} from '@europrocurement/flexy-components';

import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
/** end layouts */

/** pages */
import Accueil from '../pages/Accueil';
import Debug from '../pages/Debug';
import PrescripteurRouter from '../pages/Prescripteurs';
/** end pages */

/** menu */
import menuEntries from './menuEntries';
/** end menu */

import dark from '../assets/img/Logo_Back_Digital_dark.png';
import light from '../assets/img/Logo_Back_Digital_light.png';
import { PrescripteurBreadcrumbMapper } from '../pages/Prescripteurs/PrescripteurRouter';
import AchatsRouter, {
    AchatsBreadcrumbMapper,
    cancelEditMediaObject,
} from '../pages/Achats/AchatsRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import generateB2DPath, { generateB2DPathResponseType } from '../utils/generateB2DPath';

import logoB2D from '../assets/img/Symbole-L2D-RVB-V1-64.png';
import { ContextActionType } from '@europrocurement/flexy-components/components/organisms/ContextActionsMenu/ContextActionsMenu';

const logo = {
    light,
    dark,
};

export const BreadcrumbMapper: Array<BreadcrumbsOpts> = [
    ...PrescripteurBreadcrumbMapper,
    ...AchatsBreadcrumbMapper,
];

export const ActionsContext = React.createContext<any>(null);

const B2DLayout: React.FunctionComponent<FullLayoutProps> = (props) => {
    // Todo export context action layout
    const defaultActions: ContextActionType[] = [];

    const [contextActions, setContextActions] = React.useState<ContextActionType[]>([
        ...defaultActions,
    ]);

    const defineContextActions = (actions: ContextActionType[]) => {
        setContextActions([...defaultActions, ...actions]);
    };

    const resetContextActions = () => {
        setContextActions([...defaultActions]);
    };

    const location = useLocation();
    const navigate = useNavigate();
    const { mediaobjectid } = useParams();

    const layoutProps = {
        ...props,
        contextActions,
        contextActionMainIcon: (
            <img src={logoB2D} style={{ maxHeight: '24px' }} alt="Legal2digital 2023" />
        ),
    };

    return (
        <ActionsContext.Provider
            value={{
                contextActions: contextActions,
                defineContextActions: defineContextActions,
                resetContextActions: resetContextActions,
            }}
        >
            <FullLayout
                {...layoutProps}
                headerProps={{
                    // returnArrowClickCallback: ()=>{navigateTo()} // quand mon callback est trigger, j'execute navigateTo()
                    returnArrowClickCallback: async () => {
                        let response: generateB2DPathResponseType = {
                            status: 'STAY',
                        };

                        if (location.pathname.match(/\/achats\/liste\/saisir\/(\d+)/)) {
                            var arr = /\/achats\/liste\/saisir\/(\d+)/.exec(location.pathname);
                            if (arr && arr[1]) {
                                await cancelEditMediaObject(arr[1]);
                            }
                            response = generateB2DPath('listmediaobject');
                        }
                        if (
                            location.pathname.match(/\/achats\/liste\/modifier/) ||
                            location.pathname.match(/\/achats\/liste\/recap/)
                        )
                            response = generateB2DPath('listfacture');

                        if (response.status === 'OK') {
                            navigate(response.path);
                        }
                    },
                }}
            />
        </ActionsContext.Provider>
    );
};

const Router = [
    {
        path: '/',
        element: (
            <B2DLayout
                menuEntries={menuEntries}
                footerProps={{
                    children: (
                        <img src={logoB2D} style={{ maxHeight: '24px' }} alt="Legal2digital 2023" />
                    ),
                }}
                logoSrc={logo}
                logoRedirect="/"
                // breadcrumbMapper={BreadcrumbMapper}
            />
        ),
        children: [
            { path: '/', element: <Navigate to="/accueil" /> },
            {
                path: 'accueil',
                exact: true,
                element: <Accueil />,
            },
            {
                path: 'debug',
                element: (
                    <KeycloakRoute roles="realm:debug">
                        <Debug />
                    </KeycloakRoute>
                ),
            },
            PrescripteurRouter,
            AchatsRouter,
            {
                path: '*',
                element: <NavigateNotFound />,
            },
        ],
    },
    {
        path: '/errors',
        element: <BlankLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            { path: '403', element: <Forbidden /> },
            { path: '401', element: <Unauthorized /> },
        ],
    },
];

export default Router;
