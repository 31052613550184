import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    CategorieSocioProfessionnelleApi,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

export { type TiersConfigurationParameters };

export type CspApiObject = any & Record<string, unknown>;

export type CspOptions = CspApiObject;

export const CSP_OPTIONS_SLICE_NAME = 'cspOptions';

const mapperCspOptions = function (cspOptions: CspApiObject) {
    return {
        ...cspOptions,
    } as CspOptions;
};

export function createCspOptionsSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CategorieSocioProfessionnelleApi(conf);

    const fetchCspOptionsCollection: FetchCollectionData<CspApiObject> = ({
        pagination,
        xIdSociete,
    }) => {
        return api
            .getListeCspCategorieSocioProfessionnelleCollection(
                xIdSociete,
                undefined,
                pagination.page + 1,
            )
            .then((res) => {
                return res.data;
            })
            .catch((error) => {}) as Promise<ApiCollectionResponse<CspApiObject>>;
    };

    const fetchCspOptionsItem: FetchItemDataType<CspApiObject> = ({ idItem, xIdSociete }) => {
        return api
            .getCspCategorieSocioProfessionnelleItem(String(idItem), xIdSociete, undefined)
            .then((res) => res.data) as Promise<CspApiObject>;
    };

    const { slice: cspOptionsSlice, dataSourcesThunks: cspOptionsDataSourcesThunks } =
        SliceFactory.createSlice<CspApiObject, CspOptions>(
            CSP_OPTIONS_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchCspOptionsCollection,
            fetchCspOptionsItem,
            mapperCspOptions,
        );

    return { cspOptionsSlice, cspOptionsDataSourcesThunks };
}
