import * as React from 'react';

import md5 from 'md5';
import { useSelector, useDispatch } from 'react-redux';
import { CustomizerReducerType, setXIdSociete } from '@europrocurement/l2d-domain';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import {
    AppBar,
    IconButton,
    Box,
    Toolbar,
    Button,
    Menu,
    Avatar,
    MenuItem,
    SxProps,
} from '@mui/material';
import { Close, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';

import ProfileDropdown from '../../molecules/ProfileDropdown';
import { FlexyLayoutState } from '../../../redux/storeConfig/store';

export type HeaderProps = {
    toggleSidebar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    toggleMobileSidebar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps;
    drawerAvailability?: boolean;
    returnArrowClickCallback?: () => any;
};

const Header: React.FunctionComponent<HeaderProps> = function ({
    toggleSidebar,
    toggleMobileSidebar,
    sx,
    drawerAvailability,
    returnArrowClickCallback,
}: HeaderProps) {
    const keycloakService = UseKeycloakService();
    const dispatch = useDispatch();

    const xIdSociete = useSelector((state: FlexyLayoutState) => state.customizer.xIdSociete);

    // const keycloakService = {
    //     isAutenticated : ()=>{return false;}
    // }

    // Action de redirection utilisée lorsque
    // le bouton menu est remplacé par la flèche de retour

    const userimg = React.useCallback(
        () => `https://www.gravatar.com/avatar/${md5(keycloakService.getEmail())}`,
        [keycloakService],
    );

    // userimg = "https://www.gravatar.com/avatar/"

    // const login = keycloakService.getKc().login();
    const logout = React.useCallback(() => keycloakService.getKc().logout(), [keycloakService]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [profilDropdwnOpened, setProfilDropdwnOpened] = React.useState<Element | null>(null);

    const open = Boolean(anchorEl);

    const handleClickSelectIdSociete = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleCloseSelectIdSociete = () => setAnchorEl(null);
    const handleChangeSociete = (e: React.BaseSyntheticEvent) => {
        dispatch(setXIdSociete(e.target.value as CustomizerReducerType['xIdSociete']));
        handleCloseSelectIdSociete();
    };
    const handleClose4 = () => setProfilDropdwnOpened(null);
    const openProfilDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.target instanceof Element) setProfilDropdwnOpened(event.currentTarget);
    };

    return (
        <AppBar sx={{ backgroundColor: 'background.default', ...sx }} elevation={0}>
            <Toolbar>
                {drawerAvailability ? (
                    <>
                        {/* Pour le mode Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            size="large"
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        {/* Pour le Mobile */}
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleMobileSidebar}
                            sx={{
                                display: {
                                    lg: 'none',
                                    xs: 'flex',
                                },
                            }}
                        >
                            <MenuIcon width="20" height="20" />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {/* Pour le mode Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={returnArrowClickCallback}
                            size="large"
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <Close />
                        </IconButton>

                        {/* Pour le Mobile */}
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label="menu"
                            onClick={returnArrowClickCallback}
                            sx={{
                                display: {
                                    lg: 'none',
                                    xs: 'flex',
                                },
                            }}
                        >
                            <Close width="20" height="20" />
                        </IconButton>
                    </>
                )}
                {/* ------------ End Menu icon ------------- */}

                <Box flexGrow={1} />

                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickSelectIdSociete}
                    color="inherit"
                >
                    {(xIdSociete === 2 && 'Affiches Parisiennes') ||
                        (xIdSociete === 3 && 'Le Tout Lyon') ||
                        (xIdSociete === 5 && 'Nouvelles Publications Commerciales') ||
                        'Veuillez sélectionner une société'}
                </Button>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseSelectIdSociete}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem value={2} onClick={handleChangeSociete}>
                        2 - Affiches Parisiennes
                    </MenuItem>
                    <MenuItem value={3} onClick={handleChangeSociete}>
                        3 - Le Tout Lyon
                    </MenuItem>
                    <MenuItem value={5} onClick={handleChangeSociete}>
                        5 - Nouvelles Publications Commerciales
                    </MenuItem>
                </Menu>

                <Box
                    sx={{
                        width: '1px',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        height: '25px',
                        ml: 1,
                        mr: 1,
                    }}
                />

                {/* Profile Dropdown */}
                <Button
                    aria-label="menu"
                    color="inherit"
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={openProfilDropdown}
                >
                    <Box display="flex" alignItems="center" gap={1}>
                        <Avatar
                            src={userimg()}
                            alt={userimg()}
                            sx={{
                                width: '30px',
                                height: '30px',
                            }}
                        />
                        Bonjour, {keycloakService.getName()}
                        <ExpandMore />
                    </Box>
                </Button>

                <Menu
                    id="profile-menu"
                    anchorEl={profilDropdwnOpened}
                    open={!!profilDropdwnOpened}
                    onClose={handleClose4}
                >
                    <ProfileDropdown
                        avatarSrc={userimg()}
                        fullName={keycloakService.getName()}
                        logout={logout}
                        email={keycloakService.getEmail()}
                    />
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
