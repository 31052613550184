import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Commande,
    Dossier,
    FactureAchat,
    FactureVente,
    Fournisseur,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import {
    CommandeSelector,
    DossierSelector,
    FactureFormSelector,
    FactureVenteSelector,
    FactureachatSelector,
    FournisseurSelector,
    RootStateType,
    RubriqueFacturationSelector,
    dossierDataSourcesThunks,
    factureAchatApi,
    factureAchatLigneApi,
    selectDossier,
    selectFactureAchat,
    selectFactureVente,
    selectFournisseur,
} from '@b2d/redux/RootStore';
import { factureFormObject, ligneAchat, ligneProduit, RSFRubFac, tableauProduits } from '../types';
import { Box, Button, FormControlLabel, InputAdornment, Switch, Typography } from '@mui/material';
import { ExtandedFormContext, FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { getAcDossierStructure, totauxStructure, useGetProduitsStructure } from '../formElements';
import EnteteFacture from './EnteteFacture';
import { useResetAllForm } from '../functions/generalFunctions';
import { useMultiAnnonceContext } from '../../../SaisieFacturesMultiple';
import { useSnackbar } from 'notistack';
import { useBackFromMediaObjectEdit } from '../../../AchatsRouter';
import { useUpdateDomaine } from '../functions/venteFunctions';
import { useUpdateFormWithDossier } from '../functions/dossierFonctions';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    ajouterLigneRSF,
    defaultProduits,
    fillProduits,
    replaceProduits,
    useActionOnChangeTauxRemise,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
} from '../functions/produitFunctions';
import { arrondi, updateFormTotaux } from '../functions/calculsProduits';
import { CenterCircularProgress, Modalizer } from '@europrocurement/flexy-components';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import {
    tableauAchatToFormProduits,
} from '../functions/dataTransformers';
import { useGetDossiers, useGetLignes, useUpdateDossierOfMultiAnnonce } from '../functions/dataHooks';
import {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { reduceLines } from '../../informationWidget/DossierLinesAccordeon';

export type EditDossierSaisieMultipleType = {};
export const EditDossierSaisieMultiple = function ({}: EditDossierSaisieMultipleType) {
    const kc = UseKeycloakService();
    const isInterne = kc.checkRole('realm:interne');

    const { factureachatid, dossiernum } = useParams();
    const { mediaObject } = useMultiAnnonceContext();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resetForm = useResetAllForm();
    const onCancel = useBackFromMediaObjectEdit();
    const updateDomaine = useUpdateDomaine();
    const updateFormWithDossier = useUpdateFormWithDossier();
    const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
    const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();
    const getProduitsStructure = useGetProduitsStructure();
    const formContext = useForm({
        mode: 'onTouched',
    });

    const [hasSetRsf, setHasSetRsf] = useState<boolean>(false);
    const [hasBlured, setHasBlured] = useState<boolean>(false);
    const [modalRemiseOpen, setModalRemiseOpen] = useState<boolean>(false);
    const [afficherBoutonTxRemise, setAfficherBoutonTxRemise] = useState<boolean>(false);
    const [rsfInclusTxremise, setRsfInclusTxremise] = useState<string>();

    const state = useSelector(FactureFormSelector);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    if (!dossiernum) {
        console.error('DossierNum is undefined !');
    }

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    const factureAchatStatus: EuroprocApiResponseStatus = useSelector(FactureachatSelector).main.status;
    const factureAchat: FactureAchat | undefined = useSelector(FactureachatSelector).main.selected;
    
    const commandeSelected: Commande | undefined = useSelector(CommandeSelector).main.selected;
    const venteSelected: FactureVente | undefined = useSelector(FactureVenteSelector).main.selected;
    const dossierSelected: Dossier | undefined = useSelector(DossierSelector).main.selected;
    const fournisseurSelected: Fournisseur | undefined =
        useSelector(FournisseurSelector).main.selected;
    const fournisseurStatus: EuroprocApiResponseStatus =
        useSelector(FournisseurSelector).main.status;

    const [lastVenteUpdate, setLastVenteUpdate] = useState<number|null>(null);
    const [dossiers, setdossiers] = useState<FactureAchatFactureAchatReadDossierDossiersInner[]>(
        [],
    );
    const [dossiersStatus, setdossiersStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');

    const getDossiers = useGetDossiers();
    const getLignes = useGetLignes();

    useEffect(() => {
        if (!factureachatid || dossiersStatus === 'loading' || dossiersStatus === 'succeeded') {
            return;
        }

        setdossiersStatus('loading');
        getDossiers(+factureachatid, factureAchatApi).then((data) => {
            setdossiersStatus('succeeded');
            setdossiers(data);
        });
    }, [dossiersStatus, factureachatid, getDossiers]);

    useEffect(() => {
        if (
            dossiers &&
            dossiers.length > 0 &&
            dossiernum &&
            factureachatid &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded'
        ) {
            const doss = dossiers.find(
                (item: FactureAchatFactureAchatReadDossierDossiersInner) =>
                    item.numeroDossier === dossiernum,
            );
            if (doss && doss.idDossier) {
                setLignesStatus('loading');
                getLignes(+factureachatid, [doss.idDossier], factureAchatLigneApi).then((data) => {
                    setLignesStatus('succeeded');
                    setLignes(data);
                });
            }
        }
    }, [dossiernum, dossiers, factureachatid, getDossiers, getLignes, lignesStatus]);

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
        shallowEqual,
    );

    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;

    const searchByNumeroDossier = dossierDataSourcesThunks.autocompleteDossiers.getData;

    const searchByNumeroPresta =
        dossierDataSourcesThunks.autocompleteDossiers.data.searchByNumeroPresta;

    const actionOnRSF: any | undefined = (
        tableauProduits: tableauProduits,
        actionIfRSF: (produitRSF?: Partial<ligneProduit> | undefined) => any | undefined,
        actionNoRSF: () => any | undefined,
    ) => {
        const produits = tableauProduits;
        const produitRSF = produits.find((item) => item.rubriqueFacturation?.id === 99);

        if (produitRSF && actionIfRSF !== undefined) {
            actionIfRSF(produitRSF);
        }

        if (!produitRSF && actionNoRSF !== undefined) {
            actionNoRSF();
        }
    };

    const updateProduitsOnRemise = useCallback(
        (txRemise: string, pubLineNumber: number) => {
            if(!fournisseurSelected){
                return;
            }
            // Sert pour relever les fournisseurs dont la RSF a été modifiée manuellement.
            setHasSetRsf(true);
            ajouterLigneRSF(formContext, fournisseurSelected);
            setAfficherBoutonTxRemise(true);

            const produits = formContext.getValues(`produits`) as tableauProduits;

            if (parseFloat(txRemise) > 0 && pubLineNumber !== undefined) {
                // Calcul de la ligne de PUB
                const pubHt = formContext.getValues(`produits.${pubLineNumber}.ht`);

                if (pubHt !== undefined) {
                    const newPubHt = arrondi(pubHt / (1 - parseFloat(txRemise) / 100)) as number;
                    const newRsfHt = arrondi(newPubHt - pubHt);

                    const txtva = formContext.getValues(`produits.${pubLineNumber}.txtva`);

                    formContext.setValue(`produits.${pubLineNumber}.ht`, newPubHt);

                    fillProduits(
                        formContext,
                        {
                            idLigneVente: formContext.getValues(
                                `produits.${pubLineNumber}.idLigneVente`,
                            ),
                            ht: newPubHt,
                            txtva: formContext.getValues(`produits.${pubLineNumber}.txtva`),
                        },
                        pubLineNumber,
                    );

                    // On cherche la ligne de rsf
                    actionOnRSF(
                        produits,
                        (produitRSF: Partial<ligneProduit>) => {
                            const index = produits.indexOf(produitRSF);

                            produits[index].ht = newRsfHt;
                            produits[index].txtva = txtva;
                            console.log("setvalueNONNNN")
                            formContext.setValue('produits', produits);
                        },
                        undefined,
                    );

                    const produitsAfterAddRsf = formContext.getValues(
                        `produits`,
                    ) as tableauProduits;

                    const RSF = produitsAfterAddRsf.find(
                        (item) => item.rubriqueFacturation?.id === 99,
                    );

                    if (RSF) {
                        fillProduits(formContext, RSF, produitsAfterAddRsf.indexOf(RSF));
                        formContext.trigger(`produits.${produitsAfterAddRsf.indexOf(RSF)}.ht`);
                    }
                }
            }
        },
        [formContext, fournisseurSelected],
    );

    /**
     * Si un id de factureAchat est trouvé dans l'url,
     * alors on sélectionne la facture d'achat concernée.
     */
    useEffect(() => {
        if (factureachatid && factureAchatStatus !== 'loading' && ( !factureAchat || factureAchat.id !== +factureachatid ) ) {
                console.log('je vais chercher lachat');
                dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * Si une facture d'achat est sélectionnée et qu'elle possède un fournisseur lié,
     * alors sélectionner ce fournisseur.
     */
    useEffect(() => { // OKKKKKK
        if (factureAchat && fournisseurStatus !== 'loading' &&  factureAchat.idEntiteFacturante && (!fournisseurSelected ||fournisseurSelected.id !== factureAchat.idEntiteFacturante )) {
                console.log('je vais chercher le frs');
                dispatch(selectFournisseur({ id: factureAchat.idEntiteFacturante }));
        }
    }, [dispatch, factureAchat, fournisseurSelected]);

    /**
     * Si des lignes sont liées au numéro de dossier en paramètre,
     * alors sélectionner le dossier en question.
     */
    useEffect(() => {
        if (dossiernum && dossiers && dossiers.length > 0) {
            const idDossier = dossiers.find((doss) => doss.numeroDossier === dossiernum)?.idDossier;

            if (idDossier) {
                console.log('je vais chercher le doss');
                dispatch(selectDossier({ id: +idDossier }));
            }
        }
    }, [dispatch, dossiernum, dossiers]);

    /**
     * Si un dossier est sélectionné,
     * alors mettre à jour la section 'Dossier'.
     */
    useEffect(() => {
        if (
            dossierSelected &&
            formContext &&
            dossierSelected.numero !== formContext.getValues('numero_dossier')
        ) {
            updateFormWithDossier(formContext, dossierSelected);
        }
    }, [dossierSelected, formContext, updateFormWithDossier]);

    /**
     * S'il existe des lignes correspondantes au dossier,
     * alors :
     *     - Remplacer les produits existant
     *     - Recalculer les totaux
     */
    useEffect(() => {
        if (formContext && lignes && lignes.length > 0) {
            const tableauProduits: tableauProduits = tableauAchatToFormProduits(reduceLines(lignes), [
                ...rubFactsDataSource.data,
                RSFRubFac,
            ]);

            console.log("before j'ai des lignes je replace ",tableauProduits);
            replaceProduits(formContext, tableauProduits);
            console.log("after j'ai des lignes je replace ",tableauProduits);
            updateFormTotaux(formContext, false);
        }
    }, [formContext, lignes, rubFactsDataSource.data]);

    const factureFormStructure: FormStructure[] = React.useMemo(() => {
        return [
            {
                type : "hidden",
                name : "avoir",
                value : factureAchat?.avoir || false
            },
            ...getAcDossierStructure(
                () => {},
                dossiersDataSource,
                searchByNumeroDossier,
                searchByNumeroPresta,
                state.defaultNumeroDossier,
                state.defaultNumeroPresta,
                true,
            ),
            ...getProduitsStructure(
                formContext,
                state.rubFacts,
                undefined,
                afficherBoutonTxRemise || state.switchLigneVente ? (<Box
                    sx={{
                        display: 'flex',
                        // width: '-webkit-fill-available',
                        marginRight: '-8px',
                        marginLeft: 'auto'
                    }}
                >
                    { state.switchLigneVente && isInterne ? (<FormControlLabel
                        sx={{
                            marginLeft: 'auto',
                        }}
                        label={state.switchLigneVente.label}
                        key={`formHeaderbtn-${state.switchLigneVente.label}`}
                        control={
                            <Switch
                                onChange={(
                                    event: React.ChangeEvent,
                                    checked: React.SetStateAction<boolean>
                                ) => {
                                    dispatch({
                                        type: ACTIONS.AFFICHER_LIGNE_VENTE,
                                        payload: checked,
                                    });
                                }}
                                checked={state.afficherLigneVente}
                                size="small"
                                aria-label="switch"
                                {...state.switchLigneVente}
                            />
                        }
                    />) : null}
                    { afficherBoutonTxRemise ? (<Button
                        onClick={() => {
                            actionOnChangeTauxRemise(
                                formContext,
                                () => {
                                    setModalRemiseOpen(true);
                                },
                                (message) => {
                                    enqueueSnackbar(<Typography>{message}</Typography>, {
                                        variant: 'error',
                                    });
                                },
                            );
                        }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        sx={{ marginLeft: 'auto' }}
                    >
                        Remise intégrée
                    </Button>) : null}
                </Box>) : null,
                true,
                hasBlured,
                () => {
                    setModalRemiseOpen(true);
                },
                state.produitsVente,
                isInterne ? state.afficherLigneVente : false,
            ),
            ...totauxStructure(true, () => {}, undefined, false),
        ];
    }, [
        afficherBoutonTxRemise,
        dossiersDataSource,
        enqueueSnackbar,
        formContext,
        getProduitsStructure,
        hasBlured,
        searchByNumeroDossier,
        searchByNumeroPresta,
        state.afficherLigneVente,
        state.defaultNumeroDossier,
        state.defaultNumeroPresta,
        state.produitsVente,
        state.rubFacts,
        state.switchLigneVente,
    ]);

    /**
     * Affiche le bouton "Modifier taux remise".
     */
    useEffect(() => { // OKKKKKKK
        if (!fournisseurSelected)
            return;

        // ajouterLigneRSF(formContext, fournisseurSelected);
        if (fournisseurSelected.typeRemise?.code === 'RSF') {
            setAfficherBoutonTxRemise(true);
        } else {
            setAfficherBoutonTxRemise(false);
        }
    }, [fournisseurSelected]);


    const updateDossierOfMultiAnnonce = useUpdateDossierOfMultiAnnonce();

    /**
     * Formate le formulaire avant de mettre à jour les infos de la facture.
     * Si la mise à jour est un succès, alors l'utilisateur est envoyé vers
     * le récapitulatif.
     *
     * @param factureForm
     * @returns void
     */
    const handleSubmit = async function (factureForm: factureFormObject & { end?: boolean }) {


        console.log(factureForm)

        if (!factureachatid || !factureAchat || !dossierSelected || !dossierSelected.id) {
            return;
        }

        const lignesToPost = await updateDossierOfMultiAnnonce(
            factureForm, 
            factureAchat,
            dossierSelected.id            
        );

        console.log("fin du post",lignesToPost);

        // const resFactureAchat = await factureAchatApi.updateFactureAchatFactureAchatItem(
        //     factureachatid,
        //     factureToPost,
        // );

        resetForm(formContext);
        dispatch(selectFactureAchat({ id: +factureachatid }));

        if (factureForm.end) {
            const res = generateB2DPath('formmultirecap', {
                factureAchat: factureAchat,
                mediaObject,
            });

            if (res.status === 'OK') {
                navigate(res.path);
            } else {
                // TODO
            }
        } else {
            const res = generateB2DPath('formmultinextdossier', {
                factureAchat: factureAchat,
                mediaObject,
            });

            if (res.status === 'OK') {
                navigate(res.path);
            } else {
                console.log('error');
                // TODO
            }
        }
    };

    /**
     * Déclenché lors de la soumission du formulaire de la modal.
     * Siren exemple RSF Inclus : 987654321
     */
    const handleModalSubmit: SubmitHandler<{
        'facture-form-modal-txremise': string;
    }> = useCallback(
        (data: { 'facture-form-modal-txremise': string }) => {
            actionOnChangeTauxRemise(
                formContext,
                (produitConcernedByRsf) => {
                    const txRemise = data['facture-form-modal-txremise'];
                    updateProduitsOnRemise(txRemise, produitConcernedByRsf);
                    setHasBlured(true);
                    setModalRemiseOpen(false);
                },
                (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'error' });
                },
            );
        },
        [actionOnChangeTauxRemise, enqueueSnackbar, formContext, updateProduitsOnRemise],
    );

    /**
     * Infos dossier par N° de dossier / d'annonce
     *
     * Si un dossier est sélectionné, met à jour les informations.
     *
     */
    useEffect(() => {
        if (
            dossierSelected &&
            dossierSelected.numero &&
            `${dossierSelected.numero}` !== state.defaultNumeroDossier
        ) {
            updateFormWithDossier(formContext, dossierSelected);
        }
    }, [formContext, dossierSelected, updateFormWithDossier, state.defaultNumeroDossier]);

    /**
     * Met à jour les informations de ventes.
     */
    useEffect(() => {
        console.log('updateFormWithVentesAndEvents', dossierSelected);
        updateFormWithVentesAndEvents(
            formContext,
            dossierSelected || null,
            fournisseurSelected || null,
            venteSelected || null,
            ventesDataSource.status,
            false
        );

        // ajouterLigneRSF(formContext, fournisseurSelected);
    }, [
        formContext,
        dossierSelected,
        venteSelected,
        ventesDataSource.status,
        fournisseurSelected,
        updateFormWithVentesAndEvents,
        enqueueSnackbar,
    ]);

    /**
     * S'il n'y plus de dossier ou de fournisseur sélectionné,
     * alors on remplace le tableau de produits par la valeur par défaut.
     */
    // useEffect(() => {
    //     if (!dossierSelected) {
    //         replaceProduits(formContext, defaultProduits(state.defDomaine, false));
    //     }
    // }, [dossierSelected, formContext, state.defDomaine]);

    /**
     * Ajout des RubFacts généraux
     *
     * @see dispatch
     * @see rubFactsDataSource.data
     */
    useEffect(() => {
        const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

        dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
    }, [dispatch, rubFactsDataSource.data]);

    /**
     * Filtre sur les rubriques de facturation
     *
     * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
     *
     * @see state.defDomaine
     * @see state.rubFacts
     */
    useEffect(() => {
        dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

        if (state.defDomaine !== 0) {
            dispatch({
                type: ACTIONS.FILTER_RUB_FACTS,
                payload: state.defDomaine,
            });
        }
    }, [dispatch, rubFactsDataSource.data, state.defDomaine]);

    /**
     * Si on a une commande on met le domaine de la commande
     */
    useEffect(() => {
        if (commandeSelected?.idDomaine) {
            console.log("dossier de commande, set domaine")
            dispatch({
                type: ACTIONS.SET_DEF_DOMAINE,
                payload: commandeSelected?.idDomaine,
            });
        }
    }, [commandeSelected?.idDomaine, dispatch]);

    /**
     * Met à jour le domaine en fonction de l'achat
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

        updateDomaine(venteSelected);
    }, [venteSelected, updateDomaine, formContext]);

    /**
     * Select la facture de vente de la commande,
     * si le dossier n'a pas lui-même de facture de vente.
     *
     * On cherche dans la commande le dossier qui correspond au dossier sélectionné
     * pour s'assurer que la facture soit la bonne.
     *
     * E.g. :
     *     - Dossier sans facture : 1272353
     *     - Dossier avec facture : 802308
     */
    useEffect(() => {
        if (
            commandeSelected?.idFacture &&
            dossierSelected &&
            dossierSelected.id &&
            !dossierSelected.facture
        ) {
            const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                if (!dossierSelected.id) return false;
                return dossier.includes(dossierSelected.id.toString());
            });

            if (
                matchedDossier &&
                ventesDataSource.status !== 'loading' &&
                (!venteSelected || commandeSelected?.idFacture !== venteSelected.id)
            ) {
                console.log('je vais chercher la vente');
                dispatch(selectFactureVente({ id: commandeSelected?.idFacture }));
            } else {
                // console.log('Aucun dossier de la commande ne correspond au dossier sélectionné.');
                if (domaine !== commandeSelected.idDomaine) {
                    console.log('je set le domaine');
                    dispatch({
                        type: ACTIONS.SET_DEF_DOMAINE,
                        payload: commandeSelected.idDomaine, //TODO: Voir avec Théo si ça semble logique (l'autre SET DEF DOMAINE fait la même chose au final non ?)
                    });
                }
            }
        }
    }, [
        commandeSelected?.dossiers,
        commandeSelected?.idDomaine,
        commandeSelected?.idFacture,
        dispatch,
        domaine,
        dossierSelected,
        venteSelected,
        ventesDataSource.status,
    ]);

    /**
     * Ajout des lignes d'achat correspondant à la vente
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-54
     *
     * Si une facture de vente est trouvée :
     * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
     * la propriété 'affiche' est à false.
     *
     * @see venteSelected
     * @see formContext
     * @see addProduit
     * @see state.rubFacts
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect
        if( venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate ){
            console.log("vente")
            setLastVenteUpdate(venteSelected.id);
            syncAchatProduitsWithVente(venteSelected, formContext, state.rubFacts, dossierSelected, false);
        }
    }, [dossierSelected, formContext, state.rubFacts, syncAchatProduitsWithVente, venteSelected]);

    const modalFactureFormStructure: FormStructure[] = useMemo(() => {
        return [
            {
                type: 'number',
                name: 'facture-form-modal-txremise',
                inputlabel: 'Taux de remise',
                autoFocus: true,
                placeholder: '0',
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },

                defaultValue: '20',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChangeInput: (
                    event: React.SyntheticEvent<Element, Event>,
                    input: {
                        base: string;
                        name: string;
                        path: string;
                        objectPath: string;
                    },
                    formContext: ExtandedFormContext,
                ) => {
                    const value: string = formContext.getValue(input.path);
                    setRsfInclusTxremise(value);
                },
                onPaste: (event: React.ClipboardEvent) => {
                    event.clipboardData.getData('text').match(/\D/g) && event.preventDefault();
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                },
                rules: {
                    required: 'Taux de remise manquant',
                },
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
            },
        ];
    }, []);

    return !factureAchat || !fournisseurSelected || !dossierSelected || dossiers.length === 0 || lignes.length === 0 ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <>
            <Modalizer
                open={modalRemiseOpen}
                onClose={() => {
                    setModalRemiseOpen(false);
                }}
            >
                <Box>
                    <FlexyForm
                        formObject={{
                            'facture-form-modal-txremise': rsfInclusTxremise,
                        }}
                        formStructure={modalFactureFormStructure}
                        onSubmit={handleModalSubmit}
                        submitButton={{
                            render: ({ isSubmitting }, innerHandleSubmit: () => void) => (
                                <Box display="flex" flexDirection="row" justifyContent="end">
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        style={{ marginTop: '16px', marginBottom: '16px' }}
                                    >
                                        Appliquer la remise
                                    </Button>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Modalizer>
            {factureAchat.idEntiteFacturante === fournisseurSelected.id ? (
                <EnteteFacture
                    factureAchat={factureAchat}
                    fournisseur={fournisseurSelected}
                    showListeDossier={true}
                    dossiers={dossiers}
                />
            ) : null}
            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitting }) => (
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button
                                color="error"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<ClearIcon />}
                                onClick={() => {
                                    onCancel();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Annuler
                            </Button>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<CheckIcon />}
                                onClick={() => {
                                    formContext.handleSubmit((factureForm) => {
                                        factureForm.end = true;
                                        handleSubmit(factureForm as factureFormObject);
                                    })();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Valider modification(s)
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};
export default EditDossierSaisieMultiple;
