import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    CivilitesApi,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

export { type TiersConfigurationParameters };

export type CivilityApiObject = any & Record<string, unknown>;

export type Civilities = CivilityApiObject;

export const CIVILITIES_SLICE_NAME = 'civilities';

const mapperCivilities = function (civilities: CivilityApiObject) {
    return {
        ...civilities,
    } as Civilities;
};

export function createCivilitiesSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CivilitesApi(conf);

    const fetchCivilitiesCollection: FetchCollectionData<CivilityApiObject> = ({
        pagination,
        xIdSociete,
    }) => {
        return api
            .getListeCivilitesTblParametreReferencesCollection(
                xIdSociete,
                undefined,
                pagination.page + 1,
            )
            .then((res) => {
                return res.data;
            })
            .catch((error) => {}) as Promise<ApiCollectionResponse<CivilityApiObject>>;
    };

    const fetchCivilitiesItem: FetchItemDataType<CivilityApiObject> = ({
        pagination,
        xIdSociete,
    }) => {
        return new Promise(() => {});
    };

    const { slice: civilitiesSlice, dataSourcesThunks: civilitiesDataSourcesThunks } =
        SliceFactory.createSlice<CivilityApiObject, Civilities>(
            CIVILITIES_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchCivilitiesCollection,
            fetchCivilitiesItem,
            mapperCivilities,
        );

    return { civilitiesSlice, civilitiesDataSourcesThunks };
}
