import { UseFormReturn, FieldValues } from 'react-hook-form';
import { ligneProduit, tableauProduits } from '../types';

export type pricelineType = 'ht' | 'tva' | 'txtva' | 'ttc';

export type pricelineParameters = {
    ht: number;
    tva: number;
    txtva: number;
    ttc: number;
};

export type priceArray = pricelineParameters[];

// Pour HT
export const getHT_TVA_TTC = (TVA: number, TTC: number) => TTC - TVA;
export const getHT_TxTVA_TTC = (txTVA: number, TTC: number) => (TTC * 100) / txTVA;
export const getHT_TVA_TxTVA = (TVA: number, txTVA: number) => (TVA * 100) / txTVA;

export function getHT(TVA?: number, txTVA?: number, TTC?: number): number | undefined {
    switch ((TVA && txTVA) || (txTVA && TTC) || (TVA && TTC)) {
        case TVA && txTVA:
            if (TVA !== undefined && txTVA !== undefined) {
                return getHT_TVA_TxTVA(TVA, txTVA);
            }
            break;
        case txTVA && TTC:
            if (txTVA !== undefined && TTC !== undefined) {
                return getHT_TxTVA_TTC(txTVA, TTC);
            }
            break;
        case TVA && TTC:
            if (TVA !== undefined && TTC !== undefined) {
                return getHT_TVA_TTC(TVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }
}

// Pour TVA
export const getTVA_HT_TxTVA = (HT: number, txTVA: number) => HT * (txTVA / 100);
export const getTVA_TxTVA_TTC = (txTVA: number, TTC: number) => TTC - TTC / (1 + txTVA / 100);
export const getTVA_HT_TTC = (HT: number, TTC: number) => TTC - HT;

export function getTVA(HT?: number, txTVA?: number, TTC?: number): number | undefined {
    switch ((HT && txTVA) || (HT && TTC) || (txTVA && TTC)) {
        case HT && txTVA:
            if (HT !== undefined && txTVA !== undefined) {
                return getTVA_HT_TxTVA(HT, txTVA);
            }
            break;
        case HT && TTC:
            if (HT !== undefined && TTC !== undefined) {
                return getTVA_HT_TTC(HT, TTC);
            }
            break;
        case txTVA && TTC:
            if (txTVA !== undefined && TTC !== undefined) {
                return getTVA_TxTVA_TTC(txTVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }
}

// Pour Taux TVA
export const getTxTVA_HT_TVA = (HT: number, TVA: number) => (TVA / HT) * 100;
export const getTxTVA_TVA_TTC = (TVA: number, TTC: number) => (TVA / getHT_TVA_TTC(TVA, TTC)) * 100;
export const getTxTVA_HT_TTC = (HT: number, TTC: number) => (TTC / HT - 1) * 100;

export function getTxTVA(HT?: number, TVA?: number, TTC?: number): number | undefined {
    switch ((HT && TVA) || (HT && TTC) || (TVA && TTC)) {
        case HT && TVA:
            if (HT !== undefined && TVA !== undefined) {
                return getTxTVA_HT_TVA(HT, TVA);
            }
            break;
        case HT && TTC:
            if (HT !== undefined && TTC !== undefined) {
                return getTxTVA_HT_TTC(HT, TTC);
            }
            break;
        case TVA && TTC:
            if (TVA !== undefined && TTC !== undefined) {
                return getTxTVA_TVA_TTC(TVA, TTC);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }
}

// Pour TTC
export const getTTC_HT_TVA = (HT: number, TVA: number) => HT + TVA;
export const getTTC_HT_TxTVA = (HT: number, txTVA: number) => HT * (1 + txTVA / 100);
export const getTTC_TVA_TxTVA = (TVA: number, txTVA: number) => TVA + getHT_TVA_TxTVA(TVA, txTVA);

export function getTTC(HT?: number, TVA?: number, txTVA?: number): number | undefined {
    switch ((TVA && txTVA) || (HT && txTVA) || (HT && TVA)) {
        case TVA && txTVA:
            if (TVA !== undefined && txTVA !== undefined) {
                return getTTC_TVA_TxTVA(TVA, txTVA);
            }
            break;
        case HT && txTVA:
            if (HT !== undefined && txTVA !== undefined) {
                return getTTC_HT_TxTVA(HT, txTVA);
            }
            break;
        case HT && TVA:
            if (HT !== undefined && TVA !== undefined) {
                return getTTC_HT_TVA(HT, TVA);
            }
            break;
        default:
            // Au minimum, deux des trois valeurs doivent être remplies
            break;
    }
}

export function calculator(input: Partial<pricelineParameters>): pricelineParameters {
    let res: pricelineParameters = {
        ht: input.ht || 0,
        txtva: input.txtva || 0,
        tva: 0,
        ttc: 0,
    };

    res.tva = getTVA_HT_TxTVA(res.ht, res.txtva);
    res.ttc = getTTC_HT_TxTVA(res.ht, res.txtva);

    return res;
}

export const somme = (array: priceArray) => {
    return array.reduce(
        (total, line) => ({
            ht:
                typeof total.ht === 'number' && typeof line.ht === 'number'
                    ? total.ht + line.ht
                    : total.ht,
            tva:
                typeof total.tva === 'number' && typeof line.tva === 'number'
                    ? total.tva + line.tva
                    : total.tva,
            txtva:
                typeof total.txtva === 'number' && typeof line.txtva === 'number'
                    ? total.txtva + line.txtva
                    : total.txtva,
            ttc:
                typeof total.ttc === 'number' && typeof line.ttc === 'number'
                    ? total.ttc + line.ttc
                    : total.ttc,
        }),
        { ht: 0, tva: 0, txtva: 0, ttc: 0 },
    );
};

export function arrondi(val: number | undefined) {
    let res: number | undefined = undefined;

    if (val) {
        res = parseFloat(val.toFixed(2));
    }

    return res;
}

export const roundTo = function (num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
};

export const produitsToSomme: (
    produits: tableauProduits,
    inverse?: boolean,
) => {
    ht: string;
    tva: string;
    txtva: number;
    ttc: string;
} = (produits, inverse) => {
    const sommeProduits = somme(
        produits
            .map((produit) => {
                return { ...produit, ...calculatorProduit };
            })
            .map((produit) => {
                const codeRubrique = produit.rubriqueFacturation
                    ? produit.rubriqueFacturation.id
                    : 1;
                return {
                    ht: roundTo(
                        Number(codeRubrique === 99 ? -(produit.ht || 0) : produit.ht || 0),
                        3,
                    ),
                    tva: roundTo(
                        Number(codeRubrique === 99 ? -(produit.tva || 0) : produit.tva || 0),
                        3,
                    ),
                    txtva: roundTo(Number(produit.txtva || 0), 3),
                    ttc: roundTo(
                        Number(codeRubrique === 99 ? -(produit.ttc || 0) : produit.ttc || 0),
                        3,
                    ),
                } as pricelineParameters;
            }),
    );


    return {
        ht: roundTo(inverse === true ? -sommeProduits.ht : sommeProduits.ht, 2).toFixed(2),
        tva: roundTo(inverse === true ? -sommeProduits.tva : sommeProduits.tva, 2).toFixed(2),
        txtva: roundTo(sommeProduits.txtva, 2),
        ttc: roundTo(inverse === true ? -sommeProduits.ttc : sommeProduits.ttc, 2).toFixed(2),
    };
};

export const compareProduits = function (a: Partial<ligneProduit>, b: Partial<ligneProduit>) {
    if (a.rubriqueFacturation?.id === 1) {
        return -1;
    }
    if (b.rubriqueFacturation?.id === 1) {
        return 1;
    }
    if (a.rubriqueFacturation?.id === 99) {
        return -1;
    }
    if (b.rubriqueFacturation?.id === 99) {
        return 1;
    }

    return 0;
};

export const calculatorProduit = function (produit: Partial<ligneProduit>) {
    return calculator({
        ht: (produit.ht || 0) * 1,
        tva: (produit.tva ? +produit.tva : 0) * 1,
        txtva: (produit.txtva || 0) * 1,
        ttc: (produit.ttc ? +produit.ttc : 0) * 1,
    });
};

export const updateFormTotaux = (
    formContext: UseFormReturn<FieldValues, any>,
    disable: boolean,
) => {
    if (disable) return;
    let produits = formContext.getValues('produits');

    if (!produits) {
        return;
    }
    const avoir: boolean = formContext.getValues('avoir') || false;


    
    const totalvaleurs = produitsToSomme(produits, avoir);
    console.log("avoir",avoir,totalvaleurs);

    // Modif des Totaux
    formContext.setValue('total_ht', totalvaleurs.ht);
    formContext.setValue('total_tva', totalvaleurs.tva);
    formContext.setValue('total_txtva', totalvaleurs.txtva);
    formContext.setValue('total_ttc', totalvaleurs.ttc);
};
