import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, type TypographyProps } from '@mui/material';

export type FlexyFormLabelProps = TypographyProps & {
    children: React.ReactNode;
};

/**
 * Composant permettant d'afficher un label personnalisé
 *
 *  ## Utilisation
 *
 * ```jsx
 * <FlexyFormLabel>
 *      mon label
 * </FlexyFormLabel>
 * ```
 */
const FlexyFormLabel: React.FunctionComponent<FlexyFormLabelProps> = styled(
    (props: FlexyFormLabelProps) => <Typography variant="h6" {...props} />,
)(() => ({
    marginBottom: '5px',
    marginTop: '15px',
    display: 'block',
}));

export default FlexyFormLabel;
