import { MessagesTypes } from './ConfirmationModal';

export const defaultModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir effectuer cette action ?',
    validation: 'Oui',
    annulation: 'Non',
};

export const multiAnnonceModalMessages: MessagesTypes = {
    question: 'Êtes-vous sûr de vouloir passer en mode multi-annonces ?',
    detail: {
        message: 'Le formulaire sera réinitialisé !',
        severity: 'warning',
    },
    validation: 'Oui, passer en multi-annonces',
    annulation: 'Non, rester en annonce unique',
};

export const removeProductsModalMessages: MessagesTypes = {
    question: "Au moins un produit n'est pas entièrement rempli",
    detail: {
        message: 'Voulez-vous supprimer ce(s) produit(s) et valider ainsi ?',
        severity: 'info',
    },
    validation: 'Oui, supprimer',
    annulation: 'Non, annuler',
};

export const refuseFactureAchatModalMessages: MessagesTypes = {
    question: "Êtes-vous sûr de vouloir refuser cette facture d'achat ?",
    detail: {
        message: 'La facture ne sera pas clôturée et vous retournerez à la liste des PDFs.',
        severity: 'warning',
    },
    validation: 'Oui, refuser la facture',
    annulation: 'Non, rester sur le récapitulatif',
};

export const repriseMultiAnnonceModalMessages: MessagesTypes = {
    question: 'Facture non clôturée',
    detail: {
        message:
            "Cette facture est de type multi annonces, et n'a pas été clôturée, voulez vous la reprendre ?",
        severity: 'info',
    },
    validation: 'Oui, Reprendre la saisie',
    annulation: 'Non',
};

export const cancelAddDossier: MessagesTypes = {
    question: 'Formulaire incomplet',
    detail: {
        message:
            'Le formulaire est incomplet, voulez vous annuler la saisie de ce dossier et passer au récapitulatif ?',
        severity: 'info',
    },
    validation: 'Passer au récapitulatif',
    annulation: 'Continuer la saisie',
};

export const pauseSaisieFacture: MessagesTypes = {
    question:
        'Le formulaire est incomplet ou invalide, êtes-vous sûr de vouloir mettre en pause la saisie ?',
    detail: {
        message:
            'Vos modifications ne seront pas enregistrées, tant que la formulaire ne sera pas valide.',
        severity: 'warning',
    },
    validation: 'Oui, annuler les modifications de ce dossier',
    annulation: 'Non, continuer la saisie',
};
