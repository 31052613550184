import React, { useCallback, useEffect, useState } from 'react';

import {
    CenterCircularProgress,
    PdfViewer,
    footerHeight,
    TopbarHeight,
} from '@europrocurement/flexy-components';
import { Box, Card, Grid, Typography } from '@mui/material';
import { factureFormObject, ligneProduit, tableauProduits } from './components/forms/types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
    MediaObjectSelector,
    selectMediaObject,
    factureAchatApi,
    selectFactureAchat,
    FactureachatSelector,
    DossierSelector,
    selectAutocompleteDossier,
    FournisseurSelector,
    selectFournisseurAc,
    FactureVenteSelector,
    selectFactureVente,
    customizerSelector,
    factureAchatLigneApi,
} from '../../redux/RootStore';
import { DOSSIERS_SLICE_NAME, Dossier, FACTURES_ACHATS_SLICE_NAME, FactureAchat, Fournisseur, MediaObject } from '@europrocurement/l2d-domain';
import type {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatFactureAchatWrite,
    FactureAchatLigneDetailFactureAchatRead,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { produitsToSomme } from './components/forms/functions/calculsProduits';
import {
    FactureVente,
    FACTURE_VENTE_SLICE_NAME,
} from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import EditFacture from './components/forms/EditFacture';
import moment from 'moment';
import CodeRejetsChipList, { CodeRejetsChipType } from './components/widgets/CodeRejetsChipList';
import { factureAchatToFacureFormObject } from './components/forms/functions/dataTransformers';
import { useGetDossiers, useGetLignes, useGetRejets } from './components/forms/functions/dataHooks';

export type ModifierFacturesProps = {
    type: 'formfactureachat';
};

const ModifierFactures: React.FunctionComponent<ModifierFacturesProps> = ({ type }) => {
    const { mediaobjectid, factureachatid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const dossiers: DataSource<Dossier> = useSelector(DossierSelector).autocompleteDossiers;
    const fournisseur: DataSource<Fournisseur> =
        useSelector(FournisseurSelector).autocompleteFactures;
    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;
    const mediaObject: MediaObject = useSelector(MediaObjectSelector).main.selected;
    const factureAchatDataSource: FactureAchat = useSelector(FactureachatSelector).main;
    const factureAchat: FactureAchat = useSelector(FactureachatSelector).main.selected;
    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;

    const [dossiersofFacture, setdossiersOfFacture] = useState<
        FactureAchatFactureAchatReadDossierDossiersInner[]
    >([]);
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [rejets, setRejets] = useState<CodeRejetsChipType[]>([]);

    const [dossiersofFactureStatus, setdossiersOfFactureStatus] =
        useState<EuroprocApiResponseStatus>('idle');
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');

    const dispatch = useDispatch();



    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    useEffect(() => {
        if (factureachatid &&  (!factureAchat || (factureachatid !== `${factureAchat.id}`)) ) {
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            })
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid })); // A aller chercher dans le match.params (react-router-dom)
        }
    }, [dispatch, mediaobjectid]);

    useEffect(() => {
        if (!factureAchat || !factureAchat.idEntiteFacturante) return;
        if (!fournisseur.selected || fournisseur.selected.id !== factureAchat.idEntiteFacturante) {
            if (factureAchat.idEntiteFacturante) {
                if (fournisseur.status !== 'loading') {
                    dispatch(selectFournisseurAc({ id: factureAchat.idEntiteFacturante }));
                }
            }
        }
    }, [dispatch, factureAchat, fournisseur.selected, fournisseur.status]);

    useEffect(() => {
        if (dossiers.status !== 'loading' && lignes && lignes[0]) {
            if (!dossiers.selected || dossiers.selected.id !== lignes[0].idDossier) {
                dispatch(
                    selectAutocompleteDossier({
                        id: `${lignes[0].idDossier}`,
                    }),
                );
            }
        }
    }, [dispatch, dossiers.selected, dossiers.status, lignes]);

    useEffect(() => {
        let idFactureVente: number;
        if (dossiers.selected?.facture && dossiers.selected.facture) {
            idFactureVente = +dossiers.selected.facture.split('/')[3];
            if (ventesDataSource.status !== 'loading') {
                if (!ventesDataSource.selected || ventesDataSource.selected.id !== idFactureVente) {
                    dispatch(selectFactureVente({ id: idFactureVente }));
                }
            }
        } else {
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
        }
    }, [dispatch, dossiers.selected?.facture, ventesDataSource.selected, ventesDataSource.status]);

    const getDossiers = useGetDossiers();
    const getRejets = useGetRejets();
    const getLignes = useGetLignes();

    useEffect(() => {
        if (
            !factureachatid ||
            dossiersofFactureStatus === 'loading' ||
            dossiersofFactureStatus === 'succeeded'
        ) {
            return;
        }

        setdossiersOfFactureStatus('loading');
        getDossiers(+factureachatid, factureAchatApi).then((data) => {
            setdossiersOfFactureStatus('succeeded');
            setdossiersOfFacture(data);
        });
    }, [dossiersofFactureStatus, factureachatid, getDossiers]);

    useEffect(() => {
        if (
            dossiersofFacture &&
            factureachatid &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded' &&
            rejetsStatus !== 'loading' &&
            rejetsStatus !== 'succeeded'
        ) {
            const ids = dossiersofFacture
                .map((item: FactureAchatFactureAchatReadDossierDossiersInner) => item.idDossier)
                .filter((id: number | undefined) => id !== undefined);

            setLignesStatus('loading');
            getLignes(+factureachatid, ids as number[], factureAchatLigneApi).then((data) => {
                setLignesStatus('succeeded');
                setLignes(data);
            });

            setRejetsStatus('loading');
            getRejets(+factureachatid, ids as number[], factureAchatApi).then((data) => {
                setRejetsStatus('succeeded');
                setRejets(data);
            });
            // setFacture(factureAchatToFacureFormObject(factureAchat));
        }
    }, [
        dossiersofFacture,
        factureachatid,
        getDossiers,
        getLignes,
        getRejets,
        lignesStatus,
        rejetsStatus,
    ]);

    const onSubmitFacture: (facture: FactureAchatFactureAchatWrite) => void = (
        facture: FactureAchatFactureAchatWrite,
    ) => {
        enqueueSnackbar(<Typography>Edition a implementer 🚀</Typography>, {
            variant: 'info',
        });
    };

    const readyToDisplay = useCallback(() => {

        if (!factureAchat) {
            return false;
        }

        if (!fournisseur.selected) {
            return false;
        }

        if (!dossiers.selected) return false;

        if (factureAchat.idEntiteFacturante && factureAchat.idEntiteFacturante !== 0) {
            if (fournisseur.selected.id !== factureAchat.idEntiteFacturante) {
                return false;
            }
        }

        if (lignes && lignes.length>0) {
            let dossiersToLoad = _.uniq(lignes.map((ligne) => ligne.idDossier));


            if (dossiersToLoad.length > 1) {
                console.log('Multi dossier a implementer');
            } else if (dossiersToLoad.length === 1) {
                if (dossiers.selected.id !== dossiersToLoad[0]) return false;

                let idFactureVente: number;
                if (dossiers.selected?.facture && dossiers.selected.facture) {
                    idFactureVente = +dossiers.selected.facture.split('/')[3];
                    if (!ventesDataSource.selected) return false;
                    if (ventesDataSource.selected.id !== idFactureVente) return false;
                }
            }
        }

        return true;
    }, [factureAchat, fournisseur.selected, lignes, dossiers.selected, ventesDataSource.selected]);

    return (
        <>
            {!mediaObject || !factureAchat || !dossiers.selected || !fournisseur.selected ? (
                <>
                    {/* {!mediaObject ? (<h1>pas de mo</h1>) : null }
                {!factureAchat ? (<h1>pas de facture</h1>) : null }
                {!dossiers.selected? (<h1>pas de dossier</h1>) : null }
                {!fournisseur.selected? (<h1>pas de fournisseur</h1>) : null } */}
                    <CenterCircularProgress sx={{ height: '500px' }} />
                </>
            ) : (
                <Grid container>
                    <Grid item lg={6} sm={12}>
                        <Card
                            id="cardPdfReader"
                            sx={{
                                height: `calc(100vh -${footerHeight} - ${TopbarHeight}  )`,
                            }}
                        >
                            <PdfViewer pdfUrl={mediaObject?.contentUrl}></PdfViewer>
                        </Card>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                        <Card
                            sx={{
                                gap: '15px',
                                padding: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {factureAchat && factureAchat.createdAt ? (
                                <>
                                    <Typography variant="h5" fontWeight="bold">
                                        {`Facture saisie le : ${new Date(
                                            factureAchat.createdAt,
                                        ).toLocaleDateString()}`}
                                    </Typography>
                                </>
                            ) : null}
                            {factureAchat && factureAchat.createdBy ? (
                                <>
                                    <Typography variant="h5" fontWeight="bold">
                                        {`Facture saisie par : ${factureAchat.createdBy}`}
                                    </Typography>
                                </>
                            ) : null}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '15px',
                                    padding: '0px',
                                    alignItems: 'center',
                                }}
                            >
                                {factureAchat && rejets.length > 0 ? (
                                    <>
                                        <Typography variant="h5" fontWeight="bold">
                                            Codes rejets :{' '}
                                        </Typography>
                                        <CodeRejetsChipList rejets={rejets} full={true} />
                                    </>
                                ) : (
                                    <Typography variant="h5" fontWeight="bold">
                                        Pas de code rejet
                                    </Typography>
                                )}
                            </Box>
                        </Card>

                        <Card>
                            {/* <ReactJson src={factureAchat} /> */}
                            {readyToDisplay() ? (
                                <EditFacture
                                    facture={factureAchatToFacureFormObject(
                                        factureAchat,
                                        dossiers.selected,
                                        lignes,
                                        fournisseur.selected,
                                    )}
                                    onSubmit={onSubmitFacture}
                                    key={mediaObject?.contentUrl}
                                    resetProduct={false}
                                    resetForm={false}
                                    vente={ventesDataSource.selected}
                                />
                            ) : (
                                <CenterCircularProgress sx={{ height: '500px' }} />
                            )}
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ModifierFactures;
