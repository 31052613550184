import React from 'react';
import {
    ContactsApi,
    type CustomizerReducerType,
    type Prescripteur,
    type PrescripteursApi,
} from '@europrocurement/l2d-domain';
import { FlexyTabs } from '@europrocurement/flexy-components';
import ActivityAndTracking from './tabs/activityAndTracking/ActivityAndTracking';
import MainInformations from './tabs/MainInformations';
import LegalAnnouncments from './tabs/LegalAnnouncments';
import Formalities from './tabs/formalities/Formalities';
import Accounting from './tabs/Accounting';
import { ModalFunctions } from './PrescripteurDetail';
import { ContextActionType } from '@europrocurement/flexy-components/components/organisms/ContextActionsMenu/ContextActionsMenu';
import useContextActions from '@b2d/hooks/useContextActions';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import ContactForm from '../../forms/ContactForm';
import AddressForm from '../../forms/AddressForm';

export type PrescripteurInformationsCardProps = {
    prescripteur: Prescripteur;
    prescripteursApi: PrescripteursApi;
    contactsApi: ContactsApi;
    reloadPrescripteur: () => void;
    idSociete?: CustomizerReducerType['xIdSociete'];
    modalFunctions: ModalFunctions;
};

const PrescripteurInformationsCard: React.FunctionComponent<PrescripteurInformationsCardProps> =
    function ({
        prescripteur,
        prescripteursApi,
        contactsApi,
        idSociete,
        reloadPrescripteur,
        modalFunctions,
    }: PrescripteurInformationsCardProps) {
        const mainInformationsProps = {
            prescripteur,
            prescripteursApi,
            contactsApi,
            reloadPrescripteur,
            modalFunctions,
        };

        const addFormProps = {
            isCreate: true,
            prescriber: prescripteur,
            afterSubmit: reloadPrescripteur,
        };

        const { closeModal, openModal, setModalContent } = modalFunctions;

        const callModal = (modalContent: React.ReactElement) => {
            setModalContent(modalContent);
            openModal();
        };

        const addContact = () => callModal(<ContactForm {...addFormProps} />);

        const addAddress = () => callModal(<AddressForm {...addFormProps} />);

        // Component specific context actions

        const contextActions: ContextActionType[] = [
            {
                name: 'Ajouter contact',
                icon: <PersonAddIcon />,
                action: addContact,
            },
            {
                name: 'Ajouter adresse',
                icon: <AddHomeWorkIcon />,
                action: addAddress,
            },
        ];

        useContextActions(contextActions);

        if (!prescripteur.id) {
            return null;
        }

        return (
            <FlexyTabs
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                tabs={[
                    {
                        tabTitle: 'Activité & Suivi',
                        tabPanel: (
                            <ActivityAndTracking
                                prescripteur={prescripteur}
                                idSociete={idSociete}
                                prescripteursApi={prescripteursApi}
                                reloadPrescripteur={reloadPrescripteur}
                                modalFunctions={modalFunctions}
                            />
                        ),
                    },
                    {
                        tabTitle: 'Informations Générales',
                        tabPanel: (
                            <>
                                <MainInformations {...mainInformationsProps} />
                            </>
                        ),
                    },
                    {
                        tabTitle: 'Annonces Légales',
                        tabPanel: <LegalAnnouncments />,
                        disabled: true,
                    },
                    {
                        tabTitle: 'Formalités',
                        tabPanel: (
                            <Formalities
                                prescriberId={prescripteur.id}
                                prescribersApi={prescripteursApi}
                                modalFunctions={modalFunctions}
                            />
                        ),
                    },
                    {
                        tabTitle: 'Comptabilité',
                        tabPanel: <Accounting />,
                    },
                ]}
            />
        );
    };

export default PrescripteurInformationsCard;
