/* eslint-disable no-bitwise */
import { Avatar, SxProps } from '@mui/material';
import * as React from 'react';

function stringToColor(string: string): string {
    let hash = 0;
    let i: number;
    let color = '#';

    for (i = 0; i < string.length; i += 1) hash = string.charCodeAt(i) + ((hash << 5) - hash);
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

export const extractInitials = function (_firstName?: string, _lastName?: string): string {
    let firstname = _firstName?.trim();
    let lastname = _lastName?.trim();

    if (firstname?.includes(' '))
        firstname = firstname
            ?.split(' ')
            .map((str) => str[0])
            .join('');
    if (lastname && lastname?.includes(' '))
        lastname = lastname
            ?.split(' ')
            .map((str) => str[0])
            .join('');

    if (firstname && !lastname) return (firstname[0] + (firstname[1] || '')).toUpperCase();
    if (firstname && lastname) return (firstname[0] + (lastname[0] || '')).toUpperCase();
    if (!firstname && lastname) return (lastname[0] + (lastname[1] || '')).toUpperCase();
    return '';
};

function stringAvatar(_firstName?: string, _lastName?: string, sx?: SxProps) {
    const children: string = extractInitials(_firstName, _lastName);

    return {
        sx: { ...sx, bgcolor: stringToColor(`${_firstName} ${_lastName}`) },
        children,
    };
}

export type CustomAvatarProps = {
    firstName?: string;
    lastName?: string;
    sx?: SxProps;
};

const CustomAvatar: React.FunctionComponent<CustomAvatarProps> = function ({
    firstName,
    lastName,
    sx,
}: CustomAvatarProps) {
    return <Avatar {...stringAvatar(firstName, lastName, sx)} data-testid="CustomAvatar" />;
};

CustomAvatar.defaultProps = {
    firstName: undefined,
    lastName: undefined,
};

export default CustomAvatar;
