import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
    CenterCircularProgress,
    PdfViewer,
    footerHeight,
    TopbarHeight,
} from '@europrocurement/flexy-components';
import { Box, Card, Grid, Pagination, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    MediaObjectSelector,
    selectMediaObject,
    selectFactureAchat,
    FactureachatSelector,
    FournisseurSelector,
    selectFournisseur,
    factureAchatApi,
    factureAchatLigneApi,
} from '../../redux/RootStore';
import { FactureAchat, Fournisseur, MediaObject } from '@europrocurement/l2d-domain';
import type {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import EnteteFacture from './components/forms/saisieMultiple/EnteteFacture';
import { groupLinesByDossiers } from './components/forms/functions/dossierFonctions';
import DossierLinesAccordeon from './components/informationWidget/DossierLinesAccordeon';
import { useForm } from 'react-hook-form';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { totauxStructure } from './components/forms/formElements';
import { getTotaux } from './components/forms/functions/produitFunctions';
import { CodeRejetsChipType } from './components/widgets/CodeRejetsChipList';
import { useGetDossiers, useGetLignes, useGetRejets } from './components/forms/functions/dataHooks';

export type ConsulterMultiAnnonceProps = {
    type: 'formfactureachat';
};

const ConsulterMultiAnnonce: React.FunctionComponent<ConsulterMultiAnnonceProps> = ({ type }) => {
    const { mediaobjectid, factureachatid } = useParams();
    const mediaObject: MediaObject = useSelector(MediaObjectSelector).main.selected;
    const factureAchat: FactureAchat = useSelector(FactureachatSelector).main.selected;
    const fournisseur: DataSource<Fournisseur> = useSelector(FournisseurSelector).main.selected;

    const [dossiersofFacture, setdossiersOfFacture] = useState<
        FactureAchatFactureAchatReadDossierDossiersInner[]
    >([]);
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [rejets, setRejets] = useState<CodeRejetsChipType[]>([]);

    const [dossiersofFactureStatus, setdossiersOfFactureStatus] =
        useState<EuroprocApiResponseStatus>('idle');
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [grouped, setGrouped] = useState<{
        [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
    }>({});

    const [page, setPage] = useState<number>(1);
    const [itemPerPage, setItemPerPage] = useState<number>(5);

    const dispatch = useDispatch();

    const getDossiers = useGetDossiers();
    const getRejets = useGetRejets();
    const getLignes = useGetLignes();

    useEffect(() => {
        if (
            !factureAchat ||
            !factureAchat.id ||
            dossiersofFactureStatus === 'loading' ||
            dossiersofFactureStatus === 'succeeded'
        ) {
            return;
        }

        setdossiersOfFactureStatus('loading');
        getDossiers(factureAchat.id, factureAchatApi).then((data) => {
            console.log(data);
            setdossiersOfFactureStatus('succeeded');
            setdossiersOfFacture(data);
        });
    }, [dossiersofFactureStatus, factureAchat, getDossiers]);

    useEffect(() => {
        if (
            dossiersofFacture.length > 0 &&
            factureAchat &&
            factureAchat.id &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded' &&
            rejetsStatus !== 'loading' &&
            rejetsStatus !== 'succeeded'
        ) {
            const ids = dossiersofFacture
                .map((item: FactureAchatFactureAchatReadDossierDossiersInner) => item.idDossier)
                .filter((id: number | undefined) => id !== undefined)
                .slice(itemPerPage * (page - 1), itemPerPage * page);

            setLignesStatus('loading');
            getLignes(factureAchat.id, ids as number[], factureAchatLigneApi).then((data) => {
                setLignesStatus('succeeded');
                setLignes(data);
            });

            setRejetsStatus('loading');
            getRejets(factureAchat.id, ids as number[], factureAchatApi).then((data) => {
                setRejetsStatus('succeeded');
                setRejets(data);
            });
            // setFacture(factureAchatToFacureFormObject(factureAchat));
        }
    }, [
        dossiersofFacture,
        factureAchat,
        getLignes,
        getRejets,
        itemPerPage,
        lignesStatus,
        page,
        rejetsStatus,
    ]);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid })); // A aller chercher dans le match.params (react-router-dom)
        }
    }, [dispatch, mediaobjectid]);

    useEffect(() => {
        if (!factureAchat || lignes.length === 0) return;

        setGrouped(groupLinesByDossiers(lignes));

        if (factureAchat.idEntiteFacturante && !fournisseur) {
            dispatch(selectFournisseur({ id: +factureAchat.idEntiteFacturante }));
        }
    }, [dispatch, factureAchat, fournisseur, lignes]);

    const changePage = useCallback((page: number) => {
        setLignes([]);
        setRejets([]);
        setLignesStatus('idle');
        setRejetsStatus('idle');
        setPage(page);
    }, []);

    const factureFormStructure: FormStructure[] = useMemo(() => {
        return [...totauxStructure(true, () => {}, undefined, false)];
    }, []);

    const formContext = useForm({
        mode: 'onTouched',
    });

    useEffect(() => {
        if (!factureAchat) return;

        const totaux = getTotaux(factureAchat);
        if(factureAchat.avoir === true){
            formContext.setValue('total_ht', (-totaux.ht).toFixed(2));
            formContext.setValue('total_tva', (-totaux.tva).toFixed(2));
            formContext.setValue('total_ttc', (-totaux.ttc).toFixed(2));
        }else{
            formContext.setValue('total_ht', (totaux.ht).toFixed(2));
            formContext.setValue('total_tva', (totaux.tva).toFixed(2));
            formContext.setValue('total_ttc', (totaux.ttc).toFixed(2));
        }
    }, [factureAchat, formContext]);

    return (
        <>
            {!mediaObject || !factureAchat || !fournisseur ? (
                <>
                    <CenterCircularProgress sx={{ height: '500px' }} />
                </>
            ) : (
                <Grid container>
                    <Grid item lg={6} sm={12}>
                        <Card
                            id="cardPdfReader"
                            sx={{
                                height: `calc(100vh - ${footerHeight} - ${TopbarHeight})`,
                            }}
                        >
                            <PdfViewer pdfUrl={mediaObject?.contentUrl}></PdfViewer>
                        </Card>
                    </Grid>
                    <Grid item lg={6} sm={12}>
                        <Card>
                            <EnteteFacture
                                factureAchat={factureAchat}
                                fournisseur={fournisseur}
                                showListeDossier={false}
                            />
                            {dossiersofFactureStatus === 'loading' ||
                            rejetsStatus === 'loading' ||
                            lignesStatus === 'loading' ? (
                                <CenterCircularProgress sx={{ height: '500px' }} />
                            ) : null}

                            {Object.keys(grouped).map((libelle) => (
                                <DossierLinesAccordeon
                                    key={libelle}
                                    lines={grouped[libelle]}
                                    rejets={rejets}
                                    displayName={libelle}
                                />
                            ))}

                            <Stack alignItems="center" sx={{ marginTop: '15px' }}>
                                <Pagination
                                    page={page}
                                    count={Math.ceil(dossiersofFacture.length / itemPerPage)}
                                    onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                                        changePage(page);
                                    }}
                                />
                            </Stack>

                            <FlexyForm
                                formObject={{}}
                                formContext={formContext}
                                formStructure={factureFormStructure}
                                onSubmit={() => {}}
                                submitButton={{
                                    displayed: false,
                                }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ConsulterMultiAnnonce;
