import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/icons-material';
import { Commande, Dossier, FactureAchat, Fournisseur, RubriqueFacturation, RubriqueFacturationApiObject } from '@europrocurement/l2d-domain';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Box, InputAdornment, Typography } from '@mui/material';

import { CommandeSelector, DossierSelector, FactureFormSelector, FactureVenteSelector, FactureachatSelector, FournisseurSelector, RootStateType, RubriqueFacturationSelector, selectFactureVente } from '../../../../redux/RootStore';
import { FactureVente, FactureVenteApiObject } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';

import {
    arrondi,
    getTVA_HT_TxTVA,
    getTTC_HT_TxTVA,
    calculatorProduit,
    updateFormTotaux,
} from './functions/calculsProduits';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import {
    enteteFactureStructure,
    getDossierStructure,
    getFournisseurStructure,
    totauxStructure,
} from './formElements';
import { type ligneProduit, type factureFormObject } from './types';
import { useUpdateDomaine } from './functions/venteFunctions';

const mapOneProduitsVente = function (produitVente: TblFligneFactureVenteRead) {
    const res: ligneProduit = {
        idLigneVente: produitVente.id,
        libelle: produitVente.libelle ? produitVente.libelle : undefined,
        ht: produitVente.montant ? produitVente.montant : undefined,
        tva:
            produitVente.montant && produitVente.tauxTva
                ? arrondi(getTVA_HT_TxTVA(produitVente.montant, produitVente.tauxTva))
                : undefined,
        ttc:
            produitVente.montant && produitVente.tauxTva
                ? arrondi(getTTC_HT_TxTVA(produitVente.montant, produitVente.tauxTva))
                : undefined,
    };

    if (produitVente.rubriqueFacturation && produitVente.rubriqueFacturation !== '') {
        res.rubriqueFacturation = {
            code: produitVente.rubriqueFacturation,
            id: -1,
        };
    }
    return res;
};

export type EditFactureProps = {
    facture: factureFormObject | Partial<factureFormObject>;
    onSubmit: (data: any) => void;
    resetProduct?: boolean;
    resetForm?: boolean;
    vente?: FactureVenteApiObject;
};

const EditFacture: React.FunctionComponent<EditFactureProps> = function ({
    facture,
    onSubmit,
    resetProduct,
    resetForm,
    vente,
}) {
    const kc = UseKeycloakService();

    const multiAnnonces = false;

    // Comportement normal ( check droits keycloak )
    const isInterne = kc.checkRole('realm:interne');
    // forcage interne
    // const isInterne = true
    // forcage externe
    // const isInterne = false

    const [blockTotaux, setBlockTotaux] = useState<boolean>(true);
    const [afficherLigneVente, setAfficherLigneVente] = useState<boolean>(true);
    const [switchAfficherLigneVente, setSwitchAfficherLigneVente] = useState<any>({
        label: '',
        sx: {
            display: 'none',
        },
    });

    const updateDomaine = useUpdateDomaine();

    const rubFacts: DataSource<RubriqueFacturationApiObject> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    const commandeSelected: Commande | undefined = useSelector(CommandeSelector).main.selected;
    const venteSelected: FactureVente | undefined = useSelector(FactureVenteSelector).main.selected;
    const dossierSelected: Dossier | undefined = useSelector(DossierSelector).main.selected;

    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;

    // Reducer instanciation (replace useStates)
    const dispatch = useDispatch();
    const state = useSelector(FactureFormSelector);

    // Initialisation du formulaire
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: facture,
    });

    /**
     * DEB - utils
     */
    // Met à jour les totaux
    const updateTotaux = useCallback(() => {
        updateFormTotaux(formContext, !blockTotaux);
    }, [blockTotaux, formContext]);

    useEffect(() => {
        if (vente) {
            setSwitchAfficherLigneVente({
                label: 'Afficher les informations de vente',
                defaultChecked: afficherLigneVente,
                onChange(event: React.ChangeEvent, checked: React.SetStateAction<boolean>) {
                    setAfficherLigneVente(checked);
                    // dispatch({ type: ACTIONS.AFFICHER_LIGNE_VENTE, payload: checked });
                },
            });
        } else {
            setSwitchAfficherLigneVente({
                label: '',
                sx: {
                    display: 'none',
                },
            });
        }
    }, [vente, afficherLigneVente]);

    const aCDossier: FormStructure = useMemo(
        () => ({
            type: 'text',
            name: 'numero_dossier',
            inputlabel: 'Numero de dossier',
            placeholder: '',
            disabled: true,
            xs: 12,
            sm: 12,
            md: 12,
            lg: 6,
        }),
        [],
    );

    const aCPresta: FormStructure = useMemo(
        () => ({
            type: 'text',
            name: 'numero_annonce',
            inputlabel: "Numero d'annonce",
            placeholder: '',
            disabled: true,
            xs: 12,
            sm: 12,
            md: 12,
            lg: 6,
        }),
        [],
    );

    const fillProduits = useCallback(
        (produit: Partial<ligneProduit>, index: number) => {
            const valeurs = calculatorProduit(produit);

            formContext.setValue(`produits.${index}.tva`, valeurs.tva.toFixed(4));
            formContext.setValue(`produits.${index}.ttc`, valeurs.ttc.toFixed(4));
        },
        [formContext],
    );

    /**
     * FIN - utils
     */

    /**
     * DEBUT - Callbacks
     */

    /**
     * Ajout des RubFacts généraux
     *
     * WIP ?
     *
     * @see dispatch
     * @see rubFacts.data
     */
    useEffect(() => {
        const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFacts.data;

        dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
    }, [dispatch, rubFacts.data]);

    /**
     * Filtre sur les rubriques de facturation
     *
     * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
     *
     * @see state.defDomaine
     * @see state.rubFacts
     */
    useEffect(() => {
        if (state.defDomaine === 0) {
            dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFacts.data });
        } else {
            dispatch({ type: ACTIONS.FILTER_RUB_FACTS, payload: state.defDomaine });
        }
    }, [dispatch, rubFacts.data, state.defDomaine]);

    /**
     * Si on a une commande on met le domaine de la commande
     */
    useEffect(() => {
        if (commandeSelected?.idDomaine) {
            dispatch({
                type: ACTIONS.SET_DEF_DOMAINE,
                payload: commandeSelected?.idDomaine,
            });
        }
    }, [commandeSelected?.idDomaine, dispatch]);

    /**
     * Met à jour le domaine en fonction de l'achat
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

        updateDomaine(venteSelected);
    }, [venteSelected, updateDomaine, formContext]);

    /**
     * Select la facture de vente de la commande,
     * si le dossier n'a pas lui-même de facture de vente.
     *
     * On cherche dans la commande le dossier qui correspond au dossier sélectionné
     * pour s'assurer que la facture soit la bonne.
     *
     * E.g. :
     *     - Dossier sans facture : 1272353
     *     - Dossier avec facture : 802308
     */
    useEffect(() => {
        if (
            commandeSelected?.idFacture &&
            dossierSelected &&
            dossierSelected.id &&
            !dossierSelected.facture
        ) {
            const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                if (!dossierSelected.id) return false;
                return dossier.includes(dossierSelected.id.toString());
            });

            if (
                matchedDossier &&
                ventesDataSource.status !== 'loading' &&
                (!venteSelected || commandeSelected?.idFacture !== venteSelected.id)
            ) {
                dispatch(selectFactureVente({ id: commandeSelected?.idFacture }));
            } else {
                // console.log('Aucun dossier de la commande ne correspond au dossier sélectionné.');
                if (domaine !== commandeSelected.idDomaine) {
                    dispatch({
                        type: ACTIONS.SET_DEF_DOMAINE,
                        payload: commandeSelected.idDomaine,
                    });
                }
            }
        }
    }, [
        commandeSelected?.dossiers,
        commandeSelected?.idDomaine,
        commandeSelected?.idFacture,
        dispatch,
        domaine,
        dossierSelected,
        venteSelected,
        ventesDataSource.status,
    ]);

    // const mapProduitsVente = useCallback((factureVente:FactureVenteApiObject | undefined)=>{

    // },[dispatch]);

    /**
     * FIN - useEffects
     */

    /**
     * FormStructure
     */

    /**
     * Fragment fournisseur du formulaire
     *
     * Affiche les champs Siren, Raison sociale, Type remise
     *
     * @param fournisseursDataSource
     * @param fournisseurDataSourcesThunks.autocompleteFactures.getData
     * @param state.defaultSiren
     */
    const fournisseurStructure: FormStructure[] = useMemo(
        () =>
            getFournisseurStructure({
                type: 'text',
                name: 'siren_fournisseur',
                inputlabel: 'Siren fournisseur',
                placeholder: '',
                disabled: true,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            }),
        [],
    );

    /**
     * Fragment dossier du formulaire
     *
     * Affiche les champs Recherche par numero de dossier, Recherche par numero d'annonce, Journal, Date de parution, Annonceur
     *
     * @param aCDossier
     * @param aCPresta
     */

    const dossierStructure: FormStructure[] = useMemo(
        () => getDossierStructure(true, aCDossier, aCPresta),
        [aCDossier, aCPresta],
    );

    /**
     * Fragment produits du formulaire
     *
     * Affiche les lignes produits (Rubrique, HT, Taux TVA, TVA, TTC), Ajouter un produit, Afficher les informations de vente
     *
     * @param ACTIONS.AFFICHER_LIGNE_VENTE
     * @param state.rubFacts
     * @param state.produitsVente
     * @param state.afficherLigneVente
     */
    const produitsStructure: FormStructure[] = useMemo(
        () => [
            {
                type: 'header',
                name: 'products_header',
                label: (
                    <>
                        <List sx={{ marginRight: '5px' }} />
                        Rubriques
                    </>
                ),
                switchs: [switchAfficherLigneVente],
            },
            {
                type: 'subformarray',
                name: 'produits',
                isArray: true,
                addBtnLabel: 'Ajouter un produit',
                addBtnTooltip: 'Ajouter un produit',
                minLength: facture.produits?.length || 1,
                maxLength: facture.produits?.length || 10,
                removeBtnTooltip: 'Supprimer le produit',
                displayAddButton: true,
                gridProps: {
                    rowSpacing: 0,
                    columnSpacing: 2,
                    columns: 10,
                },
                structure: [
                    {
                        // type:'number',
                        type: 'hidden',
                        name: 'idLigneVente',
                        inputlabel: 'idLigneVente',
                    },
                    {
                        type: 'select',
                        name: 'rubriqueFacturation',
                        inputlabel: 'Rubrique',
                        options: state.rubFacts,
                        getOptionValue: (item: RubriqueFacturation) => item.id,
                        getOptionLabel: (item: RubriqueFacturation) =>
                            item.id === 99
                                ? 'RSF'
                                : state.rubFacts.find((rubFact: RubriqueFacturationApiObject) => {
                                      return item.id === rubFact.id;
                                  })?.code || '',
                        rules: {
                            required: 'Rubrique manquante',
                        },
                        disabled: true,
                        isDisabled: true,
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'ht',
                        inputlabel: 'HT',
                        placeholder: '0',
                        disabled: true,
                        inputProps: {
                            step: 1,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            const produit = formContext.getValue(
                                input.objectPath,
                            ) as Partial<ligneProduit>;
                            fillProduits(produit, +input.base.replace(/^\D+/g, ''));
                            updateTotaux();
                        },
                        rules: {
                            required: 'Prix HT manquant',
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'txtva',
                        inputlabel: 'Taux TVA',
                        placeholder: '0',
                        disabled: true,
                        InputProps: {
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                        inputProps: {
                            step: 1,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            const produit = formContext.getValue(
                                input.objectPath,
                            ) as Partial<ligneProduit>;
                            fillProduits(produit, +input.base.replace(/^\D+/g, ''));
                            updateTotaux();
                        },
                        onPaste: (event: React.ClipboardEvent) => {
                            event.clipboardData.getData('text').match(/\D/g) &&
                                event.preventDefault();
                        },
                        onKeyDown: (event: React.KeyboardEvent) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        },
                        rules: {
                            required: 'Taux TVA manquant',
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'tva',
                        inputlabel: 'TVA',
                        placeholder: '0',
                        disabled: true,
                        inputProps: {
                            step: 0.01,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            // const produit = formContext.getValue(
                            //     input.objectPath,
                            // ) as Partial<ligneProduit>;

                            // fillProduits(produit, 'tva', +input.base.replace(/^\D+/g, ''));
                            updateTotaux();
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'ttc',
                        inputlabel: 'TTC',
                        placeholder: '0',
                        disabled: true,
                        inputProps: {
                            step: 0.01,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            const produit = formContext.getValue(input.path);

                            console.log(produit);

                            // fillProduits(produit, 'ttc', +input.base.replace(/^\D+/g, ''));
                            updateTotaux();
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'customItem',
                        name: 'Informations : ',
                        renderField: (formContext, props) => {
                            if (!afficherLigneVente) {
                                return null;
                            }
                            const subform = formContext.getValue(props.objectPath);
                            let idLigneVenteform: string | number | undefined = undefined;
                            if (subform.idLigneVente) {
                                idLigneVenteform = subform.idLigneVente;
                            } else if (facture.produits) {
                                const idProduit = Number(props.objectPath.split('.')[1]);
                                if (facture.produits[idProduit]) {
                                    idLigneVenteform =
                                        facture.produits[idProduit].idLigneVente || undefined;
                                }
                            }

                            if (!idLigneVenteform) {
                                return null;
                            }

                            const ligneVenteTrouvee = vente?.lignes?.find((ligne) => {
                                return String(ligne.id) === String(idLigneVenteform);
                            });
                            if (!ligneVenteTrouvee) {
                                return null;
                            }
                            let produitvente = mapOneProduitsVente(ligneVenteTrouvee);
                            // TODO: Verrifier la condition, il trouve des lignes qu'il ne devrait pas trouver
                            return produitvente ? (
                                <Box sx={{ height: '20px' }}>
                                    <Typography
                                        sx={{
                                            fontStyle: 'italic',
                                            color: 'text.disabled',
                                        }}
                                    >
                                        <>
                                            vente : {produitvente.rubriqueFacturation?.code} -{' '}
                                            {produitvente.libelle}
                                            &nbsp;| ht: {produitvente.ht}
                                            &nbsp;| tva: {produitvente.tva}
                                            &nbsp;| ttc: {produitvente.ttc}
                                        </>
                                    </Typography>
                                </Box>
                            ) : null;
                        },
                    },
                ],
            },
        ],
        [
            afficherLigneVente,
            facture.produits,
            fillProduits,
            state.rubFacts,
            switchAfficherLigneVente,
            updateTotaux,
            vente?.lignes,
        ],
    );

    const factureFormStructure: FormStructure[] = useMemo(() => {
        // Ordre et affichage des sections de formulaire

        const factureFormStructure: FormStructure[] = [];
        if (!multiAnnonces) {
            if (!isInterne) {
                factureFormStructure.push(
                    ...enteteFactureStructure(updateTotaux, true),
                    ...fournisseurStructure,
                    ...dossierStructure,
                );
            } else {
                factureFormStructure.push(
                    ...enteteFactureStructure(updateTotaux, true),
                    ...dossierStructure,
                    ...fournisseurStructure,
                );
            }
        } else {
            factureFormStructure.push(...dossierStructure);
        }

        factureFormStructure.push(
            ...produitsStructure,
            ...totauxStructure(blockTotaux, setBlockTotaux, false, false),
        );

        return factureFormStructure;
    }, [
        blockTotaux,
        dossierStructure,
        fournisseurStructure,
        isInterne,
        multiAnnonces,
        produitsStructure,
        updateTotaux,
    ]);

    /**
     * Actions lors de l'envoi du formulaire
     *
     * Si le formulaire est rempli correctement et le bouton d'envoi est appuyé :
     * Formaliser les données du formulaire de façon à les rendres compatible avec l'API achats
     *
     * @param data
     * @see onSubmit
     *
     */
    const handleSubmit: SubmitHandler<factureFormObject> = useCallback(
        (data: factureFormObject) => {
            console.log('submit data', data);
            onSubmit(data);
            return;
        },
        [onSubmit],
    );

    return (
        <>
            {/* <Typography variant="h5">
                render count form : {renderFactureForm}
            </Typography> */}
            <FlexyForm
                formObject={facture}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                formContext={formContext}
                submitButton={{ displayed: false }}
            />
        </>
    );
};

export default EditFacture;
