import { CustomizerReducerType, INIT_STATE } from '@europrocurement/l2d-domain';

export const getCustomiserState = () => {
    const customizerStateString = localStorage.getItem('customizerState');
    try {
        const customizerState: CustomizerReducerType = JSON.parse(customizerStateString || '');
        return customizerState;
    } catch {
        return INIT_STATE;
    }
};
