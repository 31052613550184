import * as React from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';
import { FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import useFetchApi from '@b2d/hooks/usePromises';
import EmailList from './EmailList';
import AddEmail from './AddEmail';
import NotificationsFrequencies from './NotificationsFrequencies';
import ActionModal from '../../forms/ActionModal';
import useApiRequest from '@b2d/hooks/useApiRequest';

const FormalitiesNotifications: React.FC<any> = (props) => {
    const { prescribersApi, prescriberId, companyId, modalFunctions } = props;

    // 1 - Component Data / On mount fetch data
    const { isFetching, isLoaded, hasErrors, results, call: fetchApi } = useFetchApi();

    const id = prescriberId.toString();

    const notificationPromises = [
        () => prescribersApi.getEmailsNotificationsPrescripteurTiersItem(id, companyId),
        () => prescribersApi.getFrequencesNotificationsPrescripteurTiersItem(id, companyId),
    ];

    React.useEffect(() => {
        fetchApi(notificationPromises);
    }, []);

    // 2 - Component state

    const [frequency, setFrequency] = React.useState<any>();
    const [emailList, setEmailList] = React.useState<any>();

    // 3 - On result / update component state

    React.useEffect(() => {
        if (isLoaded && results) {
            extractAndStoreDataFromResponse(results);
        }
    }, [isLoaded, results]);

    const extractAndStoreDataFromResponse = (fulfilledPromises: any) => {
        fulfilledPromises.forEach(({ value }: { value: { data?: any } }) => {
            if (value?.data) {
                storeRelatedData(value.data);
            }
        });
    };

    const storeRelatedData = (data: any) => {
        const notificationEmailsData = data?.preferencesNotification?.emails;
        const frequencyData = data?.preferencesNotification?.frequences?.[0].frequence;

        if (notificationEmailsData) {
            setEmailList(notificationEmailsData);
        }
        if (typeof frequencyData === 'number') {
            setFrequency(frequencyData);
        }
    };

    // 4 - Update methods (emails & frequency)

    const { request: updateFrequency, requestState: updateFrequencyState } = useApiRequest();
    const { request: updateEmails, requestState: updateEmailsState } = useApiRequest();

    // 4a - EmailList update / store value callback

    const updateEmailList = (emailList: any) => {
        const successCallback = (result: any) => {
            const updatedEmailList = result.data.preferencesNotification?.emails ?? undefined;
            setEmailList(updatedEmailList);
        };

        updateEmails(updateEmailListRequest(emailList), { successCallback });
    };

    const updateEmailListRequest = (emailList: any) => {
        return prescribersApi.updateEmailsNotificationsPrescripteurTiersItem(
            prescriberId.toString(),
            emailListPayload(emailList),
        );
    };

    const emailListPayload = (emailList: any) => {
        return {
            preferencesNotification: {
                emails: emailList,
            },
        };
    };

    // 4b - Frequency update / store value callback

    const updateFrequencyValue = (newValue: any) => {
        const successCallback = (result: any) => {
            const updatedFrequence =
                result.data?.preferencesNotification?.frequences?.[0].frequence ?? undefined;
            setFrequency(updatedFrequence);
        };

        updateFrequency(updateFrequencyRequest(newValue), { successCallback });
    };

    const updateFrequencyRequest = (newValue: any) => {
        return prescribersApi.updateFrequencesNotificationsPrescripteurTiersItem(
            prescriberId.toString(),
            frequencyPayload(newValue),
        );
    };

    const frequencyPayload = (newValue: any) => {
        return {
            preferencesNotification: {
                frequences: [
                    {
                        societe: {
                            id: companyId,
                        },
                        frequence:
                            newValue as FrequenceNotificationPrescripteurWriteNotificationsFrequencesFrequenceEnum,
                    },
                ],
            },
        };
    };

    const { closeModal, openModal, setModalContent } = modalFunctions;

    const callModal = (modalContent: React.ReactElement) => {
        setModalContent(modalContent);
        openModal();
    };

    const deleteEmail = (deletedEmail: string) => {
        callModal(
            <ActionModal
                onClose={closeModal}
                asyncAction={() => {
                    const updatedList = emailList.filter(
                        ({ email }: { email: string }) => email !== deletedEmail,
                    );

                    // Todo utiliser la route de maj des coordonnées d un tiers pour la suppression
                }}
                title={"Suppression d'un email"}
                actionText={`Voulez-vous supprimer l\'email : ${deletedEmail} ?`}
                actionLabel={'Confirmer suppression'}
                callback={closeModal}
            />,
        );
    };

    // 5 - Render logic

    if (isFetching || isLoaded === false) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Grid container spacing={4} rowSpacing={1}>
                <Grid item xl={7} lg={12} md={12} xs={12}>
                    <FlexyHeaderForm label={'Liste des emails (notification & rappel)'} outlined />
                    <EmailList
                        emails={emailList}
                        updateEmails={updateEmailList}
                        isUpdating={updateEmailsState.isProcessing}
                        deleteEmail={deleteEmail}
                    />
                </Grid>

                <Grid item xl={5} lg={12} md={12} xs={12}>
                    <AddEmail emails={emailList} updateEmails={updateEmailList} />
                    <NotificationsFrequencies
                        frequency={parseInt(frequency)}
                        updateFrequency={updateFrequencyValue}
                        isUpdating={updateFrequencyState.isProcessing}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormalitiesNotifications;
