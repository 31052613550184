import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    PortefeuilleApi,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

export { type TiersConfigurationParameters };

export type WalletApiObject = any & Record<string, unknown>;

export type WalletOptions = WalletApiObject;

export const WALLET_OPTIONS_SLICE_NAME = 'walletOptions';

const mapperWalletOptions = function (walletOptions: WalletApiObject) {
    return {
        ...walletOptions,
    } as WalletOptions;
};

export function createWalletOptionsSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PortefeuilleApi(conf);

    const fetchWalletOptionsCollection: FetchCollectionData<WalletApiObject> = ({
        pagination,
        xIdSociete,
    }) => {
        return api
            .getListePortefeuilleCategorieSocioProfessionnelleCollection(
                xIdSociete,
                undefined,
                pagination.page + 1,
            )
            .then((res) => {
                return res.data;
            })
            .catch((error) => {}) as Promise<ApiCollectionResponse<WalletApiObject>>;
    };

    const fetchWalletOptionsItem: FetchItemDataType<WalletApiObject> = ({ idItem, xIdSociete }) => {
        return api
            .getPortefeuilleCategorieSocioProfessionnelleItem(String(idItem), xIdSociete, undefined)
            .then((res) => res.data) as Promise<WalletApiObject>;
    };

    const { slice: walletOptionsSlice, dataSourcesThunks: walletOptionsDataSourcesThunks } =
        SliceFactory.createSlice<WalletApiObject, WalletOptions>(
            WALLET_OPTIONS_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchWalletOptionsCollection,
            fetchWalletOptionsItem,
            mapperWalletOptions,
        );

    return { walletOptionsSlice, walletOptionsDataSourcesThunks };
}
