import React from 'react';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import ReactJson from 'react-json-view';
import { Grid, Card, Button, CardHeader, CardContent, Typography, Box } from '@mui/material';
import { BooleanBadge } from '@europrocurement/flexy-components';
import { useDispatch, useSelector } from 'react-redux';
import { customizerSelector } from '../../redux/RootStore';
import {
    APIENV,
    APISENV,
    APIS_ENUM,
    ENV,
    ENV_API,
    SET_ENV,
    SET_ENV_API,
} from '@europrocurement/l2d-domain';
import { useSnackbar } from 'notistack';

const Debug = function () {
    const kc = UseKeycloakService();

    const customizer = useSelector(customizerSelector);

    const snack = useSnackbar();

    const dispatch = useDispatch();

    const setEnv = function (env: ENV) {
        if (env === 'qual') {
            dispatch({
                type: SET_ENV,
                payload: env,
            });
        } else {
            snack.enqueueSnackbar('Environement ' + env + ' non configuré dans keyclaok', {
                variant: 'error',
            });
        }
    };

    const setEnvApi = function (env: ENV_API, api: APIS_ENUM) {
        dispatch({
            type: SET_ENV_API,
            payload: { env, api },
        });
    };

    // eslint-disable-next-line eqeqeq
    // eslint-disable-next-line prefer-destructuring
    let tokenParsed: object | undefined = kc.getKc().tokenParsed;
    // https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2$3FlogStreamNameFilter$3D

    if (tokenParsed === undefined) {
        tokenParsed = {};
    }
    return (
        <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12}>
                <Card>
                    <CardHeader title={<Typography fontWeight="bold">Environements</Typography>} />
                    <CardContent>
                        <Typography fontWeight="bold">Keycloak</Typography>
                        <Box display="flex">
                            {Object.keys(APIENV.keycloak).map((env) => (
                                <BooleanBadge
                                    key={env}
                                    label={env}
                                    icon
                                    toggleAction={() => {
                                        setEnv(env as ENV);
                                    }}
                                    value={customizer.env === env}
                                />
                            ))}
                        </Box>

                        {Object.values(APIS_ENUM).map((api) => (
                            <Box key={api}>
                                <Typography fontWeight="bold">Api {api}</Typography>
                                <Box display="flex">
                                    {Object.keys(APIENV.apis[api]).map((env) => (
                                        <BooleanBadge
                                            key={env}
                                            label={env}
                                            icon
                                            toggleAction={() => {
                                                setEnvApi(env as ENV_API, api as APIS_ENUM);
                                            }}
                                            value={
                                                customizer.api &&
                                                customizer.api[api] &&
                                                customizer.api[api] === env
                                            }
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
                <Card>
                    <CardHeader title={<Typography fontWeight="bold">Logs</Typography>} />
                    <CardContent>
                        <Button
                            sx={{ marginRight: '10px' }}
                            variant="contained"
                            target="_blank"
                            href="https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2"
                        >
                            Logs aws
                        </Button>
                        {[
                            'apitiers-qual1',
                            'apiachats-qual1',
                            'apidossiers-qual1',
                            'apial-qual1',
                            'apisoceiete-qual1',
                            'apimail-qual1',
                            'apisiren-qual1',
                            'apiutilisateur-qual1',
                        ].map((str) => {
                            return (
                                <Button
                                    key={str}
                                    sx={{ marginRight: '10px' }}
                                    variant="contained"
                                    target="_blank"
                                    href={
                                        'https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2$3FlogStreamNameFilter$3Dstreaming$252F' +
                                        str
                                    }
                                >
                                    {str}
                                </Button>
                            );
                        })}
                    </CardContent>
                </Card>
            </Grid>

            <Grid item lg={7} md={6} xs={12}>
                <Card>
                    <CardHeader
                        title={<Typography fontWeight="bold">Token Keycloak Parsé</Typography>}
                    />
                    <ReactJson displayDataTypes={false} theme="eighties" src={tokenParsed} />
                </Card>
            </Grid>
            <Grid item lg={5} md={6} xs={12}>
                <Card>
                    <CardHeader
                        title={<Typography fontWeight="bold">Token Keycloak JWT</Typography>}
                    />
                    <p style={{ wordWrap: 'break-word' }}>{kc.getKc().token}</p>
                </Card>
            </Grid>
        </Grid>
    );
};
export default Debug;
