import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FACTURES_ACHATS_PAUSE_DATASOURCE_NAME, FactureAchat } from '@europrocurement/l2d-domain';
import { FactureachatSelector, getFactureAchatPause } from '../../../../redux/RootStore';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import FactureAchatList from './FactureAchatList';
import { FilterDatatable } from '@europrocurement/flexy-datatable';

export type PauseFactureAchatListProps = {};

const PauseFactureAchatList: React.FunctionComponent<PauseFactureAchatListProps> = function () {
    const facturesAchatsDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_PAUSE_DATASOURCE_NAME];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
            payload: { key: 'cloture', value: false },
        });

        dispatch({
            type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
            payload: { key: 'deleted', value: false },
        });
    }, [dispatch, facturesAchatsDataSource.name, facturesAchatsDataSource.slicename]);

    useEffect(() => {
        if (
            facturesAchatsDataSource.filters.cloture === false &&
            facturesAchatsDataSource.filters.deleted === false
        ) {
            dispatch(getFactureAchatPause({}));
        }
    }, [
        dispatch,
        facturesAchatsDataSource.filters.cloture,
        facturesAchatsDataSource.filters.deleted,
    ]);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    { path: 'Liste des factures à traiter', link: '/achats/liste' },
                    'Factures en pause',
                ]}
            />
            {facturesAchatsDataSource.filters.cloture === false &&
            facturesAchatsDataSource.filters.deleted === false ? (
                <FactureAchatList
                    filtersControl
                    dataSource={facturesAchatsDataSource}
                    fetchData={getFactureAchatPause}
                    filterFilters={(filtre: FilterDatatable) => {
                        return filtre.field !== 'cloture' && filtre.field !== 'deleted';
                    }}
                />
            ) : null}
        </>
    );
};

export default PauseFactureAchatList;
