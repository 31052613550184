import { Chip, type ChipProps } from '@mui/material';
import * as React from 'react';

export type ActiveFilterBadgeProps = ChipProps & {
    field: string;
    value: string;
};

const ActiveFilterBadge: React.FunctionComponent<ActiveFilterBadgeProps> = function ({
    field,
    value,
    ...props
}: ActiveFilterBadgeProps) {
    const label = () => (
        <>
            <b>{field}</b> : &quot;{value}&quot;
        </>
    );

    return <Chip label={label()} {...props} data-testid="ActiveFilterBadge" />;
};

export default ActiveFilterBadge;
