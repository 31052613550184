import React from 'react';

import { PdfViewer } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { Card, SxProps } from '@mui/material';

export type MediaObjectReaderType = {
    mediaObject: MediaObject;
    sx?: SxProps;
};

export const MediaObjectReader = function ({ mediaObject, sx }: MediaObjectReaderType) {
    return (
        <Card id="cardPdfReader" sx={{ ...sx }}>
            <PdfViewer pdfUrl={mediaObject.contentUrl}></PdfViewer>
        </Card>
    );
};

export default MediaObjectReader;
