import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    PaysApi,
    TblPaysJsonldPaysRead,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

export { type TiersConfigurationParameters };

export type PaysApiObject = TblPaysJsonldPaysRead & Record<string, unknown>;

export type Pays = PaysApiObject;

export const PAYS_SLICE_NAME = 'pays';

const mapperPays = function (pays: PaysApiObject) {
    return {
        ...pays,
    } as Pays;
};

export function createPaysSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PaysApi(conf);

    const fetchPaysCollection: FetchCollectionData<PaysApiObject> = ({
        pagination,
        xIdSociete,
    }) => {
        return api
            .getListePaysTblPaysCollection(xIdSociete, undefined, pagination.page + 1, 500)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {}) as Promise<ApiCollectionResponse<PaysApiObject>>;
    };

    const fetchPaysItem: FetchItemDataType<PaysApiObject> = ({ idItem, xIdSociete }) => {
        return api
            .getPaysTblPaysItem(String(idItem), xIdSociete, undefined)
            .then((res) => res.data) as Promise<PaysApiObject>;
    };

    const { slice: paysSlice, dataSourcesThunks: paysDataSourcesThunks } = SliceFactory.createSlice<
        PaysApiObject,
        Pays
    >(PAYS_SLICE_NAME, TIERS_REDUCER_NAME, [], fetchPaysCollection, fetchPaysItem, mapperPays);

    return { paysSlice, paysDataSourcesThunks };
}
