import * as React from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { useSelector } from 'react-redux';
import {
    customizerSelector,
    prescripteursApi as prescribersApi,
    OptionsSelector,
} from '@b2d/redux/RootStore';
import {
    EMAIL_VALIDATION_REGEX,
    LANDLINE_PHONE_NUMBER_REGEX,
    MOBILE_PHONE_NUMBER_REGEX,
    POST_CODE_VALIDATION_REGEX,
    SIRET_FORMAT,
} from '@b2d/validation/regex';
import { COUNTRY_CODES_ISO_2 } from '@b2d/validation/validation';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Badge, Box, Card, Grid, Tooltip } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import usePromises from '@b2d/hooks/usePromises';
import ExistingPrescribersModal from './ExistingPrescribersModal';
import CustomButtons from './CustomButtons';
import { formatPayload } from './dataStructure';
import useApiRequest from '@b2d/hooks/useApiRequest';
const PrescriberForm: React.FC<any> = (props) => {
    // Utils / selectors
    const navigate = useNavigate();
    const { xIdSociete } = useSelector(customizerSelector);
    const optionsStore = useSelector(OptionsSelector);

    // Options config
    const defaultCountryOption = optionsStore.countries.find(
        ({ codeIso2 }: { codeIso2: string }) => codeIso2 === 'FR',
    );
    const defaultCspOption = optionsStore.cspOptions.find(({ id }: { id: number }) => id === 0);
    const defaultWalletOption = optionsStore.walletOptions.find(
        ({ id }: { id: number }) => id === 1,
    );

    // Component state
    const modalInitialState = {
        isOpen: false,
        isTriggered: false,
    };

    const siretInitialState = {
        isValid: true,
        isEmpty: true,
        value: '',
        siren: '',
    };

    const [modalStatus, setModalStatus] = React.useState<any>(modalInitialState);
    const [siretStatus, setSiretStatus] = React.useState<any>(siretInitialState);

    // 1 - Data fetching

    const {
        fetchedData: fetchedPrescribers,
        call: fetchPrescribers,
        reset: resetPrescribers,
    } = usePromises();

    const redirectToPrescriberDetail = (prescriberId: number) => {
        const prescriberDetailUrl = '/prescripteurs/liste/' + prescriberId;
        navigate(prescriberDetailUrl);
    };

    const { request, requestState } = useApiRequest();

    //FormContext
    const formDefaultValues: { [x: string]: any } = {
        codePays: defaultCountryOption,
    };

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: formDefaultValues,
    });

    // On prescribersFetched
    React.useEffect(() => {
        if (fetchedPrescribers && !modalStatus.isTriggered) {
            if (existingPrescribers.length > 0) setModalStatus({ isOpen: true, isTriggered: true });
        }
    }, [fetchedPrescribers]);

    React.useEffect(() => {
        if (siretStatus.isValid && !siretStatus.isEmpty) {
            fetchExistingPrescribersFromSiret();
        }
        if (!siretStatus.isValid || siretStatus.isEmpty) {
            resetPrescribers();
        }
    }, [siretStatus]);

    const fetchPrescribersFromSiret = () => {
        const siret = siretStatus.value;
        const elasticSearchParameters = `{"query":{"bool":{"must":[{"match":{"relationsSocietes.societe.id":2}},{"match":{"isPrescripteur":true}},{"match":{"demo":false}},{"match":{"deleted":false}},{"query_string":{"query":"siret:*${siret}*"}}]}}}`;
        return prescribersApi.getListTiersTiersCollection(
            xIdSociete,
            undefined,
            undefined,
            undefined,
            elasticSearchParameters,
        );
    };
    const fetchExistingPrescribersFromSiret = () => {
        fetchPrescribers([fetchPrescribersFromSiret]);
    };

    const onSubmit = async (formData: any) => {
        const payload = formatPayload(formData, xIdSociete);

        const successCallback = (result: any) => {
            const prescriberId = result.data?.id;
            if (prescriberId) {
                redirectToPrescriberDetail(prescriberId);
            }
        };

        request(prescribersApi.createPrescripteurTiersCollection(payload, xIdSociete), {
            successCallback,
        });
    };

    // SIRET VALIDATION

    // Luhn algorithm
    const isLuhnValid = (input: string) => {
        const inputContainsOnlyDigits = /^\d+/.test(input);
        if (!inputContainsOnlyDigits) {
            return false;
        }
        const splittedNumber = input.split('');
        let sum = 0;
        const numberLength = splittedNumber.length;
        const parity = numberLength % 2;
        splittedNumber.forEach((value, index) => {
            const digit = parseInt(value);
            if (index % 2 !== parity) {
                sum = sum + digit;
            } else if (digit > 4) {
                sum = sum + 2 * digit - 9;
            } else {
                sum = sum + 2 * digit;
            }
        });

        return sum % 10 === 0;
    };

    const siretValidation = (siret: string) => {
        if (siret === siretStatus.siret) {
            return;
        }

        const isSiretValid = siret === '' || (isLuhnValid(siret) && siret.length === 14);
        setSiretStatus({
            value: siret,
            isEmpty: siret === '',
            isValid: isSiretValid,
            siren: getSirenFromSiret(siret, isSiretValid),
        });
    };

    const getSirenFromSiret = (siret: string, isValid: boolean) => {
        if (siret === '' || !isValid) {
            return '';
        }
        return siret.substring(0, 9);
    };

    const getIntraVatValue = () => {
        if (siretStatus.isEmpty || !siretStatus.isValid) {
            return '';
        }

        const siren = siretStatus.siren;

        const intraVatKey = (12 + ((3 * parseInt(siren)) % 97)) % 97;
        const intraVatValue = 'FR' + intraVatKey.toString() + siren;

        return intraVatValue;
    };

    const handleSiretInput = (event: any) => {
        const value = event.target.value;
        siretValidation(value);
    };

    // MODAL DATA / METHODS

    const existingPrescribers = fetchedPrescribers?.['fetchPrescribersFromSiret'] ?? [];

    const openExistingPrescribersModal = () => {
        setModalStatus({
            ...modalStatus,
            isOpen: true,
        });
    };

    // RENDER FORM

    const mainInformationsFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <CreateIcon sx={{ marginRight: '5px' }} />
                    {'Informations principales'}
                </>
            ),
            name: 'main_informations',
        },
        {
            type: 'text',
            name: 'raisonSociale',
            inputlabel: 'Raison sociale',
            xs: 12,
            // lg: 4,
            rules: {
                required: 'Vous devez renseigner une raison sociale',
            },
            placeholder: 'Raison sociale',
        },
        {
            type: 'number',
            name: 'siret',
            inputlabel: 'SIRET',
            xs: 11,
            lg: 4,
            placeholder: 'SIRET',
            value: siretStatus.value,
            rules: {
                validate: {
                    siret: () => {
                        return siretStatus.isValid || 'Siret invalide';
                    },
                },
                pattern: {
                    value: SIRET_FORMAT,
                    message: 'Le Siret doit être constitué de 14 chiffres',
                },
                onChange: (siretValue) => handleSiretInput(siretValue),
            },
            defaultValue: siretInitialState.value,
        },
        {
            type: 'customItem',
            name: 'comptabilite',
            xs: 1,
            renderField: (formContext, props) => {
                const numberOfExistingsPrescribers = existingPrescribers.length;
                const isBadgeActive = !siretStatus.isEmpty && siretStatus.isValid;
                const canInteract = isBadgeActive && numberOfExistingsPrescribers > 0;

                const getSiretTooltipTitle = () => {
                    if (!isBadgeActive) {
                        return '';
                    }
                    if (numberOfExistingsPrescribers > 0) {
                        return `Voir le(s) ${numberOfExistingsPrescribers} prescripteur(s) associé(s) à ce numéro de Siret`;
                    } else {
                        return 'Aucun prescripteur associé à ce numéro de Siret ';
                    }
                };

                const formDisplaySiretError = formContext.formState.errors.siret?.message;

                return (
                    <Tooltip title={getSiretTooltipTitle()}>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'end'}
                            sx={{
                                height: '100%',
                                cursor: canInteract ? 'pointer' : '',
                                paddingBottom: formDisplaySiretError ? '24px' : '0px',
                            }}
                        >
                            <Badge
                                badgeContent={isBadgeActive ? numberOfExistingsPrescribers : null}
                                color="primary"
                                onClick={
                                    numberOfExistingsPrescribers > 0
                                        ? openExistingPrescribersModal
                                        : () => {}
                                }
                            >
                                <PersonIcon fontSize={'large'} color="action" />
                            </Badge>
                        </Box>
                    </Tooltip>
                );
            },
        },

        {
            type: 'text',
            name: 'siren',
            inputlabel: 'SIREN',
            xs: 12,
            lg: 3,
            placeholder: 'SIREN',
            disabled: true,
            value: siretStatus.isValid ? siretStatus.siren : '',
        },
        {
            type: 'text',
            name: 'tvaIntracommunautaire',
            inputlabel: 'TVA Intracommunautaire',
            xs: 12,
            lg: 4,
            placeholder: 'TVA',
            value: siretStatus.isValid ? getIntraVatValue() : '',
        },
    ];

    const groupsFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <GroupsIcon sx={{ marginRight: '5px' }} />
                    {'Type de prescripteur'}
                </>
            ),
            xs: 12,
            name: 'groups',
        },
        {
            type: 'boolean',
            name: 'personnePhysique',
            inputlabel: 'Personne physique',
            defaultValue: false,
            xs: 6,
        },
        {
            type: 'boolean',
            name: 'demo',
            inputlabel: 'Mode démonstration',
            defaultValue: false,
            xs: 6,
        },

        {
            type: 'select',
            name: 'portefeuille',
            inputlabel: 'Portefeuille',
            options: optionsStore.walletOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            defaultValue: defaultWalletOption,
            xs: 12,
        },
        {
            type: 'select',
            name: 'csp',
            inputlabel: 'Catégorie socioprofessionnelle',
            options: optionsStore.cspOptions,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            defaultValue: defaultCspOption,
            xs: 12,
        },
    ];

    const addressFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <LocationOnIcon sx={{ marginRight: '5px' }} />
                    {'Adresse'}
                </>
            ),
            xs: 12,
            name: 'address',
        },
        // Required fields
        {
            type: 'text',
            name: 'adresse1',
            inputlabel: 'Addresse 1',
            xs: 12,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner une adresse',
            },
            placeholder: 'Numéro, rue ...',
        },
        {
            type: 'text',
            name: 'adresse2',
            inputlabel: 'Adresse 2',
            xs: 12,
            lg: 6,
            placeholder: 'Appartement, batiment, entrée ...',
        },
        {
            type: 'text',
            name: 'adresse3',
            inputlabel: 'Adresse 3',
            xs: 12,
            lg: 6,
            placeholder: "Complément d'adresse ...",
        },
        {
            type: 'text',
            name: 'adresse4',
            inputlabel: 'Adresse 4',
            xs: 12,
            lg: 6,
            placeholder: 'cedex ? Boite postale ...',
        },
        {
            type: 'number',
            name: 'codePostal',
            inputlabel: 'Code Postal',
            sm: 12,
            md: 3,
            rules: {
                required: 'Vous devez renseigner le code postal',
                pattern: {
                    value: POST_CODE_VALIDATION_REGEX,
                    message: 'Le code postal doit être constitué de 5 chiffres',
                },
            },
        },
        {
            type: 'text',
            name: 'ville',
            inputlabel: 'Ville',
            sm: 12,
            lg: 5,
            rules: {
                required: 'Vous devez renseigner une ville',
            },
        },

        {
            type: 'select',
            name: 'codePays',
            inputlabel: 'code Pays',
            rules: {
                required: 'Vous devez renseigner un code pays',
                validate: {
                    countryCodeIso2: (option) => getValidation(option),
                },
            },
            sm: 12,
            md: 3,
            lg: 4,
            options: optionsStore.countries,
            getOptionLabel: (option: any) => option.libelle,
            getOptionValue: (option: any) => option.codeIso2,
        },
    ];

    const getValidation = (option: any) => {
        return (
            COUNTRY_CODES_ISO_2.includes(option.codeIso2) ||
            'Le code pays doit être au format ISO 3166-1 alpha-2'
        );
    };

    const contactFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <PersonIcon sx={{ marginRight: '5px' }} />
                    {'Contact'}
                </>
            ),
            xs: 12,
            name: 'contact',
        },
        {
            type: 'select',
            inputlabel: 'Civilité',
            name: 'civilite',
            xs: 12,
            lg: 5,
            options: optionsStore.civilities,
            getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
            getOptionValue: ({ id }: { id: string }) => id,
            placeholder: 'Civilité',
        },
        {
            type: 'text',
            name: 'nomContact',
            inputlabel: 'Nom',
            xs: 12,
            lg: 7,
            rules: {
                required: 'Vous devez renseigner le nom',
            },
        },
        {
            type: 'text',
            name: 'prenomContact',
            inputlabel: 'Prenom',
            xs: 12,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le prenom',
            },
        },
        {
            type: 'text',
            name: 'telephone',
            xs: 12,
            lg: 6,
            inputlabel: 'Téléphone',
            rules: {
                pattern: {
                    value: LANDLINE_PHONE_NUMBER_REGEX,
                    message: 'Le numero de téléphone est invalide',
                },
            },
        },
        {
            type: 'text',
            name: 'mobile',
            lg: 6,
            inputlabel: 'Mobile',
            xs: 12,
            rules: {
                pattern: {
                    value: MOBILE_PHONE_NUMBER_REGEX,
                    message: 'Le numero de téléphone est invalide',
                },
            },
        },
        {
            type: 'text',
            name: 'email',
            inputlabel: 'Email',
            xs: 12,
            lg: 6,
            rules: {
                required: "Vous devez renseigner l'email",
                pattern: {
                    value: EMAIL_VALIDATION_REGEX,
                    message: "L'Email est invalide",
                },
            },
        },
    ];

    const formProps = {
        formContext,
        formObject: {},
        onSubmit,
        submitButton: {
            displayed: false,
        },
    };

    return (
        <Card>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <FlexyForm formStructure={mainInformationsFormStructure} {...formProps} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FlexyForm formStructure={groupsFormStructure} {...formProps} />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <FlexyForm formStructure={addressFormStructure} {...formProps} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FlexyForm formStructure={contactFormStructure} {...formProps} />
                    </Grid>
                </Grid>
            </Box>
            <CustomButtons
                onSubmit={formContext.handleSubmit(onSubmit)}
                isSubmitting={requestState.isProcessing}
            />
            <ExistingPrescribersModal
                existingPrescribers={existingPrescribers}
                modalStatus={modalStatus}
                onModalClose={() => setModalStatus({ ...modalStatus, isOpen: false })}
                redirectToPrescriberDetail={redirectToPrescriberDetail}
            />
        </Card>
    );
};

export default PrescriberForm;
