/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    FactureAchatApi,
    GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner,
} from '../../../openApi/ApiAchats';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { ACHATS_REDUCER_NAME } from '../achatsReducer';

export { type AchatsConfigurationParameters };

export type CodeRejetStatsApiObject =
    GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner & Record<string, unknown>;

export type CodeRejetStats = CodeRejetStatsApiObject;

export const CODE_REJET_STATS_SLICE_NAME = 'coderejetstats';

const mapperCodeRejetStats = function (codeRejetStats: CodeRejetStats) {
    return {
        ...codeRejetStats,
    } as CodeRejetStats;
};

export function createCodeRejetStatsSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FactureAchatApi(conf);

    const fetchCodeRejetStatsCollection: FetchCollectionData<CodeRejetStatsApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dateFactureBefore,
            dateFactureAfter,
            createdAtBefore,
            createdAtAfter,
            categories,
            codesRejets: string | undefined = undefined;
        let idEntiteFacturante: number | undefined = undefined;

        // La gestion de la range jusqu'à aujourd'hui est géré côté API
        // pour dateFacture et createdAt

        if (filters.dateFacture) {
            dateFactureBefore = (filters.dateFacture as string[])[0];
            dateFactureAfter = (filters.dateFacture as string[])[1];
        }

        if (filters.createdAt) {
            createdAtBefore = (filters.createdAt as string[])[0];
            createdAtAfter = (filters.createdAt as string[])[1];
        }

        if (
            filters.categories &&
            Array.isArray(filters.categories) &&
            filters.categories.length > 0
        ) {
            categories = JSON.stringify(filters.categories);
        }

        if (
            filters.codesRejets &&
            Array.isArray(filters.codesRejets) &&
            filters.codesRejets.length > 0
        ) {
            codesRejets = JSON.stringify(filters.codesRejets);
        }

        if (filters.idEntiteFacturante) {
            idEntiteFacturante = filters.idEntiteFacturante as number;
        }

        return api
            .getCoderejetFaStatsFactureAchatCollection(
                xIdSociete, // xIdSociete ?: number | undefined,
                dateFactureBefore, // dateFactureBefore ?: string | undefined,
                undefined, // dateFactureStrictlyBefore ?: string | undefined,
                dateFactureAfter, // dateFactureAfter ?: string | undefined,
                undefined, // dateFactureStrictlyAfter ?: string | undefined,
                createdAtBefore, // createdAtBefore ?: string | undefined,
                undefined, // createdAtStrictlyBefore ?: string | undefined,
                createdAtAfter, // createdAtAfter ?: string | undefined,
                undefined, // createdAtStrictlyAfter ?: string | undefined,
                idEntiteFacturante, // idEntiteFacturante ?: number | undefined,
                undefined, // idEntiteFacturante2 ?: number[] | undefined,
                categories, // categories ?: string | undefined,
                codesRejets, // codesRejets ?: string | undefined,
                undefined, // options ?: AxiosRequestConfig <...> | undefined
            )
            .then((res) => res.data as CodeRejetStatsApiObject) as Promise<
            ApiCollectionResponse<CodeRejetStatsApiObject>
        >;
    };

    const fetchCodeRejetStatsItem: FetchItemDataType<CodeRejetStatsApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureAchatFactureAchatItem(String(idItem), xIdSociete) //NO ITEM FOR CODES REJETS
            .then((res) => res.data) as Promise<CodeRejetStatsApiObject>;

    const { slice: codeRejetStatsSlice, dataSourcesThunks: codeRejetStatsDataSourcesThunks } =
        SliceFactory.createSlice<CodeRejetStatsApiObject, CodeRejetStats>(
            CODE_REJET_STATS_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            [],
            fetchCodeRejetStatsCollection,
            fetchCodeRejetStatsItem,
            mapperCodeRejetStats,
            {
                linkedCodeRejets: true,
            },
        );

    return { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks };
}
