import * as React from 'react';

import { Tooltip, IconButton, type IconButtonProps } from '@mui/material';
import { Clear } from '@mui/icons-material';

export type ClearButtonProps = {
    tooltip?: string;
    size?: IconButtonProps['size'];
    color?: IconButtonProps['color'];
    onClick?: IconButtonProps['onClick'];
    disabled?: IconButtonProps['disabled'];
};

const ClearButton: React.FunctionComponent<ClearButtonProps> = function ({
    tooltip,
    ...props
}: ClearButtonProps) {
    return (
        <Tooltip
            sx={{
                position: 'absolute',
                top: '11px',
                right: '0px',
            }}
            title={tooltip}
        >
            <IconButton {...props}>
                <Clear fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

ClearButton.defaultProps = {
    size: 'medium',
    color: 'error',
    tooltip: 'Supprimer',
    onClick: undefined,
    disabled: false,
};

export default ClearButton;
