import * as React from 'react';

import {
    useForm,
    FieldValues,
    FormProvider,
    SubmitHandler,
    UseControllerProps,
    UseFormReturn,
    FormState,
    SubmitErrorHandler,
} from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import {
    FlexyInputProps,
    FlexyHeaderFormProps,
    useResolvePath,
} from '@europrocurement/flexy-components';
import { GridProps, Button, Box } from '@mui/material';

import { FormStructureRenderer } from '../FormStructureRenderer';
import {
    FlexySubFormProps,
    FlexySubFormArrayProps,
} from '../FormStructureRenderer/formStructureRenderer.type';
import { AutocompleteStoreProps } from '../AutocompleteStore';

export type FormObject = Record<string, unknown>;
export type ExtandedFormContext = UseFormReturn<FieldValues, any> & {
    getValue: (path: string) => FieldValues | any;
};

export type CustomItemRenderFieldProps = {
    base: string;
    path: string;
    objectPath: string;
    [x: string]: unknown;
};

export type onChangeInputType = (
    event: React.SyntheticEvent,
    input: {
        base: string;
        name: string;
        path: string;
        objectPath: string;
    },
    formContext: ExtandedFormContext,
) => void;

export type onBlurInputType = (
    event: React.FocusEventHandler,
    input: {
        base: string;
        name: string;
        path: string;
        objectPath: string;
    },
    formContext: ExtandedFormContext,
) => void;

export type FormStructure = (
    | (UseControllerProps & FlexyInputProps)
    | (FlexyHeaderFormProps & { type: 'header' })
    | (FlexySubFormProps & { type: 'subform' })
    | (FlexySubFormArrayProps & { type: 'subformarray' })
    | (UseControllerProps &
          AutocompleteStoreProps<Record<string, unknown>> & { type: 'autocompletestore' })
    | {
          type: 'customItem';
          name: string;
          renderField: (
              formContext: ExtandedFormContext,
              props: CustomItemRenderFieldProps,
          ) => React.ReactElement<any, any> | null;
          [x: string]: unknown;
      }
) & {
    // type: string;
    name: string;
    isArray?: boolean;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    onChangeInput?: onChangeInputType;
    onBlurInput?: onBlurInputType;
};

export type FlexyFormInputProps = {
    size?: 'small' | 'medium';
    fullWidth?: boolean;
};

const defaultSubmitButtonLabel = 'Valider';
const defaultSubmitButtonPosition = 'right';

// export type FlexyFormPropsType<T extends FieldValues> = FlexyFormPropsTypeInitialized | FlexyFormPropsTypeBasic<T> ;

export type FlexyFormPropsType<T extends FieldValues> = {
    /**
     * Objet à modifier via le form
     */
    formStructure: FormStructure[];
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    formObject: T;
    onSubmit: SubmitHandler<T>;
    onInvalid?: SubmitErrorHandler<T>;
    formContext?: UseFormReturn<T, any>;
    submitButton?: {
        displayed?: boolean;
        position?: 'left' | 'right';
        label?: string;
        render?: (
            formstate: FormState<FormObject>,
            innerHandleSubmit: () => void,
        ) => React.ReactNode;
    };
    isSubmitting?: boolean;
};

/**
 * Permet de générer dynamiquement un formulaire en fonction d'un tableau (formStructure) passé en props.
 * Le composant va remplir un objet javascript (formObject).
 * La props onSubmit connait l'existence de l'objet formObject et va traiter la soumission du formulaire.
 *
 * ## Règles de validation
 * Les règles de validation sont importé de [react-hook-form](https://react-hook-form.com/get-started#Applyvalidation) et devront être spécifié dans l'objet formStructure sous la propriété 'rules'.
 *
 * ## usage
 * ```jsx
 *
 *  const formObject = {
 *          lastName: '',
 *          firstName: ''
 *        }
 *
 *  const FormStructure: FormStructure[] = [
 *      {
 *          type: 'text',
 *          name: 'lastName',
 *          fullWidth: true,
 *          inputlabel: 'Nom',
 *          placeholder: 'Votre Nom',
 *          rules: {
 *              required: 'Vous devez renseigner votre nom',
 *              minLength: {
 *                  value: 2,
 *                  message: "Votre nom ne peut pas contenir qu'un seul caractère",
 *              },
 *          },
 *      },
 *      {
 *          type: 'text',
 *          name: 'firstName',
 *          inputlabel: 'Prénom',
 *          fullWidth: true,
 *          placeholder: 'Votre prénom',
 *          rules: {
 *              required: 'Vous devez renseigner votre prénom',
 *              minLength: {
 *                  value: 2,
 *                  message: "Votre prénom ne peut pas contenir qu'un seul caractère",
 *              },
 *          },
 *      },
 *
 * <FlexyForm onSubmit={onSubmit} formObject={formObject} formStructure={formStructure} />
 * ```
 */
// let renderCount = 0;
export const FlexyForm: React.FunctionComponent<FlexyFormPropsType<FormObject>> = function ({
    submitButton,
    ...props
}: FlexyFormPropsType<FormObject>) {
    const { onSubmit, formStructure, inputsProps, gridProps, formObject, formContext, onInvalid } =
        props;

    const defaults = formObject;

    const methods =
        formContext ||
        useForm({
            mode: 'onTouched',
            defaultValues: defaults as { [x: string]: any },
        });

    const { handleSubmit, formState, control, trigger } = methods;
    const { isValid } = formState;
    const isSubmitting = props.isSubmitting ?? formState.isSubmitting;

    const innerHandleSubmit = () => {
        return handleSubmit(onSubmit, onInvalid);
    };

    return (
        <FormProvider {...methods}>
            {/* <h6>rendercount {renderCount} </h6> */}
            {/* <DevTool control={control} /> */}
            {/* <ReactJson src={formState.errors} /> */}
            <form data-testid="FlexyForm" onSubmit={innerHandleSubmit()} noValidate>
                <FormStructureRenderer
                    structure={formStructure}
                    basePath=""
                    inputsProps={inputsProps}
                    gridProps={gridProps}
                />
                {/* <ReactJson src={{isSubmitting, isValid}} /> */}
                {submitButton?.render ? (
                    submitButton.render(formState, innerHandleSubmit)
                ) : submitButton?.displayed ||
                  submitButton?.displayed === undefined ||
                  !submitButton ? (
                    <Box
                        display="flex"
                        justifyContent={
                            submitButton?.position === 'left' ? 'flex-start' : 'flex-end'
                        }
                        alignItems={submitButton?.position === 'left' ? 'flex-start' : 'flex-end'}
                    >
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            variant="contained"
                            style={{ marginTop: '2rem', marginBottom: '16px' }}
                        >
                            {submitButton?.label || defaultSubmitButtonLabel}
                        </Button>
                    </Box>
                ) : null}
            </form>
        </FormProvider>
    );
};

FlexyForm.defaultProps = {
    inputsProps: {
        size: 'small',
        fullWidth: true,
    },
    gridProps: {
        rowSpacing: 0,
        columnSpacing: 2,
    },
    submitButton: {
        displayed: true,
        position: defaultSubmitButtonPosition,
        label: defaultSubmitButtonLabel,
    },
};

export default FlexyForm;
