import * as React from 'react';
import { AddressCard, ContactCard } from '@europrocurement/flexy-components';
import { Card, Grid } from '@mui/material';
import {
    AdresseJsonldTiersRead,
    ContactJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';

type ContactAndAddressRowProps = {
    handleEditContact: (contact: ContactJsonldTiersRead) => void;
    handleEditAddress: (address: AdresseJsonldTiersRead) => void;
    addresses: AdresseJsonldTiersRead[];
    contacts: ContactJsonldTiersRead[];
};

const ContactAndAddressRow: React.FC<ContactAndAddressRowProps> = (props) => {
    const { handleEditContact, handleEditAddress, contacts, addresses } = props;

    const mainContact = contacts.find(({ principal }) => principal);
    const mainAddress = addresses.find(({ defaut }) => defaut);

    const billingContact = contacts.find(({ comptabilite }) => comptabilite);
    const isSameContact: boolean = mainContact === billingContact;

    return (
        <>
            {/* Contact */}
            <Grid item xs={!billingContact || isSameContact ? 6 : 4}>
                {mainContact ? (
                    <ContactCard
                        contact={mainContact}
                        editAction={() => handleEditContact(mainContact)}
                    />
                ) : (
                    <Card
                        sx={{
                            minHeight: 'fit-content',
                            height: '90%',
                        }}
                    >
                        Pas de contact
                    </Card>
                )}
            </Grid>

            {!isSameContact && billingContact && (
                <Grid item xs={4}>
                    <ContactCard
                        contact={billingContact}
                        editAction={() => handleEditContact(billingContact)}
                    />
                </Grid>
            )}

            {/* Address */}
            <Grid item xs={isSameContact || !billingContact ? 6 : 4}>
                {mainAddress ? (
                    <AddressCard
                        address={mainAddress}
                        onEdit={() => handleEditAddress(mainAddress)}
                    />
                ) : (
                    <Card>{"Pas d'adresse"}</Card>
                )}
            </Grid>
        </>
    );
};

export default ContactAndAddressRow;
