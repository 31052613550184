import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { customizerSelector } from '../../../redux/storeConfig/selectors';
import { FlexyLayoutState } from '@europrocurement/flexy-components/redux/storeConfig/store';

export type LogoIconProps = {
    src?: string | { light: string; dark: string };
    redirect?: string;
};

const LogoIcon: React.FunctionComponent<LogoIconProps> = function ({
    src,
    redirect,
}: LogoIconProps) {
    const activeMode = useSelector((state: FlexyLayoutState) => state.customizer.activeMode);
    const [logo, setLogo] = React.useState<string>();

    useEffect(() => {
        if (typeof src !== 'string') setLogo(activeMode === 'dark' ? src?.dark : src?.light || '');
        else setLogo(src);
    }, [activeMode, src]);

    if (redirect)
        return (
            <Link to={redirect || '/'}>
                {src ? (
                    <img src={logo} alt="logo" style={{ width: '100%', objectFit: 'contain' }} />
                ) : null}
                {!src && <span>Logo</span>}
            </Link>
        );

    return (
        <>
            {src && <img src={logo} alt="logo" style={{ width: '100%', objectFit: 'contain' }} />}
            {!src && <span>Logo</span>}
        </>
    );
};

export default LogoIcon;
