import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import asyncDispatchMiddleware from 'async-dispatch';
import {
    // Reducers statiques :
    CustomizerReducer,
    RoutingReducer,

    // Tiers :
    ConfigurationParameters,
    createTiersReducer,
    PrescripteursApi,
    Configuration,
    TIERS_REDUCER_NAME,
    PAYS_SLICE_NAME,
    WALLET_OPTIONS_SLICE_NAME,
    CIVILITIES_SLICE_NAME,
    CSP_OPTIONS_SLICE_NAME,
    FOURNISSEUR_SLICE_NAME,
    PRESCRIPTEUR_SLICE_NAME,
    TIERS_FOURNISSEUR_SLICE_NAME,
    LogoApi,
    CategorieSocioProfessionnelleApi,
    CivilitesApi,
    PortefeuilleApi,

    // Achats :
    AchatsConfigurationParameters,
    createMediaobjectReducer,
    createFactureAchatReducer,
    createCategorieStatsReducer,
    createCodeRejetStatsReducer,
    MediaObjectApi,
    FactureAchatApi,
    AchatsConfiguration,
    ACHATS_REDUCER_NAME,
    CATEGORIE_STATS_SLICE_NAME,
    CODE_REJET_STATS_SLICE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    MEDIAOBJECT_SLICE_NAME,

    // Dossiers :
    DossiersConfigurationParameters,
    createRubriqueFacturationReducer,
    createJournalReducer,
    createDossierReducer,
    createFactureVenteReducer,
    createCommandeReducer,
    ContactsApi,
    DOSSIERS_REDUCER_NAME,
    COMMANDES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    JOURNAL_SLICE_NAME,
    RUBFACT_SLICE_NAME,
    AdressesApi,
    APIENV,
    FACTURES_ACHATS_PAUSE_DATASOURCE_NAME,
    FactureAchatLigneApi,
} from '@europrocurement/l2d-domain';
import { keycloak } from '../keycloak';
import FactureFormReducer from './FactureFormReducer';
import { getCustomiserState } from '../utils/customizerLocalSorageState';
import {
    CategorieSocioProfessionnelleJsonldTiersRead,
    PortefeuilleJsonldTiersRead,
    TblParametreReferencesCiviliteJsonldCiviliteReadCollection,
    TblPaysJsonldPaysRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';

const updateToken = () => keycloak.updateToken(10).then(() => keycloak.token || '');

/**
 * Prescripteurs
 */
const configTiers: ConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.tiers[getCustomiserState().api.tiers],
};

export const prescripteursApi = new PrescripteursApi(new Configuration(configTiers));
export const contactsApi = new ContactsApi(new Configuration(configTiers));
export const adressesApi = new AdressesApi(new Configuration(configTiers));
export const categorieSocioProfessionnelleApi = new CategorieSocioProfessionnelleApi(
    new Configuration(configTiers),
);
export const civilitesApi = new CivilitesApi(new Configuration(configTiers));
export const portefeuilleApi = new PortefeuilleApi(new Configuration(configTiers));

export const logoSpecificationsApi = new LogoApi(new Configuration(configTiers));

export const {
    prescripteurSlice,
    prescripteurDataSourcesThunks,
    fournisseurSlice,
    fournisseurDataSourcesThunks,
    tiersFournisseurSlice,
    tiersFournisseurDataSourcesThunks,
    paysSlice,
    cspOptionsSlice,
    walletOptionsSlice,
    paysDataSourcesThunks,
    civilitiesSlice,
    walletOptionsDataSourcesThunks,
    cspOptionsDataSourcesThunks,
    civilitiesDataSourcesThunks,
} = createTiersReducer(configTiers);

/**
 * Achats
 */
const configAchats: AchatsConfigurationParameters = {
    basePath: APIENV.apis.achats[getCustomiserState().api.achats],
    accessToken: updateToken,
};

const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
    createCategorieStatsReducer(configAchats);

const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
    createCodeRejetStatsReducer(configAchats);

const { factureAchatSlice, factureAchatDataSourcesThunks } =
    createFactureAchatReducer(configAchats);

const { mediaobjectSlice, mediaobjectDataSourcesThunks } = createMediaobjectReducer(configAchats);

/**
 * Dossiers
 */
const configDossiers: DossiersConfigurationParameters = {
    basePath: APIENV.apis.dossiers[getCustomiserState().api.dossiers],
    accessToken: updateToken,
};

export const mediaObjectApi = new MediaObjectApi(new AchatsConfiguration(configAchats));
export const factureAchatApi = new FactureAchatApi(new AchatsConfiguration(configAchats));
export const factureAchatLigneApi = new FactureAchatLigneApi(new AchatsConfiguration(configAchats));

// export const { annonceSlice, annonceDataSourcesThunks } =
//     createAnnonceReducer(configDossiers);

export const { commandeSlice, commandeDataSourcesThunks } = createCommandeReducer(configDossiers);

export const { dossierSlice, dossierDataSourcesThunks } = createDossierReducer(configDossiers);

export const { factureVenteSlice, factureVenteDataSourcesThunks } =
    createFactureVenteReducer(configDossiers);

export const { journalSlice, journalDataSourcesThunks } = createJournalReducer(configDossiers);

export const { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks } =
    createRubriqueFacturationReducer(configDossiers);

export const rootReducer = combineReducers({
    customizer: CustomizerReducer,
    factureForm: FactureFormReducer,
    [ACHATS_REDUCER_NAME]: combineReducers({
        [CATEGORIE_STATS_SLICE_NAME]: categorieStatsSlice.reducer,
        [CODE_REJET_STATS_SLICE_NAME]: codeRejetStatsSlice.reducer,
        [FACTURES_ACHATS_SLICE_NAME]: factureAchatSlice.reducer,
        [MEDIAOBJECT_SLICE_NAME]: mediaobjectSlice.reducer,
    }),
    [DOSSIERS_REDUCER_NAME]: combineReducers({
        // [ANNONCES_SLICE_NAME]: annonceSlice.reducer,
        [COMMANDES_SLICE_NAME]: commandeSlice.reducer,
        [DOSSIERS_SLICE_NAME]: dossierSlice.reducer,
        [FACTURE_VENTE_SLICE_NAME]: factureVenteSlice.reducer,
        [JOURNAL_SLICE_NAME]: journalSlice.reducer,
        [RUBFACT_SLICE_NAME]: rubriqueFacturationSlice.reducer,
    }),
    [TIERS_REDUCER_NAME]: combineReducers({
        [FOURNISSEUR_SLICE_NAME]: fournisseurSlice.reducer,
        [PRESCRIPTEUR_SLICE_NAME]: prescripteurSlice.reducer,
        [TIERS_FOURNISSEUR_SLICE_NAME]: tiersFournisseurSlice.reducer,
        [PAYS_SLICE_NAME]: paysSlice.reducer,
        [CIVILITIES_SLICE_NAME]: civilitiesSlice.reducer,
        [CSP_OPTIONS_SLICE_NAME]: cspOptionsSlice.reducer,
        [WALLET_OPTIONS_SLICE_NAME]: walletOptionsSlice.reducer,
    }),
    routing: RoutingReducer,
});

export const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncDispatchMiddleware),
});

export type RootStateType = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;

/**
 * Customizer
 */
export const customizerSelector = (s: RootStateType) => s.customizer;

/**
 * FactureForm
 */
export const FactureFormSelector = (s: RootStateType) => s.factureForm;

/**
 * Tiers
 */
export const FournisseurSelector = (s: RootStateType) => s.tiers.fournisseur;
export const getFournisseurs = fournisseurDataSourcesThunks.main.getData;
export const selectFournisseur = fournisseurDataSourcesThunks.main.getSelected;
export const getFournisseurAc = fournisseurDataSourcesThunks.autocompleteFactures.getData;
export const selectFournisseurAc = fournisseurDataSourcesThunks.autocompleteFactures.getSelected;

export const PrescripteursSelector = (s: RootStateType) => s.tiers.prescripteur;
export const getPrescripteurs = prescripteurDataSourcesThunks.main.getData;
export const selectPrescripteur = prescripteurDataSourcesThunks.main.getSelected;
export const getExportPrescripteurs = prescripteurDataSourcesThunks.export.getData;
export const selectExportPrescripteurs = prescripteurDataSourcesThunks.export.getSelected;

export const TiersFournisseurSelector = (s: RootStateType) => s.tiers.tiersFournisseur;
export const getTiersFournisseurs = tiersFournisseurDataSourcesThunks.main.getData;
export const selectTiersFournisseur = tiersFournisseurDataSourcesThunks.main.getSelected;

export const PaysSelector = (s: RootStateType) => s[TIERS_REDUCER_NAME][PAYS_SLICE_NAME];
export const getPays = paysDataSourcesThunks.main.getData;
export const selectPays = paysDataSourcesThunks.main.getSelected;

export const CivilitiesSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME];
export const getCivilities = civilitiesDataSourcesThunks.main.getData;
export const selectCivilities = civilitiesDataSourcesThunks.main.getSelected;

export const WalletOptionsSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME];
export const getWalletOptions = walletOptionsDataSourcesThunks.main.getData;
export const selectWalletOptions = walletOptionsDataSourcesThunks.main.getSelected;

export const CspOptionsSelector = (s: RootStateType) =>
    s[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME];
export const getCspOptions = cspOptionsDataSourcesThunks.main.getData;
export const selectCspOptions = cspOptionsDataSourcesThunks.main.getSelected;

type OptionsStore = (store: RootStateType) => {
    countries: TblPaysJsonldPaysRead[];
    cspOptions: CategorieSocioProfessionnelleJsonldTiersRead[];
    walletOptions: PortefeuilleJsonldTiersRead[];
    civilities: TblParametreReferencesCiviliteJsonldCiviliteReadCollection[];
};

export const OptionsSelector: OptionsStore = (store: RootStateType) => {
    return {
        countries: store[TIERS_REDUCER_NAME][PAYS_SLICE_NAME].main.data,
        cspOptions: store[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME].main.data,
        walletOptions: store[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME].main.data,
        civilities: store[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME].main.data,
    };
};
export const OptionsLoaded = (store: RootStateType) => {
    type OptionSlice =
        | typeof PAYS_SLICE_NAME
        | typeof CSP_OPTIONS_SLICE_NAME
        | typeof WALLET_OPTIONS_SLICE_NAME
        | typeof CIVILITIES_SLICE_NAME;

    const optionSlicesNames = [
        PAYS_SLICE_NAME,
        CSP_OPTIONS_SLICE_NAME,
        WALLET_OPTIONS_SLICE_NAME,
        CIVILITIES_SLICE_NAME,
    ];

    optionSlicesNames.forEach((sliceName: OptionSlice) => {
        const sliceStatus = store[TIERS_REDUCER_NAME][sliceName].main.status;
        if (sliceStatus !== 'succeeded') {
            return false;
        }
    });

    return true;
};

/**
 * Achats
 */
export const CategorieStatsSelector = (s: RootStateType) => s.achats.categoriestats;
export const getCategorieStats = categorieStatsDataSourcesThunks.main.getData;
export const selectCategorieStats = categorieStatsDataSourcesThunks.main.getSelected;

export const CodeRejetStatsSelector = (s: RootStateType) => s.achats.coderejetstats;
export const getCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getData;
export const selectCodeRejetStats = codeRejetStatsDataSourcesThunks.main.getSelected;

export const FactureachatSelector = (s: RootStateType) => s.achats.factureachat;
export const getFactureAchat = factureAchatDataSourcesThunks.main.getData;
export const selectFactureAchat = factureAchatDataSourcesThunks.main.getSelected;

export const getFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_PAUSE_DATASOURCE_NAME].getData;
export const selectFactureAchatPause =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const getFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getData;
export const selectFactureAchatStats =
    factureAchatDataSourcesThunks[FACTURES_ACHATS_STATS_DATASOURCE_NAME].getSelected;

export const MediaObjectSelector = (s: RootStateType) => s.achats.mediaobject;
export const getMediaObject = mediaobjectDataSourcesThunks.main.getData;
export const selectMediaObject = mediaobjectDataSourcesThunks.main.getSelected;

/**
 * Dossiers
 */
// export const AnnonceSelector = (s: RootStateType) => s.dossiers.ann;

// export const getAnnonce = annonceDataSourcesThunks.main.getData;
// export const selectAnnonce = annonceDataSourcesThunks.main.getSelected;

export const CommandeSelector = (s: RootStateType) => s.dossiers.commande;
export const getCommande = commandeDataSourcesThunks.main.getData;
export const selectCommande = commandeDataSourcesThunks.main.getSelected;

export const DossierSelector = (s: RootStateType) => s.dossiers.dos;
export const getDossier = dossierDataSourcesThunks.main.getData;
export const selectDossier = dossierDataSourcesThunks.main.getSelected;
export const selectAutocompleteDossier = dossierDataSourcesThunks.autocompleteDossiers.getSelected;

export const FactureVenteSelector = (s: RootStateType) => s.dossiers.facturevente;
export const getFactureVente = factureVenteDataSourcesThunks.main.getData;
export const selectFactureVente = factureVenteDataSourcesThunks.main.getSelected;

export const JournalSelector = (s: RootStateType) => s.dossiers.jal;
export const getJournal = journalDataSourcesThunks.main.getData;
export const selectJournal = journalDataSourcesThunks.main.getSelected;

export const RubriqueFacturationSelector = (s: RootStateType) => s.dossiers.rubfac;
export const getRubFac = rubriqueFacturationDataSourcesThunks.main.getData;
export const selectRubFac = rubriqueFacturationDataSourcesThunks.main.getSelected;

export default rootStore;
