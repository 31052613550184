// Request data structure

const defaultParams = {
    clientSuivi: true,
    venteIndirecte: true,
    creerLogin: true,
    delaiPaiement: 1,
};

const DEFAULT_CIVILITY_ID = 0;

export const formatPayload = (formData: any, xIdSociete: number) => {
    const {
        raisonSociale,
        siret,
        siren,
        tvaIntracommunautaire,
        adresse1,
        adresse2,
        adresse3,
        adresse4,
        codePostal,
        ville,
        codePays,
        civilite,
        nomContact,
        prenomContact,
        telephone,
        portefeuille,
        csp,
        mobile,
        email,
        personnePhysique,
        demo,
    } = formData;

    const { clientSuivi, venteIndirecte, creerLogin, delaiPaiement } = defaultParams;

    const address = {
        adresse1,
        adresse2,
        adresse3,
        adresse4,
        codePostal,
        ville,
        codePays: codePays.codeIso2,
    };

    const contact = {
        civilite: civilite?.id ?? DEFAULT_CIVILITY_ID,
        nomContact,
        prenomContact,
        telephonesContact: [...(telephone && { telephone })],
        mobilesContact: [...(mobile && { mobile })],
        faxsContact: [],
        emailsContact: [
            {
                email,
            },
        ],
    };

    const companyRelations = {
        societe: {
            id: xIdSociete,
        },
        delaiPaiement,
        ...(portefeuille?.id && { portefeuille: { id: portefeuille.id } }),
        ...(csp?.id && { categorieSocioProfessionnelle: { id: csp.id } }),
    };
    const payload = {
        raisonSociale,
        siret,
        siren,
        tvaIntracommunautaire,
        personnePhysique,
        demo,
        clientSuivi,
        venteIndirecte,
        creerLogin,
        adresses: [address],
        contacts: [contact],
        relationsSocietes: [companyRelations],
    };

    return payload;
};
