import { Commande, Dossier, FactureVenteApiObject } from '@europrocurement/l2d-domain';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { RootStateType, selectCommande } from '@b2d/redux/RootStore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';

export const useUpdateDomaine = function () {
    const dispatch = useDispatch();

    const dossierSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers.selected,
        shallowEqual,
    );

    const commandeDossierSelected = dossierSelected?.commande;

    const commandeSelected: Commande | undefined = useSelector(
        (s: RootStateType) => s.dossiers.commande.main.selected,
        shallowEqual,
    );

    const commandeStatus: EuroprocApiResponseStatus = useSelector(
        (s: RootStateType) => s.dossiers.commande.main.status,
        shallowEqual,
    );

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    return (factureVente: FactureVenteApiObject | undefined) => {
        if (factureVente && factureVente.idDdm) {
            if (domaine !== factureVente.idDdm) {
                dispatch({
                    type: ACTIONS.SET_DEF_DOMAINE,
                    payload: factureVente.idDdm,
                });
            }
        } else if (commandeDossierSelected) {
            let commandeDossierId = commandeDossierSelected.split('/')[3];

            if (
                commandeStatus !== 'loading' &&
                (!commandeSelected || `${commandeSelected.id}` !== commandeDossierId)
            ) {
                dispatch(
                    selectCommande({
                        id: commandeDossierId,
                    }),
                );
            }
        }
    };
};
