import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { useDispatch } from 'react-redux';
import { ColumnDatatable } from '../ColumnDatatable';
import { FlexyDatatable } from '../FlexyDatatable';
import { FiltersDatatableList, FiltersOpts, OrdersOpts } from '../DatatableHeader';
import { SxProps } from '@mui/material';
import { DatatablePagination, FlexyDatatableProps } from '../FlexyDatatableTable';
import { FlexyDatatableRaw } from '../FlexyDatatableRaw';

export type ExportMapper<T> = (item: T) => [...(string | number | Date)[]];

export type ExportOps<T> = {
    exportMapper: ExportMapper<T>;
    // getExportData: AsyncThunk<ExportDataAsyncThunk, ExportMapper<T>, Record<string, unknown>>;
};

export type StoreDatatableProps<T extends Record<string, unknown>> = {
    dataSource: DataSource<T>;
    fetchData: getDataThunkType<T>;
    columns: ColumnDatatable<T>[];
    filters?: FiltersDatatableList;
    observers?: Array<unknown>;
    /**
     * info : If this props is defined, the columns parameters is saved in localStorage
     *
     * Warning : if you use localStorage with localStorageKey, columns property need to have memoized with React.useMemo
     */
    localStorageKey?: string;
    localStorageRefreshDate?: Date;
    exportOpts?: ExportOps<T>;
    onClickRow?: FlexyDatatableProps['onClickRow'];
    filtersControl?: boolean;
    isSearch?: boolean;
    isExport?: boolean;
    isFilters?: boolean;
    mode?: 'classic' | 'raw';
    fetchWithoutFilter?: boolean;
    sx?: SxProps;
};

export const StoreDatatable = function <T extends Record<string, unknown>>({
    dataSource,
    fetchData,
    columns,
    filters,
    localStorageKey,
    localStorageRefreshDate,
    exportOpts,
    onClickRow,
    observers = [],
    filtersControl = true,
    isSearch = true,
    isExport = true,
    isFilters = true,
    mode = 'classic',
    fetchWithoutFilter = true,
    sx,
}: StoreDatatableProps<T>) {
    const dispatch = useDispatch();

    const sliceName = dataSource.slicename;

    const [activeFilters, setActiveFilters] = React.useState(dataSource.filters);

    /**
     * Changement de pagination
     */
    const onPageChange = useCallback(
        (page: number) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Pagination`,
                payload: { ...dataSource.pagination, page },
            });
            dispatch(fetchData({}));
        },
        [dispatch, dataSource.pagination, sliceName],
    );

    /**
     * Changement du nombre d'éléments sur la page
     */
    const onItemsPerPageChange = useCallback(
        (itemsPerPage: number) => {
            dispatch({
                type: `${sliceName}/set${dataSource.name}Pagination`,
                payload: { ...dataSource.pagination, itemsPerPage, page: 0 },
            });
            dispatch(fetchData({}));
        },
        [dispatch, dataSource.pagination, sliceName],
    );

    const onSearchChange = useDebouncedCallback((search) => {
        dispatch({
            type: `${sliceName}/set${dataSource.name}Search`,
            payload: { search },
        });
        dispatch(fetchData({}));
    }, 1000);

    const onFilterChangeDatasource = useDebouncedCallback((key, value) => {
        dispatch({
            type: `${sliceName}/set${dataSource.name}Filter`,
            payload: { key, value },
        });
        dispatch(fetchData({}));
    }, 500);

    const onOrdersChange = useCallback(
        (field: string, value: 'asc' | 'desc' | undefined) => {
            console.log("c'est order qui change", field);
            dispatch({
                type: `${sliceName}/set${dataSource.name}Order`,
                payload: { field, value },
            });
            dispatch(fetchData({}));
        },
        [dispatch, sliceName],
    );

    const onFilterDelete = (field: string) => {
        console.log('filtre delete', field);
        dispatch({
            type: `${sliceName}/delete${dataSource.name}Filter`,
            payload: { field },
        });
        dispatch(fetchData({}));
        let newFilters = { ...activeFilters };
        delete newFilters[field];
        // replaceActiveFilters ? replaceActiveFilters(newFilters) : setLocalActiveFilters(newFilters);
    };

    let filtersOpts: FiltersOpts | undefined = filters
        ? {
              onFilterChange: (key, value) => {
                  let newFilters = { ...activeFilters };
                  newFilters[key] = value;
                  setActiveFilters(newFilters);
                  onFilterChangeDatasource(key, value);
              },
              filters,
              activeFilters: activeFilters,
              onFilterDelete,
          }
        : undefined;

    const ordersOpts: OrdersOpts = { onOrdersChange, orders: dataSource.orders };

    const pagination: DatatablePagination = {
        ...dataSource.pagination,
        onPageChange,
        onItemsPerPageChange,
    };

    useEffect(() => {
        if (dataSource.status === 'loading' || dataSource.status === 'succeeded') return;
        if (dataSource.status === 'idle' || dataSource.data.length === 0) {
            if (fetchWithoutFilter) {
                dispatch(fetchData({}));
            }
        }
    }, [dispatch, fetchData]);

    useEffect(() => {
        if (observers.length > 0) {
            dispatch(fetchData({}));
        }
    }, [dispatch, fetchData, ...observers]);

    const [updatedColumns, setUpdatedColumns] = useState<ColumnDatatable<T>[]>(columns);

    const saveUpdatedColumns = function (toUpdate: ColumnDatatable[], localStorageKey: string) {
        const ColumnsBeforeStorage = toUpdate.map(({ label, isDisplayed }) => ({
            label,
            isDisplayed,
        }));
        if (localStorage.getItem(localStorageKey) !== JSON.stringify(ColumnsBeforeStorage)) {
            localStorage.setItem(localStorageKey, JSON.stringify(ColumnsBeforeStorage));
            localStorage.setItem(`${localStorageKey}_date`, new Date().toISOString());
        }
    };

    // Check if columns exist in localstorage and merge his with initialsColumns
    useEffect(() => {
        if (localStorageKey) {
            if (localStorageRefreshDate) {
                const columnsDate = localStorage.getItem(`${localStorageKey}_date`);
                if (columnsDate) {
                    console.log(
                        new Date(columnsDate) < localStorageRefreshDate,
                        new Date(columnsDate),
                        localStorageRefreshDate,
                    );
                    console.log(columnsDate);
                }
                if (!columnsDate || new Date(columnsDate) < localStorageRefreshDate) {
                    setUpdatedColumns(columns);
                    saveUpdatedColumns(columns, localStorageKey);
                    return;
                }
            }

            const columnsStored = localStorage.getItem(localStorageKey);

            if (columnsStored) {
                const columnsParsed: ColumnDatatable[] = JSON.parse(
                    columnsStored,
                ) as ColumnDatatable[];

                const columnsMerged = columns.map((col) => {
                    let obj = { ...col };
                    for (let i = 0; i < columnsParsed.length; i += 1) {
                        const cp = columnsParsed[i];
                        if (cp.label === col.label) {
                            obj = { ...col, ...cp };
                        }
                    }
                    return obj as ColumnDatatable;
                });
                setUpdatedColumns(columnsMerged);
                saveUpdatedColumns(columnsMerged, localStorageKey);
            }
        }
    }, [columns, localStorageKey]);

    const handleExport: FlexyDatatableProps['handleExport'] = async () => {
        if (exportOpts) {
            // const { exportMapper } = exportOpts;
            // dispatch(getExportData(exportMapper));
        } else
            console.error(
                'exportOpts is undefined (File: StoreDatatable.tsx; function: handleExport',
            );
    };

    const props: FlexyDatatableProps = {
        status: dataSource.status,
        data: dataSource.data,
        columns: updatedColumns,
        pagination: pagination,
        handleExport: exportOpts && handleExport,
        onClickRow: onClickRow,
        filtersControl: filtersControl,
        isSearch: isSearch,
        isExport: isExport,
        isFilters: isFilters,
        setColumns: (settedcolumns: ColumnDatatable[]) => {
            setUpdatedColumns(settedcolumns);
            if (localStorageKey) {
                saveUpdatedColumns(settedcolumns, localStorageKey);
            }
        },
        searchOpts: {
            onSearchChange,
            search: dataSource.search,
            filtersOpts,
            ordersOpts,
        },
        sx: { ...sx },
    };

    return mode === 'raw' ? <FlexyDatatableRaw {...props} /> : <FlexyDatatable {...props} />;
};

StoreDatatable.defaultProps = {
    filters: undefined,
};

export default StoreDatatable;
