import * as React from 'react';
import { AxiosError } from 'axios';
import { prescripteursApi as prescriberApi } from '@b2d/redux/RootStore';
import { ConfigurationCard, relSociete } from '@europrocurement/flexy-components';
import { CustomizerReducerType, Prescripteur } from '@europrocurement/l2d-domain';
import { RelationSocieteTiersJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
    getPrescriberRightsPayload,
    getPrescripteurCompanyRelationPayload,
} from '../../forms/payloads';

type ConfigurationRowProps = {
    companyRelation: RelationSocieteTiersJsonldTiersRead | undefined;
    prescriber: Prescripteur;
    companyId?: CustomizerReducerType['xIdSociete'];
    reloadPrescriber: () => void;
};

const ConfigurationRow: React.FC<ConfigurationRowProps> = (props) => {
    const { companyRelation, reloadPrescriber, prescriber, companyId } = props;

    const { enqueueSnackbar } = useSnackbar();

    const updatePrescripteurRights = async (
        field: 'affichesProAutorise' | 'formalProAutorise',
        newValue: boolean,
    ) => {
        if (!prescriber.id || !companyRelation) {
            return;
        }

        const baseFormData = getPrescriberRightsPayload(companyRelation);

        const fieldName = getFormField(field);

        if (!fieldName) {
            return;
        }

        const updatedForm = {
            ...baseFormData,
            [fieldName]: newValue,
        };

        await prescriberApi
            .updateDroitsPrescripteurTiersItem(prescriber.id.toString(), updatedForm)
            .catch((error: AxiosError) => {
                if (error?.response?.status && error.response.status === 404) {
                    enqueueSnackbar('Utilisateur non trouvé', { variant: 'error' });
                }
            });

        reloadPrescriber();
    };

    const getFormField = (
        // Usage : updatePrescripteurRights
        fieldName: keyof { affichesProAutorise: string; formalProAutorise: string },
    ): string | null => {
        const fieldsMapping = {
            affichesProAutorise: 'accesAffichesPro',
            formalProAutorise: 'accesFormalPro',
        };

        return fieldsMapping[fieldName] ?? null;
    };

    const updatePrescripteurCompanyRelation = async (field: string, newValue: boolean) => {
        if (!prescriber.id || !companyId || !companyRelation) {
            return;
        }

        const baseFormData = getPrescripteurCompanyRelationPayload(companyRelation);

        const updatedForm = {
            ...baseFormData,
            [field]: newValue,
        };

        await prescriberApi.updateRelationSocietePrescripteurRelationSocieteTiersItem(
            prescriber.id,
            companyId.toString(),
            updatedForm,
        );
        reloadPrescriber();
    };

    const changePrescripteurConfigurationAction = (field: keyof relSociete, newValue: boolean) => {
        switch (field) {
            case 'affichesProAutorise':
            case 'formalProAutorise':
                return updatePrescripteurRights(field, newValue);
            case 'clientSuivi':
            case 'clientXl':
                return updatePrescripteurCompanyRelation(field, newValue);
        }
    };

    return (
        <Grid item xs={12}>
            {companyRelation && (
                <ConfigurationCard
                    companyRelation={companyRelation}
                    changeConfiguration={changePrescripteurConfigurationAction}
                />
            )}
        </Grid>
    );
};

export default ConfigurationRow;
