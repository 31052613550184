import * as React from 'react';
import { Close as IconClose } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

export type SnackbarCloseButtonProps = {
    snackbarKey: string | number;
};

const SnackbarCloseButton: React.FunctionComponent<SnackbarCloseButtonProps> = function ({
    snackbarKey,
}: SnackbarCloseButtonProps) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            onClick={() => closeSnackbar(snackbarKey)}
            sx={{
                color: 'white',
            }}
        >
            <IconClose />
        </IconButton>
    );
};

export default SnackbarCloseButton;
