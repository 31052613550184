import React, { type FunctionComponent } from 'react';
import { Box, Button } from '@mui/material';
import { DatatableHeaderProps } from '../DatatableHeader';

export type FlexyHandleExportProps = {
    handleExport?: DatatableHeaderProps['handleExport'];
};

const FlexyHandleExport: FunctionComponent<FlexyHandleExportProps> = function ({
    handleExport,
}: FlexyHandleExportProps) {
    return handleExport ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '15px',
                width: '100%',
            }}
        >
            <Button color="success" variant="contained" onClick={handleExport}>
                Exporter les données
            </Button>
        </Box>
    ) : null;
};

FlexyHandleExport.defaultProps = {
    handleExport: undefined,
};

export default FlexyHandleExport;
