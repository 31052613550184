import { UseFormReturn, FieldValues } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import {
    Dossier,
    FACTURE_VENTE_SLICE_NAME,
    FactureAchat,
    FactureVente,
    FactureVenteApiObject,
    Fournisseur,
    RubriqueFacturation,
} from '@europrocurement/l2d-domain';
import { TotauxFactureAchatType, factureFormObject, ligneProduit, tableauProduits } from '../types';
import { calculatorProduit, compareProduits, roundTo } from './calculsProduits';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { FactureFormSelector, RootStateType, selectFactureVente } from '@b2d/redux/RootStore';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { produitVenteToLigneProduit } from './dataTransformers';
import _ from 'lodash';
import { HeaderSwitch } from '@europrocurement/flexy-components';

/**
 * Met à jour le tableau de produits de la section "Rubriques"
 * 
 * @param formContext
 * @param produits
 */
export const replaceProduits = function (
    formContext: UseFormReturn<FormObject>,
    produits: Array<ligneProduit>,
) {
    // On ne remplace que si c'est pas les memes produits
    if (!_.isEqual(produits, formContext.getValues('produits'))) {
        console.log("setvalue produits normal", produits);
        // formContext.setValue('produits', produits);
        formContext.setValue('produits', produits.map((item)=>{
            if(item.ttc){
                item.ttc = (+item.ttc).toFixed(4)
            }
            return item;
        }));
    }
};


/**
 * Ordonnance les lignes grâce à la valeur de 'ordre' d'une ligneProduit.
 * Si 'ordre' n'est pas précisé, la ligne sera en bas du tableau.
 *
 * @param produits
 * @returns
 */
export const organiserProduits = function (produits: tableauProduits) {
    // console.log('Les produits suivants vont êtres organisés : ', produits);
    produits?.sort((a, b) => {
        // console.log('Les produits s\'organisent... ', a, b);

        if (!a.ordre) a.ordre = (
            produits.length === 2 &&
            a.rubriqueFacturation?.code !== 'RSF'
        ) ? produits.length - 1 : produits.length;
        if (!b.ordre) b.ordre = (
            produits.length === 2 &&
            a.rubriqueFacturation?.code !== 'RSF'
        ) ? produits.length + 1 : produits.length;
        return a.ordre - b.ordre;
    });

    return produits;
};


/**
 * Complète les champs TVA et TTC d'une ligne de produit pour un index donné.
 *
 * @param formContext
 * @param produit
 * @param index
 */
export const fillProduits = function (
    formContext: UseFormReturn<FieldValues, any>,
    produit: Partial<ligneProduit>,
    index: number,
) {
    const valeurs = calculatorProduit(produit);
    console.log(valeurs.tva.toFixed(4));
    console.log(valeurs.ttc.toFixed(4));
    formContext.setValue(`produits.${index}.tva`, valeurs.tva.toFixed(4));
    formContext.setValue(`produits.${index}.ttc`, valeurs.ttc.toFixed(4));
};

export const useActionOnChangeTauxRemise = function () {
    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    return (
        formContext: UseFormReturn<Partial<factureFormObject>, any>,
        produitConcernedByRsfExist: (produitConcernedByRsf: number) => void,
        produitConcernedByRsfDoNotExist: (message: string) => void,
    ) => {
        const produits = formContext.getValues('produits');

        if (produits) {
            const rubFactId = rubriquePrincipaleParDomaine[domaine - 1];
            const produitConcernedByRsf = produits.findIndex(
                (item) => item.rubriqueFacturation?.id === rubFactId,
            );
            const produitConcernedByRsfHt = produits[produitConcernedByRsf].ht;

            const occurrences: Record<string, number> = {};

            produits.forEach((ligneProduit) => {
                if (ligneProduit.rubriqueFacturation && ligneProduit.rubriqueFacturation.code) {
                    const code = ligneProduit.rubriqueFacturation.code;

                    if (occurrences[code]) {
                        occurrences[code] += 1;
                    } else {
                        occurrences[code] = 1;
                    }
                }
            });

            const atLeastOneRubFactIsNotUniq = _.some(occurrences, (occurrence) => occurrence > 1);

            const moreThanOneMainRubFact =
                _.filter(produits, (ligneProduit) => {
                    const id = ligneProduit.rubriqueFacturation?.id;
                    return id && rubriquePrincipaleParDomaine.includes(id);
                }).length >= 2;

            const complexe = atLeastOneRubFactIsNotUniq && moreThanOneMainRubFact;

            if (complexe) {
                const message = `Le cas de ce dossier est trop complexe. Traitez-le manuellement.`;
                produitConcernedByRsfDoNotExist(message);
                return;
            }

            if (produitConcernedByRsfHt && produitConcernedByRsfHt > 0) {
                produitConcernedByRsfExist(produitConcernedByRsf);
            } else {
                const message = `La valeur HT de la ligne ${rubriqueCodeById[rubFactId - 1]
                    } est nécessaire pour modifier le taux de remise.`;
                produitConcernedByRsfDoNotExist(message);
            }
        }
    };
};

/**
 * Hook pour mettre à jour les infos de la section "Rubriques"
 * si un dossier et une facture de vente sont sélectionnés.
 * Sinon, s'il y a un dossier mais pas de vente, alors :
 *      - Cacher le switch "Infos ventes".
 * Sinon, s'il n'y a aucun des deux, alors :
 *      - Supprimer la facture de vente du state;
 *      - Vider la liste de produits.
 *
 * @returns void
 */
export const updateFormWithVentes = function (
    formContext: UseFormReturn<FormObject>,
    dossierSelected: Dossier | null,
    fournisseurSelected: Fournisseur | null,
    venteSelected: FactureVente | null,
    callbacks?: {
        onDossierNotSelect?: () => void;
        onVenteSelected?: () => void;
        onVenteNotSelected?: (idFactureVente: number) => void;
    },
    updateProduits?: boolean
) {
    let idFactureVenteDossierSelected: number;

    if (dossierSelected && dossierSelected.facture) {
        idFactureVenteDossierSelected = +dossierSelected.facture.split('/')[3];
        // console.log("idFactureVenteDossierSelected", idFactureVenteDossierSelected);
        if (!venteSelected || venteSelected.id !== idFactureVenteDossierSelected) {
            if (callbacks?.onVenteNotSelected) {
                callbacks.onVenteNotSelected(idFactureVenteDossierSelected);
            }
        } else {
            if (callbacks?.onVenteSelected) {
                callbacks.onVenteSelected();
            }
        }
    } else if (dossierSelected && venteSelected) {
        if (callbacks?.onVenteSelected) {
            callbacks.onVenteSelected();
        }
    } else if (!dossierSelected) {
        // S'il n'y a pas de dossier sélectionné ,
        // on affiche les lignes par défaut d'un domaine PUB.
        // Si le fournisseur est RSF, on ajoute une ligne RSF.
        if (callbacks?.onDossierNotSelect) {
            callbacks.onDossierNotSelect();
        }

        if (updateProduits === false) return;

        let needReplace = false;
        const currentProduits = formContext.getValues('produits') as tableauProduits | undefined;

        if (currentProduits) {
            // Si on a au moins une ligne mappée avec une vente on remplace
            for (let index = 0; index < currentProduits.length; index++) {
                if (
                    !(
                        currentProduits[index].idLigneVente === undefined ||
                        `${currentProduits[index].idLigneVente}` === ''
                    )
                ) {
                    needReplace = true;
                }
            }

            if (
                fournisseurSelected &&
                fournisseurSelected.typeRemise &&
                fournisseurSelected?.typeRemise.code === 'RSF'
            ) {
                if (!findProduitByIdRubFact(formContext, 99)) {
                    needReplace = true;
                }
            }
            // Si on a pas assez de lignes on remplace
            if (
                (currentProduits.length < 2 &&
                    fournisseurSelected &&
                    fournisseurSelected.typeRemise &&
                    fournisseurSelected?.typeRemise.code === 'RSF') ||
                currentProduits.length < 1
            ) {
                needReplace = true;
            }
        } else {
            // Si on a pas de produits on remplace
            needReplace = true;
        }
        if (
            needReplace &&
            fournisseurSelected &&
            fournisseurSelected.typeRemise &&
            fournisseurSelected?.typeRemise.code === 'RSF'
        ) {
            // Ligne produit par défaut avec RSF
            console.log('DEBUT DU REMPLACEMENT DES PRODUITS (AVEC RSF)...');
            replaceProduits(formContext, defaultProduits(1, true));
            console.log('FIN DU REMPLACEMENT DES PRODUITS (AVEC RSF)...');
        } else if (needReplace) {
            // Ligne produit par défaut sans RSF
            console.log('DEBUT DU REMPLACEMENT DES PRODUITS (SANS RSF)...');
            replaceProduits(formContext, defaultProduits(1, false));
            console.log('FIN DU REMPLACEMENT DES PRODUITS (SANS RSF)...');
        } else {
            console.log("pas de replace");
        }
    }
};

/**
 * Retourne un tableau de produit contenant les lignes par défaut selon :
 *
 *     - Le domaine de la vente ou de la commande
 *     - Si la RSF s'applique
 *
 * @param idDdm
 * @param isRsf
 * @returns tableauProduits
 */
export const defaultProduits = function (idDdm: number = 1, isRsf: boolean): tableauProduits {
    // console.log('DOMAINE POUR LE CHOIX DES PRODUITS PAR DEFAUT : ', idDdm, ' RSF ? ', isRsf);
    let tableau: tableauProduits = [];

    // console.log('début lignes par défaut ', tableau);

    const baseProduit: ligneProduit = {
        ordre: 1,
        rubriqueFacturation: {
            id: 1,
            code: 'PUB',
        },
        ht: undefined,
        tva: undefined,
        txtva: 20,
        ttc: undefined,
    };

    switch (idDdm) {
        case 2:
            const produitMpu = _.cloneDeep(baseProduit);
            produitMpu.rubriqueFacturation = {
                id: 13,
                code: 'MPU'
            }
            tableau.push(produitMpu);
            break;
        case 6:
            const produitAdj = _.cloneDeep(baseProduit);
            produitAdj.rubriqueFacturation = {
                id: 6,
                code: 'ADJ'
            }
            // console.log('avant ADJ', tableau);
            tableau.push(produitAdj);
            // console.log('après ADJ', tableau);
            break;
        case 8:
            const produitEpu = _.cloneDeep(baseProduit);
            produitEpu.rubriqueFacturation = {
                id: 17,
                code: 'EPU'
            }
            tableau.push(produitEpu);
            break;
        case 3:
        case 4:
        case 5:
        case 7:
        case 9:
        default:
            const defaultProduit = _.cloneDeep(baseProduit);
            // console.log('avant DEFAULT', tableau);
            tableau.push(defaultProduit);
            // console.log('après DEFAULT', tableau);
            break;
    }

    if (isRsf) {
        const produitRsf = _.cloneDeep(baseProduit);
        produitRsf.ordre = 2;
        produitRsf.rubriqueFacturation = {
            id: 99,
            code: 'RSF',
        };
        // console.log('avant RSF', tableau);
        tableau.push(produitRsf)
        // console.log('après RSF', tableau);
    } else {
        const rsfToRemove = tableau.findIndex((ligne) => {
            return ligne.rubriqueFacturation?.code === 'RSF';
        })

        if (rsfToRemove) delete tableau[rsfToRemove];
    }

    // console.log('fin lignes par défaut ', tableau);

    return tableau;
};

export const useSetSwitchLigneVente = function () {
    const dispatch = useDispatch();
    const state = useSelector(FactureFormSelector);

    return (headerswitch: HeaderSwitch) => {
        if (
            !_.isEqual(state.switchLigneVente, headerswitch) &&
            !_.isEqual(state.switchLigneVente, headerswitch)
        ) {
            dispatch({
                type: ACTIONS.SET_SWITCH_LIGNE_VENTE,
                payload: headerswitch,
            });
        }
    };
};

export const useUpdateFormWithVentesAndEvents = function () {
    const dispatch = useDispatch();
    const setSwitchLigneVente = useSetSwitchLigneVente();

    return (
        formContext: UseFormReturn<FormObject>,
        dossierSelected: Dossier | null,
        fournisseurSelected: Fournisseur | null,
        ventesDataSourceSelected: FactureVente | null,
        ventesDataSourceStatus: EuroprocApiResponseStatus,
        updateProduits?: boolean
    ) => {
        // console.log('DEBUT MISE A JOUR DU FORMULAIRE AVEC LES VENTES...', formContext.getValues('produits'));
        updateFormWithVentes(
            formContext,
            dossierSelected,
            fournisseurSelected,
            ventesDataSourceSelected,
            {
                onDossierNotSelect: () => {
                    // console.log("pas de dossier")
                    if (ventesDataSourceSelected) {
                        dispatch({
                            type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
                        });
                    }
                },
                onVenteSelected: () => {
                    // console.log("j'ai une vente")
                    setSwitchLigneVente({
                        label: 'Afficher les informations de vente',
                    });
                },
                onVenteNotSelected: (idFactureVente) => {
                    // console.log("pas de vente")
                    if (
                        ventesDataSourceStatus !== 'loading' &&
                        (!ventesDataSourceSelected ||
                            ventesDataSourceSelected.id !== idFactureVente)
                    ) {
                        console.log("je vais chercher la vente")
                        dispatch(selectFactureVente({ id: idFactureVente }));
                    }
                    setSwitchLigneVente({
                        label: '',
                        sx: {
                            display: 'none',
                        },
                    });
                },
            },
            updateProduits === false ? false : true
        );
        // console.log('FIN MISE A JOUR DU FORMULAIRE AVEC LES VENTES...', formContext.getValues('produits'));
    };
};

/**
 * Supprime les produits vides d'une facture en erreur
 *
 * @param formContext
 * @returns
 */
export const removeEmptyProduits = function (
    formContext: UseFormReturn<Partial<factureFormObject>>,
) {
    const produits = formContext.getValues(`produits`) as tableauProduits;

    if (!formContext.formState.errors.produits?.length) {
        return;
    }
    let toremove: Array<number> = [];

    for (let index = 0; index < formContext.formState.errors.produits.length; index++) {
        if (formContext.formState.errors.produits[index] !== undefined) {
            toremove.push(index);
        }
    }
    console.log("setvalue removeEmpty", produits)
    formContext.setValue(
        'produits',
        produits.reduce((p: tableauProduits, c: ligneProduit, i) => {
            if (toremove.indexOf(i) === -1) {
                p.push(c);
            }
            return p;
        }, []),
    );
};

/**
 *
 * @returns Synchronise les produits de la vente et de l'achats
 */
export const useSyncAchatProduitsWithVente = function () {
    const dispatch = useDispatch();
    const stateProduitVente = useSelector(
        (s: RootStateType) => s.factureForm.produitsVente,
        _.isEqual,
    );
    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    return (
        factureVente: FactureVenteApiObject | undefined,
        formContext: UseFormReturn<Partial<factureFormObject>>,
        rubFacts: RubriqueFacturation[],
        dossier?: Dossier | undefined,
        change?: boolean
    ) => {

        // Rubriques du formulaire
        let produitsAchat: Partial<ligneProduit & { toDelete?: boolean }>[] =
            formContext.getValues().produits || [];

        const produitsAchatIdVente = produitsAchat.map((produitAchat) => produitAchat.idLigneVente);

        let reassignProduits = false;
        let changeAchat = change === false ? false : true; // si true => on change les achats

        if (factureVente && factureVente.lignes) {
            let produitsVente: TblFligneFactureVenteRead[] = []; // Rempli avec les lignes de ventes à afficher
            let produitsAchatAjoute: TblFligneFactureVenteRead[] = []; // Rempli avec les achats à afficher
            let produitsAchatSupprime: TblFligneFactureVenteRead[] = []; // Rempli avec les achats à ne pas afficher

            let idRubFactToDelete: string[] = [];

            // Pour chaque produit du formulaire :
            // Verifier s'il est bien dans la facture OU RSF.
            produitsAchat.forEach((produitAchat) => {
                // on ne supprime pas le rsf
                if (produitAchat.rubriqueFacturation?.id === 99) {
                    return;
                }
                // on ne supprime pas une ligne vide
                if (!produitAchat.rubriqueFacturation || !produitAchat.idLigneVente) {
                    return;
                }

                // on supprime si l'idLigneVente de l'achat ne correspond pas
                // à l'id du produit de vente.
                if (
                    factureVente?.lignes
                        ?.map((produitVente) => produitVente.id)
                        ?.indexOf(produitAchat.idLigneVente) === -1
                ) {
                    if (!produitAchat.ht) {
                        produitAchat.toDelete = true;
                        produitsAchatSupprime.push({
                            rubriqueFacturation:
                                produitAchat.rubriqueFacturation &&
                                produitAchat.rubriqueFacturation.id + '',
                        });
                    }
                }
            });

            // 1 - Prendre chaque produit de la facture
            // 2 - Pour chaque produit, rechercher dans les produits du formulaire s'il y est
            // 3 - S'il n'y est pas, on l'ajoute.
            factureVente.lignes.forEach((produitVente) => {
                if (
                    produitVente.affiche &&
                    produitVente.rubriqueFacturation !== 'LIB' &&
                    produitVente.rubriqueFacturation !== 'FRT'
                ) {
                    produitsVente.push(produitVente);

                    // Assure l'unicité de type pour chaque ligne d'achat à ajouter
                    if (changeAchat && produitsAchatIdVente.indexOf(produitVente.id) === -1) {
                        if (
                            dossier &&
                            dossier.prestations &&
                            produitVente.idAnnonce === dossier.prestations[0].id
                        ) {
                            produitsAchatAjoute.push(produitVente);
                            if (produitVente.rubriqueFacturation) {
                                idRubFactToDelete.push(produitVente.rubriqueFacturation);
                            }
                        }
                    }
                }
            });

            console.log("produitsVente", produitsVente);

            if (
                !_.isEqual(
                    produitsVente.map((item: RubriqueFacturation) => {
                        return produitVenteToLigneProduit(item, rubFacts);
                    }),
                    stateProduitVente,
                )
            ) {
                dispatch({ type: ACTIONS.INIT_PRODUITS_VENTE });
                dispatch({ type: ACTIONS.UPDATE_PRODUITS_VENTE, payload: produitsVente });
            }

            if (!changeAchat) {
                return;
            }

            // Supprime les lignes qui ne sont pas RSF ou à afficher
            if (produitsAchatSupprime.length > 0) {
                // const idASuppr = lignesASupprimer.map(item=>item.id)
                produitsAchat = produitsAchat.filter((produitAchat) => {
                    // si je l'ai deja flag comme a delete => on delete
                    if (produitAchat.toDelete === true) return false;
                    // s'il est dans ma liste de rubfact a virrer => on delete
                    if (
                        produitAchat.rubriqueFacturation?.code &&
                        idRubFactToDelete.indexOf(produitAchat.rubriqueFacturation?.code) !== -1
                    )
                        return false;
                    // sinon on delete pas
                    return true;
                });
                reassignProduits = true;
            }

            produitsAchat = produitsAchat.filter((produitAchat) => {
                // s'il est dans ma liste de rubfact a supprimer => on delete
                if (
                    produitAchat.rubriqueFacturation?.code &&
                    idRubFactToDelete.indexOf(produitAchat.rubriqueFacturation?.code) !== -1
                )
                    return false;
                // sinon on delete pas
                return true;
            });

            // Ajoute les lignes à afficher (i.e: PUB, JUS, RSF, ...)
            if (produitsAchatAjoute.length > 0) {
                produitsAchatAjoute.forEach((produitAchatAjoute) => {
                    let toAdd: Partial<ligneProduit> = {
                        idLigneVente: produitAchatAjoute.id,
                        txtva: 20,
                    };
                    if (
                        produitAchatAjoute.rubriqueFacturation &&
                        produitAchatAjoute.rubriqueFacturation !== ''
                    ) {
                        const rubId = rubFacts.find(
                            (rubFact: RubriqueFacturation) =>
                                rubFact.code === produitAchatAjoute.rubriqueFacturation,
                        )?.id;

                        if (!rubId) return;

                        toAdd.rubriqueFacturation = {
                            code: produitAchatAjoute.rubriqueFacturation,
                            id: rubId,
                        };
                    }

                    if (domaine) {
                        // console.log('DEBUT DE L\'APPLICATION DE L\'ORDRE SUR LE PRODUIT SUR LEQUEL APPLIQUER LA RSF...');
                        toAdd = remiseSurProduitByDomaine(domaine, toAdd);
                        // console.log('FIN DE L\'APPLICATION DE L\'ORDRE SUR LE PRODUIT SUR LEQUEL APPLIQUER LA RSF...');
                    }

                    produitsAchat.push(toAdd);
                });

                reassignProduits = true;
            }

            if (reassignProduits) {
                // produitsAchat.sort(compareProduits).map((produit) => {
                //     return {
                //         ...produit,
                //         ...calculatorProduit(produit),
                //     };
                // });
                console.log('DEBUT DE L\'ORGANISATION DES PRODUITS...');
                replaceProduits(formContext, organiserProduits(produitsAchat));
                console.log('FIN DE L\'ORGANISATION DES PRODUITS...');
            }

            // console.log('DEBUT DE L\'ORGANISATION DES PRODUITS...');
            // replaceProduits(formContext, organiserProduits(produitsAchat));
            // console.log('FIN DE L\'ORGANISATION DES PRODUITS...');
        }
    };
};

// Numéro dossier JAL : 1272353
// Numéro dossier JAL complexe : 957499

const rubriquePrincipaleParDomaine = [1, 13, 1, 1, 1, 6, 1, 17, 1, 1];
const rubriqueCodeById = [
    'PUB',
    'PUBNS',
    'JUS',
    'FRS',
    'FRN',
    'ADJ',
    'VAC',
    'REG',
    'LIB',
    'DIV',
    'RBF',
    'ADJI',
    'MPU',
    'LOGO',
    'FRT',
    'ENT',
    'EPU',
];

const remiseSurProduitByDomaine = (domaine: number, produit: ligneProduit): ligneProduit => {
    if (
        produit.rubriqueFacturation &&
        produit.rubriqueFacturation.id === rubriquePrincipaleParDomaine[domaine - 1]
    ) {
        // console.log('LA REMISE SERA APPLIQUÉE SUR LA LIGNE : ', produit);
        produit.ordre = 1;
    } else {
        // console.log('PAS D\'APPLICATION DE REMISE SUR LA LIGNE : ', produit);
    }
    return produit;
};

/**
 * Test s'il existe au moins une prestation du dossier ayant
 * la même entité facturante que la facture d'achat.
 *
 * @param factureAchat
 * @param dossier
 * @returns boolean
 */
export const isDossierFromFournisseur = function (
    factureAchat: FactureAchat,
    dossier: Dossier,
): boolean {
    const prestations = dossier.prestations?.find((value) => {
        return value.idEntiteFacturante === factureAchat.idEntiteFacturante;
    });

    return prestations !== undefined ? true : false;
};

export const findProduitByIdRubFact = function (
    formContext: UseFormReturn<Partial<factureFormObject>, any>,
    idRubFact: number,
) {
    return formContext
        .getValues()
        .produits?.find((item) => item.rubriqueFacturation?.id === idRubFact || false);
};

export const ajouterLigne = function (
    formContext: UseFormReturn<Partial<factureFormObject>, any>,
    produit: Partial<ligneProduit>,
) {
    let newProduitsForm: tableauProduits = [];
    let produitsValues = formContext.getValues('produits');

    if (produitsValues) {
        newProduitsForm = [...produitsValues];
    }

    newProduitsForm.push(produit);

    // console.log('organisation produits après ajout de ligne', newProduitsForm);
    newProduitsForm = organiserProduits(newProduitsForm);

    // newProduitsForm.forEach((ligneProduit, index) => {
    //     if (ligneProduit.rubriqueFacturation === undefined) newProduitsForm.splice(index);
    // });
    console.log("before ajouterligne");
    replaceProduits(formContext, newProduitsForm);
    console.log("after ajouterligne");
};

export const ajouterLigneRSF = function (
    formContext: UseFormReturn<Partial<factureFormObject>>,
    fournisseur: Fournisseur | undefined,
) {
    if (fournisseur && fournisseur.typeRemise?.code === 'RSF') {
        setTimeout(() => {
            let produitRsf = findProduitByIdRubFact(formContext, 99);
            if (!produitRsf) {
                ajouterLigne(formContext, {
                    ordre: 2,
                    rubriqueFacturation: {
                        id: 99,
                        code: 'RSF',
                    },
                    ht: undefined,
                    tva: undefined,
                    txtva: 20,
                    ttc: undefined,
                });
            }
        }, 300);
    }
};

export const findRubFactById = (rubFacts: RubriqueFacturation[], id: number | undefined) => {
    return rubFacts.find((rubFact: RubriqueFacturation) => rubFact.id === id);
};

/**
 * Additionne les valeurs des lignes de détails
 *
 * @param factureAchat
 * @returns TotauxFactureAchatType
 */
export const getTotaux = function (factureAchat: FactureAchat): TotauxFactureAchatType {
    let totaux: TotauxFactureAchatType = {
        ht: 0,
        tva: 0,
        ttc: 0,
    };

    if (factureAchat.ht) {
        totaux.ht = +factureAchat.ht;
    }

    if (factureAchat.tva) {
        totaux.tva = +factureAchat.tva;
    }

    if (factureAchat.ttc) {
        totaux.ttc = +factureAchat.ttc;
    }

    Object.keys(totaux).forEach(function (key: keyof TotauxFactureAchatType) {
        totaux[key] = roundTo(totaux[key], 3);
    });

    return totaux;
};
