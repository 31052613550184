import * as React from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CSS from 'csstype';
import 'react-perfect-scrollbar/dist/css/styles.css';

export type ScrollbarProps = {
    children: React.ReactNode;
    style?: CSS.Properties;
};

const Scrollbar: React.FunctionComponent<ScrollbarProps> = function ({
    children,
    style,
}: ScrollbarProps) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );

    return isMobile ? (
        <Box data-testid="test-id-Scrollbar" sx={{ overflowX: 'auto' }}>
            {children}
        </Box>
    ) : (
        <PerfectScrollbar role="scrollbar" style={style}>
            {children}
        </PerfectScrollbar>
    );
};

Scrollbar.defaultProps = {
    style: undefined,
};

export default Scrollbar;
