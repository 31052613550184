// dans ce fichier faire les datatranformers qui a partir des forms font les objets a post put delte

import {
    FactureAchatJsonldFactureAchatWrite,
    FactureAchatLigneDetailFactureAchatRead,
    FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    detailsAchat,
    detailsWrite,
    enteteFactureForm,
    factureFormObject,
    ligneAchat,
    ligneProduit,
    ligneWrite,
    tableauAchat,
    tableauProduits,
    tableauWrite,
} from '../types';
import { toISOString } from '@b2d/utils/toISOString';
import {
    Dossier,
    FactureAchat,
    Fournisseur,
    MediaObject,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { arrondi, getTTC_HT_TxTVA, getTVA_HT_TxTVA, produitsToSomme } from './calculsProduits';
import _ from 'lodash';
import { findRubFactById } from './produitFunctions';
import moment from 'moment';
import { useDeleteLigne, useGetLignes, usePostLigne, useUpdateLigne } from './dataHooks';
import { factureAchatLigneApi } from '@b2d/redux/RootStore';

// export const
// todo faire une fonction qui transforme le enteteFactureForm en objet de post facture achats

export const formEnteteToFactureAchatWrite = function (
    formdata: enteteFactureForm,
    idSociete: number,
    mediaObject: MediaObject,
): FactureAchatJsonldFactureAchatWrite {
    let facture: FactureAchatJsonldFactureAchatWrite = {
        avoir: formdata.avoir || false,
        hasSetRsf: false,
        cloture: false,
        dateFacture: toISOString(formdata.date_facture),
        numeroFacture: formdata.numero_facture,
        idEntiteFacturante: +formdata.fournisseur.id,
        libelleFacturante: formdata.fournisseur.libelle,
        idSociete,
        pdfFacture: mediaObject['@id'],
        idJal: 555, // TODO Déplacer le jal dans les dossiers ? ou pas ? a voir
    };

    return facture;
};

/**
 * Convertis un tableauAchat (liste de produits dans une facture d'achat)
 * en tableauProduits (liste de produits dans le formulaire)
 *
 * @param tableauAchat
 * @param rubriques
 * @returns tableauProduits
 */
export const tableauAchatToFormProduits = function (
    tableauAchat: tableauAchat,
    rubriques: RubriqueFacturationApiObject[],
): tableauProduits {
    let formProduits: tableauProduits = tableauAchat.map((ligneAchat) => {
        let details: Partial<{
            ordre: number;
            rubfac: {
                id: number;
                code: string;
            };
            ht: number;
            txtva: number;
            tva: number;
            ttc: number;
        }> = {};

        if (ligneAchat.details && ligneAchat.details[0]) {
            if (ligneAchat.details[0].idRubriqueFacturation) {
                const rubFact = findRubFactById(
                    rubriques,
                    ligneAchat.details[0].idRubriqueFacturation,
                );

                details.rubfac = {
                    id: ligneAchat.details[0].idRubriqueFacturation,
                    code: rubFact && rubFact.code ? rubFact.code : 'ERROR',
                };
            }

            if (ligneAchat.details[0].idRubriqueFacturation === 1) {
                details.ordre = 1;
            } else if (ligneAchat.details[0].idRubriqueFacturation === 99) {
                details.ordre = 2;
            }

            if (ligneAchat.details[0].ht) {
                details.ht = +ligneAchat.details[0].ht;
            }

            if (ligneAchat.details[0].tauxTva) {
                details.txtva = ligneAchat.details[0].tauxTva / 100;
            }

            if (ligneAchat.details[0].tva) {
                details.tva = +ligneAchat.details[0].tva;
            }

            if (details.ht && details.txtva) {
                details.ttc = getTTC_HT_TxTVA(details.ht, details.txtva);
            }
        }

        return {
            ordre: details.ordre,
            idLigneVente: ligneAchat.idFLigneVente ? ligneAchat.idFLigneVente : undefined,
            libelle: 'dans la vente',
            rubriqueFacturation: {
                id: details.rubfac?.id || 1,
                code: details.rubfac?.code || 'PUB',
            },
            ht: details.ht,
            txtva: details.txtva,
            tva: details.tva,
            ttc: details.ttc,
        };
    });

    return formProduits;
};

/**
 * Convertis un tableauProduits (liste de produits dans le formulaire)
 * en tableauAchat (liste de produits dans une facture d'achat)
 *
 * @param tableauProduits
 * @returns tableauAchat
 */
export const formProduitsToTableauAchat = function (
    factureFormObject: factureFormObject
): tableauWrite {
    let tableauAchatValued: tableauWrite = [];

    // Formalisme du détail des lignes d'achat, puis des lignes d'achats
    factureFormObject.produits.forEach((produit) => {
        let detailsAchatValued: detailsWrite = {
            idRubriqueFacturation: produit.rubriqueFacturation?.id || 0,
            ht: produit.ht ? '' + produit.ht : '0',
            tauxTva: produit.txtva ? Number(produit.txtva) * 100 : 2000,
            tva: produit.tva ? '' + produit.tva : '0',
        };

        let ligneAchatValued: ligneWrite = {
            idDossier: factureFormObject.dossier.id,
            numeroDossier: String(factureFormObject.dossier.numero),
            idPrestation: factureFormObject.dossier.prestations[0].id,
            numeroPrestation: factureFormObject.dossier.prestations[0].numero,
            libelleAnnonceur: factureFormObject.dossier.annonceur || null,
            details: [detailsAchatValued],
        };

        if (produit.idLigneVente) {
            ligneAchatValued.idFLigneVente = produit.idLigneVente;
        }

        tableauAchatValued.push(ligneAchatValued);
    });

    return tableauAchatValued;
};


export const formProduitsToTableauLigneWrite = function (
    factureFormObject: factureFormObject,
    factureIri: string
): ligneWrite[] {
    return formProduitsToTableauAchat(factureFormObject).map((item) => {
        const ligne: ligneWrite = {
            ...item,
            idDossier: item.idDossier as number,
            idPrestation: item.idPrestation as number,
            facture: factureIri
        };
        return ligne;
    });
};


const cleanJsonLd = function (item: any) {
    delete item['@id'];
    delete item['@type'];
    delete item['@context'];
    return item;
};

const cleanClone = function (clone: Partial<FactureAchat>) {
    // clone.codesRejets = [];
    delete clone.codesRejets;

    const res: FactureAchatJsonldFactureAchatWrite = {
        ...clone,
        codesRejets: [],
        pdfFacture: '',
    };
    if (clone.pdfFacture && clone.pdfFacture.id) {
        res.pdfFacture = clone.pdfFacture['@id'];
    }
    // clone.lignes = clone.lignes?.map((ligne) => {
    //     ligne.details = ligne.details?.map((detail) => {
    //         return cleanJsonLd(detail);
    //     })
    //     return cleanJsonLd(ligne);
    // })
    return res;
};



export const formRecapTotauxToFactureAchatWrite = function (
    data: factureFormObject,
    currentFacture: FactureAchat,
    lockedTotaux: boolean,
): FactureAchatJsonldFactureAchatWrite {
    const clone = _.cloneDeep(currentFacture);

    if (!lockedTotaux) {
        clone.ht = data.total_ht.toString();
        clone.tva = data.total_tva.toString();
        clone.ttc = data.total_ttc.toString();
    }

    // if (clone.lignes && clone.lignes?.length > 0 && Object.keys(groupLinesByDossiers(clone)).length === 1) {
    //     clone.libelleAnnonceur = clone.lignes[0].libelleAnnonceur || '';
    // }

    clone.cloture = true;

    return cleanClone(clone);
};

export const produitVenteToLigneProduit = (
    produitVente: TblFligneFactureVenteRead,
    rubFacts: RubriqueFacturation[],
) => {
    const res: ligneProduit = {
        idLigneVente: produitVente.id,
        libelle: produitVente.libelle ? produitVente.libelle : undefined,
        ht: produitVente.montant ? produitVente.montant : undefined,
        tva:
            produitVente.montant && produitVente.tauxTva
                ? arrondi(getTVA_HT_TxTVA(produitVente.montant, produitVente.tauxTva))
                : undefined,
        ttc:
            produitVente.montant && produitVente.tauxTva
                ? arrondi(getTTC_HT_TxTVA(produitVente.montant, produitVente.tauxTva))
                : undefined,
    };

    if (produitVente.rubriqueFacturation && produitVente.rubriqueFacturation !== '') {
        res.rubriqueFacturation = {
            code: produitVente.rubriqueFacturation,
            id:
                rubFacts.find(
                    (rubFact: RubriqueFacturation) =>
                        rubFact.code === produitVente.rubriqueFacturation,
                )?.id || 0,
        };
    }
    return res;
};

export const factureAchatToFacureFormObject = (
    factureAchat: FactureAchat,
    dossier: Dossier,
    lignes: FactureAchatLigneJsonldFactureAchatRead[],
    fournisseur: Fournisseur,
) => {
    const facture: Partial<factureFormObject> = {};
    const produits: tableauProduits = [];

    if (fournisseur && fournisseur.siren && fournisseur.id === factureAchat.idEntiteFacturante) {
        facture.siren_fournisseur = fournisseur.siren;
        facture.raison_sociale_fournisseur = fournisseur.libelle;
        if (fournisseur.typeRemise) {
            facture.type_remise_fournisseur = `${fournisseur.typeRemise.code} (${fournisseur.typeRemise.libelle})`;
        } else {
            facture.type_remise_fournisseur = `Pas de remise`;
        }
    }

    // traitement des produits
    if (lignes) {
        // console.log(factureAchat.lignes);

        const products = lignes.reduce((prev: tableauProduits, current) => {
            if (!current.details) {
                return prev;
            }
            let res: tableauProduits = [
                ...prev,
                ...current.details.map((ligneDetail: FactureAchatLigneDetailFactureAchatRead) => {
                    const resMap: ligneProduit = {};

                    if (ligneDetail.ht) resMap.ht = +ligneDetail.ht;
                    if (ligneDetail.tva) resMap.tva = (+ligneDetail.tva).toFixed(4);
                    if (ligneDetail.ht && ligneDetail.tva)
                        resMap.ttc = (+ligneDetail.tva + +ligneDetail.ht).toFixed(4);
                    if (ligneDetail.tauxTva) resMap.txtva = +ligneDetail.tauxTva / 100;
                    if (ligneDetail.idRubriqueFacturation)
                        resMap.rubriqueFacturation = { id: ligneDetail.idRubriqueFacturation };
                    if (current.idFLigneVente) resMap.idLigneVente = current.idFLigneVente;

                    return resMap;
                }),
            ];
            return res;
        }, []);

        produits.push(...products);
    }

    facture.produits = produits;
    facture.avoir = factureAchat.avoir;

    // ajout numero facture
    if (factureAchat.numeroFacture) facture.numero_facture = factureAchat.numeroFacture;

    if (lignes[0] && lignes[0].idDossier) {
        // TODO: a répliquer sur un maximum de dispatch
        if (dossier) {
            facture.numero_dossier = dossier.numero ? String(dossier.numero) : undefined;
            facture.nom_annonceur = dossier.annonceur || "Pas de nom d'annonceur";
            if (dossier && dossier.prestations && dossier.prestations.length > 0) {
                facture.numero_annonce = dossier.prestations[0].numero
                    ? String(dossier.prestations[0].numero)
                    : undefined;
                facture.nom_journal = dossier.prestations[0].jal?.nom;
                facture.date_parution = dossier.prestations[0].dateParutionDemandee
                    ? moment(dossier.prestations[0].dateParutionDemandee).utc().format('DD/MM/YYYY')
                    : 'Pas de date de parution';
            }
        }
    }

    // facture
    if (factureAchat.dateFacture) {
        facture.date_facture = new Date(factureAchat.dateFacture);
    }

    const totaux = produitsToSomme(produits, facture.avoir);

    // console.log(totaux);

    facture.total_ht = totaux.ht;
    facture.total_tva = totaux.tva;
    facture.total_txtva = totaux.txtva;
    facture.total_ttc = totaux.ttc;

    if (factureAchat.ht) {
        facture.total_ht = factureAchat.ht;
    }
    if (factureAchat.tva) {
        facture.total_tva = factureAchat.tva;
    }
    if (factureAchat.ttc) {
        facture.total_ttc = factureAchat.ttc;
    }

    if (factureAchat.avoir === true) {
        facture.total_ht = -facture.total_ht;
        facture.total_tva = -facture.total_tva;
        facture.total_ttc = -facture.total_ttc;
    }

    return facture;
};
