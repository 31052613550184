/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    FactureAchatApi,
    FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup,
} from '../../../openApi/ApiAchats';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { ACHATS_REDUCER_NAME } from '../achatsReducer';
import { Order } from '../../../types/apiGenericTypes';

export { type AchatsConfigurationParameters };

export type FactureAchatApiObject =
    FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup &
        Record<string, unknown>;

export type FactureAchat = FactureAchatApiObject;

export const FACTURES_ACHATS_SLICE_NAME = 'factureachat';

export const FACTURES_ACHATS_STATS_DATASOURCE_NAME = 'statsFacturesAchats';
export const FACTURES_ACHATS_PAUSE_DATASOURCE_NAME = 'pauseFactureAcahts';

export const additionalFacturesAchatsDataSources: Array<string> = [
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    FACTURES_ACHATS_PAUSE_DATASOURCE_NAME,
];

const mapperFactureAchat = function (factureAchat: FactureAchatApiObject) {
    return {
        ...factureAchat,
    } as FactureAchat;
};

export function createFactureAchatSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FactureAchatApi(conf);

    const fetchFactureAchatsCollection: FetchCollectionData<FactureAchatApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dateFactureBefore,
            dateFactureAfter,
            createdAtBefore,
            createdAtAfter,
            ligneNumeroDossier,
            ligneNumeroPresta,
            updatedBy,
            numeroFacture,
            recherche,
            libelleFacturante: string | undefined = undefined,
            cloture: boolean | undefined = undefined,
            deleted: boolean | undefined = undefined,
            orderId: Order,
            orderUpdatedAt;

        let idEntiteFacturante,
            idDossier,
            idPresta,
            pdfFactureId: number | undefined = undefined;

        let categories,
            codesRejets: string[] | undefined = undefined;
        let linkedCodeRejets,
            syncG3: boolean | undefined = undefined;

        if (filters.linkedCodeRejets !== undefined) {
            linkedCodeRejets = filters.linkedCodeRejets as boolean;
        }

        if (filters.dateFacture) {
            dateFactureBefore = (filters.dateFacture as string[])[0];
            dateFactureAfter = (filters.dateFacture as string[])[1];
        }

        if (filters.createdAt) {
            createdAtBefore = (filters.createdAt as string[])[0];
            createdAtAfter = (filters.createdAt as string[])[1];
        }

        if (filters.ligneNumeroDossier) {
            ligneNumeroDossier = filters.ligneNumeroDossier as string;
        }

        if (filters.ligneNumeroPresta) {
            ligneNumeroPresta = filters.ligneNumeroPresta as string;
        }

        if (filters.updatedBy) {
            updatedBy = filters.updatedBy as string;
        }

        if (filters.idEntiteFacturante) {
            idEntiteFacturante = filters.idEntiteFacturante as number;
        }

        if (filters.idDossier) {
            idDossier = filters.idDossier as number;
        }

        if (filters.idPresta) {
            idPresta = filters.idPresta as number;
        }

        if (filters.libelleFacturante) {
            libelleFacturante = filters.libelleFacturante as string;
        }

        if (filters.cloture || filters.cloture === false) {
            cloture = filters.cloture as boolean;
        }

        if (filters.deleted === true) {
            deleted = true;
        } else if (filters.deleted === false) {
            deleted = false;
        }

        if (search && search !== '') {
            recherche = search;
        }

        if (
            filters.codesRejets &&
            Array.isArray(filters.codesRejets) &&
            filters.codesRejets.length > 0
        ) {
            codesRejets = filters.codesRejets;
        } else if (
            filters.categories &&
            Array.isArray(filters.categories) &&
            filters.categories.length > 0
        ) {
            categories = filters.categories;
        }

        if (filters.syncG3 === true) {
            syncG3 = true;
        } else if (filters.syncG3 === false) {
            syncG3 = false;
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'updatedAt') {
                orderUpdatedAt = item.value;
            }
        });

        return api
            .getFacturesAchatsFactureAchatCollection(
                xIdSociete, // xIdSociete?: number | undefined,
                pagination.page + 1, // page?: number | undefined,
                pagination.itemsPerPage, // itemsPerPage?: number | undefined,
                linkedCodeRejets, // existsCodesRejets?: boolean | undefined,
                cloture, // cloture?: boolean | undefined,
                syncG3, //  syncG3?: boolean | undefined,
                deleted, // deleted?: boolean | undefined,
                dateFactureBefore, // dateFactureBefore?: string | undefined,
                undefined, // dateFactureStrictlyBefore?: string | undefined,
                dateFactureAfter, // dateFactureAfter?: string | undefined,
                undefined, // dateFactureStrictlyAfter?: string | undefined,
                createdAtBefore, // createdAtBefore?: string | undefined,
                undefined, // createdAtStrictlyBefore?: string | undefined,
                createdAtAfter, // createdAtAfter?: string | undefined,
                undefined, // createdAtStrictlyAfter?: string | undefined,
                libelleFacturante, // libelleFacturante?: string | undefined,
                ligneNumeroDossier, // lignesNumeroDossier?: string | undefined,
                ligneNumeroPresta, // lignesNumeroPrestation?: string | undefined,
                updatedBy, // updatedBy?: string | undefined,
                undefined, // updatedBy2?: string[] | undefined,
                idEntiteFacturante, // idEntiteFacturante?: number | undefined,
                undefined, // idEntiteFacturante2?: number[] | undefined,
                idDossier, // lignesIdDossier?: number | undefined,
                undefined, // lignesIdDossier2?: number[] | undefined,
                idPresta, // lignesIdPrestation?: number | undefined,
                undefined, // lignesIdPrestation2?: number[] | undefined,
                numeroFacture, // numeroFacture?: string | undefined,
                undefined, // codesRejetsCodeRejetCategorie ?: string | undefined,
                categories, // codesRejetsCodeRejetCategorie2 ?: string[] | undefined,
                undefined, // codesRejetsCodeRejetCode ?: string | undefined,
                codesRejets, // codesRejetsCodeRejetCode2 ?: string[] | undefined,
                recherche, // string | undefined
                orderId, // , orderId?: "asc" | "desc" | undefined
                orderUpdatedAt, // orderUpdatedAt?: "asc" | "desc" | undefined
                undefined, // options?: AxiosRequestConfig<...> | undefined
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<FactureAchatApiObject>>;
    };

    const fetchFactureAchatsItem: FetchItemDataType<FactureAchatApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureAchatFactureAchatItem(String(idItem), xIdSociete)
            .then((res) => res.data) as Promise<FactureAchatApiObject>;

    const { slice: factureAchatSlice, dataSourcesThunks: factureAchatDataSourcesThunks } =
        SliceFactory.createSlice<FactureAchatApiObject, FactureAchat>(
            FACTURES_ACHATS_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalFacturesAchatsDataSources,
            fetchFactureAchatsCollection,
            fetchFactureAchatsItem,
            mapperFactureAchat,
            {
                cloture: true,
                deleted: false,
            },
            undefined,
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { factureAchatSlice, factureAchatDataSourcesThunks };
}
