import React, { useCallback } from 'react';

import { LazyBreadcrumb, ThemeSettings } from '@europrocurement/flexy-components';
import {
    CategorieStatsSelector,
    CodeRejetStatsSelector,
    FactureachatSelector,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
} from '../../../redux/RootStore';
import { useDispatch, useSelector } from 'react-redux';

import StatsTabs from './StatsTabs';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    CategorieStats,
    CodeRejetStats,
    FactureAchat,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
} from '@europrocurement/l2d-domain';
import { ThemeProvider } from '@emotion/react';
import { createTheme, PaletteOptions } from '@mui/material';
import _ from 'lodash';

import {
    red,
    pink,
    purple,
    deepPurple,
    lightGreen,
    teal,
    deepOrange,
    brown,
    blueGrey,
    amber,
} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Palette {
        ERDN?: string;
        ERCA?: string;
        ERDO?: string;
        EREN?: string;
        ERJA?: string;
        ERJU?: string;
        ERLO?: string;
        ERPF?: string;
        ERPU?: string;
        ERRC?: string;
        ERRE?: string;
        ERSO?: string;
    }
    interface PaletteOptions {
        ERDN?: string;
        ERCA?: string;
        ERDO?: string;
        EREN?: string;
        ERJA?: string;
        ERJU?: string;
        ERLO?: string;
        ERPF?: string;
        ERPU?: string;
        ERRC?: string;
        ERRE?: string;
        ERSO?: string;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        ERDN: true;
    }
}

const Stats = function () {
    const categories: DataSource<CategorieStats> = useSelector(CategorieStatsSelector).main;
    const codesRejets: DataSource<CodeRejetStats> = useSelector(CodeRejetStatsSelector).main;
    const facturesAchats: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_STATS_DATASOURCE_NAME];

    const dispatch = useDispatch();

    const initCategorieStats = useCallback(() => {
        if (categories.status === 'idle') {
            dispatch(getCategorieStats({}));
        }
    }, [categories.status, dispatch]);

    const initCodeRejetStats = useCallback(() => {
        if (codesRejets.status === 'idle') {
            dispatch(getCodeRejetStats({}));
        }
    }, [codesRejets.status, dispatch]);

    const initFactureAchatStats = useCallback(() => {
        if (facturesAchats.status === 'idle') {
            // dispatch(getFactureAchatStats({}));
        }
    }, [facturesAchats.status, dispatch]);

    initCategorieStats();
    initCodeRejetStats();
    initFactureAchatStats();

    const baseTheme = ThemeSettings();

    const customTheme = createTheme({
        palette: {
            ...baseTheme.palette,
            ERDN: baseTheme.palette.augmentColor({
                color: baseTheme.palette.primary as PaletteOptions,
            }),
            ERCA: baseTheme.palette.augmentColor({
                color: baseTheme.palette.secondary as PaletteOptions,
            }),
            ERDO: baseTheme.palette.augmentColor({ color: lightGreen }),
            EREN: baseTheme.palette.augmentColor({ color: red }),
            ERJA: baseTheme.palette.augmentColor({ color: pink }),
            ERJU: baseTheme.palette.augmentColor({ color: purple }),
            ERLO: baseTheme.palette.augmentColor({ color: deepPurple }),
            ERPF: baseTheme.palette.augmentColor({ color: amber }),
            ERPU: baseTheme.palette.augmentColor({ color: blueGrey }),
            ERRC: baseTheme.palette.augmentColor({ color: teal }),
            ERRE: baseTheme.palette.augmentColor({ color: deepOrange }),
            ERSO: baseTheme.palette.augmentColor({ color: brown }),
        },
    });

    const theme = createTheme(_.merge({}, customTheme, baseTheme, {}));

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/stats' },
                    "Nombre de factures d'achat en rejet",
                ]}
            />
            <ThemeProvider theme={theme}>
                <StatsTabs />
            </ThemeProvider>
        </>
    );
};

export default Stats;
