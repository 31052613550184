import React, { FunctionComponent, MouseEventHandler, useState } from 'react';
import { Box } from '@mui/system';
import { ColumnDatatable } from '../ColumnDatatable';
import { FlexyInputType } from '@europrocurement/flexy-components';
import { SortDirection } from '@mui/material';

export type FilterDatatable = {
    label: string;
    field: string;
    type: FlexyInputType;
    valueMap?: (value: any) => any;
};

export type OrderDatatable = {
    field: string;
    value: SortDirection;
};

export type FiltersDatatableList = FilterDatatable[];
export type OrderDatatableList = OrderDatatable[];

export type FiltersOpts = {
    filters: FiltersDatatableList;
    onFilterChange: (key: string, value: unknown) => void;
    onFilterDelete: (field: string) => void;
    activeFilters?: Record<string, unknown>;
};

export type OrdersOpts = {
    orders: OrderDatatableList;
    onOrdersChange: (key: string, value: unknown) => void;
};

export type SearchOpts = {
    onSearchChange?: (search: string) => void;
    search?: string;
    filtersOpts?: FiltersOpts;
    ordersOpts?: OrdersOpts;
};

export type DisplayOpts = {
    columns: ColumnDatatable[];
    setColumns: React.Dispatch<React.SetStateAction<ColumnDatatable[]>>;
};

export type DatatableHeaderProps = {
    searchOpts?: SearchOpts;
    displayOpts?: DisplayOpts;
    handleExport?: MouseEventHandler<HTMLButtonElement>;
};

export const DatatableHeader: FunctionComponent<DatatableHeaderProps> = function ({
    searchOpts,
    displayOpts,
    handleExport,
}: DatatableHeaderProps) {
    const { onSearchChange, filtersOpts } = (searchOpts as SearchOpts) || {
        onSearchChange: undefined,
        filtersOpts: undefined,
    };

    const { columns, setColumns } = displayOpts
        ? (displayOpts as DisplayOpts)
        : { columns: undefined, setColumns: undefined };

    const [displayFilters, setDisplayFilters] = useState<boolean>(false);

    // const activeFiltersList = function (
    //     filters: FiltersOpts['activeFilters'],
    // ): JSX.Element | undefined {
    //     if (filters) {
    //         type FieldAndValueFilter = {
    //             label: string;
    //             field: string;
    //             value: string;
    //         };

    //         const arrayOfKeys: Array<string> = Object.getOwnPropertyNames(filters);
    //         const arrayOfObject: FieldAndValueFilter[] = arrayOfKeys.map((key) => ({
    //             label:
    //                 searchOpts?.filtersOpts?.filters.find((filter) => filter.field === key)
    //                     ?.label || '',
    //             field: key,
    //             value: String(filters[key]),
    //         }));

    //         return (
    //             <>
    //                 {arrayOfObject.map(({ field, value, label }: FieldAndValueFilter) => (
    //                     <ActiveFilterBadge
    //                         key={field + value}
    //                         field={label}
    //                         value={value}
    //                         onClick={() => searchOpts?.filtersOpts?.onFilterDelete(field)}
    //                     />
    //                 ))}
    //             </>
    //         );
    //     }
    //     return undefined;
    // };

    return (
        <Box data-testid="DatatableHeader">
            p
            {/* {handleExport && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '15px',
                        width: '100%',
                    }}
                >
                    <Button color="success" variant="contained" onClick={handleExport}>
                        Exporter les données
                    </Button>
                </Box>
            )}
*/}
            {/* La barre de recherche rapide */}
            {/* <Box sx={{ display: 'flex' }}>
                {onSearchChange && (
                    <FlexyInput
                        data-testid="RapidSearch"
                        variant="outlined"
                        type="text"
                        size="small"
                        fullWidth
                        name="rapidSearch"
                        placeholder="Recherche rapide"
                        onChange={(e: BaseSyntheticEvent) => onSearchChange(e.target.value)}
                    />
                )}

                {(filtersOpts || displayOpts) && (
                    <Tooltip title="Filter list" data-testid="DisplayFiltersButton">
                        <IconButton onClick={() => setDisplayFilters((state) => !state)}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box> */}
            {/* La liste des filtres avancées */}
            {/*
          {displayFilters && (
                <>
                    <Box
                        sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
                        data-testid="FiltersContainer"
                    >
                        {filtersOpts?.filters.map((filter: FilterDatatable) => (
                            <div style={{ margin: '10px' }} key={String(filter.field)}>
                                <FlexyInput
                                    data-testid="SearchFilters"
                                    variant="outlined"
                                    type={filter.type}
                                    size="small"
                                    name={filter.label}
                                    placeholder={filter.label}
                                    inputlabel={filter.label}
                                    onChange={(e: BaseSyntheticEvent) => {
                                        if (filter.type === 'boolean')
                                            filtersOpts?.onFilterChange(
                                                filter.field,
                                                e.target.checked,
                                            );
                                        else
                                            filtersOpts?.onFilterChange(
                                                filter.field,
                                                e.target.value,
                                            );
                                    }}
                                />
                            </div>
                        ))}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        {columns &&
                            setColumns &&
                            columns.map((column, index) => (
                                <TableCell key={column.label}>
                                    <Checkbox
                                        checked={column.isDisplayed}
                                        sx={{ paddingLeft: 0 }}
                                        onClick={() => {
                                            const newCols = [...columns];
                                            newCols[index].isDisplayed =
                                                !newCols[index].isDisplayed;
                                            setColumns(newCols);
                                        }}
                                    />
                                    {column.label}
                                </TableCell>
                            ))}
                    </Box>
                </>
            )} */}
            {/* La liste des filtres actif */}
            {/*  {filtersOpts?.activeFilters && (
                <>
                    <Typography>Filtres actifs : </Typography>
                    {activeFiltersList(filtersOpts.activeFilters)}
                </>
            )} */}
        </Box>
    );
};

DatatableHeader.defaultProps = {
    searchOpts: undefined,
    displayOpts: undefined,
    handleExport: undefined,
};

export default DatatableHeader;
