import React from 'react';

import { Divider, SxProps, Typography, TypographyProps, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import { Switch, SwitchProps } from '../../atoms';

export type HeaderSwitch = SwitchProps & { label: string };

export type FlexyHeaderFormProps = {
    label: React.ReactNode;
    color?: TypographyProps['color'];
    outlined?: boolean;
    switchs?: Array<HeaderSwitch>;
    sx?: SxProps;
};

const FlexyHeaderForm: React.FunctionComponent<FlexyHeaderFormProps> = function ({
    label,
    color,
    outlined,
    switchs,
    sx,
}: FlexyHeaderFormProps) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                }}
            >
                <Typography
                    display="flex"
                    alignItems="center"
                    width="100%"
                    color={color}
                    padding={1}
                    variant="h4"
                    sx={{ marginTop: '15px', ...sx }}
                >
                    {label}
                    {switchs &&
                        switchs.map(({ label: switchLabel, ...switchprops }: HeaderSwitch) => (
                            <FormControlLabel
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                label={switchLabel}
                                key={`formHeaderbtn-${switchLabel}`}
                                control={
                                    <Switch
                                        // color="secondary"
                                        size="small"
                                        aria-label="switch"
                                        {...switchprops}
                                    />
                                }
                            />
                        ))}
                </Typography>
            </Box>
            {outlined ? (
                <Divider
                    sx={{
                        backgroundColor: color,
                        borderWidth: '1px',
                    }}
                />
            ) : null}
        </>
    );
};

FlexyHeaderForm.defaultProps = {
    color: 'secondary.main',
    outlined: false,
};

export default FlexyHeaderForm;
