import * as React from 'react';
import { ContactCard } from '@europrocurement/flexy-components';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';
import { prescripteursApi } from '../../../../../redux/RootStore';
import ActionModal from '../forms/ActionModal';
import { ContactJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

type PrescriberContactsProps = {
    prescriber: Prescripteur;
    callModal: (modalContent: React.ReactElement) => void;
    closeModal: () => void;
    afterSubmit: () => void;
};

const PrescriberContacts: React.FC<PrescriberContactsProps> = (props) => {
    const { prescriber, callModal, closeModal, afterSubmit } = props;

    const { contacts } = prescriber;

    //Compare function to determine default order of contacts
    const defaultContactSort = (
        elementA: ContactJsonldTiersRead,
        elementB: ContactJsonldTiersRead,
    ) => {
        const aMain = elementA.principal;
        const bMain = elementB.principal;
        const aBilling = elementA.comptabilite;

        if (aMain) {
            return -1;
        }

        if (aBilling && !bMain) {
            return -1;
        }

        return 1;
    };

    const sortedContacts = [...contacts].sort(defaultContactSort);

    const handleEditContact = (contact: ContactJsonldTiersRead) => {
        if (prescriber.id) {
            callModal(
                <ContactForm entity={contact} prescriber={prescriber} afterSubmit={afterSubmit} />,
            );
        }
    };

    const deleteContact = async (contactId: number | undefined) => {
        if (!contactId) {
            return;
        }

        const prescriberContacts = prescriber.contacts.filter(
            (prescriberContact) => prescriberContact.id !== contactId,
        );
        if (prescriber && prescriber.id) {
            await prescripteursApi.updateContactsPrescripteurTiersItem(prescriber.id.toString(), {
                contacts: prescriberContacts,
            });
            afterSubmit();
        }
    };

    const handleDeleteContact = (contact: ContactJsonldTiersRead) => {
        if (prescriber.id) {
            callModal(
                <ActionModal
                    onClose={closeModal}
                    asyncAction={() => deleteContact(contact.id)}
                    title={"Suppression d'un contact"}
                    actionText={'Voulez-vous supprimer ce contact ?'}
                    actionLabel={'Confirmer suppression'}
                />,
            );
        }
    };

    return (
        <Grid container>
            {sortedContacts.map((contact, _, array) => {
                return (
                    <Grid
                        key={`contact_card_${contact.id}`}
                        item
                        xs={12}
                        md={6}
                        lg={array.length > 2 ? 4 : 6}
                    >
                        <ContactCard
                            contact={contact}
                            editAction={() => handleEditContact(contact)}
                            deleteAction={() => handleDeleteContact(contact)}
                            isDeletable={
                                contact.principal === false && contact.comptabilite === false
                            }
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default PrescriberContacts;
