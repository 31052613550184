/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    DossierApi,
    DossierDossierRead,
} from '../../../openApi/ApiDossiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { DOSSIERS_REDUCER_NAME } from '../dossiersReducer';

export { type DossiersConfigurationParameters };

export type DossierApiObject = DossierDossierRead & Record<string, unknown>;

export type Dossier = DossierApiObject;

export const DOSSIERS_SLICE_NAME = 'dos';

export const DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME = 'autocompleteDossiers';

export const additionalDossiersDataSources: Array<string> = [DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME];

export const mapperDossier = function (dos: DossierApiObject) {
    return {
        ...dos,
    } as Dossier;
};

export function createDossierSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DossierApi(conf);

    const fetchDossiersCollection: FetchCollectionData<DossierApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let page = pagination.page + 1;
        let numero = undefined;
        let numero2 = undefined;
        let prestationsNumero = undefined;
        let prestationsNumero2 = undefined;
        let prestationsJalNom = undefined;
        let prestationsJalNom2 = undefined;
        let prestationsAnnonceur = undefined;
        let prestationsAnnonceur2 = undefined;
        let prestationsDateParutionDemandeeBefore = undefined;
        let prestationsDateParutionDemandeeStrictlyBefore = undefined;
        let prestationsDateParutionDemandeeAfter = undefined;
        let prestationsDateParutionDemandeeStrictlyAfter = undefined;
        let options = undefined;

        if (filters.numero) {
            numero = filters.numero as number;
        }

        if (filters.prestationsNumero) {
            prestationsNumero = filters.prestationsNumero as string;
        }
        if (filters.prestationsJalNom) {
            prestationsJalNom = filters.prestationsJalNom as string;
        }
        if (filters.prestationsAnnonceur) {
            prestationsAnnonceur = filters.prestationsAnnonceur as string;
        }

        if (filters.prestationsDateParution) {
            if (filters.prestationsDateParution instanceof Date) {
                prestationsDateParutionDemandeeAfter =
                    filters.prestationsDateParution.toISOString();
                let dateP1 = new Date(filters.prestationsDateParution).getDate() + 1;
                prestationsDateParutionDemandeeBefore = (
                    new Date(new Date(filters.prestationsDateParution).setDate(dateP1)) as Date
                ).toISOString();
            }
        }

        if (search && search !== '') {
            numero = parseFloat(search);
        }

        return api
            .getDossierCollection(
                xIdSociete, // xIdSociete?: number | undefined,
                page, // page?: number | undefined,
                numero, // numero?: number | undefined,
                numero2, // numero2?: number[] | undefined,
                prestationsNumero, // prestationsNumero?: string | undefined,
                prestationsNumero2, // prestationsNumero2?: string[] | undefined,
                prestationsJalNom, // prestationsJalNom?: string | undefined,
                prestationsJalNom2, // prestationsJalNom2?: string[] | undefined,
                prestationsAnnonceur, // prestationsAnnonceur?: string | undefined,
                prestationsAnnonceur2, // prestationsAnnonceur2?: string[] | undefined,
                prestationsDateParutionDemandeeBefore, // prestationsDateParutionDemandeeBefore?: string | undefined,
                prestationsDateParutionDemandeeStrictlyBefore, // prestationsDateParutionDemandeeStrictlyBefore?: string | undefined,
                prestationsDateParutionDemandeeAfter, // prestationsDateParutionDemandeeAfter?: string | undefined,
                prestationsDateParutionDemandeeStrictlyAfter, // prestationsDateParutionDemandeeStrictlyAfter?: string | undefined,
                options, // options?: AxiosRequestConfig<...> | undefined
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierApiObject>>;
    };

    const searchByNumeroPresta: FetchCollectionData<DossierApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getDossierCollection(xIdSociete, pagination.page + 1, undefined, undefined, search)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierApiObject>>;

    const fetchDossiersItem: FetchItemDataType<DossierApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getDossierItem(String(idItem), xIdSociete)
            .then((res) => res.data) as Promise<DossierApiObject>;

    const { slice: dossierSlice, dataSourcesThunks: dossierDataSourcesThunks } =
        SliceFactory.createSlice<DossierApiObject, Dossier>(
            DOSSIERS_SLICE_NAME,
            DOSSIERS_REDUCER_NAME,
            additionalDossiersDataSources,
            fetchDossiersCollection,
            fetchDossiersItem,
            mapperDossier,
            {},
            {
                data: {
                    searchByNumeroPresta,
                },
            },
        );

    return { dossierSlice, dossierDataSourcesThunks };
}
