import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Card, CardContent, Grid, Typography, Box, Button, Divider } from '@mui/material';
import { mediaObjectApi, getMediaObject } from '../../redux/RootStore';
import { useSnackbar } from 'notistack';

import { FileUpload, FileUploadProps } from './components/FileUpload';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { MediaObject } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router';
import generateB2DPath from '../../utils/generateB2DPath';
import { ACTIONS } from '../../redux/FactureFormReducer';
import {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { CodeRejetsChipType } from './components/widgets/CodeRejetsChipList';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';

type fileUpload = {
    file: File;
    status: 'pending' | 'success' | 'error';
    mediaobject?: MediaObject;
};

const MediaObjectAdd = function () {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [uploads, setUploads] = React.useState<Array<fileUpload>>([]);

    const [dossiersofFacture, setdossiersOfFacture] = useState<
        FactureAchatFactureAchatReadDossierDossiersInner[]
    >([]);
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [rejets, setRejets] = useState<CodeRejetsChipType[]>([]);

    const [dossiersofFactureStatus, setdossiersOfFactureStatus] =
        useState<EuroprocApiResponseStatus>('idle');
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');

    const postMediaObject = async (file: File) => {
        const fileUpload: fileUpload = {
            file,
            status: 'pending',
        };
        const index = uploads.length;

        try {
            setUploads([...uploads, fileUpload]);
            const res = await mediaObjectApi.postMediaObjectCollection(file);
            if (res.status >= 200 && res.status < 300) {
                fileUpload.status = 'success';
                fileUpload.mediaobject = res.data as MediaObject;
                setUploads([...uploads, fileUpload]);
                enqueueSnackbar(<Typography>Envoi du fichier avec succès ! 🚀</Typography>);
                dispatch(getMediaObject({}));
            } else {
                throw new Error('Erreur de communication api, statuscode : ' + res.status);
            }

            return res;
        } catch (error) {
            fileUpload.status = 'error';
            setUploads([...uploads, fileUpload]);

            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else if (error instanceof Error) {
                message = error.message;
            }
            enqueueSnackbar(
                <Typography>
                    Envoi du fichier raté ! 🌠
                    <br />
                    {message}
                </Typography>,
                {
                    variant: 'error',
                },
            );

            return null;
        }
    };

    const navigate = useNavigate();

    const navigateToMediaObject = React.useCallback(
        (mediaObject: MediaObject | undefined) => {
            if (!mediaObject) return;

            dispatch({
                type: ACTIONS.RESET,
            });

            let response = generateB2DPath('formmediaobject', { mediaObject: mediaObject });

            if (response.status === 'OK') {
                navigate(response.path);
            } else if (response.status === 'KO') {
                enqueueSnackbar(<Typography>{response.message}</Typography>, {
                    variant: 'warning',
                });
            }
        },
        [enqueueSnackbar, navigate],
    );

    const fileUploadProp: FileUploadProps = {
        accept: '.pdf',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    postMediaObject(event.target.files[i]);
                }
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                postMediaObject(event.dataTransfer.files[i]);
            }
        },
    };

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    'Ajout manuel de facture',
                ]}
            />
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <FileUpload {...fileUploadProp} />
                        </Grid>
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            xs={12}
                            sm={12}
                            md={1}
                        >
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            {uploads.length === 0 && (
                                <Grid
                                    item
                                    height="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    xs={12}
                                >
                                    <Typography
                                        color={'GrayText'}
                                        sx={{
                                            opacity: 0.5,
                                        }}
                                    >
                                        Pas de fichiers importés pour le moment.
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                {uploads.map((item) => (
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                borderRadius: '15px',
                                                border: 2,
                                                borderStyle: 'solid',
                                                borderColor:
                                                    item.status === 'pending'
                                                        ? 'warning.main'
                                                        : item.status === 'error'
                                                        ? 'error.main'
                                                        : 'success.main',
                                                paddingLeft: '30px',
                                            }}
                                        >
                                            <h4>
                                                {item.file.name}
                                                {item.status === 'success' &&
                                                item.mediaobject !== undefined ? (
                                                    <Button
                                                        sx={{ marginLeft: '30px' }}
                                                        variant="contained"
                                                        onClick={() =>
                                                            navigateToMediaObject(item.mediaobject)
                                                        }
                                                    >
                                                        Traiter
                                                    </Button>
                                                ) : null}
                                            </h4>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MediaObjectAdd;
