import { RubriqueFacturation } from '@europrocurement/l2d-domain';
import { FactureAchatJsonldFactureAchatCreateFactureAchatWrite, FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate, FactureAchatLigneJsonldFactureAchatLigneWrite, FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';

export type ligneProduit = {
    ordre?: number;
    idLigneVente?: number;
    rubriqueFacturation?: {
        id: number;
        code?: string;
    };
    libelle?: string;
    ht?: number;
    tva?: number | string;
    txtva?: number;
    ttc?: number | string;
};

export type tableauProduits = Array<Partial<ligneProduit>>;

export type enteteFactureForm = {
    numero_facture: string;
    date_facture: Date;
    avoir?: boolean;
    fournisseur: any;
    siren_fournisseur?: any;
    raison_sociale_fournisseur: string;
    type_remise_fournisseur: string;
};

export type factureFormObject = enteteFactureForm & {
    numero_annonce: string;
    numero_dossier: string;
    dossier: any;
    produits: tableauProduits;
    total_ht: number | string;
    total_tva: number | string;
    total_txtva: number;
    total_ttc: number | string;
    nom_journal: string;
    date_parution: string;
    nom_annonceur: string;
    rsf_inclus_txremise: string;
    goToNext?: boolean;
};

export type ligneAchat = FactureAchatLigneJsonldFactureAchatRead;

export type ligneWrite = FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate;

export type factureAchatCreate = FactureAchatJsonldFactureAchatCreateFactureAchatWrite;

export type tableauAchat = Array<Partial<ligneAchat>>;

export type tableauWrite = Array<Partial<ligneWrite>>;

export type detailsAchat = {
    idRubriqueFacturation: number;
    ht: string;
    tauxTva: number;
    tva: string;
};

export type detailsWrite = FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate;

export type TotauxFactureAchatType = {
    ht: number;
    tva: number;
    ttc: number;
};

export const RSFRubFac: RubriqueFacturation = {
    id: 99,
    code: 'RSF',
    domainesAchats: [
        {
            id: 53,
            domaine: { id: 1 },
            defaut: true,
        },
        {
            id: 66,
            domaine: { id: 3 },
            defaut: false,
        },
        {
            id: 74,
            domaine: { id: 4 },
            defaut: false,
        },
        {
            id: 79,
            domaine: { id: 6 },
            defaut: false,
        },
        {
            id: 84,
            domaine: { id: 7 },
            defaut: false,
        },
        {
            id: 102,
            domaine: { id: 8 },
            defaut: true,
        },
        {
            id: 105,
            domaine: { id: 10 },
            defaut: true,
        },
    ],
};
