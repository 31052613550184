import React, { useEffect } from 'react';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import {
    enteteFactureStructure,
    getFournisseurAcStructure,
    getFournisseurStructure,
} from '../formElements';
import { useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { useBackFromMediaObjectEdit } from '../../../AchatsRouter';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { FactureAchat, Fournisseur } from '@europrocurement/l2d-domain';
import { shallowEqual, useSelector } from 'react-redux';
import { RootStateType, customizerSelector, factureAchatApi } from '@b2d/redux/RootStore';
import { getFournisseurAc } from '@b2d/redux/RootStore';
import { updateFormWithFournisseur } from '../functions/fournisseurFunctions';
import { enteteFactureForm } from '../types';
import { useMultiAnnonceContext } from '../../../SaisieFacturesMultiple';
import { formEnteteToFactureAchatWrite } from '../functions/dataTransformers';
import { useNavigate } from 'react-router';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useResetAllForm } from '../functions/generalFunctions';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export type EnteteSaisieMultipleType = {};

export const EnteteSaisieMultiple = function ({}: EnteteSaisieMultipleType) {
    const { mediaObject } = useMultiAnnonceContext();
    const { xIdSociete } = useSelector(customizerSelector);

    const navigate = useNavigate();

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
        shallowEqual,
    );

    //TODO Voir ce qu'on fait des avoirs

    const factureFormStructure: FormStructure[] = React.useMemo(() => {
        return [
            ...enteteFactureStructure(() => {}),
            ...getFournisseurStructure(
                getFournisseurAcStructure(fournisseursDataSource, getFournisseurAc),
            ),
        ];
    }, [fournisseursDataSource]);

    const formContext = useForm({
        mode: 'onTouched',
        // defaultValues: {},
    });

    const resetForm = useResetAllForm();

    const handleSubmit = async function (data: enteteFactureForm) {
        const factureToPost = formEnteteToFactureAchatWrite(data, xIdSociete, mediaObject);

        const resFactureAchat = await factureAchatApi.createFactureAchatFactureAchatCollection(
            factureToPost,
            xIdSociete,
        );

        resetForm(formContext);

        const res = generateB2DPath('formmultiadddossier', {
            factureAchat: resFactureAchat.data as FactureAchat,
            mediaObject,
        });

        if (res.status === 'OK') {
            navigate(res.path);
        } else {
            // TODO
        }
    };

    const onCancel = useBackFromMediaObjectEdit();

    /**
     * Afficher infos fournisseur
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-53
     *
     * Si le N° de dossier / annonce change :
     * Remplir les champs Siren, Raison sociale, Type remise
     * Ajouter une ligne de produit : Remise
     *
     * @see fournisseursDataSource.selected
     * @see updateRSFFournisseur
     */
    useEffect(() => {
        const fournisseur = fournisseursDataSource.selected;

        if (fournisseur !== undefined) {
            formContext.setValue('fournisseur', fournisseur);
            formContext.setValue('siren_fournisseur', fournisseur);
            formContext.trigger('siren_fournisseur');
        }

        updateFormWithFournisseur(formContext, fournisseur);
    }, [formContext, fournisseursDataSource.selected]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography color="secondary" typography="h1">
                        Saisie de la facturante
                    </Typography>
                </Box>
            </Box>

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitting }) => (
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button
                                color="error"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<ClearIcon />}
                                onClick={() => {
                                    onCancel();
                                }}
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            >
                                Annuler la saisie
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<CheckIcon />}
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            >
                                Valider l'en-tête de facture
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};

export default EnteteSaisieMultiple;
