import React, { useCallback, useMemo, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COMMANDES_SLICE_NAME, DOSSIERS_SLICE_NAME, FACTURES_ACHATS_SLICE_NAME, FACTURE_VENTE_SLICE_NAME, FOURNISSEUR_SLICE_NAME, FactureAchat, FactureAchatApiObject } from '@europrocurement/l2d-domain';
import {
    ColumnDatatable,
    FilterDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import SyncIcon from '@mui/icons-material/Sync';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationModal from '../modals/ConfirmationModal';
import { factureAchatApi, mediaObjectApi } from '../../../../redux/RootStore';
import { Button, Tooltip, Typography } from '@mui/material';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import generateB2DPath, { accessibleDirections } from '../../../../utils/generateB2DPath';
import { useSnackbar } from 'notistack';
import { downloadFromUrl } from '../../../../utils/download';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { groupLinesByDossiers } from '../forms/functions/dossierFonctions';
import CodeRejetsChipList from '../widgets/CodeRejetsChipList';
import { repriseMultiAnnonceModalMessages } from '../modals/modalSpec';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { customizerSelector } from '@europrocurement/flexy-components/redux/storeConfig/selectors';

function ActionMenu({
    factureAchat,
    onNavigate,
    onSync,
}: {
    factureAchat: FactureAchat;
    onNavigate: (factureAchat: FactureAchat) => void;
    onSync: (factureAchat: FactureAchat) => void;
}) {
    const dispatch = useDispatch();
    const { request, requestState } = useApiRequest();
    const { isProcessing } = requestState;
    const { xIdSociete } = useSelector(customizerSelector);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Action lors du clique du bouton de téléchargement du PDF.
     * Dispatch n'étant pas asynchrone, le reste de la logique
     * passe par un useEffect.
     */
    const onDownload = useCallback(async (factureAchat: FactureAchat) => {
        if (factureAchat.pdfFacture && factureAchat.pdfFacture.id) {
            let mediaObject = await mediaObjectApi.getMediaObjectItem(
                `${factureAchat.pdfFacture.id}`,
            );
            if (mediaObject.data && mediaObject.data.contentUrl) {
                downloadFromUrl(
                    mediaObject.data.contentUrl,
                    mediaObject.data.originalName
                        ? mediaObject.data.originalName
                        : `pdf-facture-${factureAchat.pdfFacture.id}.pdf`,
                );
            }
        }
    }, []);

    const onDelete = useCallback(
        async (factureAchat: FactureAchat) => {
            if (!factureAchat.id) return;
            console.log(factureAchat.id);
            console.log(factureAchat);

            const successCallback = (result: any) => {
                // const prescriberId = result.data?.id;
                // if (prescriberId) {
                //     redirectToPrescriberDetail(prescriberId);
                // }
                console.log('Here is the successCallback !!!');
            };

            request(
                factureAchatApi.deleteFactureAchatFactureAchatItem(
                    factureAchat.id.toString(),
                    xIdSociete,
                ),
                {
                    successCallback,
                },
            );
        },
        [request, xIdSociete],
    );

    let response = generateB2DPath('formfactureachat', { factureAchat: factureAchat });

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <Button
                        onClick={() => {
                            onNavigate(factureAchat);
                        }}
                        style={{ textDecoration: 'none', padding: '0' }}
                        size="small"
                    >
                        <Typography sx={{ color: 'text.primary' }}>Consulter</Typography>
                    </Button>
                </MenuItem>
                {factureAchat.id ? (
                    <MenuItem
                        disabled={factureAchat.cloture === true || factureAchat.syncG3 === true}
                        onClick={() => {
                            onDelete(factureAchat);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Supprimer</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {response.status === 'OK' ? (
                    <MenuItem onClick={handleClose}>
                        <Link to={response.path} target="_blank" style={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: 'text.primary' }}>
                                Ouvrir dans un nouvel onglet
                            </Typography>
                        </Link>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {factureAchat.id ? (
                    <MenuItem
                        onClick={() => {
                            onDownload(factureAchat);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Afficher PDF</Typography>
                    </MenuItem>
                ) : (
                    <MenuItem>Erreur, facture manquante</MenuItem>
                )}
                {factureAchat.id && factureAchat.cloture === true ? (
                    <MenuItem
                        onClick={() => {
                            onSync(factureAchat);
                            handleClose();
                        }}
                    >
                        <Typography sx={{ color: 'text.primary' }}>Synchroniser avec G3</Typography>
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    );
}

export type FactureAchatListProps = {
    dataSource: DataSource<FactureAchat>;
    fetchData: getDataThunkType<FactureAchatApiObject>;
    filtersControl?: boolean;
    filterFilters?: (
        filtre: FilterDatatable,
        index: number,
        array: FiltersDatatableList,
    ) => unknown;
};

export const navigateToFacture = (
    factureAchat: FactureAchat,
    navigate: (path: string) => void,
    onError: (message: string) => void,
) => {
    let fallback: accessibleDirections = 'formfactureachat';

    if ((factureAchat.countDossier || 1) > 1) {
        fallback = 'consulterMultiFacture';
    }

    let response = generateB2DPath(fallback, { factureAchat: factureAchat });

    if (response.status === 'OK') {
        navigate(response.path);
    } else if (response.status === 'KO') {
        onError(response.message);
    }
};

const FactureAchatList: React.FunctionComponent<FactureAchatListProps> = function ({
    dataSource,
    fetchData,
    filtersControl = true,
    filterFilters = () => true,
}) {
    const selectedFactureAchat: FactureAchat | undefined = dataSource.selected;

    const keycloak = UseKeycloakService();

    const [repriseFactureModalOpen, setRepriseFactureModalOpen] = useState<boolean>(false);
    const [factureAReprende, setFactureAReprende] = useState<FactureAchat | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    const navigateToFactureCb = useCallback(
        (factureAchat: FactureAchat) => {
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
            });

            if (factureAchat.cloture === false) {
                setRepriseFactureModalOpen(true);
                setFactureAReprende(factureAchat);
            } else {
                navigateToFacture(factureAchat, navigate, (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'warning' });
                });
            }
        },
        [enqueueSnackbar, selectedFactureAchat, navigate, resetStates],
    );

    const reprendreFacture = useCallback(() => {
        if (!factureAReprende) return;
        resetStates();

        let fallback: accessibleDirections = 'formmultiadddossier';

        let response = generateB2DPath(fallback, { factureAchat: factureAReprende });
        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [factureAReprende, resetStates, navigate]);

    const synchroG3Facture = useCallback(
        async (factureAchat: FactureAchat) => {
            if (!factureAchat.id) {
                enqueueSnackbar(
                    <Typography>Erreur technique la facture n'a pas d'identifiant</Typography>,
                    { variant: 'warning' },
                );
                return;
            }
            try {
                const res = await factureAchatApi.syncG3FactureAchatFactureAchatItem(
                    `${factureAchat.id}`,
                    { syncG3: true },
                );

                if (res.status >= 200 && res.status < 300) {
                    enqueueSnackbar(<Typography>Synchronisation effectuée</Typography>, {
                        variant: 'success',
                    });
                } else {
                    throw new Error(`Erreur ${res.status}`);
                }
            } catch (error) {
                if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, { variant: 'error' });
                }
            }
            return;
        },
        [enqueueSnackbar],
    );

    const columns: ColumnDatatable<FactureAchat>[] = useMemo(
        () => [
            {
                label: 'N° de facture',
                isDisplayed: true,
                render: (factureAchat: FactureAchat) => {
                    return (
                        <>
                            <Typography>{factureAchat.numeroFacture}</Typography>
                            {factureAchat.syncG3 ? (
                                <Tooltip title="Facture synchronisée avec G3">
                                    <SyncIcon color={'primary'} />
                                </Tooltip>
                            ) : null}
                            {factureAchat.cloture === false ? (
                                <Tooltip title="Facture multiple non cloturée">
                                    <UnpublishedIcon color={'warning'} />
                                </Tooltip>
                            ) : null}
                        </>
                    );
                },
            },
            {
                label: 'Créé le',
                render: (factureAchat: FactureAchat) => {
                    let faCreationDate = null;

                    if (factureAchat.createdAt) {
                        faCreationDate = new Date(
                            Date.parse(factureAchat.createdAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{faCreationDate}</Typography>;
                },
                isDisplayed: true,
            },
            {
                label: 'Créé par',
                render: 'createdBy',
                isDisplayed: false,
            },
            {
                label: 'Modifié le',
                render: (factureAchat: FactureAchat) => {
                    let faCreationDate = null;

                    if (factureAchat.updatedAt) {
                        faCreationDate = new Date(
                            Date.parse(factureAchat.updatedAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{faCreationDate}</Typography>;
                },
                field: 'updatedAt',
                sortable: true,
                isDisplayed: true,
            },
            {
                label: 'Modifié par',
                render: 'updatedBy',
                isDisplayed: true,
            },
            {
                label: 'Numero Dossier',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.numeroDossier;
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Numero Annonce',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `(${factureAchat.countDossier} dossiers)`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.numeroPrestation;
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Id Dossier G3',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.idDossier;
                    }
                    return null;
                },
                isDisplayed: false,
            },
            {
                label: 'Id Prestation G3',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.countDossier && factureAchat.countDossier > 1) {
                        return `Facture multiple`;
                    }
                    if (factureAchat.firstDossier) {
                        return factureAchat.firstDossier.idPrestation;
                    }
                    return null;
                },
                isDisplayed: false,
            },
            {
                label: 'Fournisseur',
                render: 'libelleFacturante',
                isDisplayed: true,
            },
            {
                label: 'Annonceur',
                render: 'libelleAnnonceur',
                isDisplayed: true,
            },
            {
                label: 'ID Fournisseur',
                render: 'idEntiteFacturante',
                isDisplayed: false,
            },
            {
                label: 'Code rejets',
                render: (factureAchat) => {
                    if (
                        factureAchat.firstCodesRejets &&
                        factureAchat.firstCodesRejets?.length > 0
                    ) {
                        return (
                            <CodeRejetsChipList
                                rejets={factureAchat.firstCodesRejets}
                                full={false}
                                max={3}
                                count={
                                    factureAchat.countCodesRejets ||
                                    factureAchat.firstCodesRejets.length
                                }
                            />
                        );
                    }
                    return '';
                },
                isDisplayed: true,
            },
            {
                label: 'Date de facturation',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.dateFacture) {
                        return new Date(factureAchat.dateFacture).toLocaleDateString();
                    }
                    return '';
                },
                isDisplayed: false,
            },
            {
                label: 'Montant total',
                render: (factureAchat: FactureAchat) => {
                    if (factureAchat.totalTtc) {
                        return parseFloat(factureAchat.ttc || '0').toFixed(2);
                    }
                    return '';
                },
                isDisplayed: false,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (item) => (
                    <ActionMenu
                        factureAchat={item}
                        onNavigate={navigateToFactureCb}
                        onSync={synchroG3Facture}
                    />
                ),
                isDisplayed: true,
            },
        ],
        [navigateToFactureCb, synchroG3Facture],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'linkedCodeRejets',
            label: 'Avec code rejet',
            type: 'boolean',
        },
        {
            field: 'cloture',
            label: 'Factures clôturées',
            type: 'boolean',
        },
        {
            field: 'syncG3',
            label: 'Factures synchronisées G3',
            type: 'boolean',
        },
        {
            field: 'libelleFacturante',
            label: 'Fournisseur',
            type: 'text',
        },
        {
            field: 'ligneNumeroDossier',
            label: 'Numero Dossier',
            type: 'number',
        },
        {
            field: 'ligneNumeroPresta',
            label: 'Numero Annonce',
            type: 'text',
        },
        {
            field: 'updatedBy',
            label: 'Mes saisies',
            type: 'boolean',
            valueMap: (value: boolean) => {
                if (value === true) {
                    return keycloak.getTokenParsed().email || undefined;
                }
                return undefined;
            },
        },
        {
            field: 'isMulti',
            label: 'Facture multi-dossiers',
            type: 'boolean',
        },
    ];

    return (
        <>
            <ConfirmationModal
                isModalOpen={repriseFactureModalOpen}
                closeModal={() => {
                    setRepriseFactureModalOpen(false);
                }}
                messages={repriseMultiAnnonceModalMessages}
                actionOnValidation={() => {
                    reprendreFacture();
                }}
                actionOnCancellation={() => {
                    setRepriseFactureModalOpen(false);
                }}
            />
            <StoreDatatable
                dataSource={dataSource}
                // activeFilters={activeFilters}
                // replaceActiveFilters={replaceActiveFilters}
                columns={columns}
                fetchData={fetchData}
                filters={filterFilters ? filters.filter(filterFilters) : filters}
                localStorageKey="FlexyStoreDatatableFactureAchat"
                localStorageRefreshDate={new Date('2023-06-23T09:42:00.000Z')}
                onClickRow={(e: React.MouseEvent<HTMLElement>, item: FactureAchat) =>
                    navigateToFactureCb(item)
                }
                filtersControl={filtersControl}
            />
        </>
    );
};

export default FactureAchatList;
