import * as React from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import {
    DatatableHeaderProps,
    DisplayOpts,
    FilterDatatable,
    FiltersOpts,
    SearchOpts,
} from '../DatatableHeader';
import { FlexyInput } from '@europrocurement/flexy-components';

export type FlexyDatatableFiltersProps = {
    filtersOpts: FiltersOpts;
    mode?: 'classic' | 'raw';
    sx?: SxProps;
};

const FlexyDatatableFilters: React.FunctionComponent<FlexyDatatableFiltersProps> = function ({
    filtersOpts,
    mode = 'classic',
    sx = {},
}) {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: mode === 'classic' ? 'column' : 'row',
                gap: '10px',
                ...sx,
            }}
        >
            {mode === 'classic' ? <Typography variant="h2">Filtres de recherche</Typography> : null}

            {filtersOpts?.filters.map((filter: FilterDatatable, i) => {
                const value =
                    (filter.type !== 'boolean' && filtersOpts?.activeFilters?.[filter.field]) || '';
                const checked =
                    (filter.type === 'boolean' && filtersOpts?.activeFilters?.[filter.field]) ||
                    false;

                return (
                    <Box key={String(filter.label + filter.field + i)}>
                        <FlexyInput
                            key={String(filter.field)}
                            {...{
                                value: value,
                                checked: checked,
                            }}
                            data-testid="SearchFilters"
                            variant="outlined"
                            type={filter.type}
                            size="small"
                            name={filter.label}
                            placeholder={filter.label}
                            inputlabel={filter.label.replace(/^\w/, (c) => c.toUpperCase())}
                            onChange={(e: React.BaseSyntheticEvent | Date, checked: boolean) => {
                                let value;
                                if (filter.type === 'boolean') {
                                    value = checked;
                                } else if (filter.type === 'date') {
                                    value = new Date(e as Date);
                                } else {
                                    value = (e as React.BaseSyntheticEvent).target.value;
                                }

                                if (filter.valueMap) value = filter.valueMap(value);
                                filtersOpts?.onFilterChange(filter.field, value);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default FlexyDatatableFilters;
