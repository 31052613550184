//React import
import * as React from 'react';

//Components
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FlexyHeaderForm } from '@europrocurement/flexy-components';

//Data / utils / custom hooks
import { PrescripteursApi } from '@europrocurement/l2d-domain';
import Prescripteur from '@europrocurement/l2d-domain/entities/tiers/prescripteur';
import { ContactsApi } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import PrescriberInformations from './PrescriberInformations';
import PrescriberContacts from './PrescriberContacts';
import PrescriberAddresses from './PrescriberAddresses';
import { ModalFunctions } from '../PrescripteurDetail';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type InfosGeneralesProps = {
    prescripteur: Prescripteur;
    prescripteursApi: PrescripteursApi;
    contactsApi: ContactsApi;
    reloadPrescripteur: () => void;
    modalFunctions: ModalFunctions;
};

const MainInformations: React.FunctionComponent<InfosGeneralesProps> = function ({
    prescripteur,
    modalFunctions,
    reloadPrescripteur,
}) {
    const { closeModal, openModal, setModalContent } = modalFunctions;

    const callModal = (modalContent: React.ReactElement) => {
        setModalContent(modalContent);
        openModal();
    };

    const afterSubmit = () => {
        reloadPrescripteur();
        closeModal();
        setModalContent(null);
    };

    const prescriberAccordionProps = {
        prescriber: prescripteur,
        callModal,
        closeModal,
        afterSubmit,
    };

    type AccordionItem = {
        title: string;
        component: React.ReactElement;
        addAction?: () => void;
        addTooltip?: string;
    };

    const accordions: Array<AccordionItem> = [
        {
            title: 'Renseignements Société',
            component: <PrescriberInformations {...prescriberAccordionProps} />,
        },
        {
            title: 'Contacts',
            component: <PrescriberContacts {...prescriberAccordionProps} />,
        },
        {
            title: 'Adresses',
            component: <PrescriberAddresses {...prescriberAccordionProps} />,
        },
    ];

    const cloneCardStyle = {
        //Card style consistency,
        boxShadow: `0px 7px 30px 0px rgba(90, 114, 123, 0.11)`,
        padding: '10px',
        margin: '15px !important',
        borderRadius: '20px !important',
    };
    return (
        <>
            {accordions.map(({ title, component }) => {
                return (
                    <Accordion
                        sx={{
                            ...cloneCardStyle,
                            '&:before': {
                                backgroundColor: 'inherit',
                            },
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            sx={{
                                boxShadow: 'none',
                            }}
                        >
                            <Box>
                                <FlexyHeaderForm sx={{ margin: '0px' }} label={title} outlined />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>{component}</AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};

export default MainInformations;
