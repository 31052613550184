import { RelationSocieteTiersJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

/**
 * Payload formats for Api requests
 */

export const getPrescripteurCompanyRelationPayload = (
    companyRelation: RelationSocieteTiersJsonldTiersRead,
) => {
    const {
        clientSuivi,
        clientXl,
        tiersNonFiable,
        dafc,
        modePaiement,
        delaiPaiement,
        emailSousclientObligatoire,
        partenaireFormalite,
        logoFormalite,
    } = companyRelation;

    return {
        clientSuivi,
        clientXl,
        tiersNonFiable,
        dafc,
        delaiPaiement,
        modePaiement: {
            id: modePaiement?.id,
        },
        emailSousclientObligatoire,
        partenaireFormalite,
        logoFormalite: logoFormalite ?? '',
    };
};

export const getPrescriberRightsPayload = (
    companyRelation: RelationSocieteTiersJsonldTiersRead,
) => {
    const { affichesProAutorise, formalProAutorise } = companyRelation;

    const fields = [affichesProAutorise, formalProAutorise];

    const isFormValid = fields.every((field) => typeof field === 'boolean');

    if (isFormValid) {
        return {
            accesAffichesPro: affichesProAutorise,
            accesFormalPro: formalProAutorise,
        };
    }
};
