export class KeycloakRoleMalFormedError extends Error {
    constructor(inputMessage?: string) {
        let message =
            'Role malformed, role should be undefined to only check auth, "myrole" to check client role or "myressource:myrole"|"realm:myrole" to check role on ressource|realm or an array of roles ex: ["myrole","myressource:myrole","realm:myrole"]';
        if (inputMessage) {
            message = inputMessage;
        }
        super(message);
        Object.setPrototypeOf(this, KeycloakRoleMalFormedError.prototype);
    }
}

export default KeycloakRoleMalFormedError;
