import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { FactureAchat, Fournisseur, RubriqueFacturation } from '@europrocurement/l2d-domain';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import {
    FactureachatSelector,
    RubriqueFacturationSelector,
    factureAchatApi,
    factureAchatLigneApi,
    selectFournisseur,
} from '@b2d/redux/RootStore';
import { RootStateType } from '@b2d/redux/RootStore';
import { selectFactureAchat } from '@b2d/redux/RootStore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { CenterCircularProgress, FlexyAccordeon } from '@europrocurement/flexy-components';
import EnteteFacture from './EnteteFacture';
import {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import { groupLinesByDossiers } from '../functions/dossierFonctions';
import { getTotaux } from '../functions/produitFunctions';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { refuseFactureAchatModalMessages } from '../../../components/modals/modalSpec';
import { formRecapTotauxToFactureAchatWrite } from '../functions/dataTransformers';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useSnackbar } from 'notistack';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { totauxStructure } from '../formElements';
import { useBackFromMediaObjectEdit, useNavigateToDernierDossier } from '../../../AchatsRouter';
import { factureFormObject } from '../types';
import { RSFRubFac } from '../types';
import DossierLinesAccordeon from '../../informationWidget/DossierLinesAccordeon';
import { useGetDossiers, useGetLignes, useSynchroG3Facture } from '../functions/dataHooks';

export type RecapSaisieMultipleType = {};
export const RecapSaisieMultiple = function ({}: RecapSaisieMultipleType) {
    const { factureachatid } = useParams();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const onCancel = useBackFromMediaObjectEdit();
    const navigateToDernierDossier = useNavigateToDernierDossier();

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main,
        shallowEqual,
    );

    const factureAchat: FactureAchat | undefined = useSelector(FactureachatSelector).main.selected;

    const formContext = useForm({
        mode: 'onTouched',
    });

    const [lockedTotaux, setLockedTotaux] = useState<boolean>(true);
    const [modalRefuseFactureAchatOpen, setModalRefuseFactureAchatOpen] = useState<boolean>(false);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    const [dossiers, setdossiers] = useState<FactureAchatFactureAchatReadDossierDossiersInner[]>(
        [],
    );
    const [dossiersStatus, setdossiersStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');

    const [grouped, setGrouped] = useState<{
        [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
    }>({});

    const [page, setPage] = useState<number>(1);
    const [itemPerPage, setItemPerPage] = useState<number>(5);

    const getDossiers = useGetDossiers();
    const getLignes = useGetLignes();
    const synchroG3Facture = useSynchroG3Facture();

    useEffect(() => {
        if (!factureachatid || dossiersStatus === 'loading' || dossiersStatus === 'succeeded') {
            return;
        }

        setdossiersStatus('loading');
        getDossiers(+factureachatid, factureAchatApi).then((data) => {
            console.log(data);
            setdossiersStatus('succeeded');
            setdossiers(data);
        });
    }, [dossiersStatus, factureachatid, getDossiers]);

    useEffect(() => {
        if (
            dossiers &&
            dossiers.length > 0 &&
            factureAchat &&
            factureAchat.id &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded'
        ) {
            const ids = dossiers
                .map((item: FactureAchatFactureAchatReadDossierDossiersInner) => item.idDossier)
                .filter((id: number | undefined) => id !== undefined)
                .slice(itemPerPage * (page - 1), itemPerPage * page);

            setLignesStatus('loading');
            getLignes(factureAchat.id, ids as number[], factureAchatLigneApi).then((data) => {
                setLignesStatus('succeeded');
                setLignes(data);
            });
        }
    }, [dossiers, factureAchat, getDossiers, getLignes, itemPerPage, lignesStatus, page]);

    /**
     * Si le paramètre factureachatid est trouvé,
     * alors sélectionner la facture d'achat correspondante.
     */
    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * ??
     * S'il existe un fournisseur lié à la facture d'achat,
     * alors sélectionner le fournisseur.
     */
    useEffect(() => {
        if (!factureAchat) return;

        setGrouped(groupLinesByDossiers(lignes));

        if (factureAchat.idEntiteFacturante && !fournisseursDataSource.selected) {
            dispatch(selectFournisseur({ id: +factureAchat.idEntiteFacturante }));
        }
    }, [dispatch, factureAchat, fournisseursDataSource.selected, lignes]);

    /**
     * S'il existe une facture d'achat,
     * alors additionner les valeurs des lignes de produits de chaque dossier,
     * puis les enregistrer dans le formulaire.
     */
    useEffect(() => {
        if (!factureAchat) return;

        const totaux = getTotaux(factureAchat);
        if (factureAchat.avoir === true) {
            formContext.setValue('total_ht', (-totaux.ht).toFixed(2));
            formContext.setValue('total_tva', (-totaux.tva).toFixed(2));
            formContext.setValue('total_ttc', (-totaux.ttc).toFixed(2));
        } else {
            formContext.setValue('total_ht', (totaux.ht).toFixed(2));
            formContext.setValue('total_tva', (totaux.tva).toFixed(2));
            formContext.setValue('total_ttc', (totaux.ttc).toFixed(2));
        }
    }, [factureAchat, formContext]);

    /**
     * Formate le formulaire avant de mettre à jour les infos de la facture.
     * Si la mise à jour est un succès, alors l'utilisateur est envoyé vers
     * la liste des MediaObjects.
     *
     * @param factureForm
     * @returns void
     */
    const handleSubmit = async function (factureForm: factureFormObject) {
        if (!factureachatid || !factureAchat) {
            return;
        }

        const factureToPost = formRecapTotauxToFactureAchatWrite(
            factureForm,
            factureAchat,
            lockedTotaux,
        );

        await factureAchatApi.updateFactureAchatFactureAchatItem(factureachatid, factureToPost);

        console.log('synchro G3 ok for this id ?? ', factureachatid);
        const resSync = await synchroG3Facture(factureachatid);
        console.log('response synchro G3 ', resSync);

        if (!resSync) {
            enqueueSnackbar(
                <Typography>
                    La synchronisation G3 a échouée !
                </Typography>,
                { variant: 'error' },
            );
        }

        const res = generateB2DPath('listmediaobject');

        if (res.status === 'OK') {
            navigate(res.path);
            enqueueSnackbar(
                <Typography>
                    Facture d'achat {factureAchat.numeroFacture} validée avec succès !
                </Typography>,
                {
                    variant: 'success',
                    autoHideDuration: 10000,
                },
            );
        } else {
            // TODO Traitement supplémentaire en cas d'erreur ?
            enqueueSnackbar(
                <Typography>
                    La validation de la facture d'achat {factureAchat.numeroFacture} a échouée ! ❌
                </Typography>,
                {
                    variant: 'error',
                    autoHideDuration: 10000,
                },
            );
        }
    };

    const changePage = useCallback((page: number) => {
        setLignes([]);
        setLignesStatus('idle');
        setPage(page);
    }, []);

    const factureFormStructure: FormStructure[] = useMemo(() => {
        return [
            ...totauxStructure(lockedTotaux, (switchEditTotaux) => {
                setLockedTotaux(switchEditTotaux);
            }),
        ];
    }, [lockedTotaux]);

    return !factureAchat || !fournisseursDataSource.selected ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <>
            <ConfirmationModal
                isModalOpen={modalRefuseFactureAchatOpen}
                closeModal={() => {
                    setModalRefuseFactureAchatOpen(false);
                }}
                messages={refuseFactureAchatModalMessages}
                actionOnValidation={() => {
                    onCancel();
                    setModalRefuseFactureAchatOpen(false);
                }}
                actionOnCancellation={() => {
                    setModalRefuseFactureAchatOpen(false);
                }}
            />

            <EnteteFacture
                factureAchat={factureAchat}
                fournisseur={fournisseursDataSource.selected}
                showListeDossier={true}
                dossiers={dossiers}
            />
            {Object.keys(grouped).map((libelle) => (
                <DossierLinesAccordeon key={`${libelle}`} lines={grouped[libelle]} displayName={libelle} />
            ))}

            <Stack alignItems="center" sx={{ marginTop: '15px' }}>
                <Pagination
                    page={page}
                    count={Math.ceil(dossiers.length / itemPerPage)}
                    onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                        changePage(page);
                    }}
                />
            </Stack>

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitting }) => (
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button
                                key={`reprendre-saisie-${isSubmitting}`}
                                color="primary"
                                disabled={isSubmitting}
                                variant="outlined"
                                endIcon={<ArrowRightAltIcon />}
                                onClick={() => {
                                    navigateToDernierDossier(factureAchat);
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Reprendre la saisie
                            </Button>
                            <Button
                                key={`valider-facture-${isSubmitting}`}
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<CheckIcon />}
                                onClick={() => {
                                    formContext.handleSubmit((factureForm) => {
                                        if (!lockedTotaux) {
                                            factureForm.ht = formContext.getValues('total_ht');
                                            factureForm.tva = formContext.getValues('total_tva');
                                            factureForm.ttc = formContext.getValues('total_ttc');
                                        }
                                        handleSubmit(factureForm as factureFormObject);
                                    })();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Valider la facture d'achat
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};

export default RecapSaisieMultiple;
