import { UseFormReturn } from 'react-hook-form';
import { FormObject } from '@europrocurement/flexy-form';
import { resetDossiersFields } from './dossierFonctions';
import { resetFournisseursFields } from './fournisseurFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import {
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { RootStateType } from '@b2d/redux/RootStore';

/**
 *
 * @param formContext
 */
export const resetDossierAndFournisseurs = function (formContext: UseFormReturn<FormObject>) {
    resetDossiersFields(formContext);
    resetFournisseursFields(formContext);
};

/**
 *
 * @returns
 */
export const useResetAllForm = function () {
    const dispatch = useDispatch();
    const resetStates = useResetFactureAchatState();

    return (formContext: UseFormReturn<FormObject>) => {
        resetStates(formContext);
        dispatch({
            type: ACTIONS.RESET,
        });
    };
};

/**
 * Vide
 *
 * @returns void
 */
export const useResetFactureAchatState = function () {
    const dispatch = useDispatch();
    const defaultNumeroDossier = useSelector(
        (s: RootStateType) => s.factureForm.defaultNumeroDossier,
    );
    const defaultNumeroPresta = useSelector(
        (s: RootStateType) => s.factureForm.defaultNumeroPresta,
    );
    const defaultSiren = useSelector((s: RootStateType) => s.factureForm.defaultSiren);
    const factureVente = useSelector((s: RootStateType) => s.dossiers.facturevente.main.selected);
    const dossier = useSelector((s: RootStateType) => s.dossiers.dos.autocompleteDossiers.selected);
    const fournisseur = useSelector(
        (s: RootStateType) =>
            s.tiers[FOURNISSEUR_SLICE_NAME][FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME]
                .selected,
    );

    return (formContext: UseFormReturn<FormObject>) => {
        resetDossierAndFournisseurs(formContext);

        formContext.setValue('fournisseur', {});
        formContext.setValue('numero_dossier', null);
        formContext.setValue('numero_annonce', null);

        if (defaultNumeroDossier !== '') {
            dispatch({
                type: ACTIONS.SET_NUM_DOSSIER,
                payload: '',
            });
        }
        if (defaultNumeroPresta !== '') {
            dispatch({
                type: ACTIONS.SET_NUM_PRESTA,
                payload: '',
            });
        }
        if (defaultSiren !== '') {
            dispatch({
                type: ACTIONS.SET_SIREN,
                payload: '',
            });
        }
        if (fournisseur) {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Selected`,
            });
        }
        if (factureVente) {
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
        }
        if (dossier) {
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/delete${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });
        }
    };
};
