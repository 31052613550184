import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import type { Reducer } from '@reduxjs/toolkit';
import {
    ColorsPaletteVariant,
    FlexyInput,
    FlexyTabs,
    FlexyFormLabel,
    CenterCircularProgress,
    FlexyDateRange,
    FlexyDateRangeProps,
} from '@europrocurement/flexy-components';
import { AutocompleteStore } from '@europrocurement/flexy-form';
import FactureAchatList from './lists/FactureAchatList';
import CodesRejetPanel from './CodeRejetsPanel';
import CategoriesPanel from './CategoriesPanel';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import {
    CategorieStats,
    CodeRejetStats,
    CodeRejetStatsApiObject,
    FactureAchat,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    Fournisseur,
    FOURNISSEUR_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import {
    CategorieStatsSelector,
    CodeRejetStatsSelector,
    FactureachatSelector,
    FournisseurSelector,
    getCategorieStats,
    getCodeRejetStats,
    getFactureAchatStats,
    fournisseurDataSourcesThunks,
} from '../../../redux/RootStore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    FiltersDatatableList,
    FiltersOpts,
    FlexyFiltersList,
} from '@europrocurement/flexy-datatable';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import {
    Folder,
    Category,
    Receipt,
    AccountBalance,
    ShortText,
    Newspaper,
    EuroSymbol,
    ProductionQuantityLimits,
    Sell,
} from '@mui/icons-material';
import RienAAfficher from './RienAAfficher';
import { DateRange } from 'mui-daterange-picker';

import _ from 'lodash';
import { toISOString } from '../../../utils/toISOString';

type StatsTabsProps = {};

/**
 * Tri dans l'ordre alphanumérique en prenant en compte la numérotation
 * des codes rejets
 *
 * TODO: A fonctionné, mais ne marche plus.
 */
const sortList = (array: CodeRejetStatsApiObject[]) => {
    array.slice().sort((a: CodeRejetStatsApiObject, b: CodeRejetStatsApiObject) => {
        const aName = a.name ? a.name : 'undefined';
        const bName = b.name ? b.name : 'undefined';

        const aMatch = aName.match(/([A-Z]+)(\d+)/);
        const bMatch = bName.match(/([A-Z]+)(\d+)/);
        if (aMatch && bMatch) {
            const aLetters = aMatch[1];
            const bLetters = bMatch[1];
            const aNumber = parseInt(aMatch[2], 10);
            const bNumber = parseInt(bMatch[2], 10);

            if (aLetters < bLetters) {
                return -1;
            } else if (aLetters > bLetters) {
                return 1;
            } else {
                return aNumber - bNumber;
            }
        } else {
            if (aName < bName) {
                return -1;
            } else {
                return 1;
            }
        }
    });
    return array;
};

type statsFiltersReducerType = {
    afficherZeros: boolean;
    dateFacture: string[] | null[];
    createdAt: string[] | null[];
};

const initState: statsFiltersReducerType = {
    afficherZeros: false,
    dateFacture: [null, null],
    createdAt: [null, null],
};

/**
 * Actions à appeler lorsque l'on souhaite dispatch une action sur le
 * reducer des filtres
 */
const actions = {
    RESET: 'statsFilters.reset',
    SWITCH_AFFICHER_ZEROS: 'statsFilters.switchAfficherZeros',
    CHANGE_DATE_FACTURE: 'statsFilters.changeDateFacture',
    CHANGE_CREATED_AT: 'statsFilters.changeCreatedAt',
};

const statesFiltersReducer: Reducer<statsFiltersReducerType> = (state = initState, action) => {
    let newState: statsFiltersReducerType = { ...state };

    switch (action.type) {
        case actions.RESET:
            newState = { ...initState };
            break;
        case actions.SWITCH_AFFICHER_ZEROS:
            newState = {
                ...state,
                afficherZeros: !state.afficherZeros,
            };
            break;
        case actions.CHANGE_DATE_FACTURE:
            newState = {
                ...state,
                dateFacture: [action.payload.before, action.payload.after],
            };
            break;
        case actions.CHANGE_CREATED_AT:
            newState = {
                ...state,
                createdAt: [action.payload.before, action.payload.after],
            };
            break;
        default:
            newState = { ...state };
            break;
    }

    return newState;
};

const StatsTabs: React.FunctionComponent<StatsTabsProps> = function () {
    const categoriesDataSource: DataSource<CategorieStats> = useSelector(
        CategorieStatsSelector,
        shallowEqual,
    ).main;
    const codesRejetsDataSource: DataSource<CodeRejetStats> = useSelector(
        CodeRejetStatsSelector,
        shallowEqual,
    ).main;
    const facturesAchatsDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_STATS_DATASOURCE_NAME];
    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        FournisseurSelector,
        shallowEqual,
    ).main;

    const listCategories = categoriesDataSource.data;
    const listCodesRejets = codesRejetsDataSource.data;
    const listFacturesAchats = facturesAchatsDataSource.data;

    // TODO: Vérifier ce qui est encore utile et nettoyer
    let catProps: { [key: string]: JSX.Element } = {
        ERDN: <AccountBalance fontSize="large" />,
        ERCA: <Category fontSize="large" />,
        ERDO: <Folder fontSize="large" />,
        EREN: <ShortText fontSize="large" />,
        ERJA: <Newspaper fontSize="large" />,
        ERJU: <Receipt fontSize="large" />,
        ERLO: <Receipt fontSize="large" />,
        ERPF: <EuroSymbol fontSize="large" />,
        ERPU: <EuroSymbol fontSize="large" />,
        ERRC: <ProductionQuantityLimits fontSize="large" />,
        ERRE: <Sell fontSize="large" />,
        ERSO: <AccountBalance fontSize="large" />,
    };

    let codProps: { [key: string]: JSX.Element } = useMemo(() => {
        return {
            ERDO1: <Folder fontSize="large" />,
            ERDO2: <Folder fontSize="large" />,
            ERDO3: <Folder fontSize="large" />,
            ERDO4: <Folder fontSize="large" />,
            ERDO5: <Folder fontSize="large" />,
            ERDO6: <Folder fontSize="large" />,
            ERJA1: <Newspaper fontSize="large" />,
            ERJA2: <Newspaper fontSize="large" />,
            ERCA1: <Category fontSize="large" />,
            ERCA2: <Category fontSize="large" />,
            ERCA3: <Category fontSize="large" />,
            ERCA4: <Category fontSize="large" />,
            ERCA5: <Category fontSize="large" />,
            ERCA6: <Category fontSize="large" />,
            ERDN1: <AccountBalance fontSize="large" />,
            ERDN2: <AccountBalance fontSize="large" />,
            ERPU1: <EuroSymbol fontSize="large" />,
            ERPU2: <EuroSymbol fontSize="large" />,
            ERPU3: <EuroSymbol fontSize="large" />,
            ERPU4: <EuroSymbol fontSize="large" />,
            ERPF1: <EuroSymbol fontSize="large" />,
            ERPF2: <EuroSymbol fontSize="large" />,
            ERRC1: <ProductionQuantityLimits fontSize="large" />,
            ERRC2: <ProductionQuantityLimits fontSize="large" />,
            ERLO1: <Receipt fontSize="large" />,
            ERLO2: <Receipt fontSize="large" />,
            EREN1: <ShortText fontSize="large" />,
            EREN2: <ShortText fontSize="large" />,
            EREN3: <ShortText fontSize="large" />,
            EREN4: <ShortText fontSize="large" />,
            EREN5: <ShortText fontSize="large" />,
            EREN6: <ShortText fontSize="large" />,
            EREN7: <ShortText fontSize="large" />,
            EREN8: <ShortText fontSize="large" />,
            EREN9: <ShortText fontSize="large" />,
            EREN10: <ShortText fontSize="large" />,
            ERJU1: <Receipt fontSize="large" />,
            ERJU2: <Receipt fontSize="large" />,
            ERRE1: <Sell fontSize="large" />,
            ERRE2: <Sell fontSize="large" />,
            ERRE3: <Sell fontSize="large" />,
            ERRE4: <Sell fontSize="large" />,
            ERRE5: <Sell fontSize="large" />,
            ERSO1: <AccountBalance fontSize="large" />,
            ERSO2: <AccountBalance fontSize="large" />,
            ERSO3: <AccountBalance fontSize="large" />,
            ERSO4: <AccountBalance fontSize="large" />,
            ERSO5: <AccountBalance fontSize="large" />,
        };
    }, []);

    const dispatch = useDispatch();
    // Le reducer ne contient que les states des filtres
    const [statsFiltersState, statsFiltersDispatch] = useReducer(statesFiltersReducer, initState);
    const [activeTab, setActiveTab] = useState(0);
    const [categoriesProps, setCategoriesProps] = useState<string[]>(Object.keys(catProps));
    const [codesProps, setCodesProps] = useState<string[]>(Object.keys(codProps));
    const [dateFactureReset, setDateFactureReset] = useState<boolean>(false);
    const [createdAtReset, setCreatedAtReset] = useState<boolean>(false);
    const [fournisseurReset, setFournisseurReset] = useState<boolean>(false);

    let categoriesCount: number = 0;
    let codesRejetsCount: number = 0;
    let facturesEnRejetCount: number | undefined = facturesAchatsDataSource.pagination.total;

    /**
     * Filtre 'Afficher les éléments vides'
     *
     * Modifie les compteurs pour chaque DataSource en prenant en compte ou
     * non les éléments vides
     */
    if (!statsFiltersState.afficherZeros) {
        listCategories.forEach((categorie) => {
            categoriesCount = categorie.count !== 0 ? categoriesCount + 1 : categoriesCount;
        });

        listCodesRejets.forEach((codeRejet) => {
            codesRejetsCount = codeRejet.count !== 0 ? codesRejetsCount + 1 : codesRejetsCount;
        });
    } else {
        categoriesCount = listCategories.length;
        codesRejetsCount = listCodesRejets.length;
    }

    // Gestion du changement de tab ('Par catégories', 'Par codes rejets', 'Détails')
    const handleActiveTab = (newTab: number): void => {
        if (newTab !== activeTab) {
            setActiveTab(newTab);
        }
    };

    // Options de l'input select des Categories
    const categoriesOptions: Array<{
        value: string;
        label: string;
    }> = categoriesProps.map((value) => {
        return { value, label: value };
    });

    // Options de l'input select des Codes rejets
    const codesRejetsOptions: Array<{
        value: string;
        label: string;
    }> = codesProps.map((value) => {
        return { value, label: value };
    });

    // Reset state est temporairement activé
    const onResetActive = useCallback(
        (field: 'dateFacture' | 'createdAt' | 'idEntiteFacturante' | 'all') => {
            switch (field) {
                case 'all':
                    if (!dateFactureReset && !createdAtReset && !fournisseurReset) {
                        setDateFactureReset(true);
                        setCreatedAtReset(true);
                        setFournisseurReset(true);
                        setTimeout(() => {
                            setDateFactureReset(false);
                            setCreatedAtReset(false);
                            setFournisseurReset(false);
                        }, 1000);
                    }
                    break;
                case 'dateFacture':
                    if (!dateFactureReset) {
                        setDateFactureReset(true);
                        setTimeout(() => {
                            setDateFactureReset(false);
                        }, 1000);
                    }
                    break;
                case 'createdAt':
                    if (!createdAtReset) {
                        setCreatedAtReset(true);
                        setTimeout(() => {
                            setCreatedAtReset(false);
                        }, 1000);
                    }
                    break;
                case 'idEntiteFacturante':
                    if (!fournisseurReset) {
                        setFournisseurReset(true);
                        setTimeout(() => {
                            setFournisseurReset(false);
                        }, 1000);
                    }
                    break;
                default:
                    break;
            }
        },
        [createdAtReset, dateFactureReset, fournisseurReset],
    );

    // Met à jour les filtres de date
    const onUpdateDateRange = useCallback(
        (filter: string, range: DateRange) => {
            if (!range.endDate || !range.startDate) return;

            const before = toISOString(range.endDate, false);
            const after = toISOString(range.startDate, true);

            let actionTypeName = undefined;

            switch (filter) {
                case 'dateFacture':
                    actionTypeName = actions.CHANGE_DATE_FACTURE;
                    break;
                case 'createdAt':
                    actionTypeName = actions.CHANGE_CREATED_AT;
                    break;
            }

            statsFiltersDispatch({
                type: actionTypeName,
                payload: {
                    before: before,
                    after: after,
                },
            });
            dispatch({
                type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                payload: { key: filter, value: [before, after] },
            });

            dispatch(getCategorieStats({}));
        },
        [categoriesDataSource.name, categoriesDataSource.slicename, dispatch],
    );

    // Actions de modification ou de suppression sur les trois DataSources
    const dispatchTabs = useCallback(
        (action: 'delete' | 'set', payload: any) => {
            dispatch({
                type: `${categoriesDataSource.slicename}/${action}${categoriesDataSource.name}Filter`,
                payload: payload,
            });
            dispatch({
                type: `${codesRejetsDataSource.slicename}/${action}${codesRejetsDataSource.name}Filter`,
                payload: payload,
            });
            dispatch({
                type: `${facturesAchatsDataSource.slicename}/${action}${facturesAchatsDataSource.name}Filter`,
                payload: payload,
            });
            dispatch(getCategorieStats({}));
            dispatch(getCodeRejetStats({}));
            dispatch(getFactureAchatStats({}));
        },
        [
            categoriesDataSource.name,
            categoriesDataSource.slicename,
            codesRejetsDataSource.name,
            codesRejetsDataSource.slicename,
            dispatch,
            facturesAchatsDataSource.name,
            facturesAchatsDataSource.slicename,
        ],
    );

    // Comportement lorsque l'on souhaite modifier un filtre
    const onFilterChange = useDebouncedCallback(
        (field: string, value: number | string | Array<{ value: string }>) => {
            dispatchTabs('set', {
                key: field,
                value: Array.isArray(value)
                    ? value.map(({ value }: { value: string }) => value)
                    : value,
            });
        },
        500,
    );

    // Comportement lorsque l'on souhaite supprimer un filtre
    const onFilterDelete = useCallback(
        (field: string) => {
            dispatchTabs('delete', { field });

            switch (field) {
                case 'idEntiteFacturante':
                    onResetActive(field as 'idEntiteFacturante');
                    break;
                case 'dateFacture':
                    onResetActive(field as 'dateFacture');
                    statsFiltersDispatch({
                        type: actions.CHANGE_DATE_FACTURE,
                        payload: {
                            before: null,
                            after: null,
                        },
                    });
                    break;
                case 'createdAt':
                    onResetActive(field as 'createdAt');
                    statsFiltersDispatch({
                        type: actions.CHANGE_CREATED_AT,
                        payload: {
                            before: null,
                            after: null,
                        },
                    });
                    break;
            }
        },
        [dispatchTabs, onResetActive],
    );

    // Met à jour le filtre idEntiteFacturante
    useEffect(() => {
        // Libelle du fournisseur selectionné
        const idSelectedFournisseur = fournisseursDataSource?.selected?.id;
        // Filtre idEntiteFacturante des DataSources des tabs
        const catLibFactFilter = categoriesDataSource.filters.idEntiteFacturante;
        const codLibFactFilter = codesRejetsDataSource.filters.idEntiteFacturante;
        const facLibFactFilter = facturesAchatsDataSource.filters.idEntiteFacturante;

        if (idSelectedFournisseur) {
            if (
                catLibFactFilter !== idSelectedFournisseur ||
                codLibFactFilter !== idSelectedFournisseur ||
                facLibFactFilter !== idSelectedFournisseur
            ) {
                onFilterChange('idEntiteFacturante', idSelectedFournisseur);
            }
        } else {
            /**
             * Si une des DataSources a le filtre,
             * alors qu'il n'y a pas de fournisseur selecitonné,
             * alors le filtre est supprimé.
             */
            if (catLibFactFilter || codLibFactFilter || facLibFactFilter) {
                onFilterDelete('idEntiteFacturante');
            }
        }
    }, [
        categoriesDataSource.filters.idEntiteFacturante,
        codesRejetsDataSource.filters.idEntiteFacturante,
        facturesAchatsDataSource.filters.idEntiteFacturante,
        fournisseursDataSource.selected,
        onFilterChange,
        onFilterDelete,
    ]);

    // Liste des filtres à afficher
    // (Sert à l'affichage des chips de la liste des filtres actifs)
    const filters: FiltersDatatableList = [
        {
            field: 'emptyresults',
            label: 'Afficher les éléments vides',
            type: 'boolean',
        },
        {
            field: 'categories',
            label: 'Catégorie(s)',
            type: 'select',
        },
        {
            field: 'codesRejets',
            label: 'Code(s) Rejet(s)',
            type: 'select',
        },
        {
            field: 'idEntiteFacturante',
            label: 'Fournisseur',
            type: 'select',
        },
        {
            field: 'dateFacture',
            label: 'Facturée le',
            type: 'date',
        },
        {
            field: 'createdAt',
            label: 'Créée le',
            type: 'date',
        },
    ];

    /**
     * Synchroniser les filtres de la DataSource de categoriesstats vers codesrejetsstats et factureachat
     *
     * !! ATENTION !! L'ajout de codesRejets et codesRejets.filters dans les dépendances fait boucler
     *
     */
    useEffect(() => {
        if (!_.isEqual(categoriesDataSource.filters, codesRejetsDataSource.filters)) {
            const payloadValue: (filter: string) => string | string[] = (filter: string) => {
                let res: string | string[];

                // Prise en compte des cas particuliers de filtres multiples
                // (categories et codes rejets)
                if (Array.isArray(categoriesDataSource.filters[filter])) {
                    res = ((categoriesDataSource.filters[filter] || []) as Array<string>).map(
                        (value: string) => value,
                    );
                } else {
                    res = categoriesDataSource.filters[filter] as string;
                }

                return res;
            };

            // Pour chaque filtres de categoriesDataSource, les appliquer
            // aux autres DataSources
            Object.keys(categoriesDataSource.filters).forEach((filter) => {
                dispatch({
                    type: `${codesRejetsDataSource.slicename}/set${codesRejetsDataSource.name}Filter`,
                    payload: { key: filter, value: payloadValue(filter) },
                });
                dispatch({
                    type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
                    payload: { key: filter, value: payloadValue(filter) },
                });
            });

            // Actualiser les tabs 'Par codes rejets' et 'Détails'
            dispatch(getCodeRejetStats({}));
            dispatch(getFactureAchatStats({}));
        }
    }, [
        categoriesDataSource.filters,
        codesRejetsDataSource.filters,
        codesRejetsDataSource.name,
        codesRejetsDataSource.slicename,
        dispatch,
        facturesAchatsDataSource.name,
        facturesAchatsDataSource.slicename,
    ]);

    // Afficher uniquement les codes rejets des categories selectionées
    useEffect(() => {
        if (categoriesDataSource.filters['categories']) {
            setCodesProps(
                Object.keys(codProps).filter((code: string) => {
                    return (
                        (categoriesDataSource.filters['categories'] || []) as Array<string>
                    ).some((categorie: string) => code.startsWith(categorie));
                }),
            );
        } else {
            setCodesProps(Object.keys(codProps));
        }
    }, [categoriesDataSource.filters, codProps]);

    let filtersOpts: FiltersOpts = {
        onFilterChange,
        filters,
        activeFilters: categoriesDataSource.filters,
        onFilterDelete: (field: string) => {
            if (field === 'idEntiteFacturante') {
                dispatch({
                    type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
                });
            }
            onFilterDelete(field);
        },
    };

    // À cause de problèmes de typages,
    // l'assignation des paramètres de FlexyDateRange
    // doit se faire dans un objet en amont.
    let dateFactureRangeProps: FlexyDateRangeProps = {
        type: 'text',
        size: 'small',
        variant: 'outlined',
        placeholder: 'Sélectionnez une plage de dates...',
        inputlabel: 'Par date de facturation',
        name: 'dashboard-datefacture-filter',
        key: 'dashboard-datefacture-filter',
        reset: dateFactureReset,
        onChange: (dateRange: DateRange) => onUpdateDateRange('dateFacture', dateRange),
    } as FlexyDateRangeProps;

    let createdAtRangeProps: FlexyDateRangeProps = {
        type: 'text',
        size: 'small',
        variant: 'outlined',
        placeholder: 'Sélectionnez une plage de dates...',
        inputlabel: 'Par date de création',
        name: 'dashboard-createdat-filter',
        key: 'dashboard-createdat-filter',
        reset: createdAtReset,
        onChange: (dateRange: DateRange) => onUpdateDateRange('createdAt', dateRange),
    } as FlexyDateRangeProps;

    return (
        <>
            <Card
                sx={{
                    overflow: 'visible',
                    width: '100%',
                }}
            >
                <CardContent>
                    <Typography variant="h5" fontWeight="bold">
                        Filtres
                        <Button
                            variant="outlined"
                            onClick={() => {
                                dispatch({
                                    type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
                                });
                                statsFiltersDispatch({ type: actions.RESET });
                                onResetActive('all');
                                if (filtersOpts.activeFilters) {
                                    Object.keys(filtersOpts.activeFilters).forEach((filter) => {
                                        onFilterDelete(filter);
                                    });
                                }
                            }}
                            sx={{
                                marginLeft: '10px',
                            }}
                        >
                            Reset
                        </Button>
                    </Typography>
                    <Grid container rowSpacing={0} columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <FlexyInput
                                type="select"
                                inputlabel="Categories"
                                name="dashboard-categories-filter"
                                key="dashboard-categories-filter"
                                options={categoriesOptions}
                                value={(
                                    (categoriesDataSource.filters['categories'] ||
                                        []) as Array<string>
                                ).map((code: string) =>
                                    categoriesOptions.find(
                                        ({ value }: { value: string }) => value === code,
                                    ),
                                )} // mapper la valeur comme pour les options voir si il faut le mettre en default value
                                isMulti
                                onChange={(values: Array<{ value: string }>) => {
                                    if (values.length === 0) {
                                        onFilterDelete('categories');
                                    } else {
                                        dispatch({
                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                            payload: {
                                                key: 'categories',
                                                value: values.map(
                                                    ({ value }: { value: string }) => value,
                                                ),
                                            },
                                        });
                                        dispatch(getCategorieStats({}));
                                    }
                                }}
                                getOptionValue={({ value }: { value: string }) => value}
                                sx={{
                                    zIndex: 10,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <FlexyInput
                                type="select"
                                inputlabel="Codes rejets"
                                name="dashboard-codesrejets-filter"
                                key="dashboard-codesrejets-filter"
                                options={codesRejetsOptions}
                                value={(
                                    (categoriesDataSource.filters['codesRejets'] ||
                                        []) as Array<string>
                                ).map((code: string) =>
                                    codesRejetsOptions.find(
                                        ({ value }: { value: string }) => value === code,
                                    ),
                                )} // mapper la valeur comme pour les options voir si il faut le mettre en default value
                                isMulti
                                onChange={(values: Array<{ value: string }>) => {
                                    if (values.length === 0) {
                                        onFilterDelete('codesRejets');
                                    } else {
                                        dispatch({
                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                            payload: {
                                                key: 'codesRejets',
                                                value: values.map(
                                                    ({ value }: { value: string }) => value,
                                                ),
                                            },
                                        });
                                        dispatch(getCategorieStats({}));
                                    }
                                }}
                                getOptionValue={({ value }: { value: string }) => value}
                                sx={{
                                    zIndex: 10,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <AutocompleteStore
                                dataSource={fournisseursDataSource}
                                fetchData={fournisseurDataSourcesThunks.main.getData}
                                renderField={'libelle'}
                                idProperty={'id'}
                                inputlabel="Fournisseur"
                                inputProps={{
                                    variant: 'outlined',
                                    size: 'small',
                                }}
                                getOptionLabel={(item: Fournisseur) =>
                                    `${item.libelle} - ${item.siren}` || ''
                                }
                                simpleRenderOption={(item: Fournisseur) =>
                                    `${item.siren} (${item.libelle})`
                                }
                                reset={fournisseurReset}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box>
                                <FlexyFormLabel>
                                    <Typography component="span">
                                        {dateFactureRangeProps.inputlabel}
                                    </Typography>
                                </FlexyFormLabel>
                                <FlexyDateRange {...dateFactureRangeProps} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box>
                                <FlexyFormLabel>
                                    <Typography component="span">
                                        {createdAtRangeProps.inputlabel}
                                    </Typography>
                                </FlexyFormLabel>
                                <FlexyDateRange {...createdAtRangeProps} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box
                                sx={{
                                    width: 'fit-content',
                                }}
                            >
                                <FlexyInput
                                    type="boolean"
                                    inputlabel="Afficher les éléments vides"
                                    name="dashboard-afficherzero-filter"
                                    key="dashboard-afficherzero-filter"
                                    value={statsFiltersState.afficherZeros}
                                    onChange={() => {
                                        statsFiltersDispatch({
                                            type: actions.SWITCH_AFFICHER_ZEROS,
                                        });
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <FlexyFiltersList key="filters-list-categories" filtersOpts={filtersOpts} />
                </CardContent>
            </Card>
            <FlexyTabs
                status={undefined}
                sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
                activeTab={activeTab}
                handleActiveTab={handleActiveTab}
                tabs={[
                    {
                        tabTitle: `Par catégories (${
                            categoriesDataSource.status === 'loading' ? '...' : categoriesCount
                        })`,
                        tabPanel:
                            categoriesDataSource.status === 'loading' ? (
                                <CenterCircularProgress
                                    sx={{ height: '300px' }}
                                    data-testid="StatusLoading"
                                />
                            ) : categoriesCount === 0 ? (
                                <RienAAfficher />
                            ) : (
                                <CategoriesPanel
                                    cardsProps={catProps}
                                    afficherZeros={statsFiltersState.afficherZeros}
                                    handleActiveTab={handleActiveTab}
                                />
                            ),
                    },
                    {
                        tabTitle: `Par codes rejets (${
                            codesRejetsDataSource.status === 'loading' ? '...' : codesRejetsCount
                        })`,
                        tabPanel:
                            codesRejetsDataSource.status === 'loading' ? (
                                <CenterCircularProgress
                                    sx={{ height: '300px' }}
                                    data-testid="StatusLoading"
                                />
                            ) : codesRejetsCount === 0 ? (
                                <RienAAfficher />
                            ) : (
                                <CodesRejetPanel
                                    cardsProps={catProps}
                                    afficherZeros={statsFiltersState.afficherZeros}
                                    handleActiveTab={handleActiveTab}
                                />
                            ),
                    },
                    {
                        tabTitle: `Détails (${
                            facturesAchatsDataSource.status === 'loading'
                                ? '...'
                                : facturesEnRejetCount
                        })`,
                        tabPanel: (
                            <FactureAchatList
                                filtersControl={false}
                                dataSource={facturesAchatsDataSource}
                                fetchData={getFactureAchatStats}
                            />
                        ),
                    },
                ]}
            />
        </>
    );
};

export default StatsTabs;
