import * as React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { PageContainer } from '../PageContainer';

export type ErrorPageType = {
    statusCode: number;
    message: string;
    children?: React.ReactNode;
};

export const ErrorPage = function ({ statusCode, message, children }: ErrorPageType) {
    return (
        <PageContainer title="Error" description="this is Error page">
            <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                textAlign="center"
                justifyContent="center"
                sx={{ backgroundColor: '#e4f5ff' }}
                data-testid="test-id-ErrorPage"
            >
                <Container maxWidth="md">
                    <Typography
                        align="center"
                        variant="h1"
                        sx={{
                            pt: 2,
                            color: (theme) =>
                                `${
                                    theme.palette.mode === 'dark'
                                        ? 'rgba(0, 0, 0, 0.87)'
                                        : 'rgba(0, 0, 0, 0.87)'
                                }`,
                        }}
                    >
                        {statusCode}
                    </Typography>
                    <Typography
                        align="center"
                        variant="h4"
                        sx={{
                            pt: 1,
                            pb: 3,
                            color: (theme) =>
                                `${
                                    theme.palette.mode === 'dark'
                                        ? 'rgba(0, 0, 0, 0.87)'
                                        : 'rgba(0, 0, 0, 0.87)'
                                }`,
                        }}
                    >
                        {message}
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/"
                        disableElevation
                    >
                        Retours à l&apos;accueil
                    </Button>
                    {children}
                </Container>
            </Box>
        </PageContainer>
    );
};

export default ErrorPage;
