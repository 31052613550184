import * as React from 'react';
import { ActionsContext } from '../router/router';
import { ContextActionType } from '@europrocurement/flexy-components/components/organisms/ContextActionsMenu/ContextActionsMenu';

const useContextActions = (componentSpecificActions: ContextActionType[]) => {
    const { defineContextActions, resetContextActions } = React.useContext(ActionsContext);

    React.useEffect(() => {
        // On mount define component specific context actions
        defineContextActions(componentSpecificActions);
        // On component unmount, reset context actions to default
        return () => resetContextActions();
    }, []);
};

export default useContextActions;
