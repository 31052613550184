import React from 'react';

import { AxiosResponse, AxiosPromise } from 'axios';

/*  This hook handle collection of Api request :
 * @return
 * - Request status feedback
 * - Result collection
 */

const initalState = {
    isFetching: false,
    isLoaded: false,
    hasErrors: false,
    promiseNames: [],
    fetchedData: undefined,
};

const usePromises = () => {
    // AxiosPromise |
    const [isFetching, setIsFetching] = React.useState<boolean>(initalState.isFetching);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(initalState.isLoaded);
    const [hasErrors, setHasErrors] = React.useState<boolean>(initalState.hasErrors);
    const [promiseNames, setPromiseNames] = React.useState<string[]>(initalState.promiseNames);
    const [fetchedData, setFetchedData] = React.useState<any>(initalState.fetchedData);
    const [results, setResults] = React.useState<PromiseSettledResult<AxiosResponse<any, any>>[]>();

    const call = async (inputs: Array<() => AxiosPromise>, withToaster: boolean = false) => {
        setIsFetching(true);

        const promises = inputs.map((promise) => promise());
        setPromiseNames(inputs.map(({ name }) => name));

        await Promise.allSettled(promises).then((results: any) => {
            results.forEach((result: any) => {
                const { status } = result;

                if (status === 'rejected') {
                    setHasErrors(true);
                }
            });

            setResults(results);
        });

        setIsFetching(false);
        setIsLoaded(true);
    };

    const formatDataFromResponse = (results: any) => {
        let fetchedData = {};

        results.forEach((result: any, index: number) => {
            const { value } = result;

            const data = value?.data;
            if (data) {
                fetchedData = {
                    ...fetchedData,
                    [promiseNames[index]]: data['hydra:member'],
                };
            }
        });

        setFetchedData(fetchedData);
    };

    React.useEffect(() => {
        if (isLoaded && results) {
            formatDataFromResponse(results);
        }
    }, [isLoaded, results]);

    const reset = () => {
        setResults(undefined);
        setFetchedData(undefined);
    };

    return { isFetching, isLoaded, hasErrors, results, fetchedData, call, reset };
};

export default usePromises;
