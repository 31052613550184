import * as React from 'react';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import { AddressCard } from '@europrocurement/flexy-components';
import AddressForm from '../../../forms/AddressForm';
import ActionModal from '../forms/ActionModal';
import { prescripteursApi } from '../../../../../redux/RootStore';
import { AdresseJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

type PrescriberAddressesProps = {
    prescriber: Prescripteur;
    callModal: (modalContent: React.ReactElement) => void;
    closeModal: () => void;
    afterSubmit: () => void;
};

const PrescriberAddresses: React.FC<PrescriberAddressesProps> = (props) => {
    const { prescriber, callModal, closeModal, afterSubmit } = props;

    const deleteAddress = async (addressId: number) => {
        const prescriberAddresses = prescriber.adresses.filter(
            (prescriberAddress) => prescriberAddress.id !== addressId,
        );
        if (prescriber && prescriber.id) {
            await prescripteursApi.updateAdressesPrescripteurTiersItem(prescriber.id.toString(), {
                adresses: prescriberAddresses,
            });
            afterSubmit();
        }
    };

    const handleDeleteAddress = (address: any) => {
        //Todo type params
        if (prescriber.id) {
            callModal(
                <ActionModal
                    onClose={closeModal}
                    asyncAction={() => deleteAddress(address.id)}
                    title={"Suppression d'une adresse"}
                    actionText={'Voulez-vous supprimer cette adresse ?'}
                    actionLabel={'Confirmer suppression'}
                />,
            );
        }
    };

    const handleEditAddress = (address: any) => {
        //Todo type params
        if (prescriber.id) {
            callModal(
                <AddressForm entity={address} prescriber={prescriber} afterSubmit={afterSubmit} />,
            );
        }
    };

    //Compare function to determine default order of address
    const defaultAddressSort = (
        elementA: AdresseJsonldTiersRead,
        elementB: AdresseJsonldTiersRead,
    ) => {
        const aDefault = elementA.defaut;
        const bDefault = elementB.defaut;
        const aBilling = elementA.facturation;

        if (aDefault) {
            return -1;
        }

        if (aBilling && !bDefault) {
            return -1;
        }

        return 1;
    };

    const sortedAddresses = [...prescriber.adresses].sort(defaultAddressSort);
    const hasAddress = sortedAddresses.length > 0;

    return (
        <Grid container>
            {hasAddress ? (
                <>
                    {sortedAddresses.map((address, _, array) => {
                        return (
                            <Grid
                                key={`address_card_${address.id}`}
                                item
                                xs={12}
                                md={6}
                                lg={array.length > 2 ? 4 : 6}
                            >
                                <AddressCard
                                    address={address}
                                    onDelete={() => handleDeleteAddress(address)}
                                    onEdit={() => handleEditAddress(address)}
                                    isDeletable={
                                        address.defaut === false && address.facturation === false
                                    }
                                />
                            </Grid>
                        );
                    })}
                </>
            ) : (
                <p>Aucune adresse</p>
            )}
        </Grid>
    );
};

export default PrescriberAddresses;
