import React, { type FunctionComponent } from 'react';
import { ActiveFilterBadge } from '@europrocurement/flexy-components';
import { Typography } from '@mui/material';
import { FiltersOpts } from '../DatatableHeader';

export type FlexyFiltersListProps = {
    filtersOpts?: FiltersOpts;
};

// Formate un interval de dates
const dateRangeToString = (dateRange: string[]): string => {
    const beforeDate = new Date(dateRange[0]).toLocaleDateString('FR-fr');
    const afterDate = new Date(dateRange[1]).toLocaleDateString('FR-fr');
    return beforeDate === afterDate ? afterDate : `${afterDate} au ${beforeDate}`;
};

const FlexyFiltersList: FunctionComponent<FlexyFiltersListProps> = function ({
    filtersOpts,
}: FlexyFiltersListProps) {
    const { activeFilters } = filtersOpts as FiltersOpts;

    let filtersList = null;

    if (activeFilters) {
        type FieldAndValueFilter = {
            label: string;
            field: string;
            value: string;
        };

        const arrayOfKeys: Array<string> = Object.getOwnPropertyNames(activeFilters).filter(
            (filterName) => filtersOpts?.filters.some((item) => item.field === filterName),
        );

        const arrayOfObject: FieldAndValueFilter[] = arrayOfKeys.map((key) => ({
            label:
                filtersOpts?.filters
                    .find((filter) => filter.field === key)
                    ?.label.replace(/^\w/, (c) => c.toUpperCase()) || '',
            field: key,
            value: String(
                activeFilters[key] === true
                    ? 'Oui'
                    : activeFilters[key] === false
                    ? 'Non'
                    : key === 'dateFacture' || key === 'createdAt'
                    ? dateRangeToString(activeFilters[key] as string[])
                    : activeFilters[key],
            ),
        }));

        filtersList = (
            <>
                {arrayOfObject.length > 0 ? (
                    <Typography padding={2} component="div">
                        Filtres actifs :
                        {arrayOfObject.map(({ field, value, label }: FieldAndValueFilter) => (
                            <ActiveFilterBadge
                                sx={{
                                    marginLeft: '10px',
                                }}
                                key={field + value}
                                field={label}
                                value={value}
                                onDelete={() => filtersOpts?.onFilterDelete(field)}
                            />
                        ))}
                    </Typography>
                ) : null}
            </>
        );
    }
    return activeFilters ? filtersList : null;
};

FlexyFiltersList.defaultProps = {
    filtersOpts: undefined,
};

export default FlexyFiltersList;
