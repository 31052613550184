import React, { useEffect, useCallback, useRef } from 'react';
import { Box, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
// eslint-disable-next-line import/no-cycle
import { CenterCircularProgress } from '../../organisms';
import { TopbarHeight, breadcrumbHeight, footerHeight } from '../../../assets';
import TabPanel from '../TabPanel';
import Scrollbar from '../Scrollbar';

export type TabStructure = {
    tabTitle: string;
    tabPanel: React.ReactNode;
    disabled?: boolean;
};

export type FlexyTabsProps = {
    status?: EuroprocApiResponseStatus;
    tabs: Array<TabStructure>;
    orientation?: 'vertical' | 'horizontal';
    activeTab?: number; // Communique le changement de tab
    handleActiveTab?: (newTab: number) => void; // Communique le changement de tab
    sx?: SxProps;
};

const FlexyTabs: React.FunctionComponent<FlexyTabsProps> = function ({
    status,
    tabs,
    orientation = 'horizontal',
    activeTab,
    sx,
    handleActiveTab,
}) {
    const [value, setValue] = React.useState(0);

    // Si 'handleActiveTab' et 'activeTab' existes et 'activeTab' différent
    // de la nouvelle valeur, alors 'handleActiveTab' est utilisé.
    // Dans tous les cas, 'value' change.
    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            if (handleActiveTab && activeTab && activeTab !== newValue) {
                handleActiveTab(newValue);
            }
            setValue(newValue);
        },
        [activeTab, handleActiveTab],
    );

    // Met à jour 'value' quand 'activeTab' existe et a changé.
    useEffect(() => {
        if (activeTab && activeTab !== value) {
            setValue(activeTab);
        }
    }, [activeTab, value]);

    const renderTabPanel = () => {
        switch (status) {
            case undefined:
            case 'succeeded':
                return tabs.map((tab, index) => (
                    <TabPanel key={tab.tabTitle} value={value} index={index}>
                        {tab.tabPanel}
                    </TabPanel>
                ));
            case 'idle':
            case 'loading':
                return <CenterCircularProgress data-testid="StatusLoading" />;
            default:
                return <Typography>Une erreur lors du chargement est survenue</Typography>;
        }
    };

    const renderTabs = () =>
        tabs.map((tab) => (
            <Tab
                disabled={tab.disabled ?? false}
                label={
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ opacity: tab.disabled ? '0.4' : '1' }}
                    >
                        {tab.tabTitle}
                    </Typography>
                }
                key={tab.tabTitle}
            />
        ));

    const tabRef = useRef<HTMLButtonElement>(null);
    const heightRef = tabRef?.current?.offsetHeight || 0;
    const gap = 15;

    return (
        <Box
            sx={
                orientation === 'vertical'
                    ? {
                          flexGrow: 1,
                          display: 'flex',
                          ...sx,
                      }
                    : { ...sx }
            }
        >
            <Tabs
                ref={tabRef}
                value={value}
                textColor="secondary"
                indicatorColor="secondary"
                onChange={handleChange}
                orientation={orientation}
                sx={
                    orientation === 'vertical'
                        ? { borderRight: 1, borderColor: 'divider' }
                        : { borderBottom: 1, borderColor: 'divider' }
                }
            >
                {renderTabs()}
            </Tabs>

            <Box
                sx={
                    orientation === 'vertical'
                        ? {
                              padding: '30px 40px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: `${gap}px`,
                          }
                        : {
                              height: `calc(100vh - ${TopbarHeight}px - ${gap}px - ${heightRef}px - ${footerHeight}px - ${breadcrumbHeight}px ) `,
                          }
                }
            >
                <Scrollbar>{renderTabPanel()}</Scrollbar>
            </Box>
        </Box>
    );
};

export default FlexyTabs;
