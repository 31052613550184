import type {
    CustomizerReducerActiveModeType,
    CustomizerReducerType,
} from '@europrocurement/l2d-domain';
import type { Theme } from '@mui/system';

import _ from 'lodash';
import {
    BLUE_THEME,
    GREEN_THEME,
    RED_THEME,
    BLACK_THEME,
    PURPLE_THEME,
    INDIGO_THEME,
    ORANGE_THEME,
    L2D_THEME,
    THEME_LIST,
} from '@europrocurement/l2d-domain';

import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { FlexyLayoutState } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { useSelector } from 'react-redux';
import typography from './Typography';
import components from './Override';
import shadows from './Shadows';
import { customizerSelector } from '../../redux/storeConfig/selectors';
import { useAppSelector } from '../../redux/storeConfig/hooks';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        default: string;
        dark: string;
        paper: string;
    }

    interface TypeText {
        light: string;
    }

    interface PaletteOptions {
        danger?: PaletteColor;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        danger: true;
    }
}

// ##############################
// Global Variables
// ##############################
export type ColorsPaletteVariant =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'error'
    | 'danger'
    | 'warning';

export type NamedTheme = ThemeOptions & { name: THEME_LIST };

const SidebarWidth = 265;
const TopbarHeight = 70;
const paddingContent = 30;
const footerHeight = 40;
const breadcrumbHeight = 70;

const baseTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#1a97f5',
            light: '#e6f4ff',
            dark: '#1682d4',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#1e4db7',
            light: '#ddebff',
            dark: '#173f98',
            contrastText: '#ffffff',
        },
        success: {
            main: '#00c292',
            light: '#ebfaf2',
            dark: '#00964b',
            contrastText: '#ffffff',
        },
        info: {
            main: '#0bb2fb',
            light: '#a7e3f4',
        },
        error: {
            main: '#e46a76',
            light: '#fdf3f5',
            dark: '#e45a68',
        },
        danger: {
            main: '#FF8552',
            contrastText: '#ffffff',
            light: '#ffb680',
            dark: '#c75626',
        },
        warning: {
            main: '#fec90f',
            light: '#fff4e5',
            dark: '#dcb014',
            contrastText: '#ffffff',
        },
        grey: {
            A100: '#ecf0f2',
            A200: '#99abb4',
            A400: '#767e89',
            A700: '#e6f4ff',
        },
        action: {
            disabledBackground: 'rgba(73,82,88,0.12)',
            hoverOpacity: 0.02,
            hover: 'rgba(0, 0, 0, 0.03)',
        },
    },
    shape: {
        borderRadius: 5,
    },
    mixins: {
        toolbar: {
            color: '#949db2',
            '@media(min-width:1280px)': {
                minHeight: TopbarHeight,
                padding: '0 30px',
            },
            '@media(max-width:1280px)': {
                minHeight: '64px',
            },
        },
    },
    components,
    typography,
    shadows,
};

const themesOptions: NamedTheme[] = [
    {
        ...baseTheme,
        name: L2D_THEME,
        palette: {
            primary: {
                main: '#0CBFF2',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#011B58',
                contrastText: '#ffffff',
            },
            success: {
                main: '#62C370',
                contrastText: '#ffffff',
            },
            info: {
                main: '#a7e3f4',
                contrastText: '#ffffff',
            },
            error: {
                main: '#F43939',
                contrastText: '#ffffff',
            },
            danger: {
                main: '#FF8552',
                contrastText: '#ffffff',
                light: '#ffb680',
                dark: '#c75626',
            },
            warning: {
                main: '#F1D302',
                contrastText: '#ffffff',
            },
        },
    },
    {
        ...baseTheme,
        name: BLUE_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#1a97f5',
                light: '#e6f4ff',
                dark: '#1682d4',
            },
            secondary: {
                main: '#1e4db7',
                light: '#ddebff',
                dark: '#173f98',
            },
        },
    },
    {
        ...baseTheme,
        name: GREEN_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#00cec3',
                light: '#d7f8f6',
                dark: '#02b3a9',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#066a73',
            },
        },
    },
    {
        ...baseTheme,
        name: PURPLE_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#7352ff',
                light: '#e5e0fa',
                dark: '#5739d6',
            },
            secondary: {
                main: '#402e8d',
            },
        },
    },
    {
        ...baseTheme,
        name: INDIGO_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#1e4db7',
                light: '#e6f4ff',
                dark: '#0c399e',
            },
            secondary: {
                main: '#11397b',
            },
        },
    },
    {
        ...baseTheme,
        name: ORANGE_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#03c9d7',
                light: '#e5fafb',
                dark: '#05b2bd',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#fb9678',
                light: '#fcf1ed',
                dark: '#e67e5f',
                contrastText: '#ffffff',
            },
        },
    },
    {
        ...baseTheme,
        name: RED_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#ff5c8e',
                light: '#fce6ed',
                dark: '#d43653',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#5e244d',
            },
        },
    },
    {
        ...baseTheme,
        name: BLACK_THEME,
        palette: {
            ...baseTheme.palette,
            primary: {
                main: '#1c2025',
            },
        },
    },
];

const themesDarkOveride: NamedTheme[] = [
    {
        ...baseTheme,
        name: L2D_THEME,
        palette: {
            primary: {
                main: '#011B58',
                contrastText: '#e6e5e8',
            },
            secondary: {
                main: '#0CBFF2',
                contrastText: '#e6e5e8',
            },
        },
    },
];

export const BuildTheme = (
    config: {
        theme: THEME_LIST;
    },
    override?: Partial<ThemeOptions>,
): Theme => {
    let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

    const activeMode: CustomizerReducerActiveModeType = useSelector(
        (state: FlexyLayoutState) => state.customizer.activeMode,
    );

    const baseMode = {
        palette: {
            mode: activeMode,
            background: {
                default: activeMode === 'dark' ? '#20232a' : '#fafbfb',
                dark: activeMode === 'dark' ? '#1c2025' : '#ffffff',
                paper: activeMode === 'dark' ? '#282C34' : '#ffffff',
            },
            text: {
                primary: activeMode === 'dark' ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
                secondary: activeMode === 'dark' ? '#adb0bb' : '#777e89',
                light: 'rgba(0, 0, 0, 0.25)',
            },
        },
    };
    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        [themeOptions] = themesOptions;
    }
    let themeDarkOveride = {};
    if (activeMode === 'dark') {
        themeDarkOveride = themesDarkOveride.find((theme) => theme.name === config.theme) || {};
    }

    const theme = createTheme(
        _.merge({}, baseTheme, baseMode, themeOptions, themeDarkOveride, override, {}),
    );
    return theme;
};

export {
    TopbarHeight,
    SidebarWidth,
    paddingContent,
    footerHeight,
    breadcrumbHeight,
    baseTheme,
    themesOptions,
};
