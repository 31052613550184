import {
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
    Divider,
    Grid,
    IconButton,
    Paper,
    PaperProps,
} from '@mui/material';
import { Close as CloseIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';

import * as React from 'react';
import { Box, SxProps } from '@mui/system';
import Draggable from 'react-draggable';

export type ModalizerProps = DialogProps & {
    open: DialogProps['open'];
    onClose: () => void;
    children: DialogProps['children'];
    modalTitle?: DialogTitleProps['children'];
    modalActions?: DialogActionsProps['children'];
    gridSx?: SxProps;
    isDraggable?: boolean;
};

const PaperComponent = function (props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
};

const Modalizer: React.FunctionComponent<ModalizerProps> = function ({
    open,
    onClose,
    modalActions,
    modalTitle,
    children,
    gridSx = {},
    isDraggable = false,
    ...props
}: ModalizerProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            sx={{ margin: 'auto' }}
            {...props}
        >
            {isDraggable ? (
                <Box
                    id="draggable-dialog-title"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        backgroundColor: 'rgba(0, 24, 56, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'move',
                    }}
                >
                    <MoreHorizIcon
                        sx={{
                            fontSize: '15px',
                            color: 'rgba(0, 24, 56, 0.5)',
                        }}
                    />
                </Box>
            ) : null}

            {onClose && (
                <Box sx={{ position: 'absolute', right: '0' }}>
                    <IconButton
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        sx={{ margin: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}

            <Grid sx={{ padding: '20px 50px', ...gridSx }}>
                {modalTitle ? (
                    <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {' '}
                        {modalTitle}{' '}
                    </DialogTitle>
                ) : null}
                {children}
                {modalActions && <DialogActions> {modalActions} </DialogActions>}
            </Grid>
        </Dialog>
    );
};

Modalizer.defaultProps = {
    modalActions: undefined,
    modalTitle: undefined,
};

export default Modalizer;
