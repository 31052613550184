import { ConfigurationParameters } from '../../openApi/ApiDossiers';
import { createCommandeSlice } from './slices/commandeSlice';
import { createDossierSlice } from './slices/dossierSlice';
import { createFactureVenteSlice } from './slices/factureVenteSlice';
import { createJournalSlice } from './slices/journalSlice';
import { createRubriqueFacturationSlice } from './slices/rubriqueFactSlice';

export const DOSSIERS_REDUCER_NAME = 'dossiers';

export function createCommandeReducer(configuration: ConfigurationParameters) {
    const { commandeSlice, commandeDataSourcesThunks } = createCommandeSlice(configuration);
    return { commandeSlice, commandeDataSourcesThunks };
}

export function createDossierReducer(configuration: ConfigurationParameters) {
    const { dossierSlice, dossierDataSourcesThunks } = createDossierSlice(configuration);
    return { dossierSlice, dossierDataSourcesThunks };
}

export function createFactureVenteReducer(configuration: ConfigurationParameters) {
    const { factureVenteSlice, factureVenteDataSourcesThunks } =
        createFactureVenteSlice(configuration);
    return { factureVenteSlice, factureVenteDataSourcesThunks };
}

export function createJournalReducer(configuration: ConfigurationParameters) {
    const { journalSlice, journalDataSourcesThunks } = createJournalSlice(configuration);
    return { journalSlice, journalDataSourcesThunks };
}

export function createRubriqueFacturationReducer(configuration: ConfigurationParameters) {
    const { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks } =
        createRubriqueFacturationSlice(configuration);
    return { rubriqueFacturationSlice, rubriqueFacturationDataSourcesThunks };
}
