import {
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
    MEDIAOBJECT_SLICE_NAME,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import { TblFligneFactureVenteRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { FACTURE_VENTE_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import type { Reducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
    arrondi,
    getTTC_HT_TxTVA,
    getTVA_HT_TxTVA,
    pricelineType,
} from '../pages/Achats/components/forms/functions/calculsProduits';
import { produitVenteToLigneProduit } from '../pages/Achats/components/forms/functions/dataTransformers';
import { HeaderSwitch } from '@europrocurement/flexy-components';

export const ACTIONS = {
    RESET_STATE: 'factureform.resetState',
    FULL_RESET: 'factureform.fullReset',
    RESET: 'factureform.reset',
    SET_DEF_DOMAINE: 'factureform.setDefDomaine',
    SET_NUM_DOSSIER: 'factureform.setNumeroDossier',
    SET_NUM_PRESTA: 'factureform.setNumeroPresta',
    SET_LAST_TOUCHED: 'factureform.setLastTouched',
    SET_SIREN: 'factureform.setSiren',
    REPLACE_PRODUITS_ACHAT: 'factureform.replaceProduitsAchat',
    INIT_PRODUITS_ACHAT: 'factureform.initProduitsAchat',
    ADD_PRODUIT_ACHAT: 'factureform.addProduitAchat',
    INIT_PRODUITS_VENTE: 'factureform.initProduitsVente',
    UPDATE_PRODUITS_VENTE: 'factureform.updateProduitsVente',
    ADD_PRODUIT_VENTE: 'factureform.addProduitVente',
    AFFICHER_LIGNE_VENTE: 'factureform.afficherLigneVente',
    ADD_ROW_TOTAUX: 'factureform.addRowTotaux',
    DELETE_ROW_TOTAUX: 'factureform.deleteRowTotaux',
    ORDER_RUB_FACTS: 'factureform.orderRubFacts',
    FILTER_RUB_FACTS: 'factureform.filterRubFacts',
    ADD_RUB_FACTS: 'factureform.addRubFacts',
    ADD_RUB_FACT: 'factureform.addRubFact',
    SET_RUB_FACT: 'factureform.setRubFact',
    SET_SWITCH_LIGNE_VENTE: 'factureform.setSwitchLigneVente',
};

type ligneProduit = {
    idLigneVente?: number;
    rubriqueFacturation?: {
        id: number;
        code?: string;
    };
    libelle?: string;
    ht?: number;
    tva?: number;
    txtva?: number;
    ttc?: number;
};

type tableauProduits = Array<Partial<ligneProduit>>;

type totaux = {
    ht: number;
    tva: number;
    txtva: number;
    ttc: number;
};

export type FactureFormReducerType = {
    defaultNumeroPresta: string;
    defaultNumeroDossier: string;
    defaultSiren: string;
    produitsVente: tableauProduits;
    afficherLigneVente: boolean;
    lastTouched: [pricelineType | undefined, pricelineType | undefined];
    // produits: tableauProduits;
    totaux: totaux;
    rubFacts: RubriqueFacturation[];
    defDomaine: number;
    switchLigneVente: HeaderSwitch;
};

export const INIT_STATE: FactureFormReducerType = {
    defaultNumeroPresta: '',
    defaultNumeroDossier: '',
    defaultSiren: '',
    produitsVente: [],
    afficherLigneVente: true,
    lastTouched: [undefined, undefined],
    // produits: [
    //     {
    //         ht: 0,
    //         tva: 0,
    //         txtva: 0,
    //         ttc: 0,
    //     },
    // ],
    totaux: {
        ht: 0,
        tva: 0,
        txtva: 0,
        ttc: 0,
    },
    rubFacts: [],
    defDomaine: 1,
    switchLigneVente: {
        label: '',
        sx: {
            display: 'none',
        },
    },
};

export const FactureFormReducer: Reducer<FactureFormReducerType> = (state = INIT_STATE, action) => {
    let newState: FactureFormReducerType = { ...state };

    const orderRubFacts = (rubFacts: RubriqueFacturationApiObject[]) => {
        const orderedRubFacts = [
            ...rubFacts.filter((rubFact) => rubFact.code !== 'RSF'),
            ...rubFacts.filter((rubFact) => rubFact.code === 'RSF'),
        ];
        return orderedRubFacts;
    };

    switch (action.type) {
        case ACTIONS.RESET_STATE:
            const { rubFacts } = state;
            newState = { ...INIT_STATE, rubFacts };
            break;

        // clean les selected et le form
        case ACTIONS.FULL_RESET:
            // clean facture achat selected
            action.asyncDispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });

            // clean media object  achat selected
            action.asyncDispatch({
                type: `${MEDIAOBJECT_SLICE_NAME}/deletemainSelected`,
            });

            // clean media object  achat selected
            action.asyncDispatch({
                type: ACTIONS.RESET,
            });
            break;
        // le form
        case ACTIONS.RESET:
            // clean media object  achat selected
            action.asyncDispatch({
                type: ACTIONS.RESET_STATE,
            });

            // clean search dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clean du dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/delete${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });

            // clean serach fournisseur selected
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clean du fournisseur selected
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Selected`,
            });

            // clean serach fournisseur
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clear data fournisseur
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/clear${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Data`,
            });

            // clear data dossiers
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/clear${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Data`,
            });

            // clear data achat
            action.asyncDispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/clearmainData`,
            });

            // clear selected achat
            action.asyncDispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });

            break;
        case ACTIONS.SET_DEF_DOMAINE:
            // param = factureVente?.idDdm: number

            newState = {
                ...state,
                defDomaine: action.payload || 0,
            };
            break;
        case ACTIONS.SET_LAST_TOUCHED:
            // param = type: pricelineType

            newState = {
                ...state,
                lastTouched: [state.lastTouched[1], action.payload],
            };
            break;
        case ACTIONS.SET_NUM_DOSSIER:
            // param = dossier.numero: number | null | undefined
            if (state.defaultNumeroDossier === action.payload) {
                break;
            }

            newState = {
                ...state,
                defaultNumeroDossier: `${action.payload}`,
            };
            break;
        case ACTIONS.SET_NUM_PRESTA:
            // param = dossier.prestations[0].numero: string | undefined
            if (state.defaultNumeroPresta === action.payload) {
                break;
            }
            newState = {
                ...state,
                defaultNumeroPresta: action.payload || '',
            };
            break;
        case ACTIONS.SET_SIREN:
            if (state.defaultSiren === action.payload) {
                break;
            }

            newState = {
                ...state,
                defaultSiren: action.payload,
            };
            break;
        case ACTIONS.REPLACE_PRODUITS_ACHAT:
            // param = produits: tableauProduits

            newState = {
                ...state,
                // produits: action.payload,
            };
            break;
        case ACTIONS.INIT_PRODUITS_ACHAT:
            // no param

            newState = {
                ...state,
                // produits: [
                //     {
                //         ht: 0,
                //         tva: 0,
                //         txtva: 0,
                //         ttc: 0,
                //     },
                // ],
            };
            break;
        case ACTIONS.ADD_PRODUIT_ACHAT:
            // param = produit: Partial<ligneProduit>

            newState = {
                ...state,
                // produits: [...state.produits, action.payload],
            };
            break;
        case ACTIONS.INIT_PRODUITS_VENTE:
            // no param

            newState = {
                ...state,
                produitsVente: [],
            };
            break;
        case ACTIONS.ADD_PRODUIT_VENTE:
            // param = produitVente: TblFligneFactureVenteRead

            newState = {
                ...state,
                produitsVente: [...state.produitsVente, action.payload],
            };
            break;
        case ACTIONS.UPDATE_PRODUITS_VENTE:
            // param = produitsVenteToDisplay (produitsVente): produitVente[]

            const updatedProduitsVente = action.payload.map((item: RubriqueFacturation) => {
                return produitVenteToLigneProduit(item, state.rubFacts);
            });

            newState = {
                ...state,
                produitsVente: updatedProduitsVente,
            };
            break;
        case ACTIONS.ADD_ROW_TOTAUX:
            //param = produit: Partial<ligneProduit>
            let biggerTotal = state.totaux;
            const produitToAdd: Partial<ligneProduit> = action.payload;

            biggerTotal.ht += produitToAdd.ht ? produitToAdd.ht : 0;
            biggerTotal.tva += produitToAdd.tva ? produitToAdd.tva : 0;
            biggerTotal.txtva += produitToAdd.txtva ? produitToAdd.txtva : 0;
            biggerTotal.ttc += produitToAdd.ttc ? produitToAdd.ttc : 0;

            newState = {
                ...state,
                totaux: biggerTotal,
            };
            break;
        case ACTIONS.DELETE_ROW_TOTAUX:
            //param = produit: Partial<ligneProduit>
            let lowerTotal = state.totaux;
            const produitToDelete: Partial<ligneProduit> = action.payload;

            lowerTotal.ht -= produitToDelete.ht ? produitToDelete.ht : 0;
            lowerTotal.tva -= produitToDelete.tva ? produitToDelete.tva : 0;
            lowerTotal.txtva -= produitToDelete.txtva ? produitToDelete.txtva : 0;
            lowerTotal.ttc -= produitToDelete.ttc ? produitToDelete.ttc : 0;

            newState = {
                ...state,
                totaux: lowerTotal,
            };
            break;
        case ACTIONS.ORDER_RUB_FACTS:
            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(state.rubFacts).map((m) => [m.id, m])).values(),
                ],
            };
            break;
        case ACTIONS.FILTER_RUB_FACTS:
            // param = defDomaine: number
            if (action.payload === 0) break;

            const filteredRubFacts = state.rubFacts.filter((rubFact) => {
                return rubFact.domainesAchats?.find((domaine) => {
                    return domaine.domaine?.id === action.payload;
                })
                    ? true
                    : false;
            });

            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(filteredRubFacts).map((m) => [m.id, m])).values(),
                ],
            };
            break;
        case ACTIONS.ADD_RUB_FACTS:
            const newRubFacts: RubriqueFacturationApiObject[] = [
                ...state.rubFacts,
                ...action.payload,
            ];

            newState = {
                ...state,
                rubFacts: [...new Map(orderRubFacts(newRubFacts).map((m) => [m.id, m])).values()],
            };
            break;
        case ACTIONS.ADD_RUB_FACT:
            const newRubFactsOneElement: RubriqueFacturationApiObject[] = [
                ...state.rubFacts,
                action.payload,
            ];

            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(newRubFactsOneElement).map((m) => [m.id, m])).values(),
                ],
            };
            break;

        case ACTIONS.SET_RUB_FACT:
            const setRubFacts: RubriqueFacturationApiObject[] = [...action.payload];

            newState = {
                ...state,
                rubFacts: [...new Map(orderRubFacts(setRubFacts).map((m) => [m.id, m])).values()],
            };
            break;

        case ACTIONS.AFFICHER_LIGNE_VENTE:
            newState = {
                ...state,
                afficherLigneVente: action.payload,
            };
            break;
        case ACTIONS.SET_SWITCH_LIGNE_VENTE:
            if (!_.isEqual(state.switchLigneVente, action.payload)) {
                newState = {
                    ...state,
                    switchLigneVente: action.payload,
                };
            } else {
                return state;
            }
            break;
        default:
            newState = { ...state };
            break;
    }

    return newState;
};

// dispatch({
//     type: `${facturesAchats.slicename}/delete${facturesAchats.name}Selected`,
// });
// // clean du media object
// dispatch({
//     type: `${mediaObjects.slicename}/delete${mediaObjects.name}Selected`,
// });
// // clean du dossier selected
// dispatch({
//     type: `${dossiers.slicename}/delete${dossiers.name}Selected`,
// });
// // clean du state du fournisseur
// dispatch({
//     type: `${fournisseurs.slicename}/delete${fournisseurs.name}Selected`,
// });
// // clean du state du fournisseur main
// dispatch({
//     type: `${fournisseursMain.slicename}/delete${fournisseursMain.name}Selected`,
// });

export default FactureFormReducer;
