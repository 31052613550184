import React, { type FunctionComponent } from 'react';
import { ActiveFilterBadge, FlexyInput } from '@europrocurement/flexy-components';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatatableHeaderProps, DisplayOpts, FiltersOpts, SearchOpts } from '../DatatableHeader';

export type FlexySearchProps = {
    searchOpts?: SearchOpts;
    toggleRightMenu?: () => void;
};

const FlexySearch: FunctionComponent<FlexySearchProps> = function ({
    searchOpts,
    toggleRightMenu,
}: FlexySearchProps) {
    const { onSearchChange, filtersOpts, search } = searchOpts as SearchOpts;

    return searchOpts ? (
        <Box sx={{ display: 'flex' }}>
            {onSearchChange ? (
                <FlexyInput
                    data-testid="RapidSearch"
                    variant="outlined"
                    type="text"
                    size="small"
                    fullWidth
                    name="rapidSearch"
                    placeholder="Recherche rapide"
                    defaultValue={search}
                    onChange={(e: React.BaseSyntheticEvent) => {
                        return onSearchChange(e.target.value);
                    }}
                />
            ) : null}

            {filtersOpts && (
                <>
                    <Tooltip title="Liste des filtres" data-testid="DisplayFiltersButton">
                        <IconButton onClick={() => toggleRightMenu && toggleRightMenu()}>
                            <FilterAltIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </Box>
    ) : null;
};

FlexySearch.defaultProps = {
    searchOpts: undefined,
    toggleRightMenu: undefined,
};

export default FlexySearch;
