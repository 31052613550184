import { useContext } from 'react';
import { KeycloakInstance, KeycloakTokenParsed as BaseKeycloakTokenParsed } from 'keycloak-js';
import { KeycloakRoleMalFormedError } from './KeycloakRoleMalFormedError';
import { reactKeycloakWebContext } from './KeycloakContext';

export interface KeycloakTokenParsed extends BaseKeycloakTokenParsed {
    name?: string;
    email?: string;
}

export type KeycloakRoleDefinition = undefined | string | string[];

export class KeycloakService {
    private initialized: boolean;

    private keycloakInstance: KeycloakInstance;

    public constructor(keycloak: KeycloakInstance, initialized: boolean) {
        this.keycloakInstance = keycloak;
        this.initialized = initialized;
    }

    public isInitialized() {
        return this.initialized;
    }

    public checkRole(role?: KeycloakRoleDefinition): boolean {
        if (!this.keycloakInstance.authenticated) {
            return false;
        }

        if (role === undefined) {
            return this.keycloakInstance.authenticated;
        }

        if (typeof role === 'string') {
            const splitedRole = role.split(':');

            if (splitedRole.length === 1) {
                return this.keycloakInstance.hasResourceRole(splitedRole[0]);
            }
            if (splitedRole.length === 2) {
                if (splitedRole[0] === 'realm') {
                    return this.keycloakInstance.hasRealmRole(splitedRole[1]);
                }
                return this.keycloakInstance.hasResourceRole(splitedRole[1], splitedRole[0]);
            }
            throw new KeycloakRoleMalFormedError();
        } else {
            return role.map((item) => this.checkRole(item)).indexOf(false) === -1;
        }
    }

    public getKc() {
        return this.keycloakInstance;
    }

    public accountManagement() {
        return this.getKc().accountManagement();
    }

    public isAutenticated() {
        return this.keycloakInstance.authenticated || false;
    }

    public getName() {
        if (typeof this.getTokenParsed() === 'object') {
            return this.getTokenParsed().name || '';
        }
        return '';
    }

    public getEmail() {
        if (typeof this.getTokenParsed() === 'object') {
            return this.getTokenParsed().email || '';
        }
        return '';
    }

    public getTokenParsed() {
        return this.getKc().tokenParsed as KeycloakTokenParsed;
    }
}

export function UseKeycloakService() {
    const ctx = useContext(reactKeycloakWebContext);

    if (!ctx) {
        throw new Error('useKeycloak hook must be used inside ReactKeyService context');
    }

    if (!ctx.authClient) {
        throw new Error('authClient has not been assigned to ReactKeyService');
    }

    const { authClient, initialized } = ctx;
    return new KeycloakService(authClient, initialized);
}

export default UseKeycloakService;
