import React, { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { experimentalStyled, Container, Box, Theme } from '@mui/material';
import { SnackbarAction, SnackbarKey, SnackbarProvider } from 'notistack';

import {
    SidebarWidth,
    TopbarHeight,
    paddingContent,
    footerHeight,
} from '../../../assets/global/Theme-variable';
import { SnackbarCloseButton } from '../../atoms';
import { type LogoIconProps } from '../../atoms/LogoIcon';
import { Sidebar, Header, Footer, type MenuEntry, HeaderProps, FooterProps } from '../../organisms';
import { Scrollbar } from '../../molecules';
import ContextActionsMenu, {
    ContextActionType,
} from '../../organisms/ContextActionsMenu/ContextActionsMenu';

export type FullLayoutProps = {
    menuEntries: MenuEntry[];
    footerProps: FooterProps;
    logoSrc?: LogoIconProps['src'];
    logoRedirect?: string;
    headerProps?: HeaderProps;
    contextActions?: ContextActionType[];
    contextActionMainIcon?: ReactNode;
};

const MainWrapper = experimentalStyled('div')(() => ({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
}));

const PageWrapper = experimentalStyled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        paddingTop: TopbarHeight,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '64px',
    },
}));

// Fermeture du menu latéral forcé + icone de retour en haut à gauche pour les routes suivantes
function isDrawerRoute(pathname: string) {
    const basePath = '/achats/liste/';

    const paths = [
        `/achats/liste/saisir`,
        `/achats/liste/saisir/multi/entete`,
        `/achats/liste/saisir/multi/dossier`,
        `/achats/liste/saisir/multi/recap`,
        `/achats/liste/modifier`,
        `/achats/liste/recap`,
    ];

    return !paths.includes(pathname);
}

const FullLayout = function (props: FullLayoutProps) {
    const {
        menuEntries,
        footerProps,
        logoSrc,
        logoRedirect,
        headerProps,
        contextActions,
        contextActionMainIcon,
    } = props;

    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

    // const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const lgUp = true;

    const { pathname } = useLocation();

    const useBasePath = () => {
        const location = useLocation();
        const params = useParams<Record<string, string>>();

        return Object.values(params).reduce(
            (path, param) => (path && param ? path.replace(`/${param}`, '') : null),
            location.pathname,
        );
    };

    const basePath = useBasePath();

    const pathWithoutLastPart = typeof basePath === 'string' ? basePath : pathname;

    const closeBtn: SnackbarAction = (key: SnackbarKey) => (
        <SnackbarCloseButton snackbarKey={key} />
    );

    return (
        <MainWrapper data-testid="test-id-FullLayout">
            <Header
                sx={{
                    paddingLeft:
                        isSidebarOpen && lgUp && isDrawerRoute(pathWithoutLastPart)
                            ? `${SidebarWidth}px !important`
                            : '',
                    backgroundColor: 'background.default',
                }}
                toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
                toggleMobileSidebar={() => setMobileSidebarOpen(true)}
                drawerAvailability={isDrawerRoute(pathWithoutLastPart)}
                {...headerProps}
            />

            <Sidebar
                logoSrc={logoSrc}
                logoRedirect={logoRedirect}
                menuEntries={menuEntries}
                isSidebarOpen={isSidebarOpen && isDrawerRoute(pathWithoutLastPart)}
                isMobileSidebarOpen={isMobileSidebarOpen && isDrawerRoute(pathWithoutLastPart)}
                onSidebarClose={() => setMobileSidebarOpen(false)}
            />

            <PageWrapper>
                <Container
                    maxWidth={false}
                    sx={{
                        height: `calc(100vh - ${footerHeight}px - ${TopbarHeight}px)`,
                    }}
                >
                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={3000}
                        anchorOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                        action={closeBtn}
                        variant="success"
                    >
                        <Scrollbar>
                            <Box
                                sx={{
                                    paddingLeft:
                                        isSidebarOpen && lgUp && isDrawerRoute(pathWithoutLastPart)
                                            ? `${SidebarWidth + paddingContent}px !important`
                                            : `${paddingContent}px !important`,
                                    paddingRight: `${paddingContent}px !important`,
                                }}
                            >
                                <Outlet />
                            </Box>
                        </Scrollbar>
                    </SnackbarProvider>
                    <Footer {...footerProps} />
                </Container>
            </PageWrapper>
            <ContextActionsMenu actions={contextActions} mainIcon={contextActionMainIcon} />
        </MainWrapper>
    );
};

export default FullLayout;
