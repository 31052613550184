import * as React from 'react';
import { Button } from '@mui/material';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { ErrorPage } from '../ErrorPage';

export const Forbidden = function () {
    const kc = UseKeycloakService();

    return (
        <ErrorPage statusCode={403} message="Vous n'avez pas les droits d'acceder a cette page">
            <Button variant="contained" color="primary" onClick={() => kc.getKc().login()}>
                Login
            </Button>
        </ErrorPage>
    );
};
export default Forbidden;
