import * as React from 'react';
import { Box, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexyInput } from '@europrocurement/flexy-components';
import { EmailPrescripteurWriteNotificationsEmails } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useDebounce } from 'use-debounce';
import ActionModal from '../../forms/ActionModal';

type EmailListProps = {
    isUpdating: boolean;
    emails: EmailPrescripteurWriteNotificationsEmails[];
    updateEmails: any;
    deleteEmail: any;
};

const EmailList: React.FC<EmailListProps> = (props) => {
    const { emails, updateEmails, isUpdating, deleteEmail } = props;

    if (!emails) {
        return null;
    }

    // Info : Notification & Rappel boolean trigger email deletion if the two values are set to false

    const hasUnsetReminders = emails.some((email) => email.rappel === true);
    const hasUnsetNotifications = emails.some((email) => email.notification === true);

    const toggleNotification = (emailValue: string) => {
        const updatedEmails = emails.map((email) =>
            email.email === emailValue
                ? {
                      ...email,
                      notification: !email.notification,
                  }
                : email,
        );
        updateEmails(updatedEmails);
    };

    const toggleReminder = (emailValue: string) => {
        const updatedEmails = emails.map((email) =>
            email.email === emailValue
                ? {
                      ...email,
                      rappel: !email.rappel,
                  }
                : email,
        );
        updateEmails(updatedEmails);
    };

    const toggleList = (field: 'rappel' | 'notification', newListValue: boolean) => {
        const updatedEmails = emails.map((email) => {
            return {
                ...email,
                [field]: newListValue,
            };
        });

        updateEmails(updatedEmails);
    };

    const isListEmpty = emails && emails.length === 0;

    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{ margin: '15px 0', opacity: '0.8' }}
            >
                <Box display={'flex'} alignItems={'center'}>
                    <EmailIcon fontSize="medium" sx={{ marginLeft: '15px' }} />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Tooltip title="Notification">
                        <NotificationsIcon fontSize="medium" />
                    </Tooltip>
                    <FlexyInput
                        sx={{ marginRight: '45px' }}
                        type="boolean"
                        name={'list_rappel_switch'}
                        value={hasUnsetNotifications}
                        disabled={isListEmpty || isUpdating}
                        onChange={() => toggleList('notification', !hasUnsetNotifications)}
                    />
                    <Tooltip title="Rappel">
                        <AlarmOnIcon fontSize="medium" />
                    </Tooltip>
                    <FlexyInput
                        sx={{ marginRight: '45px' }}
                        type="boolean"
                        name={'list_rappel_switch'}
                        value={hasUnsetReminders}
                        disabled={isListEmpty || isUpdating}
                        onChange={() => toggleList('rappel', !hasUnsetReminders)}
                    />
                </Box>
            </Box>
            <hr />

            <List
                sx={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                }}
            >
                {isListEmpty && (
                    <Box
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'center'}
                        sx={{ opacity: '0.5' }}
                    >
                        <p>(Aucun email dans cette liste)</p>
                    </Box>
                )}

                {emails.map((formItem: any, index: number) => {
                    const { email, notification, rappel } = formItem;

                    const notificationFlag = notification as boolean;
                    const reminderFlag = rappel as boolean;

                    return (
                        <ListItem
                            key={`notifications-email_list_${index}`}
                            sx={{
                                backgroundColor: `${index % 2 === 0 ? '' : '#f6f6f6'}`,
                            }}
                        >
                            <ListItemText primary={email} />
                            <Box display={'flex'}>
                                <FlexyInput
                                    sx={{ marginRight: '70px' }}
                                    type="boolean"
                                    name={'notification_switch'}
                                    key={`notification_switch_${index}`}
                                    disabled={isUpdating}
                                    value={notificationFlag}
                                    onChange={() => toggleNotification(email)}
                                />
                                <FlexyInput
                                    sx={{ marginRight: '28px' }}
                                    type="boolean"
                                    name={'rappel_switch'}
                                    key={`rappel_switch_${index}`}
                                    value={reminderFlag}
                                    disabled={isUpdating}
                                    onChange={() => toggleReminder(email)}
                                />
                            </Box>
                            {/* <IconButton aria-label="delete" onClick={() => deleteEmail(email)}>
                                <DeleteIcon />
                            </IconButton> */}
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

export default EmailList;
