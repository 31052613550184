import React, { useCallback, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FactureAchatApiObject, MediaObject } from '@europrocurement/l2d-domain';
import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import { NoteAdd, FileDownload } from '@mui/icons-material';

import { MediaObjectSelector, getMediaObject } from '../../../../redux/RootStore';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import generateB2DPath from '../../../../utils/generateB2DPath';
import { useSnackbar } from 'notistack';
import { Chip, Typography } from '@mui/material';

const MediaObjectList = function () {
    const mediaObjectDataSource = useSelector(MediaObjectSelector).main;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMediaObject({}));
    }, [dispatch]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    const navigateToMediaObject = useCallback(
        (mediaObject: MediaObject) => {
            if (mediaObjectDataSource.selected) {
                resetStates();
            }

            let response = generateB2DPath('formmediaobject', { mediaObject: mediaObject });

            if (response.status === 'OK') {
                navigate(response.path);
            } else if (response.status === 'KO') {
                enqueueSnackbar(<Typography>{response.message}</Typography>, {
                    variant: 'warning',
                });
            }
        },
        [enqueueSnackbar, mediaObjectDataSource.selected, navigate, resetStates],
    );

    /**
     * Action lors du clique du bouton de téléchargement du PDF.
     */
    const onDownload = (mediaObject: MediaObject) => {
        if (mediaObject.contentUrl && mediaObject.name) {
            let link = document.createElement('a');
            link.href = mediaObject.contentUrl;
            link.target = '_blank';
            link.type = 'application/octet-stream';
            link.download = `${mediaObject.name}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const columns: ColumnDatatable<MediaObject>[] = React.useMemo(
        () => [
            {
                label: 'Nom du fichier',
                render: 'originalName',
                isDisplayed: true,
            },
            {
                label: 'Date de dépôt',
                render: (mediaObject: MediaObject) => {
                    let moCreationDate = null;

                    if (mediaObject.createdAt) {
                        moCreationDate = new Date(
                            Date.parse(mediaObject.createdAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{moCreationDate}</Typography>;
                },
                isDisplayed: true,
            },
            {
                label: 'Déposé par',
                render: 'createdBy',
                isDisplayed: true,
            },
            {
                label: 'Facture liée',
                onClickCell: () => {},
                render: (mediaObject: MediaObject) => {
                    return !mediaObject.facturesAchat || mediaObject.facturesAchat.length === 0
                        ? null
                        : mediaObject.facturesAchat.map((facture: FactureAchatApiObject) => {
                              return (
                                  <Chip
                                      color="warning"
                                      label={`${facture.numeroFacture}`}
                                      size="small"
                                      key={`${facture.numeroFacture}-${facture.id}-${mediaObject.id}`}
                                      onClick={() => {
                                          navigate(`modifier/${facture.id}/${mediaObject.id}`);
                                      }}
                                      sx={{
                                          color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                      }}
                                  />
                              );
                          });
                },
                isDisplayed: false,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (mediaObject: MediaObject) => {
                    return mediaObject.id ? (
                        <>
                            <Link
                                to={`saisir/${mediaObject.id}`}
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    navigateToMediaObject(mediaObject);
                                }}
                            >
                                <NoteAdd color="secondary" />
                            </Link>
                            <Link
                                to=""
                                onClick={() => {
                                    onDownload(mediaObject);
                                }}
                                style={{ textDecoration: 'none' }}
                            >
                                <FileDownload color="secondary" />
                            </Link>
                        </>
                    ) : null;
                },
                isDisplayed: true,
            },
        ],
        [navigate, navigateToMediaObject],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'createdByMe',
            label: 'Déposé par moi',
            type: 'boolean',
        },
        {
            field: 'createdBy',
            label: 'Déposé par',
            type: 'text',
        },
        {
            field: 'processingFacture',
            label: 'Pdf en traitement',
            type: 'boolean',
        },
    ];

    return mediaObjectDataSource ? (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: '/achats/liste' },
                    'Liste des factures à traiter',
                ]}
            />
            <StoreDatatable
                dataSource={mediaObjectDataSource}
                columns={columns}
                fetchData={getMediaObject}
                filters={filters}
                localStorageKey="FlexyStoreDatatableMediaObject"
                onClickRow={(e: React.MouseEvent<HTMLElement>, item: MediaObject) =>
                    navigateToMediaObject(item)
                } // Empêche la bonne redirection vers la facture liée (même en utilisant un Link)
            />
        </>
    ) : null;
};

export default MediaObjectList;
