import React, { useEffect } from 'react';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { getRubFac, RubriqueFacturationSelector } from '../../redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { RubriqueFacturation } from '@europrocurement/l2d-domain';

const AchatsRoot: React.FunctionComponent<{}> = function () {
    let rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const dispatch = useDispatch();

    useEffect(() => {
        if (rubFactsDataSource.status === 'idle') {
            dispatch(getRubFac({}));
        }
    }, [dispatch, rubFactsDataSource.status]);

    return (
        <KeycloakRoute roles="api-tiers:tiers-read">
            <Outlet />
        </KeycloakRoute>
    );
};

export default AchatsRoot;
