// Phone number must start with 0, followed by 1 digit
// Then 4 groups of 2 digits
// Allow optionnal space between group of numbers

export const PHONE_NUMBER_VALIDATION_REGEX: RegExp = /^0\d\s?([\d]{2}[\s]?){4}$/;
export const EMAIL_VALIDATION_REGEX: RegExp = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
export const POST_CODE_VALIDATION_REGEX: RegExp = /^\d{5}$/;
export const SIRET_FORMAT: RegExp = /^(\d?){14}$/;
export const LANDLINE_PHONE_NUMBER_REGEX: RegExp = /^(0|\+33)[1-5;8-9]([\s]?[0-9]{2}){4}$/;
export const MOBILE_PHONE_NUMBER_REGEX: RegExp = /^(0|\+33)[6-7]([\s]?[0-9]{2}){4}$/;
