import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import {
    Commande,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    Dossier,
    FactureAchat,
    FactureVente,
    Fournisseur,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    CommandeSelector,
    DossierSelector,
    FactureFormSelector,
    FactureVenteSelector,
    FactureachatSelector,
    FournisseurSelector,
    RootStateType,
    RubriqueFacturationSelector,
    dossierDataSourcesThunks,
    factureAchatApi,
    factureAchatLigneApi,
    selectFactureAchat,
    selectFactureVente,
    selectFournisseur,
} from '@b2d/redux/RootStore';
import EnteteFacture from './EnteteFacture';
import { CenterCircularProgress, Modalizer } from '@europrocurement/flexy-components';
import { ExtandedFormContext, FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { Box, Button, FormControlLabel, InputAdornment, Switch, Typography } from '@mui/material';
import {
    useNavigateToFactureAchatNextDossier,
    useNavigateToFactureAchatRecap,
    useNavigateToMediaObjectsList,
} from '../../../AchatsRouter';
import PauseIcon from '@mui/icons-material/Pause';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { factureFormObject, ligneProduit, tableauProduits } from '../types';
import { getAcDossierStructure, totauxStructure, useGetProduitsStructure } from '../formElements';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import SearchDossierModal from '../../modals/SearchDossierModal';
import { useUpdateFormWithDossier } from '../functions/dossierFonctions';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import {
    ajouterLigneRSF,
    isDossierFromFournisseur,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
    fillProduits,
    useActionOnChangeTauxRemise,
    replaceProduits,
    defaultProduits
} from '../functions/produitFunctions';
import { formProduitsToTableauLigneWrite } from '../functions/dataTransformers';
import { useResetAllForm, useResetFactureAchatState } from '../functions/generalFunctions';
import { useUpdateDomaine } from '../functions/venteFunctions';
import { useMultiAnnonceContext } from '../../../SaisieFacturesMultiple';
import { useSnackbar } from 'notistack';
import { updateFormWithFournisseur } from '../functions/fournisseurFunctions';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { arrondi } from '../functions/calculsProduits';
import ConfirmationModal, { MessagesTypes } from '../../modals/ConfirmationModal';
import { cancelAddDossier, defaultModalMessages, pauseSaisieFacture } from '../../modals/modalSpec';
import { FactureAchatFactureAchatReadDossierDossiersInner } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { useGetDossiers } from '../functions/dataHooks';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

export type DossierSaisieMultipleType = {};

export const DossierSaisieMultiple = function ({}: DossierSaisieMultipleType) {
    const kc = UseKeycloakService();
    const isInterne = kc.checkRole('realm:interne');

    const { factureachatid } = useParams();
    const { mediaObject } = useMultiAnnonceContext();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const resetForm = useResetAllForm();
    const navigateToMediaObjectsList = useNavigateToMediaObjectsList();
    const navigateToFactureAchatRecap = useNavigateToFactureAchatRecap();
    const navigateToFactureAchatNextDossier = useNavigateToFactureAchatNextDossier();
    const updateDomaine = useUpdateDomaine();
    const updateFormWithDossier = useUpdateFormWithDossier();
    const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
    const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
    const resetStates = useResetFactureAchatState();
    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();
    const getProduitsStructure = useGetProduitsStructure();
    const formContext = useForm({
        mode: 'onTouched',
    });

    const [hasSetRsf, setHasSetRsf] = useState<boolean>(false);
    const [hasBlured, setHasBlured] = useState<boolean>(false);
    const [modalDossierOpen, setModalDossierOpen] = useState<boolean>(false);
    const [modalRemiseOpen, setModalRemiseOpen] = useState<boolean>(false);
    const [afficherBoutonTxRemise, setAfficherBoutonTxRemise] = useState<boolean>(false);
    const [rsfInclusTxremise, setRsfInclusTxremise] = useState<string>();
    const [modalRemoveProduitsOpen, setModalRemoveProduitsOpen] = useState<boolean>(false);
    const [lastVenteUpdate, setLastVenteUpdate] = useState<number|null>(null);
    const [confirmationModalState, setConfirmationModalState] = useState<{
        isModalOpen: boolean;
        messages: MessagesTypes;
        actionOnValidation: () => void;
        actionOnCancellation: () => void;
        isDraggable?: boolean;
    }>({
        isModalOpen: false,
        messages: defaultModalMessages,
        actionOnValidation: () => {},
        actionOnCancellation: () => {},
    });

    const [dossiers, setdossiers] = useState<FactureAchatFactureAchatReadDossierDossiersInner[]>(
        [],
    );
    const [dossiersStatus, setdossiersStatus] = useState<EuroprocApiResponseStatus>('idle');

    const getDossiers = useGetDossiers();

    useEffect(() => {
        if (!factureachatid || dossiersStatus === 'loading' || dossiersStatus === 'succeeded') {
            return;
        }

        setdossiersStatus('loading');
        getDossiers(+factureachatid, factureAchatApi).then((data) => {
            console.log(data);
            setdossiersStatus('succeeded');
            setdossiers(data);
        });
    }, [dossiersStatus, factureachatid, getDossiers]);

    const state = useSelector(FactureFormSelector);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    const factureAchat: FactureAchat | undefined = useSelector(FactureachatSelector).main.selected;
    const commandeSelected: Commande | undefined = useSelector(CommandeSelector).main.selected;
    const venteSelected: FactureVente | undefined = useSelector(FactureVenteSelector).main.selected;
    const dossierSelected: Dossier | undefined =
        useSelector(DossierSelector).autocompleteDossiers.selected;
    const fournisseurSelected: Fournisseur | undefined =
        useSelector(FournisseurSelector).main.selected;

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
        shallowEqual,
    );

    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;

    const searchByNumeroDossier = dossierDataSourcesThunks.autocompleteDossiers.getData;

    const searchByNumeroPresta =
        dossierDataSourcesThunks.autocompleteDossiers.data.searchByNumeroPresta;

    const actionOnRSF: any | undefined = (
        tableauProduits: tableauProduits,
        actionIfRSF: (produitRSF?: Partial<ligneProduit> | undefined) => any | undefined,
        actionNoRSF: () => any | undefined,
    ) => {
        const produits = tableauProduits;
        const produitRSF = produits.find((item) => item.rubriqueFacturation?.id === 99);

        if (produitRSF && actionIfRSF !== undefined) {
            actionIfRSF(produitRSF);
        }

        if (!produitRSF && actionNoRSF !== undefined) {
            actionNoRSF();
        }
    };

    const updateProduitsOnRemise = useCallback(
        (txRemise: string, pubLineNumber: number) => {
            // Sert pour relever les fournisseurs dont la RSF a été modifiée manuellement.
            setHasSetRsf(true);
            ajouterLigneRSF(formContext, fournisseurSelected);
            setAfficherBoutonTxRemise(true);

            const produits = formContext.getValues(`produits`) as tableauProduits;

            if (fournisseurSelected && parseFloat(txRemise) > 0 && pubLineNumber !== undefined) {
                // Calcul de la ligne de PUB
                const pubHt = formContext.getValues(`produits.${pubLineNumber}.ht`);

                if (pubHt !== undefined) {
                    const newPubHt = arrondi(pubHt / (1 - parseFloat(txRemise) / 100)) as number;
                    const newRsfHt = arrondi(newPubHt - pubHt);

                    const txtva = formContext.getValues(`produits.${pubLineNumber}.txtva`);

                    formContext.setValue(`produits.${pubLineNumber}.ht`, newPubHt);

                    fillProduits(
                        formContext,
                        {
                            idLigneVente: formContext.getValues(
                                `produits.${pubLineNumber}.idLigneVente`,
                            ),
                            ht: newPubHt,
                            txtva: formContext.getValues(`produits.${pubLineNumber}.txtva`),
                        },
                        pubLineNumber,
                    );

                    // On cherche la ligne de rsf
                    actionOnRSF(
                        produits,
                        (produitRSF: Partial<ligneProduit>) => {
                            const index = produits.indexOf(produitRSF);

                            produits[index].ht = newRsfHt;
                            produits[index].txtva = txtva;

                            formContext.setValue('produits', produits);
                        },
                        undefined,
                    );

                    const produitsAfterAddRsf = formContext.getValues(
                        `produits`,
                    ) as tableauProduits;

                    const RSF = produitsAfterAddRsf.find(
                        (item) => item.rubriqueFacturation?.id === 99,
                    );

                    if (RSF) {
                        fillProduits(formContext, RSF, produitsAfterAddRsf.indexOf(RSF));
                        formContext.trigger(`produits.${produitsAfterAddRsf.indexOf(RSF)}.ht`);
                    }
                }
            }
        },
        [formContext, fournisseurSelected],
    );

    /**
     * Si un id de factureAchat est trouvé dans l'url,
     * alors on sélectionne la facture d'achat concernée.
     */
    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * Si aucun fournisseur n'est sélectionné lorsque l'on a une facture d'achat,
     * alors on sélectionne le fournisseur lié à la facture d'achat.
     */
    useEffect(() => {
        if (
            factureAchat &&
            factureAchat.idEntiteFacturante &&
            (!fournisseurSelected || factureAchat.idEntiteFacturante !== fournisseurSelected.id)
        ) {
            dispatch(selectFournisseur({ id: +factureAchat.idEntiteFacturante }));
        }
    }, [dispatch, factureAchat, formContext, fournisseurSelected]);

    /**
     * Affiche un toast si le fournisseur de la facture d'achat
     * ne correspond à aucun fournisseur des prestations du dossier.
     */
    useEffect(() => {
        if (factureAchat && dossierSelected) {
            if (!isDossierFromFournisseur(factureAchat, dossierSelected)) {
                enqueueSnackbar(
                    <Typography>
                        Le dossier sélectionné n'est pas lié au fournisseur{' '}
                        {factureAchat.libelleFacturante}.
                    </Typography>,
                    {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    },
                );
            }
        }
    }, [factureAchat, enqueueSnackbar, dossierSelected]);

    /**
     * Affiche un toast si le dossier a déjà été traité
     * dans une facture d'achat clôturée.
     */
    useEffect(() => {
        if (factureAchat && dossierSelected) {
            if (!dossierSelected.numero) return;

            const numeroDossierSelected = dossierSelected.numero.toString();

            let isOneNumDossierEqual = dossiers.find((dossierRecapItem) => {
                return dossierRecapItem.numeroDossier === numeroDossierSelected;
            });

            if (isOneNumDossierEqual) {
                enqueueSnackbar(
                    <Typography>
                        Le dossier sélectionné a déjà été traité dans cette facture.
                    </Typography>,
                    {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    },
                );
            }
        }
    }, [enqueueSnackbar, dossierSelected, factureAchat, dossiers]);

    /**
     * Ajoute la ligne RSF au tableau de produit,
     * si un fournisseur est sélectionné
     */
    useEffect(() => {
        if (!factureAchat || !fournisseurSelected) return;

        updateFormWithFournisseur(formContext, fournisseurSelected, {
            typeRemise: (type: TypeRemiseFournisseurRead) => {
                if (type.code === 'RSF' || type.code === 'RSF Inclus') {
                    // ajouterLigneRSF(formContext, fournisseurSelected);
                    setAfficherBoutonTxRemise(true);
                }
            },
        });
    }, [dispatch, factureAchat, formContext, fournisseurSelected]);

    /**
     * Affiche le bouton "Modifier taux remise".
     */
    useEffect(() => {
        if (!factureAchat || !rubFactsDataSource.data || !dossierSelected || !fournisseurSelected)
            return;

        if (fournisseurSelected.typeRemise?.code === 'RSF') {
            setAfficherBoutonTxRemise(true);
        } else {
            setAfficherBoutonTxRemise(false);
        }
    }, [dossierSelected, factureAchat, formContext, fournisseurSelected, rubFactsDataSource.data]);

    /**
     * Formate le formulaire avant de mettre à jour les infos de la facture.
     * Si la mise à jour est un succès, alors l'utilisateur est envoyé vers
     * le récapitulatif.
     *
     * @param factureForm
     * @returns void
     */
    const handleSubmit = async function (
        factureForm: factureFormObject & { end?: boolean; pause?: boolean },
    ) {
        if (!factureachatid || !factureAchat || !factureAchat['@id']) {
            return;
        }

        const lines = formProduitsToTableauLigneWrite(factureForm, factureAchat['@id']);

        const promises = lines.map((line)=>factureAchatLigneApi.createFactureAchatLigneFactureAchatLigneCollection(line));

        await Promise.all(promises);

        resetForm(formContext);
        dispatch(selectFactureAchat({ id: +factureachatid }));

        if (factureForm.end) {
            navigateToFactureAchatRecap(factureAchat as FactureAchat, mediaObject);
        } else if (factureForm.pause) {
            navigateToMediaObjectsList();
        } else {
            navigateToFactureAchatNextDossier(factureAchat as FactureAchat, mediaObject);
        }
    };

    /**
     * Déclenché lors de la soumission du formulaire de la modal.
     * Siren exemple RSF Inclus : 987654321
     */
    const handleModalSubmit: SubmitHandler<{
        'facture-form-modal-txremise': string;
    }> = useCallback(
        (data: { 'facture-form-modal-txremise': string }) => {
            actionOnChangeTauxRemise(
                formContext,
                (produitConcernedByRsf) => {
                    const txRemise = data['facture-form-modal-txremise'];
                    updateProduitsOnRemise(txRemise, produitConcernedByRsf);
                    setHasBlured(true);
                    setModalRemiseOpen(false);
                },
                (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'error' });
                },
            );
        },
        [actionOnChangeTauxRemise, enqueueSnackbar, formContext, updateProduitsOnRemise],
    );

    /**
     * Infos dossier par N° de dossier / d'annonce
     *
     * Si un dossier est sélectionné, met à jour les informations.
     *
     */
    useEffect(() => {
        if (
            dossierSelected &&
            dossierSelected.numero &&
            `${dossierSelected.numero}` !== state.defaultNumeroDossier
        ) {
            updateFormWithDossier(formContext, dossierSelected);
        } else if (!dossierSelected) {
            resetStates(formContext);
        }
    }, [
        formContext,
        dossierSelected,
        state.defaultNumeroDossier,
        updateFormWithDossier,
        resetStates,
    ]);

    /**
     * Met à jour les informations de ventes.
     */
    useEffect(() => {
        //if ( !dossierSelected && !venteSelected ) return;

        updateFormWithVentesAndEvents(
            formContext,
            dossierSelected || null,
            fournisseurSelected || null,
            venteSelected || null,
            ventesDataSource.status,
        );
    }, [
        formContext,
        dossierSelected,
        venteSelected,
        ventesDataSource.status,
        fournisseurSelected,
        updateFormWithVentesAndEvents,
        enqueueSnackbar,
    ]);

    /**
     * S'il n'y plus de dossier ou de fournisseur sélectionné,
     * alors on remplace le tableau de produits par la valeur par défaut.
     */
    useEffect(() => {
        if (!dossierSelected && !fournisseurSelected) {
            replaceProduits(formContext, defaultProduits(1, false));
        }
    }, [dossierSelected, formContext, fournisseurSelected]);

    /**
     * Ajout des RubFacts généraux
     *
     * @see dispatch
     * @see rubFacts.data
     */
    useEffect(() => {
        const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

        dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
    }, [dispatch, rubFactsDataSource.data]);

    /**
     * Filtre sur les rubriques de facturation
     *
     * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
     *
     * @see state.defDomaine
     * @see state.rubFacts
     */
    useEffect(() => {
        dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

        if (state.defDomaine !== 0) {
            dispatch({
                type: ACTIONS.FILTER_RUB_FACTS,
                payload: state.defDomaine,
            });
        }
    }, [dispatch, rubFactsDataSource.data, state.defDomaine]);

    /**
     * Si on a une commande on met le domaine de la commande
     */
    useEffect(() => {
        if (commandeSelected?.idDomaine) {
            dispatch({
                type: ACTIONS.SET_DEF_DOMAINE,
                payload: commandeSelected?.idDomaine,
            });
        }
    }, [commandeSelected?.idDomaine, dispatch]);

    /**
     * Met à jour le domaine en fonction de l'achat
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect
        updateDomaine(venteSelected);
    }, [venteSelected, updateDomaine, formContext]);

    /**
     * Select la facture de vente de la commande,
     * si le dossier n'a pas lui-même de facture de vente.
     *
     * On cherche dans la commande le dossier qui correspond au dossier sélectionné
     * pour s'assurer que la facture soit la bonne.
     *
     * E.g. :
     *     - Dossier sans facture : 1272353
     *     - Dossier avec facture : 802308
     */
    useEffect(() => {
        if (
            commandeSelected?.idFacture &&
            dossierSelected &&
            dossierSelected.id &&
            !dossierSelected.facture
        ) {
            const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                if (!dossierSelected.id) return false;
                return dossier.includes(dossierSelected.id.toString());
            });

            if (
                matchedDossier &&
                ventesDataSource.status !== 'loading' &&
                (!venteSelected || commandeSelected?.idFacture !== venteSelected.id)
            ) {
                dispatch(selectFactureVente({ id: commandeSelected?.idFacture }));
            } else {
                // console.log('Aucun dossier de la commande ne correspond au dossier sélectionné.');
                if (domaine !== commandeSelected.idDomaine) {
                    dispatch({
                        type: ACTIONS.SET_DEF_DOMAINE,
                        payload: commandeSelected.idDomaine,
                    });
                }
            }
        }
    }, [
        commandeSelected?.dossiers,
        commandeSelected?.idDomaine,
        commandeSelected?.idFacture,
        dispatch,
        domaine,
        dossierSelected,
        venteSelected,
        ventesDataSource.status,
    ]);

    /**
     * Ajout des lignes d'achat correspondant à la vente
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-54
     *
     * Si une facture de vente est trouvée :
     * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
     * la propriété 'affiche' est à false.
     *
     * @see venteSelected
     * @see formContext
     * @see addProduit
     * @see state.rubFacts
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect
        if( venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate ){
            setLastVenteUpdate(venteSelected.id);
            syncAchatProduitsWithVente(venteSelected, formContext, state.rubFacts, dossierSelected);
        }
        
    }, [lastVenteUpdate, dossierSelected, formContext, state.rubFacts, syncAchatProduitsWithVente, venteSelected]);

    const modalFactureFormStructure: FormStructure[] = useMemo(() => {
        return [
            {
                type: 'number',
                name: 'facture-form-modal-txremise',
                inputlabel: 'Taux de remise',
                autoFocus: true,
                placeholder: '0',
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },

                defaultValue: '20',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChangeInput: (
                    event: React.SyntheticEvent<Element, Event>,
                    input: {
                        base: string;
                        name: string;
                        path: string;
                        objectPath: string;
                    },
                    formContext: ExtandedFormContext,
                ) => {
                    const value: string = formContext.getValue(input.path);
                    setRsfInclusTxremise(value);
                },
                onPaste: (event: React.ClipboardEvent) => {
                    event.clipboardData.getData('text').match(/\D/g) && event.preventDefault();
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                },
                rules: {
                    required: 'Taux de remise manquant',
                },
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
            },
        ];
    }, []);

    const factureFormStructure: FormStructure[] = React.useMemo(() => {
        return [
            {
                type : "hidden",
                name : "avoir",
                value : factureAchat?.avoir || false
            },
            ...getAcDossierStructure(
                () => setModalDossierOpen(true),
                dossiersDataSource,
                searchByNumeroDossier,
                searchByNumeroPresta,
                state.defaultNumeroDossier,
                state.defaultNumeroPresta,
            ),
            ...getProduitsStructure(
                formContext,
                state.rubFacts,                
                undefined,
                afficherBoutonTxRemise || state.switchLigneVente ? (<Box
                    sx={{
                        display: 'flex',
                        // width: '-webkit-fill-available',
                        marginRight: '-8px',
                        marginLeft: 'auto'
                    }}
                >
                    { state.switchLigneVente && isInterne ? (<FormControlLabel
                        sx={{
                            marginLeft: 'auto',
                        }}
                        label={state.switchLigneVente.label}
                        key={`formHeaderbtn-${state.switchLigneVente.label}`}
                        control={
                            <Switch
                                onChange={(
                                    event: React.ChangeEvent,
                                    checked: React.SetStateAction<boolean>
                                ) => {
                                    dispatch({
                                        type: ACTIONS.AFFICHER_LIGNE_VENTE,
                                        payload: checked,
                                    });
                                }}
                                checked={state.afficherLigneVente}
                                size="small"
                                aria-label="switch"
                                {...state.switchLigneVente}
                            />
                        }
                    />) : null}
                    { afficherBoutonTxRemise ? (<Button
                        onClick={() => {
                            actionOnChangeTauxRemise(
                                formContext,
                                () => {
                                    setModalRemiseOpen(true);
                                },
                                (message) => {
                                    enqueueSnackbar(<Typography>{message}</Typography>, {
                                        variant: 'error',
                                    });
                                },
                            );
                        }}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        sx={{ marginLeft: 'auto' }}
                    >
                        Remise intégrée
                    </Button>) : null}
                </Box>) : null,
                true,
                hasBlured,
                () => {
                    setModalRemiseOpen(true);
                },
                state.produitsVente,
                isInterne ? state.afficherLigneVente : false,
            ),
            ...totauxStructure(true, () => {}, undefined, false),
        ];
    }, [
        factureAchat?.avoir,
        actionOnChangeTauxRemise,
        afficherBoutonTxRemise,
        dossiersDataSource,
        enqueueSnackbar,
        formContext,
        getProduitsStructure,
        hasBlured,
        searchByNumeroDossier,
        searchByNumeroPresta,
        state.afficherLigneVente,
        state.defaultNumeroDossier,
        state.defaultNumeroPresta,
        state.produitsVente,
        state.rubFacts,
        state.switchLigneVente,
    ]);

    return !factureAchat || !fournisseurSelected ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <>
            <SearchDossierModal
                isModalOpen={modalDossierOpen}
                isDraggable={true}
                closeModal={(dossier: Dossier | null) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                    setModalDossierOpen(false);
                }}
            ></SearchDossierModal>
            <ConfirmationModal
                isModalOpen={modalRemoveProduitsOpen}
                closeModal={() => {
                    confirmationModalState.actionOnCancellation();
                }}
                messages={confirmationModalState.messages}
                actionOnValidation={() => {
                    confirmationModalState.actionOnValidation();
                    setModalRemoveProduitsOpen(false);
                }}
                actionOnCancellation={() => {
                    confirmationModalState.actionOnCancellation();
                }}
            />
            <Modalizer
                open={modalRemiseOpen}
                onClose={() => {
                    setModalRemiseOpen(false);
                }}
            >
                <Box>
                    <FlexyForm
                        formObject={{
                            'facture-form-modal-txremise': rsfInclusTxremise,
                        }}
                        formStructure={modalFactureFormStructure}
                        onSubmit={handleModalSubmit}
                        submitButton={{
                            render: ({ isSubmitting }, innerHandleSubmit: () => void) => (
                                <Box display="flex" flexDirection="row" justifyContent="end">
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        style={{ marginTop: '16px', marginBottom: '16px' }}
                                    >
                                        Appliquer la remise
                                    </Button>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Modalizer>

            {factureAchat.idEntiteFacturante === fournisseurSelected.id ? (
                <EnteteFacture
                    factureAchat={factureAchat}
                    fournisseur={fournisseurSelected}
                    showListeDossier={true}
                    dossiers={dossiers}
                />
            ) : null}

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitting }) => (
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button
                                color="error"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<PauseIcon />}
                                onClick={() => {
                                    formContext.handleSubmit(
                                        (factureForm) => {
                                            factureForm.pause = true;
                                            handleSubmit(factureForm as factureFormObject).then(
                                                () => {
                                                    enqueueSnackbar(
                                                        <Typography>
                                                            Le dossier a bien été enregistré et la
                                                            saisie mise en pause.
                                                        </Typography>,
                                                        { variant: 'success' },
                                                    );
                                                },
                                                (err) => {
                                                    console.error(err);
                                                    enqueueSnackbar(
                                                        <Typography>
                                                            Une erreur est survenue lors de
                                                            l'enregistrement du dossier.
                                                        </Typography>,
                                                        { variant: 'error' },
                                                    );
                                                },
                                            );
                                        },
                                        () => {
                                            setModalRemoveProduitsOpen(true);
                                            setConfirmationModalState({
                                                isModalOpen: modalRemoveProduitsOpen,
                                                messages: pauseSaisieFacture,
                                                actionOnValidation: () => {
                                                    navigateToMediaObjectsList();
                                                },
                                                actionOnCancellation: () => {
                                                    setModalRemoveProduitsOpen(false);
                                                },
                                            });
                                        },
                                    )();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Mettre en pause la saisie
                            </Button>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                endIcon={<PlaylistAddCheckIcon />}
                                onClick={() => {
                                    formContext.handleSubmit(
                                        (factureForm) => {
                                            factureForm.end = true;
                                            handleSubmit(factureForm as factureFormObject).then(
                                                () => {
                                                    enqueueSnackbar(
                                                        <Typography>
                                                            Le dossier a bien été enregistré.
                                                        </Typography>,
                                                        { variant: 'success' },
                                                    );
                                                },
                                                (err) => {
                                                    console.error(err);
                                                    enqueueSnackbar(
                                                        <Typography>
                                                            Une erreur est survenue lors de
                                                            l'enregistrement du dossier.
                                                        </Typography>,
                                                        { variant: 'error' },
                                                    );
                                                },
                                            );
                                        },
                                        () => {
                                            setModalRemoveProduitsOpen(true);
                                            setConfirmationModalState({
                                                isModalOpen: modalRemoveProduitsOpen,
                                                messages: cancelAddDossier,
                                                actionOnValidation: () => {
                                                    navigateToFactureAchatRecap(
                                                        factureAchat,
                                                        mediaObject,
                                                    );
                                                },
                                                actionOnCancellation: () => {
                                                    setModalRemoveProduitsOpen(false);
                                                },
                                            });
                                        },
                                    )();
                                }}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Consulter le récapitulatif
                            </Button>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                type="submit"
                                variant="outlined"
                                endIcon={<ArrowRightAltIcon />}
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                Dossier suivant
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};

export default DossierSaisieMultiple;
