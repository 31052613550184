import { customizerSelector, factureAchatApi, factureAchatLigneApi } from '@b2d/redux/RootStore';
import { FactureAchat, FactureAchatApi, FactureAchatLigneApi } from '@europrocurement/l2d-domain';
import { FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { factureFormObject, tableauWrite } from '../types';
import { formProduitsToTableauLigneWrite } from './dataTransformers';
import useToaster from '@b2d/hooks/useToaster';

export const useGetDossiers = function () {
    // const [dossiersStatus, setdossiersStatus] = useState<EuroprocApiResponseStatus>('idle');
    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;

    return async (idFacture: number, factureAchatApi: FactureAchatApi) => {
        // if(dossiersStatus === 'idle'){
        // setdossiersStatus('loading');
        const dossiersRes = await factureAchatApi.getDossiersFactureAchatItem(
            `${idFacture}`,
            xIdSociete,
        );
        if (dossiersRes.data.dossiers) {
            // setdossiersStatus('succeeded');
            return dossiersRes.data.dossiers;
        } else {
            // setdossiersStatus('failed');
        }
        // }
        return [];
    };
};

export const useGetLignes = function () {
    // const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');

    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;
    // console.log('rerenderhook lignes');

    return async (
        idFacture: number,
        idsDossiers: number[],
        factureAchatLigneApi: FactureAchatLigneApi,
    ) => {
        // if(lignesStatus === 'idle'){
        // setLignesStatus('loading');
        // console.log('select lignes ', idsDossiers);
        const lignesRes =
            await factureAchatLigneApi.getLignesFactureAchatFactureAchatLigneCollection(
                xIdSociete,
                1,
                1000,
                idFacture,
                undefined,
                undefined,
                idsDossiers,
            );
        if (lignesRes.data) {
            // setLignesStatus('succeeded');
            return lignesRes.data['hydra:member'];
        } else {
            // setLignesStatus('failed');
        }
        // }
        return [];
    };
};

export const useUpdateLigne = function () {
    const deleteLigne = useDeleteLigne();
    const addLigne = usePostLigne();

    return async (
        ligneIdToDelete: number,
        ligneToAdd: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
        factureAchatLigneApi: FactureAchatLigneApi,
    ) => {
        const deleteResponse =
            await deleteLigne(ligneIdToDelete.toString(), factureAchatLigneApi);

        if (deleteResponse) {
            const addResponse =
                await addLigne(ligneToAdd, factureAchatLigneApi);

            if (addResponse) {
                return true;
            }
        }

        return false;
    }
}

export const useDeleteLigne = function () {
    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;

    return async (
        idLigne: string,
        factureAchatLigneApi: FactureAchatLigneApi,
    ): Promise<boolean> => {
        const lignesRes =
            await factureAchatLigneApi.deleteFactureAchatLigneFactureAchatLigneItem(
                idLigne,
                xIdSociete,
            )

        if (lignesRes.status === 204) {
            return true;
        }

        return false;
    }
}

export const usePostLigne = function () {
    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;

    return async (
        ligne: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate,
        factureAchatLigneApi: FactureAchatLigneApi,
    ): Promise<boolean> => {
        const lignesRes =
            await factureAchatLigneApi.createFactureAchatLigneFactureAchatLigneCollection(
                ligne,
                xIdSociete,
            )

        if (lignesRes.status === 201) {
            return true
        }

        return false;
    }
}

export const useGetRejets = function () {
    // const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');

    const xIdSociete: number = useSelector(customizerSelector).xIdSociete;

    console.log('rerenderhook lignes');

    return async (idFacture: number, idsDossiers: number[], factureAchatApi: FactureAchatApi) => {
        // if(rejetsStatus === 'idle'){
        // setRejetsStatus('loading');
        console.log('select rejets');
        const rejetsRes =
            await factureAchatApi.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(
                xIdSociete, // number | undefined,
                1, // page?: number | undefined,
                1000, // itemsPerPage?: number | undefined,
                idFacture, // factureId?: number | undefined,
                undefined, // factureId2?: number[] | undefined,
                undefined, // idDossier?: number | undefined,
                idsDossiers, // idDossier2?: number[] | undefined,
                undefined, // options?: AxiosRequestConfig<any> | undefined
            );
        if (rejetsRes.data) {
            const apirejets = rejetsRes.data['hydra:member'];
            // setRejetsStatus('succeeded');
            return apirejets;
        } else {
            // setRejetsStatus('failed');
        }
        // }
        return [];
    };
};

/**
 * Synchronise la facture avec G3.
 * 
 * @param FactureAchat factureAchat
 * @returns Promise<boolean>
 */
export const useSynchroG3Facture = function () {

    const { handleApiResponse } = useToaster();

    return async function (
        factureAchatId: string,
    ): Promise<boolean> {

        const syncG3 = factureAchatApi.syncG3FactureAchatFactureAchatItem(
            `${factureAchatId}`,
            { syncG3: true },
        );

        await handleApiResponse(
            syncG3,
            undefined,
            false
        );

        return Promise.resolve(true);
    }
};

/**
 * Prépare le formulaire pour la mise à jour des lignes de la facture d'achat.
 * 
 * @param factureForm 
 * @param currentFacture 
 * @param hasSetRsf 
 * @param dossier 
 * @returns 
 */
export const useUpdateDossierOfMultiAnnonce = function () {

    const getLignes = useGetLignes();
    const deleteLigne = useDeleteLigne();
    const addLigne = usePostLigne();

    return async function (
        factureForm: factureFormObject,
        currentFacture: FactureAchat,
        currentDossierId: number,
    ): Promise<boolean> {

        let currentLignes: Array<FactureAchatLigneJsonldFactureAchatRead> = [];
        const tableau: tableauWrite = [];

        if (!currentFacture.id || !currentFacture['@id']) {
            console.error('id et/ou @id de la facture courante manquant(s) !');
            return Promise.resolve(false);
        }

        const newProduits = formProduitsToTableauLigneWrite(
            factureForm,
            currentFacture['@id']
        )

        currentLignes = await getLignes(
            currentFacture.id,
            [currentDossierId],
            factureAchatLigneApi
        );

        if (currentLignes.length === 0) {
            console.error('Pas de lignes pour ce dossier !');
            return Promise.resolve(false);
        }

        let deletePromises = currentLignes.map((ligne) => {
            if (!ligne.id) {
                console.error('id de ligne manquant !');
                return null;
            }

            return deleteLigne(
                ligne.id.toString(),
                factureAchatLigneApi
            )
        })

        let productsPromises = newProduits.map((newLigne) => {
            return addLigne(
                newLigne,
                factureAchatLigneApi
            )
        })

        return Promise.all([...deletePromises, ...productsPromises]).then((res) => {
            console.log("ok", res);
            return Promise.resolve(true);
        }).catch((res) => {
            console.log("pas ok", res);
            return Promise.resolve(false);
        })

    };


};