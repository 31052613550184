import React, { useState, useCallback } from 'react';
import { type SxProps, Pagination, Box, IconButton, Tooltip } from '@mui/material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ZoomIn, ZoomOut, RotateLeft, RotateRight, OpenInBrowser } from '@mui/icons-material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ScrollBar from 'react-perfect-scrollbar';

type IconSizeProps = 'small' | 'medium' | 'large';

export type PdfViewerProps = {
    pdfUrl?: string | null;
    defaultPage?: number;
    iconSize?: IconSizeProps;
    sx?: SxProps;
};

type PDFDocumentProxy = {
    numPages: number;
};

const PdfViewer: React.FunctionComponent<PdfViewerProps> = function ({
    pdfUrl,
    defaultPage = 1,
    iconSize = 'medium',
    sx,
}: PdfViewerProps) {
    const [pages, setPages] = useState<number>(0);
    const [page, setPage] = useState<number>(defaultPage);
    const [scale, setScale] = useState<number>(1);
    const [rotate, setRotate] = useState<number>(0);
    const [pdfWidth, setPdfWidth] = useState<number>(0);

    const divRef: React.RefCallback<HTMLElement> = useCallback((node) => {
        if (node !== null) {
            setPdfWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setPages(numPages);
    };

    const onDocumentError = (err: Error) => {
        console.error('pdf viewer error:', err);
    };

    const onSetScale = (type: number) => {
        let newScale = type ? scale + 0.1 : scale - 0.1;

        if (newScale > 5) {
            newScale = 5;
        } else if (newScale < 0.1) {
            newScale = 0.1;
        }

        setScale(newScale);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <Box
            role="application"
            sx={{
                zIndex: '1',
                width: '100%',
                height: 'calc(100%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...sx,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        height: '24px',
                        width: '100%',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'flex-end',
                    }}
                >
                    <Tooltip title="Ouvrir le pdf dans un onglet">
                        <IconButton
                            data-testid="download"
                            size={iconSize}
                            color="secondary"
                            aria-label="download"
                            onClick={() => {
                                if (pdfUrl) {
                                    const link = document.createElement('a');
                                    link.href = pdfUrl;
                                    link.target = '_blank';
                                    link.type = 'application/octet-stream';
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }
                            }}
                        >
                            <OpenInBrowser />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Augmenter le zoom">
                        <IconButton
                            data-testid="zoomIn"
                            size={iconSize}
                            color="secondary"
                            aria-label="zoom in"
                            onClick={() => {
                                onSetScale(1);
                            }}
                        >
                            <ZoomIn />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Réduire le zoom">
                        <IconButton
                            data-testid="zoomOut"
                            size={iconSize}
                            color="secondary"
                            aria-label="zoom out"
                            onClick={() => {
                                onSetScale(0);
                            }}
                        >
                            <ZoomOut />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Rotation vers la gauche">
                        <IconButton
                            data-testid="rotationLeft"
                            size={iconSize}
                            color="secondary"
                            aria-label="rotate left"
                            onClick={() => {
                                setRotate((((rotate - 90) % 360) + 360) % 360);
                            }}
                        >
                            <RotateLeft />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Rotation vers la droite">
                        <IconButton
                            data-testid="rotationRight"
                            size={iconSize}
                            color="secondary"
                            aria-label="rotate right"
                            onClick={() => {
                                setRotate((rotate + 90) % 360);
                            }}
                        >
                            <RotateRight />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box
                    ref={divRef}
                    sx={{
                        width: 'calc(100%)',
                        overflow: 'hidden',
                    }}
                >
                    <ScrollBar>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentError}
                        >
                            <Page
                                pageNumber={page}
                                scale={scale}
                                width={pdfWidth}
                                rotate={rotate}
                            />
                        </Document>
                    </ScrollBar>
                </Box>
            </Box>
            <Box>
                {pages > 1 ? (
                    <Pagination
                        sx={{
                            marginTop: '10px',
                        }}
                        data-testid="pagination"
                        size={iconSize}
                        color="secondary"
                        count={pages}
                        page={page}
                        onChange={handleChange}
                        showFirstButton
                        showLastButton
                    />
                ) : null}
            </Box>
        </Box>
    );
};

PdfViewer.defaultProps = {
    sx: undefined,
    defaultPage: 1,
    iconSize: 'small',
};

export default PdfViewer;
