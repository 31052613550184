import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, type FabProps, Tooltip } from '@mui/material';

export type AddButtonProps = {
    tooltip?: string;
    size?: FabProps['size'];
    color?: FabProps['color'];
    onClick?: FabProps['onClick'];
    disabled?: FabProps['disabled'];
};

const AddButton: React.FunctionComponent<AddButtonProps> = function ({
    tooltip,
    ...props
}: AddButtonProps) {
    return (
        <Tooltip title={tooltip}>
            <Fab {...props}>
                <AddIcon />
            </Fab>
        </Tooltip>
    );
};

AddButton.defaultProps = {
    size: 'medium',
    color: 'primary',
    tooltip: 'Ajouter',
    onClick: undefined,
    disabled: false,
};

export default AddButton;
