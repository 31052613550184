import { useSnackbar } from 'notistack';
import { generateB2DPathResponseType } from '../utils/generateB2DPath';

const useToaster = () => {
    const { enqueueSnackbar } = useSnackbar();

    const handleApiResponse = async (asyncCall: any, callBack: () => void = () => { }, toast: boolean = true) => {
        return asyncCall
            .then((res: any) => {
                callBack();
                toast ? enqueueSnackbar('Modification effectuée avec succès') : null;
            })
            .catch((error: any) => {
                callBack();
                toast ? enqueueSnackbar('Une erreur est survenue', { variant: 'error' }) : null;
            });
    };

    const handleRouterResponse = (
        routerResponse: generateB2DPathResponseType,
        callBack: () => void,
        debug?: true,
    ) => {
        if (routerResponse.status === 'OK') {
            callBack();
        } else if (routerResponse.status === 'KO') {
            if (debug) console.log(`Page inaccessible car : ${routerResponse.message}`);
            enqueueSnackbar("Impossible d'accéder à cette page.", { variant: 'error' });
        }
    };

    return { handleApiResponse, handleRouterResponse } as const;
};

export default useToaster;
