import * as React from 'react';
import { FlexyAccordeon, FlexySelect } from '@europrocurement/flexy-components';
import { Prescripteur } from '@europrocurement/l2d-domain';
import {
    CategorieSocioProfessionnelleJsonldTiersRead,
    PortefeuilleJsonldTiersRead,
    RelationSocieteTiersJsonldTiersRead,
    UtilisateurJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { Box, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    OptionsSelector,
    customizerSelector,
    prescripteursApi as prescriberApi,
} from '@b2d/redux/RootStore';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { getPrescripteurCompanyRelationPayload } from '../../forms/payloads';

type AccordionsSideBarProps = {
    prescriber: Prescripteur;
    companyRelation: RelationSocieteTiersJsonldTiersRead;
};

type UpdatableGroup = PortefeuilleJsonldTiersRead | CategorieSocioProfessionnelleJsonldTiersRead;

const AccordionsSideBar: React.FC<AccordionsSideBarProps> = (props) => {
    const { prescriber, companyRelation } = props;

    const { request, requestState } = useApiRequest();
    const { xIdSociete } = useSelector(customizerSelector);

    const optionsStore = useSelector(OptionsSelector);
    const { walletOptions, cspOptions } = optionsStore;

    if (!companyRelation) {
        return <CircularProgress />;
    }

    const defaultCspOption = optionsStore.cspOptions.find(({ id }: { id: number }) => id === 0);
    const defaultWalletOption = optionsStore.walletOptions.find(
        ({ id }: { id: number }) => id === 1,
    );

    const formatUserName = (utilisateur: UtilisateurJsonldTiersRead | undefined): string => {
        if (!utilisateur) {
            return '';
        }
        return `${utilisateur?.prenom} ${utilisateur?.nom}`;
    };

    const handleGroupUpdate = (group: UpdatableGroup) => {
        if (!group || !group['@type'] || !prescriber.id) {
            return;
        }

        const lowerCaseFirstLetter = (string: string) => {
            const firstLetter = string.charAt(0);
            return string.replace(firstLetter, firstLetter.toLowerCase());
        };

        const groupKey = lowerCaseFirstLetter(group['@type']);
        const basePayload = getPrescripteurCompanyRelationPayload(companyRelation);

        const payload = {
            ...basePayload,
            [groupKey]: { id: group.id },
        };
        const groupUpdateRequest =
            prescriberApi.updateRelationSocietePrescripteurRelationSocieteTiersItem(
                prescriber.id,
                xIdSociete.toString(),
                payload,
            );

        request(groupUpdateRequest, {});
    };

    const groupSelectProps = {
        getOptionLabel: ({ libelle }: { libelle: string }) => libelle,
        getOptionValue: ({ id }: { id: string }) => id,
        onChange: handleGroupUpdate,
    };

    const getPrescriberWalletOption = () => {
        const walletId = companyRelation?.portefeuille?.id;
        if (!walletId) {
            return defaultWalletOption;
        }
        return walletOptions.find(({ id }) => id === walletId);
    };

    const getPrescriberCspOptions = () => {
        const cspId = companyRelation?.categorieSocioProfessionnelle?.id;
        if (!cspId) {
            return defaultCspOption;
        }
        return cspOptions.find(({ id }) => id === cspId);
    };

    const accordions = [
        {
            title: 'Informations générales',
            content: (
                <>
                    <Box marginBottom={2}>
                        <Typography marginBottom={2} fontWeight={'bold'}>
                            {'Portefeuille :'}
                        </Typography>
                        <FlexySelect
                            options={walletOptions}
                            {...groupSelectProps}
                            value={getPrescriberWalletOption()}
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Typography marginBottom={2} fontWeight={'bold'}>
                            {'Categorie socioprofessionnelle :'}
                        </Typography>
                        <FlexySelect
                            options={cspOptions}
                            value={getPrescriberCspOptions()}
                            {...groupSelectProps}
                        />
                    </Box>
                </>
            ),
            defaultExpanded: true,
        },
        {
            title: 'Annonces Légales',
            fakeContent: true,
            content: (
                <Typography>
                    <b>Commercial : </b> <br />
                </Typography>
            ),
        },
        {
            title: 'Formalités',
            content: (
                <>
                    <Typography marginBottom={2}>
                        <b>Commercial : </b> <br />
                    </Typography>
                    <Typography>
                        <b>Formaliste : </b> <br />
                        {formatUserName(prescriber.formaliste)}
                    </Typography>
                </>
            ),
        },
        {
            title: 'Commerce',
            fakeContent: true,
            content: (
                <Typography>
                    <b>Commercial : </b> <br />
                </Typography>
            ),
        },
        {
            title: 'Comptabilité',
            fakeContent: true,
            content: (
                <>
                    <Typography marginBottom={2}>
                        <b>encours : </b> <br /> #TODO
                    </Typography>
                    <Typography>
                        <b>
                            {' '}
                            délai de paiement : <br />{' '}
                        </b>{' '}
                        #TODO
                    </Typography>
                </>
            ),
        },
    ];

    return (
        <>
            {accordions.map(({ title, fakeContent, content, defaultExpanded }, index) => {
                const randomInt = (min: number, max: number) =>
                    Math.floor(Math.random() * (max - min) + min);

                const fakeUi = (
                    <Box>
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width="100%"
                            sx={{ margin: '10px 0px' }}
                        ></Skeleton>
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width="100%"
                            sx={{ margin: '10px 0px' }}
                        ></Skeleton>
                        <Skeleton
                            animation={false}
                            variant="rectangular"
                            width={`${randomInt(60, 100)}%`}
                            sx={{ margin: '10px 0px' }}
                        ></Skeleton>
                    </Box>
                );

                const accordionContent = fakeContent ? fakeUi : content;
                return (
                    <Grid key={`${index}_${title}`} item>
                        <FlexyAccordeon
                            key={`${title}_${index}`}
                            defaultExpanded={defaultExpanded ?? false}
                            accordionSummary={
                                <Typography fontSize="1.125rem" fontWeight="bold">
                                    {title}
                                </Typography>
                            }
                            accordionDetails={accordionContent}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export default AccordionsSideBar;
