/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    ActionApi,
    MediaObjectApi,
    CodeRejetApi,
    MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup,
} from '../../../openApi/ApiAchats';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { ACHATS_REDUCER_NAME } from '../achatsReducer';
// import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

export { type AchatsConfigurationParameters };

export type MediaObjectApiObject = MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup &
    Record<string, unknown>;

export type MediaObject = MediaObjectApiObject;

export const MEDIAOBJECT_SLICE_NAME = 'mediaobject';

const mapperMediaObject = function (mediaobject: MediaObjectApiObject) {
    return {
        ...mediaobject,
    } as MediaObject;
};

const dateOffset = 2 * 60 * 1000;

export function createMediaObjectSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new MediaObjectApi(conf);

    const fetchMediaObjectsCollection: FetchCollectionData<MediaObjectApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let createdBy,
            startDate,
            endDate: string | undefined = undefined;
        let createdByMe,
            linkedFacture: boolean | undefined = undefined;

        if (filters.createdBy) {
            createdBy = filters.createdBy as string;
        }

        if (filters.linkedFacture !== undefined) {
            linkedFacture = filters.linkedFacture as boolean;
        }

        if (filters.processingFacture === false) {
            startDate = new Date(new Date().getTime() - dateOffset).toISOString();
        } else if (filters.processingFacture === true) {
            endDate = new Date(new Date().getTime() - dateOffset).toISOString();

            startDate = new Date(new Date().getTime() + dateOffset).toISOString();
        }

        // processingFacture
        if (filters.createdByMe === true) {
            return api
                .listMyMediaObjectMediaObjectCollection(
                    pagination.page + 1, // page?: number | undefined,
                    pagination.itemsPerPage, // itemsPerPage?: number | undefined,
                    undefined, // createdBy?: string | undefined,
                    undefined, // createdBy2?: string[] | undefined,
                    linkedFacture, // existsFacturesAchat?: boolean | undefined,
                    startDate, // dateDebutTraitementBefore?: string | undefined,
                    undefined, // dateDebutTraitementStrictlyBefore?: string | undefined, dateDebutTraitementAfter?: string | undefined,
                    endDate, // dateDebutTraitementAfter?: string | undefined,
                    undefined, // dateDebutTraitementStrictlyAfter?: string | undefined,
                    undefined, // options?: AxiosRequestConfig<...> | undefined
                )
                .then((res) => res.data) as Promise<ApiCollectionResponse<MediaObjectApiObject>>;
        }

        return api
            .listMediaObjectMediaObjectCollection(
                pagination.page + 1, // page?: number | undefined,
                pagination.itemsPerPage, // itemsPerPage?: number | undefined,
                createdBy, // createdBy?: string | undefined,
                undefined, // createdBy2?: string[] | undefined,
                linkedFacture, // existsFacturesAchat?: boolean | undefined,
                startDate, // dateDebutTraitementBefore?: string | undefined,
                undefined, // dateDebutTraitementStrictlyBefore?: string | undefined, dateDebutTraitementAfter?: string | undefined,
                endDate, // dateDebutTraitementAfter?: string | undefined,
                undefined, // dateDebutTraitementStrictlyAfter?: string | undefined,
                undefined, // options?: AxiosRequestConfig<...> | undefined
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<MediaObjectApiObject>>;
    };

    const fetchMediaObjectsItem: FetchItemDataType<MediaObjectApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getMediaObjectItem(String(idItem))
            .then((res) => res.data) as Promise<MediaObjectApiObject>;

    const { slice: mediaobjectSlice, dataSourcesThunks: mediaobjectDataSourcesThunks } =
        SliceFactory.createSlice<MediaObjectApiObject, MediaObject>(
            MEDIAOBJECT_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            [],
            fetchMediaObjectsCollection,
            fetchMediaObjectsItem,
            mapperMediaObject,
            {
                linkedFacture: false,
                processingFacture: false,
            },
        );

    return { mediaobjectSlice, mediaobjectDataSourcesThunks };
}
