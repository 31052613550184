import { ContactJsonldTiersReadCiviliteEnum } from '@europrocurement/l2d-domain/openApi/ApiTiers';

/** Generic form options */
type Civilite = {
    id: ContactJsonldTiersReadCiviliteEnum;
    libelle: string;
    abreviation: string;
};

const civilites: Civilite[] = [
    {
        id: 102,
        libelle: 'Monsieur',
        abreviation: 'M.',
    },
    {
        id: 103,
        libelle: 'Madame',
        abreviation: 'Mme',
    },
    {
        id: 3509,
        libelle: 'Maître',
        abreviation: 'Me',
    },
    {
        id: 4520,
        libelle: 'Messieurs',
        abreviation: 'MM.',
    },
    {
        id: 4521,
        libelle: 'Mesdames',
        abreviation: 'Mmes',
    },
    {
        id: 4522,
        libelle: 'Maîtres',
        abreviation: 'Mes',
    },
];

export const civilitiesOptions = civilites.map((civilite) => ({
    value: civilite.id,
    label: civilite.libelle,
}));

/**Standards responsive rules */
export const responsiveBehaviours = {
    primary: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
    },
    secondary: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    },
};

export const isMobilePhoneNumber = (phoneNumber: string) => {
    return phoneNumber.startsWith('06') || phoneNumber.startsWith('07');
};
