import React, { FocusEventHandler, useCallback } from 'react';
import {
    Checkbox,
    Radio,
    Tooltip,
    Typography,
    Box,
    type RadioProps,
    type CheckboxProps,
} from '@mui/material';

import Switch, { type SwitchProps } from '../../atoms/Switch';
import FlexyDate, { FlexyDateProps } from '../../atoms/FlexyDate';
import FlexySelect, { FlexySelectProps } from '../../atoms/FlexySelect';
import FlexyFormLabel from '../../atoms/FlexyFormLabel';
import FlexyTextField, { FlexyTextFieldProps } from '../../atoms/FlexyTextField';

export type FlexyInputType =
    | 'boolean'
    | 'radio'
    | 'range'
    | 'checkbox'
    | 'date'
    | 'datetime'
    | 'time'
    | 'mobiledate'
    | 'mobiledatetime'
    | 'mobiletime'
    | 'select'
    | 'email'
    | 'text'
    | 'number'
    | 'textarea'
    | 'password'
    | 'hidden';

type FieldGroup = FlexyInputType[];

export const textFieldGroup: FieldGroup = ['email', 'text', 'number', 'textarea'];

export const isInFieldGroup = (type: FlexyInputType, fieldGroup: FieldGroup): boolean =>
    fieldGroup.includes(type);

export type RenderFieldInputType =
    | SwitchProps
    | RadioProps
    | CheckboxProps
    | FlexyDateProps
    | FlexySelectProps
    | FlexyTextFieldProps;

export type FlexyInputProps<T = RenderFieldInputType> = T & {
    inputlabel?: string;
    type: FlexyInputType;
    name: string;
    required?: boolean;
    verticalSwitch?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
};

/**
 * Ce composant gère la création de tout composants input en fonction de son type.
 * la props 'type' peut prendre les valeurs : 'boolean' 'radio' 'range' 'checkbox' 'date' 'select' 'datetime' 'time' 'mobiledate' 'mobiledatetime' 'mobiletime'.
 * Ansi que tout les types acceptable dans [la norme HTML5](https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input).
 *
 * Par défaut : 'text'.
 *
 * inputlabel : Valeur affiché dans le label.
 *
 * name: necessaire pour identifier le champs et son label.
 *
 * ## Utilisation
 *
 * ```jsx
 * <FlexyInput
 *      type={'text'},
 *      variant={'outlined'},
 *      fullWidth={true}
 *     size={'small'},
 *  />
 * ```
 */
const FlexyInput: React.FunctionComponent<FlexyInputProps<RenderFieldInputType>> = function ({
    verticalSwitch,
    ...props
}: FlexyInputProps<RenderFieldInputType>) {
    const {
        inputlabel,
        type,
        name,
        required,
        inputRef,
        onBlur,
    }: FlexyInputProps<RenderFieldInputType> = props;

    const renderField = useCallback(() => {
        const textFieldProps: FlexyTextFieldProps = { ...props } as FlexyTextFieldProps;
        const numericFieldProps: FlexyTextFieldProps = { ...props } as FlexyTextFieldProps;
        // numericFieldProps.type = 'text';
        const switchProps: SwitchProps = { ...props } as SwitchProps;
        const radioProps: RadioProps = { ...props } as RadioProps;
        const checkboxProps: CheckboxProps = { ...props } as CheckboxProps;
        const flexyDateProps: FlexyDateProps = { ...props } as FlexyDateProps;
        const flexySelectProps: FlexySelectProps = { ...props } as FlexySelectProps;

        switch (type) {
            case 'boolean':
                return (
                    <Switch
                        inputRef={inputRef}
                        {...switchProps}
                        type="checkbox"
                        data-testid="Switch"
                    />
                );
            case 'radio':
                return <Radio inputRef={inputRef} {...radioProps} data-testid="Radio" />;
            case 'checkbox':
                return <Checkbox inputRef={inputRef} {...checkboxProps} data-testid="Checkbox" />;
            case 'date':
            case 'datetime':
            case 'time':
            case 'mobiledate':
            case 'mobiledatetime':
            case 'mobiletime':
                return <FlexyDate inputRef={inputRef} {...flexyDateProps} />;
            case 'select':
                return <FlexySelect {...flexySelectProps} />;
            case 'number':
                numericFieldProps.onBlur = onBlur;
                // The following onBlur event doesn't work
                // return <FlexyTextField
                //     name={name}
                //     inputRef={inputRef}
                //     onBlur={handleBlur}
                //     {...numericFieldProps}
                // />;
                // The following onBlur event works
                return <FlexyTextField name={name} inputRef={inputRef} {...numericFieldProps} />;
            case 'hidden':
                return (
                    <FlexyTextField
                        name={name}
                        inputRef={inputRef}
                        {...textFieldProps}
                        type="text"
                        sx={{ display: 'none' }}
                    />
                );
            case 'text':
            case 'email':
            case 'password':
            default:
                return <FlexyTextField name={name} inputRef={inputRef} {...textFieldProps} />;
        }
    }, [props, type, inputRef, name]);

    return type === 'boolean' && (verticalSwitch === undefined || verticalSwitch === false) ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
            }}
        >
            <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                <Typography component="span">
                    {inputlabel}
                    {required && (
                        <Tooltip title="Le champ est requis">
                            <Typography component="span" color="danger.main">
                                &nbsp;&nbsp;*
                            </Typography>
                        </Tooltip>
                    )}
                </Typography>
            </FlexyFormLabel>
            {renderField()}
        </Box>
    ) : (
        <>
            {type !== 'hidden' && (
                <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                    <Typography component="span">
                        {inputlabel}
                        {required && (
                            <Tooltip title="Le champ est requis">
                                <Typography component="span" color="danger.main">
                                    &nbsp;&nbsp;*
                                </Typography>
                            </Tooltip>
                        )}
                    </Typography>
                </FlexyFormLabel>
            )}
            {renderField()}
        </>
    );
};

FlexyInput.defaultProps = {
    inputlabel: undefined,
    required: false,
    inputRef: undefined,
};

export default FlexyInput;
