import React from 'react';

import { AxiosResponse, AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';

const useApiRequest = () => {
    type AdditionalParameters = {
        withToaster?: boolean;
        successCallback?: (result: any) => void;
    };

    type RequestState = {
        error: boolean;
        success: boolean;
        isProcessing: boolean;
    };

    const initialState = {
        error: false,
        success: false,
        response: null,
        isProcessing: false,
    };

    const [requestState, _setRequestState] = React.useState<RequestState>(initialState);

    const { enqueueSnackbar } = useSnackbar();

    const setRequestState = (key: string, value: any) =>
        _setRequestState({
            ...requestState,
            [key]: value,
        });

    const defaultAdditionalParameters = {
        withToaster: true,
    };

    const DEFAULT_SUCCESS_MESSAGE = 'effectuée avec succès';
    const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue';

    const successStatuses = [200, 201];

    const getDefaultSuccessMessage = (statusCode: number) => {
        let prefix = '';
        switch (statusCode) {
            case 200:
                prefix = 'Mise à jour';
                break;
            case 201:
                prefix = 'Création';
                break;
        }

        return prefix + ' ' + DEFAULT_SUCCESS_MESSAGE;
    };

    const request = async (request: AxiosPromise, additionalParams: AdditionalParameters) => {
        const parameters = { ...defaultAdditionalParameters, ...additionalParams };

        setRequestState('isProcessing', true);

        const response = await request
            .then((result) => {
                if (successStatuses.includes(result.status)) {
                    handleSuccess(result, parameters);
                } else {
                    console.error('CODE RESPONSE NOTE SUPPORTED');
                }
            })
            .catch((error) => {
                setRequestState('error', true);
                handleFailure(error, parameters);
            });

        setRequestState('isProcessing', false);

        return response;
    };

    const handleSuccess = (result: AxiosResponse<any, any>, parameters: any) => {
        const { withToaster, successCallback } = parameters;

        setRequestState('success', true);

        if (successCallback) {
            successCallback(result);
        }

        if (withToaster) {
            enqueueSnackbar(getDefaultSuccessMessage(result.status));
        }
    };

    const handleFailure = (error: any, parameters: any) => {
        const { withToaster } = parameters;

        setRequestState('error', true);

        if (withToaster) {
            const validationErrors = error?.response?.data?.exception?.violations;
            if (validationErrors) {
                handleApiValidationErrorMessages(validationErrors);
            } else {
                defaultErrorFeedback();
            }
        }
    };

    const handleApiValidationErrorMessages = (validationErrors: any[]) => {
        validationErrors.forEach(({ message, property }: { message: string; property: string }) => {
            const [propertyName] = property.split('.').slice(-1);
            const messageContent = `${propertyName ? propertyName + ' :' : ''}${message}`;
            enqueueSnackbar(messageContent, { variant: 'error' });
        });
    };

    const defaultErrorFeedback = () => {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
    };

    return { request, requestState };
};

export default useApiRequest;
