import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    SxProps,
    Typography,
} from '@mui/material';
import { ColorsPaletteVariant } from '../../../assets';

export type DataCardProps = {
    title: string;
    children: React.ReactNode;
    details?: React.ReactNode;
    icon?: React.ReactNode;
    color?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    sx?: SxProps;
    tools?: React.ReactNode;
};

const DataCard: React.FunctionComponent<DataCardProps> = function ({
    title,
    children,
    details,
    icon,
    color,
    onClick,
    sx,
    tools,
    ...props
}) {
    const colorPrincipal = color ? `${color}.main` : 'background.paper';
    const colorSecondary = color ? `${color}.contrastText` : 'text.primary';

    return (
        <Card
            role="contentinfo"
            sx={{
                backgroundColor: 'background.paper',
                color: 'text.primary',
                height: 'auto',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: colorPrincipal,
                ...sx,
            }}
            {...props}
        >
            <CardHeader
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Typography variant="h5" fontWeight="bold">
                            {title}
                        </Typography>
                        {icon ? (
                            <Typography
                                width="48px"
                                height="48px"
                                borderRadius="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    backgroundColor: colorPrincipal,
                                    color: 'background.paper',
                                }}
                            >
                                {icon}
                            </Typography>
                        ) : null}
                    </Box>
                }
            />

            <CardContent>
                <Typography variant="h3">{children}</Typography>
                <Typography variant="h6" sx={{ opacity: '0.6' }}>
                    {details}
                </Typography>
            </CardContent>
            {tools ? (
                <CardActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {tools}
                </CardActions>
            ) : null}
        </Card>
    );
};

export default DataCard;
