import * as React from 'react';
import { Alert, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import { EMAIL_VALIDATION_REGEX } from '@b2d/validation/regex';

const AddEmail = (props: any) => {
    const { emails, updateEmails } = props;

    const [newEmail, setNewEmail] = React.useState<string>('');
    const [feedBackError, setFeedbackError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (feedBackError) {
            setTimeout(() => setFeedbackError(null), 5000);
        }
    }, [feedBackError]);
    const emailAlreadyExist = () => {
        const emailValues = emails.map((emailItem: any) => emailItem.email);

        return emailValues.includes(newEmail);
    };

    const emailIsInvalid = () => {
        return EMAIL_VALIDATION_REGEX.test(newEmail) === false;
    };

    const validateEmail = () => {
        if (emailAlreadyExist()) {
            setFeedbackError('L`email est déja présent dans la liste');
            return false;
        }

        if (emailIsInvalid()) {
            setFeedbackError('L`email est invalide');
            return false;
        }

        setFeedbackError(null);
        return true;
    };

    const addNewEmail = (newEmail: string) => {
        setNewEmail(newEmail);

        // dev feature : todo remove
        if (newEmail.includes(', ')) {
            addEmails();
            return;
        }

        const isEmailValid = validateEmail();

        if (isEmailValid) {
            addEmail(newEmail);
            setNewEmail('');
        }
    };

    const addEmail = (newEmail: string) => {
        updateEmails([
            ...emails,
            {
                email: newEmail,
                notification: true,
                rappel: true,
            },
        ]);
    };

    // dev feature : todo remove
    const addEmails = () => {
        const newEmails = newEmail.split(', ');

        const newEmailsObjects = newEmails.map((value) => ({
            email: value,
            notification: true,
            rappel: true,
        }));

        updateEmails([...emails, ...newEmailsObjects]);
    };

    return (
        <>
            <FlexyHeaderForm label={'Ajouter email'} outlined />
            <Box display={'flex'} marginTop={'30px'}>
                <FlexyInput
                    data-testid="email"
                    variant="outlined"
                    type="text"
                    size="small"
                    fullWidth
                    value={newEmail}
                    name="email"
                    placeholder="example@domain.com"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNewEmail(event.target.value)
                    }
                />
                <Button
                    onClick={() => addNewEmail(newEmail)}
                    variant="contained"
                    sx={{ marginLeft: '25px' }}
                    disabled={newEmail === ''}
                >
                    <AddIcon />
                </Button>
            </Box>
            <Alert
                severity="warning"
                sx={{
                    marginTop: '20px',
                    backgroundColor: 'rgb(240, 220, 220)',
                    visibility: `${!feedBackError ? 'hidden' : ''}`,
                    opacity: `${feedBackError ? '1' : '0'}`,
                    transition: `all 250ms linear ${feedBackError ? '0.5s' : ''}`,
                }}
            >
                {feedBackError}
            </Alert>
        </>
    );
};

export default AddEmail;
