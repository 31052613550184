import * as React from 'react';
import { useDebounce } from 'use-debounce';
import { FlexyFormLabel, FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import { FormalitiesTabProps } from './Formalities';
import PartnerLogo from './PartnerLogo';
import useFetchApi from '@b2d/hooks/usePromises';
import useApiRequest from '@b2d/hooks/useApiRequest';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

// import { logoSpecificationApi } from '../../../../../../redux/RootStore';

export interface FormalitiesSettingsProps extends FormalitiesTabProps {}

const FormalitiesSettings: React.FC<FormalitiesSettingsProps> = (props) => {
    const { modalFunctions, prescriberId, prescribersApi, companyId } = props;

    // 1 - Component Data / On mount fetch data

    const [commercialConditions, setCommercialConditions] = React.useState<any>();
    const [logoSpecifications, setLogoSpecifications] = React.useState<any>();
    const [dataFetched, setDataFetched] = React.useState<boolean>(false);

    const id = prescriberId.toString();
    const getSettings = () =>
        prescribersApi.getGrilleForfaitairePrescripteurTiersItem(id, companyId);

    // const getLogoSpecifications = () =>
    //     logoSpecificationApi.getLogoSpecificationsTblParametreReferencesCollection();

    const { isFetching, isLoaded, hasErrors, results, call: fetchApi } = useFetchApi();

    React.useEffect(() => {
        fetchApi([
            getSettings,
            //     getLogoSpecifications
        ]);
    }, []);

    // 2 - Component state & debounce logic

    const updateFormField = (field: string, value: boolean | string | number) => {
        const { formData } = commercialConditions || {};

        setCommercialConditions({
            formData: {
                ...formData,
                logoFormalite: logoFormalite ?? '', // Prevent sending null | undefined
                [field]: value,
            },
            isWaitingUpdate: true,
        });
    };

    const [debouncedForm, controls] = useDebounce(commercialConditions, 1000);
    const [skipDebounce, setSkipDebounce] = React.useState<boolean>(false);

    React.useEffect(() => {
        const { formData, isWaitingUpdate } = commercialConditions || {};

        if (skipDebounce) {
            setSkipDebounce(false);
        } else if (isWaitingUpdate) {
            updateCommercialConditions({
                conditionsCommerciales: [formData],
            });
        }
    }, [debouncedForm]);

    const {
        auForfait,
        factureCourrier,
        factureSimplifiee,
        logoFormalite,
        partenaireFormalite,
        depotDossierFtp,
        emailSousclientObligatoire,
        signatureElectronique,
        typeFacture,
    } = commercialConditions?.formData || {};

    const updateWithoutDebounce = (field: string, value: string | number | boolean) => {
        setSkipDebounce(true);

        const { formData } = commercialConditions;

        updateCommercialConditions({
            conditionsCommerciales: [
                {
                    ...formData,
                    logoFormalite: logoFormalite ?? '', // Prevent sending null | undefined
                    [field]: value,
                },
            ],
        });
        updateFormField(field, value);
    };

    //3 - On result / update component state

    React.useEffect(() => {
        if (isLoaded && results) {
            extractAndStoreDataFromResponse(results);
            setDataFetched(true);
        }
    }, [isLoaded, results]);

    const extractAndStoreDataFromResponse = (fulfilledPromises: any) => {
        fulfilledPromises.forEach(({ value }: { value: { data?: any } }) => {
            if (value?.data) {
                storeRelatedData(value.data);
            }
        });
    };

    const storeRelatedData = (data: any) => {
        const commercialConditionsData = data?.conditionsCommerciales?.[0];
        const logoSpecifications = data?.['hydra:member'];

        const getLogoSpecificationValueBasedOnName = (name: string) => {
            const value = logoSpecifications.find((logoSpec: any) => logoSpec.name === name).value;

            if (name === 'LOGO_EXTENSION') {
                const arrayOfExtensions = value.split(',');
                const trimmedArray = arrayOfExtensions.map((value: string) => value.trim());
                return trimmedArray;
            }

            return value;
        };

        if (commercialConditionsData) {
            setCommercialConditions({
                formData: commercialConditionsData,
                isWaitingUpdate: false,
            });
        }

        if (logoSpecifications) {
            setLogoSpecifications({
                width: getLogoSpecificationValueBasedOnName('LOGO_LARGEUR'),
                height: getLogoSpecificationValueBasedOnName('LOGO_HAUTEUR'),
                maxSize: getLogoSpecificationValueBasedOnName('LOGO_POIDS'),
                allowedTypes: getLogoSpecificationValueBasedOnName('LOGO_EXTENSION'),
            });
        }
    };

    // 4 - CommercialConditions update

    const { request, requestState } = useApiRequest();
    const { isProcessing } = requestState;

    const updateCommercialConditions = (commercialConditionsParams: any) => {
        const successCallback = (result: any) => {
            const updatedCommercialConditions = result.data?.conditionsCommerciales[0];
            setCommercialConditions({
                formData: updatedCommercialConditions,
                isWaitingUpdate: false,
            });
        };

        request(updateCommercialConditionsRequest(commercialConditionsParams), {
            successCallback,
        });
    };

    const updateCommercialConditionsRequest = (commercialConditionsParams: any) => {
        return prescribersApi.updateConditionsCommercialesFormalitesPrescripteurTiersItem(
            prescriberId.toString(),
            commercialConditionsParams,
            companyId,
        );
    };

    // 5 - Render

    // 5a - Form structure / inputs

    const invoicesFlags = [
        {
            label: 'Au forfait',
            value: auForfait,
            apiFieldName: 'auForfait',
        },
        {
            label: 'Envoi postal de la facture',
            value: factureCourrier,
            apiFieldName: 'factureCourrier',
        },
        {
            label: 'Facture simplifiée',
            value: factureSimplifiee,
            apiFieldName: 'factureSimplifiee',
        },
    ];

    const settingsFlags = [
        {
            label: 'Dépôt des dossiers via FTP',
            value: depotDossierFtp,
            apiFieldName: 'depotDossierFtp',
        },
        {
            label: 'Signature electronique',
            value: signatureElectronique,
            apiFieldName: 'signatureElectronique',
        },
        {
            label: 'Email sous client obligatoire',
            value: emailSousclientObligatoire,
            apiFieldName: 'emailSousclientObligatoire',
        },
    ];

    const invoiceTypes = [
        {
            label: 'Prescripteur',
            value: 1,
        },
        {
            label: 'Client envoyé au prescripteur',
            value: 2,
        },
        {
            label: 'Prescripteur',
            value: 3,
        },
    ];

    // 5b - Switch group sub component

    const switchWrapperStyle = {
        flex: `1 0 60%`,
        marginRight: '15px',
    };

    const SwitchGroup = ({ booleanFields }: { booleanFields: any }) => (
        <Box display={'flex'} flexWrap={'wrap'} sx={{ marginTop: '20px' }}>
            {booleanFields.map((booleanField: any, index: number) => {
                const { label, value, apiFieldName } = booleanField;

                return (
                    <Box sx={switchWrapperStyle}>
                        <FlexyInput
                            type="boolean"
                            inputlabel={label}
                            name={`${label}_switch`}
                            key={`commercial_conditions_boolean_switch_${index}`}
                            value={value}
                            disabled={isProcessing}
                            onChange={() => {
                                updateFormField(apiFieldName, !value);
                            }}
                        />
                    </Box>
                );
            })}
        </Box>
    );

    // 5c - render logic

    if (isFetching || dataFetched === false) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid container spacing={4} rowSpacing={1}>
            <Grid item xl={7} lg={12}>
                <FlexyHeaderForm label={'Configuration'} outlined />
                <SwitchGroup booleanFields={settingsFlags} />
                <FlexyHeaderForm label={'Facturation'} outlined />
                <SwitchGroup booleanFields={invoicesFlags} />
                <Box display={'flex'} alignItems={'center'}>
                    <FlexyFormLabel paddingRight={8}>
                        <Typography component="span">{'Type de facture'}</Typography>
                    </FlexyFormLabel>
                    {typeFacture && (
                        <RadioGroup
                            sx={{ flexGrow: '1' }}
                            aria-labelledby="invoice-type-radio-buttons-group-label"
                            name="invoice-type-buttons-group"
                            value={typeFacture}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(event.target.value);
                                updateFormField('typeFacture', newValue);
                            }}
                        >
                            <Box display={'flex'} justifyContent={'space-between'}>
                                {invoiceTypes.map(({ value, label }) => (
                                    <FormControlLabel
                                        value={value}
                                        control={<Radio />}
                                        label={label}
                                        disabled={isProcessing}
                                    />
                                ))}
                            </Box>
                        </RadioGroup>
                    )}
                </Box>
            </Grid>
            <Grid item xl={5} lg={12}>
                <Box
                    sx={switchWrapperStyle}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <FlexyHeaderForm label={`Partenaire formalité`} />

                    <FlexyInput
                        name={`partner_switch`}
                        type="boolean"
                        value={partenaireFormalite}
                        disabled={isProcessing}
                        onChange={() => {
                            updateWithoutDebounce('partenaireFormalite', !partenaireFormalite);
                        }}
                    />
                </Box>

                {/* Divider / Keep theme consistency */}
                <FlexyHeaderForm label={''} sx={{ margin: '0', padding: '0' }} outlined />
                <PartnerLogo
                    isActive={partenaireFormalite === true}
                    imageSpecifications={logoSpecifications}
                    modalFunctions={modalFunctions}
                    base64StringLogo={logoFormalite}
                    updateLogo={(value: string) => updateWithoutDebounce('logoFormalite', value)}
                />
            </Grid>
        </Grid>
    );
};

export default FormalitiesSettings;
