import React from 'react';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadDefaultImage from './FileUploadDefaultImage.png';

export type FileUploadProps = {
    imageButton?: boolean;
    accept: string;
    hoverLabel?: string;
    dropLabel?: string;
    width?: string;
    height?: string;
    backgroundColor?: string;
    image?: {
        url: string;
        imageStyle?: {
            width?: string;
            height?: string;
        };
    };
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop: (event: React.DragEvent<HTMLElement>) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    imageButton = false,
    hoverLabel = 'Glisser un fichier ici',
    dropLabel = 'Glisser un fichier ici',
    width = '100%',
    height = '200px',
    backgroundColor = '#fff',
    image: {
        url = FileUploadDefaultImage,
        imageStyle = {
            height: 'inherit',
        },
    } = {},
    onChange,
    onDrop,
}) => {
    const [imageUrl, setImageUrl] = React.useState(url);
    const [labelText, setLabelText] = React.useState<string>(hoverLabel);
    const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            setIsMouseOver(false);
        },
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(dropLabel);
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(hoverLabel);
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            handleDrop(e);
        },
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (imageButton && event.target?.files) {
            for (let i = 0; i < event.target?.files.length; i++) {
                if (event.target?.files[i]) {
                    setImageUrl(URL.createObjectURL(event.target.files[i]));
                }
            }
        }
        onChange(event);
    };

    const handleDrop = (event: React.DragEvent<HTMLElement>) => {
        stopDefaults(event);
        setLabelText(hoverLabel);
        setIsDragOver(false);
        if (imageButton && event.dataTransfer.files.length > 0) {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (event.dataTransfer.files[i]) {
                    setImageUrl(URL.createObjectURL(event.dataTransfer.files[i]));
                }
            }
        }
        onDrop(event);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Tooltip title={accept}>
                    <Button variant="contained" component="label">
                        Importer un fichier
                        <input
                            type="file"
                            onChange={handleChange}
                            accept={accept}
                            multiple
                            hidden
                        />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Typography>OU</Typography>
            </Grid>
            <Grid item xs={12}>
                <label htmlFor="file-upload" {...dragEvents}>
                    <Box
                        width={width}
                        height={height}
                        bgcolor={backgroundColor}
                        sx={{
                            border: 3,
                            borderStyle: 'dashed',
                            borderColor: 'primary.main',
                            borderRadius: '30px',
                        }}
                    >
                        {imageButton ? (
                            <Box position="absolute" height={height} width={width}>
                                <img alt="file upload" src={imageUrl} style={imageStyle} />
                            </Box>
                        ) : null}

                        {!imageButton || isDragOver || isMouseOver ? (
                            <>
                                <Box
                                    height="100%"
                                    width="100%"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CloudUploadIcon fontSize="large" />
                                    <Typography>{labelText}</Typography>
                                    <Typography>({accept})</Typography>
                                </Box>
                            </>
                        ) : null}
                    </Box>
                </label>
            </Grid>
        </Grid>
    );
};
