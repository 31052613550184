import { Dossier, FactureAchat, MediaObject } from '@europrocurement/l2d-domain';
import { generatePath } from 'react-router';
import {
    achatsSuportedViewPathResolver,
    achatsViewPathResolver,
} from '../pages/Achats/AchatsRouter';

export type accessibleDirections = achatsSuportedViewPathResolver;
export type possibleParams = {
    factureAchat?: FactureAchat;
    mediaObject?: MediaObject;
    dossiernum?: string;
};

export type generateB2DPathResponseType =
    | {
          status: 'OK';
          path: string;
      }
    | {
          status: 'KO';
          message: string;
      }
    | {
          status: 'STAY';
      };

export const generateB2DPath = (
    direction: accessibleDirections,
    params?: possibleParams,
): generateB2DPathResponseType => {
    switch (direction) {
        case 'formmultimediaobject':
        case 'formmediaobject':
            if (!params?.mediaObject) return { status: 'KO', message: 'PDF manquant ! 💥' };

            const mediaObjectParams: { mediaobjectid: string } = {
                mediaobjectid: `${params.mediaObject.id}`,
            };

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), mediaObjectParams),
            };

        case 'formmultirecap':
        case 'formmultiadddossier':
        case 'formmultinextdossier':
        case 'formfactureachat':
        case 'consulterMultiFacture':
            if (!params?.factureAchat) return { status: 'KO', message: 'Facture manquante ! 💥' };

            let factureAchat = params.factureAchat;

            let mediaobjectparam = undefined;

            if (!factureAchat.pdfFacture) {
                if (!params.mediaObject || !params.mediaObject.id) {
                    return { status: 'KO', message: 'PDF manquant ! 💥' };
                } else {
                    mediaobjectparam = params.mediaObject.id;
                }
            }

            let idMediaobject = mediaobjectparam || factureAchat.pdfFacture?.id;
            let factureParams: {
                factureachatid: string;
                mediaobjectid: string;
            } = {
                factureachatid: `${factureAchat.id}`,
                mediaobjectid: '',
            };

            if (!idMediaobject)
                return { status: 'KO', message: "Impossible d'extraire l'id du PDF ! 💥" };

            factureParams.mediaobjectid = String(idMediaobject);

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), factureParams),
            };
        case 'formmultieditdossier':
        case 'nextEditDossierMultiFacturePath':
            if (!params?.factureAchat) return { status: 'KO', message: 'Facture manquante ! 💥' };
            if (!params?.dossiernum) return { status: 'KO', message: 'N° dossier manquant ! 💥' };

            let factureAchatDossier = params?.factureAchat;
            let numeroDossier = params?.dossiernum;

            let mediaobjectparamDossier = undefined;

            if (!factureAchatDossier.pdfFacture) {
                if (!params.mediaObject || !params.mediaObject.id) {
                    return { status: 'KO', message: 'PDF manquant ! 💥' };
                } else {
                    mediaobjectparamDossier = params.mediaObject.id;
                }
            }

            let idMediaobjectDossier =
                mediaobjectparamDossier || factureAchatDossier.pdfFacture?.id;
            let dossierParams: {
                factureachatid: string;
                mediaobjectid: string;
                dossiernum: string;
            } = {
                factureachatid: `${factureAchatDossier.id}`,
                mediaobjectid: '',
                dossiernum: `${numeroDossier}`,
            };

            if (!idMediaobjectDossier)
                return { status: 'KO', message: "Impossible d'extraire l'id du PDF ! 💥" };

            dossierParams.mediaobjectid = String(idMediaobjectDossier);

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), dossierParams),
            };
        case 'listmediaobject':
        case 'listfacture':
        case 'listfacturepause':
        case 'downloadMediaObject':
        case 'uploadMediaObject':
        case 'achatsstats':
            return {
                status: 'OK',
                path: achatsViewPathResolver(direction),
            };
        default:
            return {
                status: 'STAY',
            };
    }
};

export default generateB2DPath;
