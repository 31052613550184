import { ConfigurationParameters } from '../../openApi/ApiTiers';
import { createFournisseurSlice } from './slices/fournisseurSlice';
import { createPrescripteurSlice } from './slices/prescripteurSlice';
import { createTiersFournisseurSlice } from './slices/tiersFournisseurSlice';
import { createPaysSlice } from './slices/paysSlice';
import { createWalletOptionsSlice } from './slices/walletOptionsSlice';
import { createCspOptionsSlice } from './slices/cspOptionsSlice';
import { createCivilitiesSlice } from './slices/civilitiesSlice';

export const TIERS_REDUCER_NAME = 'tiers';

export function createTiersReducer(configuration: ConfigurationParameters) {
    const { prescripteurSlice, prescripteurDataSourcesThunks } =
        createPrescripteurSlice(configuration);
    const { fournisseurSlice, fournisseurDataSourcesThunks } =
        createFournisseurSlice(configuration);
    const { tiersFournisseurSlice, tiersFournisseurDataSourcesThunks } =
        createTiersFournisseurSlice(configuration);
    const { paysSlice, paysDataSourcesThunks } = createPaysSlice(configuration);

    const { walletOptionsSlice, walletOptionsDataSourcesThunks } =
        createWalletOptionsSlice(configuration);
    const { cspOptionsSlice, cspOptionsDataSourcesThunks } = createCspOptionsSlice(configuration);
    const { civilitiesSlice, civilitiesDataSourcesThunks } = createCivilitiesSlice(configuration);

    return {
        prescripteurSlice,
        prescripteurDataSourcesThunks,
        fournisseurSlice,
        fournisseurDataSourcesThunks,
        tiersFournisseurSlice,
        tiersFournisseurDataSourcesThunks,
        paysSlice,
        walletOptionsSlice,
        cspOptionsSlice,
        paysDataSourcesThunks,
        walletOptionsDataSourcesThunks,
        cspOptionsDataSourcesThunks,
        civilitiesSlice,
        civilitiesDataSourcesThunks,
    };
}
