import * as React from 'react';
import { TabPanel } from '@europrocurement/flexy-components';
import { Box, Card, Tab, Tabs } from '@mui/material';

type VerticalTabsProps = {
    tabs: VerticalTab[];
    defaultTabIndex?: number;
};

type VerticalTab = {
    index: number;
    label: string;
    tabContent: React.ReactNode;
};

const VerticalTabs: React.FunctionComponent<VerticalTabsProps> = (props) => {
    const { tabs, defaultTabIndex = 0 } = props;
    const [tabCurrentIndex, setTabCurrentIndex] = React.useState<number>(defaultTabIndex);

    const tabStyle = {
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
    };

    const tabWrapperStyle = {
        padding: '0px 30px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    };

    return (
        <Card sx={tabStyle}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabCurrentIndex}
                onChange={(event: React.SyntheticEvent, indexValue: number) =>
                    setTabCurrentIndex(indexValue)
                }
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {tabs.map(({ label }) => (
                    <Tab label={label} />
                ))}
            </Tabs>

            {tabs.map(({ index, tabContent }) => (
                <TabPanel value={tabCurrentIndex} index={index}>
                    <Box sx={tabWrapperStyle}>{tabContent}</Box>
                </TabPanel>
            ))}
        </Card>
    );
};

export default VerticalTabs;
