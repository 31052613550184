import * as React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { breadcrumbHeight } from '../../../assets';
// const breadcrumbHeight = 100;

type PathAndLink = {
    path: string;
    link: string;
};

export type LazyBreadcrumbsProps = {
    path: Array<PathAndLink | string>;
    bypass?: boolean;
    sx?: SxProps;
};

const LazyBreadcrumb: React.FunctionComponent<LazyBreadcrumbsProps> = function ({
    path,
    bypass,
    sx,
}) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ height: `${breadcrumbHeight}px`, ...sx }}
            role="navigation"
        >
            <Box display="flex" flexDirection="row">
                {path?.map((uniquePath, index, arr) => {
                    if (index < arr.length - 1) {
                        return typeof uniquePath === 'object' ? (
                            <Link
                                to={uniquePath.link}
                                style={{ textDecoration: 'none' }}
                                key={uniquePath.path}
                            >
                                <Typography
                                    variant="body1"
                                    component="span"
                                    color="text.primary"
                                    marginRight={1}
                                >
                                    {`${uniquePath.path} ${index !== arr.length - 2 ? ' > ' : ' '}`}
                                </Typography>
                            </Link>
                        ) : (
                            <Typography
                                variant="body1"
                                component="span"
                                color="text.primary"
                                key={uniquePath}
                            >
                                {`${uniquePath} ${index !== arr.length - 2 ? ' > ' : ''}`}
                            </Typography>
                        );
                    }
                    return '';
                })}
            </Box>
            {!bypass && (
                <Typography variant="h1" color="secondary">
                    {`${path[path.length - 1]}`}
                </Typography>
            )}
        </Box>
    );
};

export default LazyBreadcrumb;
