import * as React from 'react';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { FlexyFormLabel, FlexyHeaderForm } from '@europrocurement/flexy-components';
import { useSelector } from 'react-redux';
import { OptionsSelector } from '@b2d/redux/RootStore';

const AccountingSettings: React.FC<any> = (props) => {
    const { modalFunctions, prescriberId, prescribersApi, companyId } = props;

    const paymentTerm = 1;
    const paymentMode = 1;

    const paymentTerms = [
        { value: 1, label: 'Comptant' },
        { value: 2, label: 'A Réception de Facture' },
        { value: 3, label: 'Paiement à 30 Jours' },
        { value: 4, label: 'Paiement à 45 Jours' },
        { value: 5, label: 'Paiement à 60 Jours' },
    ];

    // Those values came from TBL_Def_Mode_Paiement => load those options as config option in option store instead of hard coded values
    const paymentModes = [
        { value: 1, code: 'CHE', label: 'Chèque' },
        { value: 2, code: 'MPR', label: 'Prelèvement Mensuel' },
        { value: 3, code: 'TPR', label: 'Prelèvement Trimestriel' },
        { value: 4, code: 'EPR', label: 'Prelèvement Echeancier' },
        { value: 5, code: 'CB', label: 'Carte' },
        { value: 6, code: 'VIR', label: 'Virement' },
        { value: 7, code: 'ESP', label: 'Espèce' },
    ];

    const formRowStyle = { marginTop: '15px' };
    const formRowLabelStyle = { width: '200px' };

    return (
        <Grid container spacing={4} rowSpacing={1}>
            <Grid item xl={12} lg={12}>
                <FlexyHeaderForm label={'Paiement'} outlined />
                <Box display={'flex'} alignItems={'center'} sx={formRowStyle}>
                    <FlexyFormLabel sx={formRowLabelStyle}>
                        <Typography component="span">{'Délai de paiement'}</Typography>
                    </FlexyFormLabel>
                    {paymentTerm && (
                        <RadioGroup
                            sx={{ flexGrow: '1' }}
                            aria-labelledby="invoice-type-radio-buttons-group-label"
                            name="invoice-type-buttons-group"
                            value={paymentTerm}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(event.target.value);
                            }}
                        >
                            <Box display={'flex'}>
                                {paymentTerms.map(({ value, label }) => (
                                    <FormControlLabel
                                        value={value}
                                        control={<Radio />}
                                        label={label}
                                        disabled={false}
                                    />
                                ))}
                            </Box>
                        </RadioGroup>
                    )}
                </Box>
                <Box display={'flex'} alignItems={'center'} sx={formRowStyle}>
                    <FlexyFormLabel sx={formRowLabelStyle}>
                        <Typography component="span">{'Mode de paiement'}</Typography>
                    </FlexyFormLabel>
                    {paymentMode && (
                        <RadioGroup
                            sx={{ flexGrow: '1' }}
                            aria-labelledby="invoice-type-radio-buttons-group-label"
                            name="invoice-type-buttons-group"
                            value={paymentMode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newValue = parseInt(event.target.value);
                                console.log(newValue);
                            }}
                        >
                            <Box display={'flex'}>
                                {paymentModes.map(({ value, label, code }) => (
                                    <FormControlLabel
                                        value={value}
                                        control={<Radio />}
                                        label={label}
                                        disabled={false}
                                    />
                                ))}
                            </Box>
                        </RadioGroup>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AccountingSettings;
