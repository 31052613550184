import {
    ConfigurationParameters,
    Configuration,
    PrescripteursApi,
    TiersTiersJsonldTiersRead,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
    filtersToElasticQuery,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

export { type ConfigurationParameters };

export const PRESCRIPTEUR_SLICE_NAME = 'prescripteur';

export type PrescripteurApi = TiersTiersJsonldTiersRead & Record<string, unknown>;

export type Prescripteur = PrescripteurApi & {};

const mapperPrescripteur = function (prescripteur: PrescripteurApi) {
    return {
        nomDentreprise: prescripteur.raisonSociale,
        ...prescripteur,
    } as Prescripteur;
};

export function createPrescripteurSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PrescripteursApi(conf);

    const fetchPrescripteursCollection: FetchCollectionData<PrescripteurApi> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
        options,
    }) => {
        let aditionalMatch = undefined;

        if (search && search !== '') {
            aditionalMatch = [
                {
                    query_string: {
                        query: `**${search}**`,
                        fields: [
                            'relationsSocietes.codeClient', // Tie-code
                            'raisonSociale',
                            'adresses.codePostal',
                            'adresses.ville',
                            'siren',
                            'siret',
                            'contacts.emailsContact.email',
                            'contacts.telephonesContact.telephone',
                            'contacts.nomContact',
                            'contacts.prenomContact',
                        ],
                    },
                },
            ];
        }

        const mesdatas = api
            .getListTiersTiersCollection(
                xIdSociete,
                undefined,
                pagination.page + 1,
                pagination.itemsPerPage,
                filtersToElasticQuery(
                    {
                        'relationsSocietes.societe.id': xIdSociete,
                        ...filters,
                    },
                    aditionalMatch,
                    orders,
                ),
                options,
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<PrescripteurApi>>;

        return mesdatas;
    };

    const fetchPrescripteursItem: FetchItemDataType<PrescripteurApi> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
        options,
    }) =>
        api
            .getTiersTiersItem(String(idItem), xIdSociete, undefined, options)
            .then((res) => res.data) as Promise<PrescripteurApi>;

    const { slice: prescripteurSlice, dataSourcesThunks: prescripteurDataSourcesThunks } =
        SliceFactory.createSlice<PrescripteurApi, Prescripteur>(
            PRESCRIPTEUR_SLICE_NAME,
            TIERS_REDUCER_NAME,
            ['export'],
            fetchPrescripteursCollection,
            fetchPrescripteursItem,
            mapperPrescripteur,
            {
                isPrescripteur: true,
                demo: false,
                deleted: false,
            },
            {},
            [
                // {
                //     field : 'raisonSociale',
                //     value : 'asc'
                // }
            ],
        );

    return { prescripteurSlice, prescripteurDataSourcesThunks };
}
