import * as React from 'react';
import { AxiosError } from 'axios';
import { Box, Card, Typography } from '@mui/material';
import { BooleanBadge } from '../../atoms';
import fprologo from './fprologo.png';
import apprologo from './apprologo.png';
import CenterCircularProgress from '../CenterCircularProgress/CenterCircularProgress';

export type relSociete = {
    affichesProAutorise?: boolean;
    clientSuivi?: boolean;
    clientXl?: boolean;
    formalProAutorise?: boolean;
};

export type ConfigurationCardProps = {
    companyRelation: relSociete;
    changeConfiguration: (field: keyof relSociete, newValue: boolean) => Promise<unknown>;
};

export type BadgeLoadingItem = {
    field: string;
    isLoading: boolean;
};

export type BadgeLoadingCollection = BadgeLoadingItem[];

const ConfigurationCard: React.FunctionComponent<ConfigurationCardProps> = function ({
    companyRelation,
    changeConfiguration,
}: ConfigurationCardProps) {
    const { clientXl, clientSuivi, affichesProAutorise, formalProAutorise } = companyRelation;

    type BooleanBadgeProps = {
        label: string;
        logo?: string;
        field: keyof relSociete;
        value?: boolean;
    };

    const booleanBadges: Array<BooleanBadgeProps> = [
        {
            label: 'Affiche Pro',
            logo: apprologo,
            field: 'affichesProAutorise',
            value: affichesProAutorise,
        },
        {
            label: 'Formal Pro',
            logo: fprologo,
            field: 'formalProAutorise',
            value: formalProAutorise,
        },
        {
            label: 'Client suivi',
            field: 'clientSuivi',
            value: clientSuivi,
        },
        // {
        //     label: 'Facturation DOD',
        //     field: '', //définir donnée relation
        //     value: false
        // },
        {
            label: 'Client XL',
            field: 'clientXl',
            value: clientXl,
        },
    ];

    const [isConfigurationUpdating, setIsConfigurationUpdating] = React.useState<boolean>(false);

    const toggleValue = (field: keyof relSociete, value?: boolean) => {
        setIsConfigurationUpdating(true);

        changeConfiguration(field, !value)
            .then(() => {
                setIsConfigurationUpdating(false);
            })
            .catch((err: AxiosError) => {
                console.error('err');
                console.error(err);
            });
    };

    return (
        <Card sx={{ gap: '15px', padding: '15px', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    padding: '0px',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" fontWeight="bold">
                    Configuration{' '}
                </Typography>

                {booleanBadges.map(({ label, logo, field, value }) => (
                    <BooleanBadge
                        key={label}
                        label={label}
                        icon
                        logo={logo}
                        value={value}
                        toggleAction={() => toggleValue(field, value)}
                        disabled={isConfigurationUpdating}
                    />
                ))}

                {isConfigurationUpdating && (
                    <CenterCircularProgress sx={{ width: 'inherit', maxHeight: '20px' }} />
                )}
            </Box>
        </Card>
    );
};

export default ConfigurationCard;
