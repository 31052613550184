import * as React from 'react';

import { Box, Typography, Avatar, Button } from '@mui/material';
import { KeycloakHasRole, KeycloakIsAuth } from '@europrocurement/l2d-keycloak';
import { Link } from 'react-router-dom';

export type ProfileDropdownProps = {
    fullName: string;
    jobName?: string;
    email?: string;
    avatarSrc?: string;
    logout: () => void;
};

const ProfileDropdown: React.FunctionComponent<ProfileDropdownProps> = function ({
    fullName,
    jobName,
    email,
    avatarSrc,
    logout,
}: ProfileDropdownProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                role="menu"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    padding: '15px',
                    gap: '15px',
                }}
            >
                <Box>
                    <Avatar src={avatarSrc} sx={{ width: '42px', height: '42px' }} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}
                >
                    {fullName ? <Typography variant="h2">{fullName}</Typography> : null}
                    {jobName ? <Typography variant="body1">{jobName}</Typography> : null}
                    {email ? <Typography variant="body1">{email}</Typography> : null}
                </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: '10px' }}>
                <KeycloakIsAuth>
                    <>
                        <Button onClick={logout} variant="outlined" title="Déconnexion">
                            Déconnexion
                        </Button>

                        <KeycloakHasRole kcRole="realm:debug">
                            <Button
                                color="primary"
                                variant="contained"
                                component={Link}
                                to="/debug"
                                disableElevation
                            >
                                debug
                            </Button>
                        </KeycloakHasRole>
                    </>
                </KeycloakIsAuth>
            </Box>
        </Box>
    );
};

export default ProfileDropdown;
