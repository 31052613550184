import { Modalizer } from '@europrocurement/flexy-components';
import { Alert, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';

export type MessagesTypes = {
    question?: string;
    detail?: {
        message: string;
        severity: 'error' | 'warning' | 'info' | 'success';
    };
    validation?: string;
    annulation?: string;
};

export type ConfirmationModalProps = {
    isModalOpen: boolean;
    isDraggable?: boolean;
    closeModal?: () => void;
    messages?: MessagesTypes;
    actionOnValidation?: () => void;
    actionOnCancellation?: () => void;
};

const confirmationModal: React.FunctionComponent<ConfirmationModalProps> = function ({
    isModalOpen,
    isDraggable,
    closeModal,
    messages,
    actionOnValidation,
    actionOnCancellation,
}) {
    return (
        <Modalizer
            open={isModalOpen}
            onClose={() => {
                if (closeModal) closeModal();
            }}
            isDraggable={isDraggable}
        >
            <Box>
                <Typography style={{ marginBottom: '8px' }}>
                    {messages?.question || 'Êtes-vous sûr de vouloir valider cette action ?'}
                </Typography>
                {messages?.detail ? (
                    <Alert severity={messages.detail.severity}>{messages.detail.message}</Alert>
                ) : null}
                <Box display="flex" flexDirection="row" justifyContent="center" gap={2}>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={actionOnCancellation}
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                    >
                        {messages?.annulation || 'Annuler'}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={actionOnValidation}
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                    >
                        {messages?.validation || 'Valider'}
                    </Button>
                </Box>
            </Box>
        </Modalizer>
    );
};

export default confirmationModal;
