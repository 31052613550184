import * as React from 'react';

import {
    FieldArrayMethodProps,
    FieldValues,
    useFieldArray,
    UseFieldArrayReturn,
    useFormContext,
} from 'react-hook-form';
import { ClearButton, useResolvePath } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import { Button, Grid, GridProps, Tooltip, Typography } from '@mui/material';

import type { FlexySubFormProps } from './formStructureRenderer.type';
import { FormStructureRenderer, type FormStructureRendererProps } from './FormStructureRenderer';
import type { ExtandedFormContext, FlexyFormInputProps, FormStructure } from '../FlexyForm';
// eslint-disable-next-line import/no-cycle

export type SubFormArrayEvents = {
    onAppend?: (
        item: any,
        formContext: ExtandedFormContext,
        fieldArray: UseFieldArrayReturn<FieldValues, string, 'id'>,
    ) => void;
    beforeAppend?: (
        item: any,
        formContext: ExtandedFormContext,
        fieldArray: UseFieldArrayReturn<FieldValues, string, 'id'>,
    ) => {
        item?: any;
    } | void;

    onRemove?: (
        index: number,
        item: any,
        formContext: ExtandedFormContext,
        fieldArray: UseFieldArrayReturn<FieldValues, string, 'id'>,
    ) => void;
    beforeRemove?: (
        index: number,
        item: any,
        formContext: ExtandedFormContext,
        fieldArray: UseFieldArrayReturn<FieldValues, string, 'id'>,
    ) => void;
    // onRemove: ()=>void;
};

export type SubformArrayProps = {
    input: FormStructure & FlexySubFormProps;
    basePath: string;
    displayAddButton?: boolean;
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    events?: SubFormArrayEvents;
};

export const SubformArray: React.FunctionComponent<SubformArrayProps> = function ({
    input,
    basePath,
    displayAddButton,
    inputsProps,
    gridProps,
    events,
}) {
    const { resolvePath } = useResolvePath();
    const formContext = useFormContext();

    if (displayAddButton === undefined) {
        displayAddButton = true;
    }

    const extandedFormContext = {
        ...formContext,
        getValue: (path: string) => resolvePath(path, formContext.getValues()),
    };

    const { formState } = formContext;

    const fieldArray = useFieldArray({
        name: basePath,
        shouldUnregister: true,
    });

    const { fields, append, remove } = fieldArray;

    const onAppendItem = (item: any, options?: FieldArrayMethodProps | undefined) => {
        if (events && events.beforeAppend) {
            const res = events.beforeAppend(item, extandedFormContext, fieldArray);
            if (res && res.item) {
                item = res.item;
            }
        }
        append(item);
        if (events && events.onAppend) {
            events.onAppend(item, extandedFormContext, fieldArray);
        }
    };

    // React.useEffect(()=>{
    //     if (!formState.isDirty) {
    //         const fieldsLength = fields.length || input.defaultLength || 1;

    //         const diff = fieldsLength - fields.length;

    //         for (let index = 0; index < diff; index += 1) {
    //             onAppendItem({},{shouldFocus:false});
    //         }
    //     }
    // },[]);

    const minLength = input.minLength || 0;
    const maxLength = input.maxLength || Infinity;

    const onRemoveItem = (index: number) => {
        const item = extandedFormContext.getValue(`${basePath}.${index}`);
        if (events && events.beforeRemove) {
            events.beforeRemove(index, item, extandedFormContext, fieldArray);
        }
        remove(index);
        if (events && events.onRemove) {
            events.onRemove(index, item, extandedFormContext, fieldArray);
        }
    };

    return (
        <>
            {fields.map(({ id, ...object }, index) => {
                const props = {
                    ...input,
                    shouldUnregister: true,
                    basePath: `${basePath}.${index}.`,
                    inputsProps,
                    gridProps,
                } as FormStructureRendererProps;

                return (
                    <React.Fragment key={id}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="end"
                            justifyContent="space-between"
                            position="relative"
                            sx={{ width: '100%' }}
                        >
                            <FormStructureRenderer {...props} />
                            {fields.length > minLength ? (
                                <ClearButton
                                    size={
                                        inputsProps?.size !== undefined
                                            ? inputsProps.size
                                            : 'medium'
                                    }
                                    tooltip={input.removeBtnTooltip}
                                    onClick={() => {
                                        onRemoveItem(index);
                                    }}
                                />
                            ) : null}
                        </Box>
                    </React.Fragment>
                );
            })}

            {fields.length < maxLength && displayAddButton ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} />
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Grid item xs={12} sx={{ textAlign: 'right' }}>
                            {/* Outlined button */}
                            <Tooltip title={input.addBtnTooltip || 'Ajouter'}>
                                <Button
                                    color="secondary"
                                    aria-label="ajout"
                                    variant="outlined"
                                    sx={{
                                        height: 'fit-content',
                                    }}
                                    size={
                                        inputsProps?.size !== undefined
                                            ? inputsProps.size
                                            : 'medium'
                                    }
                                    onClick={() => onAppendItem({})}
                                >
                                    <Typography>{input.addBtnLabel || 'Ajouter'}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
};

export default SubformArray;
