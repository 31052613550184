import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { DataCard } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { CategorieStats, CodeRejetStats } from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import {
    CategorieStatsSelector,
    CodeRejetStatsSelector,
    getCategorieStats,
} from '../../../redux/RootStore';

type CodeRejetsPanelProps = {
    afficherZeros: boolean;
    cardsProps: { [key: string]: JSX.Element };
    handleActiveTab: (newTab: number) => void;
};

const CodeRejetsPanel: React.FunctionComponent<CodeRejetsPanelProps> = function ({
    afficherZeros,
    cardsProps,
    handleActiveTab,
}) {
    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;
    const codesRejetsDataSource: DataSource<CodeRejetStats> =
        useSelector(CodeRejetStatsSelector).main;
    const codesRejets = codesRejetsDataSource.data;

    const dispatch = useDispatch();

    const navigateToTab = useCallback(
        (newTab: number) => {
            handleActiveTab(newTab);
        },
        [handleActiveTab],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container>
                    {[...codesRejets]
                        .sort((a, b) => {
                            if (!a.name || !b.name) return 0;
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        })
                        .map((codeRejet) => {
                            return codeRejet &&
                                codeRejet.name &&
                                (!afficherZeros
                                    ? codeRejet.count && codeRejet.count !== 0
                                    : true) ? (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={4}
                                    key={`${codeRejet.name} • ${codeRejet.libelle}`}
                                >
                                    <DataCard
                                        title={`${codeRejet.name} • ${codeRejet.libelle}`}
                                        icon={cardsProps[codeRejet.name.substring(0, 4)]}
                                        color={codeRejet.name.substring(0, 4)}
                                        tools={
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    dispatch({
                                                        type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                                        payload: {
                                                            key: 'codesRejets',
                                                            value: [codeRejet.name],
                                                        },
                                                    });
                                                    dispatch(getCategorieStats({}));
                                                    navigateToTab(2);
                                                }}
                                            >
                                                Détails {codeRejet.name}
                                            </Button>
                                        }
                                        sx={{
                                            minHeight: '90%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <strong>{codeRejet.count}</strong> Facture(s) en
                                            anomalie(s)
                                        </Typography>
                                    </DataCard>
                                </Grid>
                            ) : (
                                false
                            );
                        })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CodeRejetsPanel;
