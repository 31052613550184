import Axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';

import {
    CenterCircularProgress,
    PdfViewer,
    footerHeight,
    TopbarHeight,
} from '@europrocurement/flexy-components';
import { Card, Grid, Typography } from '@mui/material';
import { factureAchatCreate, factureFormObject } from './components/forms/types';
import FactureForm from './components/forms/FactureForm';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { useNavigate, useParams } from 'react-router-dom';
import {
    MediaObjectSelector,
    selectMediaObject,
    factureAchatApi,
    getMediaObject,
    mediaObjectApi,
} from '../../redux/RootStore';
import { FactureAchat, MediaObject } from '@europrocurement/l2d-domain';
import generateB2DPath from '../../utils/generateB2DPath';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { ACTIONS } from '../../redux/FactureFormReducer';
import MediaObjectReader from './components/widgets/MediaObjectReader';
import { tradErrors } from '../../utils/tradErrors';
import { useBackFromMediaObjectEdit } from './AchatsRouter';
import { useSynchroG3Facture } from './components/forms/functions/dataHooks';

export type SaisieFacturesProps = {};

const SaisieFactures: React.FunctionComponent<SaisieFacturesProps> = () => {
    const { mediaobjectid } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const synchroG3Facture = useSynchroG3Facture();

    const [sendFacture, setSendFacture] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;
    const [mediaObjectStatus, setMediaObjectStatus] = useState<EuroprocApiResponseStatus>('idle');

    /**
     * S'il existe un mediaobjectid et que le statut du MediaObject n'est pas "succeeded",
     * alors on modifie sa date de début de traitement à la date d'aujourd'hui
     * et on passe son statut à "succeeded".
     */
    useEffect(() => {
        if (!mediaobjectid) {
            enqueueSnackbar(<Typography>Il n'y a pas de PDF correspondant ! 💥</Typography>);
        } else if (mediaObjectStatus !== 'succeeded') {
            mediaObjectApi.patchMediaObjectItem(mediaobjectid, {
                dateDebutTraitement: new Date().toISOString(),
            });
            setMediaObjectStatus('succeeded');
        }
    }, [enqueueSnackbar, mediaobjectid, mediaObjectStatus]);

    /**
     * Sélectionner le MediaObject correspondant à l'id en paramètre dans l'url.
     */
    useEffect(() => {
        if (
            mediaobjectid &&
            mediaObjectDataSource.status !== 'loading' &&
            (!mediaObjectDataSource.selected ||
                `${mediaObjectDataSource.selected.id}` !== mediaobjectid)
        ) {
            dispatch(selectMediaObject({ id: +mediaobjectid }));
        }
    }, [dispatch, mediaObjectDataSource.selected, mediaObjectDataSource.status, mediaobjectid]);

    const mediaObject = useMemo(
        () => mediaObjectDataSource.selected,
        [mediaObjectDataSource.selected],
    );
    const newFacture: Partial<factureFormObject> = useMemo(() => {
        return {
            produits: [
                {
                    rubriqueFacturation: {
                        code: 'PUB',
                        id: 1,
                    },
                    txtva: 20,
                },
            ],
        };
    }, []);
    // const newFacture: Partial<factureFormObject> = useMemo(()=>{return { produits: [{txtva:20}]};},[]);

    async function fetchFacture(facture: factureAchatCreate) {
        return await factureAchatApi.createFactureAchatFactureAchatCollection(facture).then(
            async (res) => {
                if (res.status < 200 && res.status >= 300) {
                    enqueueSnackbar(
                        <Typography>
                            Envoi de la facture raté ! (${res.status}) <br />
                            Si le problème persiste, contacter le support.
                        </Typography>,
                        { variant: 'error' },
                    );
                    throw new Error(`
                        Erreur de communication avec l'API (${res.status})...
                        Si le problème persiste, contacter le support.
                    `);
                }

                const factureCreated = res.data;

                if (!factureCreated.id) {
                    enqueueSnackbar(
                        <Typography>
                            Cette facture n'a pas d'identifiant ! <br />
                            Veuillez contacter le support.
                        </Typography>,
                        { variant: 'error' },
                    );
                    throw new Error(`
                        Information manquante dans l'enregistrement...
                        Si le problème persiste, contacter le support.
                    `);
                }

                const resSync = await synchroG3Facture(factureCreated.id.toString());

                if (!resSync) {
                    enqueueSnackbar(
                        <Typography>
                            La synchronisation G3 a échouée !
                        </Typography>,
                        { variant: 'error' },
                    );
                }

                enqueueSnackbar(<Typography>Envoi de la facture avec succès ! 🚀</Typography>);
                return res;
            },
            (err) => {
                let message: string = '';

                if (typeof err === 'string') {
                    message = err;
                } else if (Axios.isAxiosError(err)) {
                    if (err.response?.data) {
                        const axiosErrorResponse = err.response.data;
                        console.log(axiosErrorResponse);

                        if (axiosErrorResponse.exception.violations) {
                            const propertyRegex = /(?<=\[|\.)\w+(?=\])|\w+(?=$|\[|\.)+/g;

                            message = axiosErrorResponse.exception.violations.reduce(
                                (
                                    acc: string,
                                    { message, property }: { message?: string; property?: string },
                                ) => {
                                    if (property && message) {
                                        const regexResult = property.match(propertyRegex);
                                        if (regexResult instanceof Array) {
                                            let prop = regexResult[regexResult.length - 1];
                                            const productdline = property.match(/lignes\[(\d+)\]/);
                                            if (productdline instanceof Array) {
                                                prop = `Produit ${Number(productdline[1]) + 1}`;
                                                console.log(productdline);
                                            }
                                            acc += `${prop}: ${tradErrors(message)}\n`;
                                        }
                                    } else if (message) {
                                        acc += message;
                                    } else {
                                        acc += 'erreur non traitée \n';
                                    }

                                    return acc;
                                },
                                '',
                            );
                        } else if (axiosErrorResponse.exception.message) {
                            const axiosErrorResponseMessage: string =
                                axiosErrorResponse.exception.message;
                            if (axiosErrorResponseMessage.includes('ODBC Driver')) {
                                message = "Impossible d'échanger avec la base de données.";
                                console.log(axiosErrorResponseMessage);
                            } else if (
                                axiosErrorResponseMessage.includes('Call to a member function')
                            ) {
                                message = 'Erreur technique lors de la verrification des regles.';
                                console.log(axiosErrorResponseMessage);
                            } else {
                                message = axiosErrorResponseMessage;
                            }
                        } else {
                            message = axiosErrorResponse.error;
                        }
                    }
                } else if (err.exception) {
                    message = err.exception.message;
                }

                enqueueSnackbar(
                    <Typography>
                        Envoi de la facture raté ! 💥
                        <br />
                        {message}
                    </Typography>,
                    { variant: 'error' },
                );
                throw new Error(`
                Envoi de la facture raté ! (${message})...
                Si le problème persiste, contacter le support.
            `);
            },
        );
    }

    const navigateToNewFacture = async () => {
        console.log('beforegetmo', [...mediaObjectDataSource.data]);
        setTimeout(async () => {
            let mos: { payload: { 'hydra:member': MediaObject[] } } = (await dispatch(
                getMediaObject({}),
            )) as unknown as { payload: { 'hydra:member': MediaObject[] } };
            console.log('beforegetmo', [...mediaObjectDataSource.data]);
            console.log('beforegetmo', mos.payload['hydra:member']);

            if (mos.payload['hydra:member'].length === 0) {
                navigateToListe();
                enqueueSnackbar(
                    <Typography>Toutes les factures sont traitées ou en traitement</Typography>,
                );
            } else {
                let response = null;
                response = generateB2DPath('formmediaobject', {
                    mediaObject: mos.payload['hydra:member'][0],
                });
                if (response.status === 'OK') {
                    dispatch({
                        type: ACTIONS.RESET,
                    });
                    navigate(response.path);
                } else if (response.status === 'KO') {
                    enqueueSnackbar(<Typography>{response.message}</Typography>, {
                        variant: 'warning',
                    });
                }
            }
        }, 500);
    };

    const navigateToListe = () => {
        const response = generateB2DPath('listmediaobject');

        if (response.status === 'OK') {
            navigate(response.path);
        } else if (response.status === 'KO') {
            enqueueSnackbar(<Typography>{response.message}</Typography>, {
                variant: 'warning',
            });
        }
    };

    const onSubmitFacture: (
        facture: factureAchatCreate,
        goToNext: boolean,
    ) => void = async (facture: factureAchatCreate, goToNext = false) => {
        setSendFacture(true);
        try {
            if (mediaObject) {
                facture.pdfFacture = mediaObject['@id'];
            }

            await fetchFacture(facture);
            await 
            setSendFacture(false);

            dispatch(getMediaObject({}));

            if (goToNext) {
                return navigateToNewFacture();
            }
            return navigateToListe();
        } catch (error) {
            setSendFacture(false);
        }
    };

    const onCancel = useBackFromMediaObjectEdit();

    return (
        <>
            {/* <Typography variant="h5">
                render count wrapper : {renderFactureWrapper}
            </Typography> */}
            {!mediaObject || mediaObjectDataSource.status === 'loading' ? (
                <CenterCircularProgress sx={{ height: '500px' }} />
            ) : (
                <Grid container>
                    <Grid item lg={6} sm={12}>
                        <MediaObjectReader
                            mediaObject={mediaObject}
                            sx={{
                                height: `calc(100vh -${footerHeight} - ${TopbarHeight}  )`,
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} sm={12}>
                        <Card>
                            <FactureForm
                                mediaObject={mediaObject}
                                key={mediaObject?.contentUrl}
                                facture={newFacture}
                                onSubmit={onSubmitFacture}
                                onCancel={() => {
                                    onCancel();
                                }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SaisieFactures;
