import { achatsBasePath } from '../pages/Achats/AchatsRouter';
import { prescripteursBasePath } from '../pages/Prescripteurs/PrescripteurRouter';
// import AchatsRouter from "../pages/Achats/AchatsRouter";

export type suportedModules = 'root' | 'accueil' | 'debug' | 'achats' | 'prescripteurs';

export const modulePathResolver = function (module: suportedModules) {
    switch (module) {
        case 'root':
            return '/';
        case 'accueil':
            return '/accueil';
        case 'debug':
            return '/debug';
        case 'achats':
            return '/' + achatsBasePath;
        case 'prescripteurs':
            return '/' + prescripteursBasePath;
        default:
            throw new Error('unimplemented path');
    }
};
