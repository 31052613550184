import * as React from 'react';
import { MenuEntry } from '@europrocurement/flexy-components';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SettingsIcon from '@mui/icons-material/Settings';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { PrescripteurMenuEntries } from '../pages/Prescripteurs/PrescripteurRouter';
import { AchatsMenuEntries } from '../pages/Achats/AchatsRouter';

const menuEntries: MenuEntry[] = [
    // Accueil
    {
        navlabel: true,
        subheader: 'Accueil',
        href: '/',
    },
    {
        title: 'Dashboard',
        icon: <HomeIcon />,
        href: '/accueil',
    },

    // Prescripteur
    ...PrescripteurMenuEntries,

    // Formalité
    {
        navlabel: true,
        subheader: 'Formalités',
        href: 'formalite',
        role: 'realm:interne',
    },
    {
        title: 'Liste des dossiers',
        icon: <NewspaperIcon />,
        href: '/formalite/liste',
        role: 'realm:interne',
    },
    {
        title: 'Nouveau dossier',
        icon: <NewspaperIcon />,
        href: '/formalite/new',
        role: 'realm:interne',
    },

    // Facturation
    {
        navlabel: true,
        subheader: 'Facturation',
        href: '/facturation',
        role: 'realm:interne',
    },
    {
        title: 'Recherche de facture',
        icon: <PaymentsIcon />,
        href: '/facturation',
        role: 'realm:interne',
    },

    // Comptabilité

    ...AchatsMenuEntries,

    // Administration
    {
        navlabel: true,
        subheader: 'Administration',
        href: '/admin',
        role: 'realm:interne',
    },
    {
        title: 'gestion des utilisateurs',
        icon: <AdminPanelSettingsIcon />,
        href: '/admin/users',
        role: 'realm:interne',
    },
    {
        title: 'gestion des Formalités',
        icon: <FormatListBulletedIcon />,
        href: '/admin/formalite',
        role: 'realm:interne',

        collapse: true,
        children: [
            {
                title: 'Grille des tarifs',
                icon: <PriceChangeIcon />,
                href: '/admin/formalite/tarifs',
                role: 'realm:interne',
            },
            {
                title: 'Parametrage',
                icon: <SettingsIcon />,
                href: '/admin/formalite/parametrage',
                role: 'realm:interne',
            },
        ],
    },
];

export default menuEntries;
