import { DataSource, Order } from './EuroprocurementApiType';

type FiltersToQuery = (
    filters: DataSource<any>['filters'],
    aditionalMatch?: Array<Record<string, unknown>>,
    orders?: DataSource<any>['orders'],
) => string;

export const filtersToElasticQuery: FiltersToQuery = (filters, aditionalMatch, orders) => {
    const query: any = {
        query: {
            bool: {
                must: [],
            },
        },
        // ,
        // sort:[
        //     {
        //         "raisonSociale.keyword" : {
        //             "order" : "asc"
        //         }
        //     }
        // ]
    };

    if (aditionalMatch) {
        query.query.bool.must.push(...aditionalMatch);
    }

    for (const [key, value] of Object.entries(filters)) {
        if (typeof value === 'boolean') query.query.bool.must.push({ match: { [key]: value } });
        if (typeof value === 'number') query.query.bool.must.push({ match: { [key]: value } });
        if (typeof value === 'string')
            query.query.bool.must.push({
                query_string: {
                    query: `${key}:*${value}*`,
                },
            });
    }

    if (orders && orders.length > 0) {
        query.sort = orders.map((order: Order) => {
            let field = `${order.field}.keyword`;

            let res = {
                [field]: {
                    order: order.value,
                },
            };

            return res;
        });
    }

    console.log(query);

    return JSON.stringify(query);
};
