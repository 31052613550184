import * as React from 'react';
import { Grid } from '@mui/material';
import {
    AdresseJsonldTiersRead,
    ContactJsonldTiersRead,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import type {
    CustomizerReducerType,
    Prescripteur,
    PrescripteursApi,
} from '@europrocurement/l2d-domain';
import { ModalFunctions } from '../../PrescripteurDetail';
import AddressForm from '@b2d/pages/Prescripteurs/forms/AddressForm';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';
import NoteRow from './NoteRow';
import AccordionsSideBar from './AccordionsSideBar';
import ContactAndAddressRow from './ContactAndAddressRow';
import ConfigurationRow from './ConfigurationRow';

export type ActivityAndTrackingProps = {
    prescripteur: Prescripteur;
    idSociete?: CustomizerReducerType['xIdSociete'];
    prescripteursApi: PrescripteursApi;
    reloadPrescripteur: () => void;
    modalFunctions: ModalFunctions;
};

const ActivityAndTracking: React.FunctionComponent<ActivityAndTrackingProps> = function ({
    prescripteur,
    idSociete,
    modalFunctions,
    reloadPrescripteur,
}) {
    const { closeModal, openModal, setModalContent } = modalFunctions;

    const callModal = (modalContent: React.ReactElement) => {
        setModalContent(modalContent);
        openModal();
    };

    const { contacts, adresses: addresses } = prescripteur;

    const getCompany = () => {
        const defaultCompany = prescripteur.relationsSocietes[0];
        const relatedCompany = prescripteur.relationsSocietes.find(
            (relation) => relation.societe?.id === idSociete,
        );

        return idSociete && relatedCompany ? relatedCompany : defaultCompany;
    };

    const companyRelation = getCompany();

    const afterSubmit = () => {
        reloadPrescripteur();
        closeModal();
        setModalContent(null);
    };

    const handleEditAddress = (address: AdresseJsonldTiersRead) => {
        if (prescripteur.id) {
            callModal(
                <AddressForm
                    entity={address}
                    prescriber={prescripteur}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    const handleEditContact = (contact: ContactJsonldTiersRead) => {
        if (prescripteur.id) {
            callModal(
                <ContactForm
                    entity={contact}
                    prescriber={prescripteur}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    return (
        <Grid container spacing={2}>
            {/* Left side */}
            <Grid item xs={9}>
                <Grid container>
                    <ConfigurationRow
                        companyRelation={companyRelation}
                        prescriber={prescripteur}
                        reloadPrescriber={reloadPrescripteur}
                        companyId={idSociete}
                    />
                    <ContactAndAddressRow
                        handleEditContact={handleEditContact}
                        handleEditAddress={handleEditAddress}
                        contacts={contacts}
                        addresses={addresses}
                    />
                    <NoteRow prescriber={prescripteur} reloadPrescripteur={reloadPrescripteur} />
                </Grid>
            </Grid>

            {/* Right side */}
            <Grid item xs={3}>
                <Grid container paddingY={2} direction="column" spacing={3}>
                    <AccordionsSideBar
                        prescriber={prescripteur}
                        companyRelation={companyRelation}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ActivityAndTracking;
