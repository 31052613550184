import * as React from 'react';
import { Grid, GridProps } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { FormStructure } from '..';
// eslint-disable-next-line import/no-cycle
import { FormItemRenderer } from './FormItemRenderer';
import { FlexySubFormProps } from './formStructureRenderer.type';
import { FlexyFormInputProps } from '../FlexyForm';

export type FormStructureRendererProps = FlexySubFormProps & {
    basePath: string;
    shouldUnregister?: boolean;
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
};

export const FormStructureRenderer: React.FunctionComponent<FormStructureRendererProps> =
    function ({
        structure,
        basePath,
        shouldUnregister,
        inputsProps,
        gridProps,
    }: FormStructureRendererProps) {
        return (
            <Grid container {...gridProps}>
                {structure.map((input: FormStructure, index: number) => (
                    <Grid
                        item
                        key={`kfg-${input.name}-${String(index)}`}
                        xs={input.xs || 12}
                        sm={input.sm}
                        md={input.md}
                        lg={input.lg}
                        sx={input.type === 'hidden' ? { display: 'none' } : {}}
                    >
                        <FormItemRenderer
                            input={input}
                            basePath={basePath}
                            shouldUnregister={shouldUnregister}
                            inputsProps={inputsProps}
                            gridProps={gridProps}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };
FormStructureRenderer.defaultProps = {
    shouldUnregister: false,
};

export default FormStructureRenderer;
