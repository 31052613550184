/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Achats
 * API de gestion des achats
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionActionRead
 */
export interface ActionActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionActionRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionActionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionActionRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionActionRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionCodeRejetRead
 */
export interface ActionCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof ActionCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionCodeRejetRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionFactureAchatReadTimestampableBlameambleGroup
 */
export interface ActionFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionFactureAchatReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldActionRead
 */
export interface ActionJsonldActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldActionRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldActionRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldActionRead
     */
    'bloquant'?: boolean;
}
/**
 * @type ActionJsonldActionReadContext
 * @export
 */
export type ActionJsonldActionReadContext = ActionJsonldActionReadContextOneOf | string;

/**
 * 
 * @export
 * @interface ActionJsonldActionReadContextOneOf
 */
export interface ActionJsonldActionReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldActionReadContextOneOf
     */
    'hydra': ActionJsonldActionReadContextOneOfHydraEnum;
}

export const ActionJsonldActionReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type ActionJsonldActionReadContextOneOfHydraEnum = typeof ActionJsonldActionReadContextOneOfHydraEnum[keyof typeof ActionJsonldActionReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ActionJsonldCodeRejetRead
 */
export interface ActionJsonldCodeRejetRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionJsonldCodeRejetRead
     */
    'bloquant'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface ActionJsonldFactureAchatReadTimestampableBlameambleGroup
 */
export interface ActionJsonldFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetCodeRejetRead
 */
export interface CodeRejetCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetCodeRejetRead
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetCodeRejetRead
     */
    'bloquant'?: boolean;
    /**
     * 
     * @type {Array<ActionCodeRejetRead>}
     * @memberof CodeRejetCodeRejetRead
     */
    'actions'?: Array<ActionCodeRejetRead>;
}
/**
 * 
 * @export
 * @interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldCodeRejetRead
 */
export interface CodeRejetJsonldCodeRejetRead {
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'manuel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'bloquant'?: boolean;
    /**
     * 
     * @type {Array<ActionJsonldCodeRejetRead>}
     * @memberof CodeRejetJsonldCodeRejetRead
     */
    'actions'?: Array<ActionJsonldCodeRejetRead>;
}
/**
 * 
 * @export
 * @interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'libelle'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'action'?: ActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'action'?: ActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatActionFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionFactureAchatReadTimestampableBlameambleGroup}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'action'?: ActionFactureAchatReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionFactureAchatReadTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'action'?: ActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'action'?: ActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ActionJsonldFactureAchatReadTimestampableBlameambleGroup}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'action'?: ActionJsonldFactureAchatReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'codeRejet'?: CodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'etat'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat
 */
export interface FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCodeRejetUpdateEtat
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
     */
    'codeRejet'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetFactureAchatWrite
 */
export interface FactureAchatCodeRejetFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetFactureAchatWrite
     */
    'codeRejet'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'codeRejet'?: CodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'etat'?: string;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    'codeRejet'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatCodeRejetJsonldFactureAchatWrite
 */
export interface FactureAchatCodeRejetJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    'codeRejet'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatCodeRejetJsonldFactureAchatWrite
     */
    'idDossier'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatDossierJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'notes'?: Array<FactureAchatNoteFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatLigneFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatCreateFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadDossier
 */
export interface FactureAchatFactureAchatReadDossier {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossier
     */
    'id'?: number;
    /**
     * dossiers de la facture
     * @type {Array<FactureAchatFactureAchatReadDossierDossiersInner>}
     * @memberof FactureAchatFactureAchatReadDossier
     */
    'dossiers'?: Array<FactureAchatFactureAchatReadDossierDossiersInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadDossierDossiersInner
 */
export interface FactureAchatFactureAchatReadDossierDossiersInner {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'numeroDossier'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'idPrestation'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'libelleAnnonceur'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadDossierDossiersInner
     */
    'numeroPrestation'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'countDossier'?: number | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'countDossier'?: number | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner
     */
    'id'?: number;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner
     */
    'codeRejet'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner
     */
    'etat'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet
     */
    'categorie'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInnerCodeRejet
     */
    'libelle'?: string;
}
/**
 * Premier dossier de la facture
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
     */
    'idDossier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
     */
    'numeroDossier'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
     */
    'idPrestation'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
     */
    'libelleAnnonceur'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier
     */
    'numeroPrestation'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionFactureAchatReadTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionFactureAchatReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup>}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatSyncG3
 */
export interface FactureAchatFactureAchatSyncG3 {
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatSyncG3
     */
    'syncG3'?: boolean;
}
/**
 * 
 * @export
 * @interface FactureAchatFactureAchatWrite
 */
export interface FactureAchatFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatNoteFactureAchatWrite>}
     * @memberof FactureAchatFactureAchatWrite
     */
    'notes'?: Array<FactureAchatNoteFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalRead
 */
export interface FactureAchatJalFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'numeroPiece'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'exportCompta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateExportCompta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'libelleCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'vuEnCompta'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureMultiAnnonce'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureAValider'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'factureImportee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateimport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'rrrFacture'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateRrrFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'vuCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'dateVuCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'fichierPdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidDateComptable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'userNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'montantRrrFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxRemiseJalReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'idFactureRbtFraisAchats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'comptaControle'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'logMajRemiseTaux'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'achatRrr'?: boolean;
    /**
     * 
     * @type {FactureAchatJalFactureAchatJalReadTypeRemiseJal}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'typeRemiseJal'?: FactureAchatJalFactureAchatJalReadTypeRemiseJal | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteFrais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htAutre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaAutre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaAutre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htPublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaPublicite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaPublicite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidComptePublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htPubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaPubliciteNs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaPubliciteNs'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidComptePubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaJustif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaRcsSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaRcsSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaRcsNonSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htAdju'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaAdju'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaAdju'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteAdju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htHonorairesFormalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaHonorairesFormalite'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaHonorairesFormalite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteHonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'htMarchePublic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tvaMarchePublic'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'tauxTvaMarchePublic'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidCompteMarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidSp2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'cegidTl3MarchePublic'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'litige'?: boolean;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneFactureAchatJalRead>}
     * @memberof FactureAchatJalFactureAchatJalRead
     */
    'lignes'?: Array<TblFactureAchatJalLigneFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalReadTypeRemiseJal
 */
export interface FactureAchatJalFactureAchatJalReadTypeRemiseJal {
}
/**
 * 
 * @export
 * @interface FactureAchatJalFactureAchatJalWrite
 */
export interface FactureAchatJalFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneFactureAchatJalWrite>}
     * @memberof FactureAchatJalFactureAchatJalWrite
     */
    'lignes'?: Array<TblFactureAchatJalLigneFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalRead
 */
export interface FactureAchatJalJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'numeroPiece'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'exportCompta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateExportCompta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'libelleCompta'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'vuEnCompta'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureMultiAnnonce'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureAValider'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'factureImportee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateimport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'rrrFacture'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateRrrFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'vuCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'dateVuCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'fichierPdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidDateComptable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'userNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'montantRrrFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxRemiseJalReel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'idFactureRbtFraisAchats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'comptaControle'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'logMajRemiseTaux'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'achatRrr'?: boolean;
    /**
     * 
     * @type {FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'typeRemiseJal'?: FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteFrais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Frais'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htAutre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaAutre'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaAutre'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htPublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaPublicite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaPublicite'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidComptePublicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Publicite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htPubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaPubliciteNs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaPubliciteNs'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidComptePubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3PubliciteNs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaJustif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaJustif'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteJustif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Justif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaRcsSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaRcsSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteRcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3RcsSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaRcsNonSoumis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteRcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3RcsNonSoumis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htAdju'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaAdju'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaAdju'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteAdju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3Adju'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htHonorairesFormalite'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaHonorairesFormalite'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaHonorairesFormalite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteHonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3HonorairesFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'htMarchePublic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tvaMarchePublic'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'tauxTvaMarchePublic'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidCompteMarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidSp2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl1MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl2MarchePublic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'cegidTl3MarchePublic'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'litige'?: boolean;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneJsonldFactureAchatJalRead>}
     * @memberof FactureAchatJalJsonldFactureAchatJalRead
     */
    'lignes'?: Array<TblFactureAchatJalLigneJsonldFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
 */
export interface FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalReadTypeRemiseJal
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatJalJsonldFactureAchatJalWrite
 */
export interface FactureAchatJalJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'lienPdfFactureImportee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalLigneJsonldFactureAchatJalWrite>}
     * @memberof FactureAchatJalJsonldFactureAchatJalWrite
     */
    'lignes'?: Array<TblFactureAchatJalLigneJsonldFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetJsonldFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'lignes'?: Array<FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatCreateFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadDossier
 */
export interface FactureAchatJsonldFactureAchatReadDossier {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    'id'?: number;
    /**
     * dossiers de la facture
     * @type {Array<FactureAchatFactureAchatReadDossierDossiersInner>}
     * @memberof FactureAchatJsonldFactureAchatReadDossier
     */
    'dossiers'?: Array<FactureAchatFactureAchatReadDossierDossiersInner>;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'countDossier'?: number | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'firstDossier'?: FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstDossier | null;
    /**
     * nombre de dossiers
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'countDossier'?: number | null;
    /**
     * Deux premiers codes rejets de la facture
     * @type {Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'firstCodesRejets'?: Array<FactureAchatFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupFirstCodesRejetsInner>;
    /**
     * nombre de codes rejets
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'countCodesRejets'?: number | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'idFactureAchatJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'syncG3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'actions'?: Array<FactureAchatActionJsonldFactureAchatReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup>}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'pdfFacture'?: FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'ttc'?: string | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
 */
export interface FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroupPdfFacture
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatSyncG3
 */
export interface FactureAchatJsonldFactureAchatSyncG3 {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatSyncG3
     */
    'syncG3'?: boolean;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldFactureAchatWrite
 */
export interface FactureAchatJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idJal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'dateFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idEntiteFacturante'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'libelleFacturante'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'rcsEntiteFacturante'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idTypeTarif'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'cloture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'avoir'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'hasSetRsf'?: boolean;
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'codesRejets'?: Array<FactureAchatCodeRejetJsonldFactureAchatWrite>;
    /**
     * 
     * @type {Array<FactureAchatNoteJsonldFactureAchatWrite>}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'notes'?: Array<FactureAchatNoteJsonldFactureAchatWrite>;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'pdfFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldFactureAchatWrite
     */
    'ttc'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
 */
export interface FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatJsonldStatsRead
 */
export interface FactureAchatJsonldStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldStatsRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatJsonldStatsRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatLigneWrite
 */
export interface FactureAchatLigneDetailFactureAchatLigneWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailFactureAchatRead
 */
export interface FactureAchatLigneDetailFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailFactureAchatRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatLigneWrite
 */
export interface FactureAchatLigneDetailJsonldFactureAchatLigneWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneDetailJsonldFactureAchatRead
 */
export interface FactureAchatLigneDetailJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'idRubriqueFacturation'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'quantite'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'ht'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'tva'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'tauxTva'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneDetailJsonldFactureAchatRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatLigneFactureAchatCreateFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatCreateFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatLigneWrite
 */
export interface FactureAchatLigneFactureAchatLigneWrite {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idDossier': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idPrestation': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatLigneWrite>}
     * @memberof FactureAchatLigneFactureAchatLigneWrite
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatLigneWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossier': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idPrestation': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate>}
     * @memberof FactureAchatLigneFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatLigneWriteFactureAchatLigneCreate>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneFactureAchatRead
 */
export interface FactureAchatLigneFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailFactureAchatRead>}
     * @memberof FactureAchatLigneFactureAchatRead
     */
    'details'?: Array<FactureAchatLigneDetailFactureAchatRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite>}
     * @memberof FactureAchatLigneJsonldFactureAchatCreateFactureAchatWrite
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatCreateFactureAchatWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatLigneWrite
 */
export interface FactureAchatLigneJsonldFactureAchatLigneWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idDossier': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idPrestation': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatLigneWrite>}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWrite
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatLigneWrite>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
 */
export interface FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'facture'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idDossier': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idPrestation': number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate>}
     * @memberof FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatLigneWriteFactureAchatLigneCreate>;
}
/**
 * 
 * @export
 * @interface FactureAchatLigneJsonldFactureAchatRead
 */
export interface FactureAchatLigneJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'numeroDossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'libelleAnnonceur'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'numeroPrestation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatLigneDetailJsonldFactureAchatRead>}
     * @memberof FactureAchatLigneJsonldFactureAchatRead
     */
    'details'?: Array<FactureAchatLigneDetailJsonldFactureAchatRead>;
}
/**
 * 
 * @export
 * @interface FactureAchatMediaObjectReadTimestampableBlameambleGroup
 */
export interface FactureAchatMediaObjectReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'numeroFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'forced'?: boolean;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof FactureAchatMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatNoteFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatCreateFactureAchatWrite
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatRead
 */
export interface FactureAchatNoteFactureAchatRead {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatReadTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteFactureAchatWrite
 */
export interface FactureAchatNoteFactureAchatWrite {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteFactureAchatWrite
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite
 */
export interface FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatCreateFactureAchatWrite
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatRead
 */
export interface FactureAchatNoteJsonldFactureAchatRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatRead
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
 */
export interface FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'userCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'userMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatNoteJsonldFactureAchatWrite
 */
export interface FactureAchatNoteJsonldFactureAchatWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof FactureAchatNoteJsonldFactureAchatWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatNoteJsonldFactureAchatWrite
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface FactureAchatStatsJsonldStatsRead
 */
export interface FactureAchatStatsJsonldStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatStatsJsonldStatsRead
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface FactureAchatStatsStatsRead
 */
export interface FactureAchatStatsStatsRead {
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsStatsRead
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureAchatStatsStatsRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureAchatStatsStatsRead
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetActionCollection200Response
 */
export interface GetActionCollection200Response {
    /**
     * 
     * @type {Array<ActionJsonldActionRead>}
     * @memberof GetActionCollection200Response
     */
    'hydra:member': Array<ActionJsonldActionRead>;
    /**
     * 
     * @type {number}
     * @memberof GetActionCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetActionCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetActionCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraSearch
 */
export interface GetActionCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetActionCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetActionCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetActionCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetActionCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetActionCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetActionCollection200ResponseHydraView
 */
export interface GetActionCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetActionCollection401Response
 */
export interface GetActionCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GetActionCollection403Response
 */
export interface GetActionCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {GetActionCollection403ResponseException}
     * @memberof GetActionCollection403Response
     */
    'exception'?: GetActionCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface GetActionCollection403ResponseException
 */
export interface GetActionCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof GetActionCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetActionCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCategorieFaStatsFactureAchatCollection200Response
 */
export interface GetCategorieFaStatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatStatsJsonldStatsRead>}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:member': Array<FactureAchatStatsJsonldStatsRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetCategorieFaStatsFactureAchatCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCodeRejetCollection200Response
 */
export interface GetCodeRejetCollection200Response {
    /**
     * 
     * @type {Array<CodeRejetJsonldCodeRejetRead>}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:member': Array<CodeRejetJsonldCodeRejetRead>;
    /**
     * 
     * @type {number}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetCodeRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetCoderejetFaStatsFactureAchatCollection200Response
 */
export interface GetCoderejetFaStatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner>}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200Response
     */
    'hydra:member'?: Array<GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner>;
    /**
     * 
     * @type {number}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
}
/**
 * 
 * @export
 * @interface GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
 */
export interface GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner {
    /**
     * 
     * @type {string}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCoderejetFaStatsFactureAchatCollection200ResponseHydraMemberInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
 */
export interface GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:member': Array<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFacturesAchatsFactureAchatCollection200Response
 */
export interface GetFacturesAchatsFactureAchatCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:member': Array<FactureAchatJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFacturesAchatsFactureAchatCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFacturesAchatsJalsFactureAchatJalCollection200Response
 */
export interface GetFacturesAchatsJalsFactureAchatJalCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatJalJsonldFactureAchatJalRead>}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:member': Array<FactureAchatJalJsonldFactureAchatJalRead>;
    /**
     * 
     * @type {number}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetFacturesAchatsJalsFactureAchatJalCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetLignesFactureAchatFactureAchatLigneCollection200Response
 */
export interface GetLignesFactureAchatFactureAchatLigneCollection200Response {
    /**
     * 
     * @type {Array<FactureAchatLigneJsonldFactureAchatRead>}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:member': Array<FactureAchatLigneJsonldFactureAchatRead>;
    /**
     * 
     * @type {number}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof GetLignesFactureAchatFactureAchatLigneCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ListMediaObjectMediaObjectCollection200Response
 */
export interface ListMediaObjectMediaObjectCollection200Response {
    /**
     * 
     * @type {Array<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup>}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:member': Array<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup>;
    /**
     * 
     * @type {number}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraView}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:view'?: GetActionCollection200ResponseHydraView;
    /**
     * 
     * @type {GetActionCollection200ResponseHydraSearch}
     * @memberof ListMediaObjectMediaObjectCollection200Response
     */
    'hydra:search'?: GetActionCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface MediaObject
 */
export interface MediaObject {
    /**
     * 
     * @type {File}
     * @memberof MediaObject
     */
    'file'?: File | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObject
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObject
     */
    'dateDebutTraitement'?: string | null;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObject
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectDateDebutTraitement
 */
export interface MediaObjectDateDebutTraitement {
    /**
     * 
     * @type {string}
     * @memberof MediaObjectDateDebutTraitement
     */
    'dateDebutTraitement'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectFactureAchatReadTimestampableBlameambleGroup
 */
export interface MediaObjectFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldDateDebutTraitement
 */
export interface MediaObjectJsonldDateDebutTraitement {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldDateDebutTraitement
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitement
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitement
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldDateDebutTraitement
     */
    'dateDebutTraitement'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldFactureAchatReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
 */
export interface MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup>}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'facturesAchat'?: Array<FactureAchatJsonldMediaObjectReadTimestampableBlameambleGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaObjectMediaObjectReadTimestampableBlameambleGroup
 */
export interface MediaObjectMediaObjectReadTimestampableBlameambleGroup {
    /**
     * 
     * @type {number}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'dateDebutTraitement'?: string | null;
    /**
     * 
     * @type {Array<FactureAchatMediaObjectReadTimestampableBlameambleGroup>}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'facturesAchat'?: Array<FactureAchatMediaObjectReadTimestampableBlameambleGroup>;
    /**
     * Date de création de l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'createdAt'?: string;
    /**
     * Date de création de modification de l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedAt'?: string;
    /**
     * Identifiant de l\'utilisateur qui a créé l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'createdBy'?: string | null;
    /**
     * Identifiant de l\'utilisateur qui a modifié l\'entité
     * @type {string}
     * @memberof MediaObjectMediaObjectReadTimestampableBlameambleGroup
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailFactureAchatJalRead
 */
export interface TblFactureAchatJalDetailFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailFactureAchatJalWrite
 */
export interface TblFactureAchatJalDetailFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailFactureAchatJalWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailJsonldFactureAchatJalRead
 */
export interface TblFactureAchatJalDetailJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalRead
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalDetailJsonldFactureAchatJalWrite
 */
export interface TblFactureAchatJalDetailJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'idFLigneVente'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'idRubriqueFacturation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'tva'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'tauxTva'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalDetailJsonldFactureAchatJalWrite
     */
    'cegidTl3'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneFactureAchatJalRead
 */
export interface TblFactureAchatJalLigneFactureAchatJalRead {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailFactureAchatJalRead>}
     * @memberof TblFactureAchatJalLigneFactureAchatJalRead
     */
    'details'?: Array<TblFactureAchatJalDetailFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneFactureAchatJalWrite
 */
export interface TblFactureAchatJalLigneFactureAchatJalWrite {
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailFactureAchatJalWrite>}
     * @memberof TblFactureAchatJalLigneFactureAchatJalWrite
     */
    'details'?: Array<TblFactureAchatJalDetailFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneJsonldFactureAchatJalRead
 */
export interface TblFactureAchatJalLigneJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'quiCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'quiModif'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'dateCree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'dateModif'?: string | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailJsonldFactureAchatJalRead>}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalRead
     */
    'details'?: Array<TblFactureAchatJalDetailJsonldFactureAchatJalRead>;
}
/**
 * 
 * @export
 * @interface TblFactureAchatJalLigneJsonldFactureAchatJalWrite
 */
export interface TblFactureAchatJalLigneJsonldFactureAchatJalWrite {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'idPrestation'?: number | null;
    /**
     * 
     * @type {Array<TblFactureAchatJalDetailJsonldFactureAchatJalWrite>}
     * @memberof TblFactureAchatJalLigneJsonldFactureAchatJalWrite
     */
    'details'?: Array<TblFactureAchatJalDetailJsonldFactureAchatJalWrite>;
}
/**
 * 
 * @export
 * @interface TblJalTypeRemiseJsonldFactureAchatJalRead
 */
export interface TblJalTypeRemiseJsonldFactureAchatJalRead {
    /**
     * 
     * @type {ActionJsonldActionReadContext}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@context'?: ActionJsonldActionReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblJalTypeRemiseJsonldFactureAchatJalRead
     */
    '@type'?: string;
}

/**
 * ActionApi - axios parameter creator
 * @export
 */
export const ActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionItem: async (code: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getActionItem', 'code', code)
            const localVarPath = `/achats/actions/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionApi - functional programming interface
 * @export
 */
export const ActionApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActionCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionItem(code, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionApi - factory interface
 * @export
 */
export const ActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionApiFp(configuration)
    return {
        /**
         * Récupérer la liste des actions
         * @summary Récupérer la liste des actions
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<GetActionCollection200Response> {
            return localVarFp.getActionCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionItem(code: string, xIdSociete?: number, options?: any): AxiosPromise<ActionJsonldActionRead> {
            return localVarFp.getActionItem(code, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionApi - object-oriented interface
 * @export
 * @class ActionApi
 * @extends {BaseAPI}
 */
export class ActionApi extends BaseAPI {
    /**
     * Récupérer la liste des actions
     * @summary Récupérer la liste des actions
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public getActionCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).getActionCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une action
     * @summary Récupérer les informations d\'une action
     * @param {string} code Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public getActionItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).getActionItem(code, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodeRejetApi - axios parameter creator
 * @export
 */
export const CodeRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/code_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetItem: async (code: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getCodeRejetItem', 'code', code)
            const localVarPath = `/achats/code_rejets/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeRejetApi - functional programming interface
 * @export
 */
export const CodeRejetApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCodeRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeRejetCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeRejetItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeRejetJsonldCodeRejetRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeRejetItem(code, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodeRejetApi - factory interface
 * @export
 */
export const CodeRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeRejetApiFp(configuration)
    return {
        /**
         * Récupérer la liste des codes rejets
         * @summary Récupérer la liste des codes rejets
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<GetCodeRejetCollection200Response> {
            return localVarFp.getCodeRejetCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet
         * @summary Récupérer les informations d\'un code rejet
         * @param {string} code Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeRejetItem(code: string, xIdSociete?: number, options?: any): AxiosPromise<CodeRejetJsonldCodeRejetRead> {
            return localVarFp.getCodeRejetItem(code, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeRejetApi - object-oriented interface
 * @export
 * @class CodeRejetApi
 * @extends {BaseAPI}
 */
export class CodeRejetApi extends BaseAPI {
    /**
     * Récupérer la liste des codes rejets
     * @summary Récupérer la liste des codes rejets
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeRejetApi
     */
    public getCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return CodeRejetApiFp(this.configuration).getCodeRejetCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet
     * @summary Récupérer les informations d\'un code rejet
     * @param {string} code Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeRejetApi
     */
    public getCodeRejetItem(code: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return CodeRejetApiFp(this.configuration).getCodeRejetItem(code, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatApi - axios parameter creator
 * @export
 */
export const FactureAchatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatFactureAchatCollection: async (factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatJsonldFactureAchatCreateFactureAchatWrite' is not null or undefined
            assertParamExists('createFactureAchatFactureAchatCollection', 'factureAchatJsonldFactureAchatCreateFactureAchatWrite', factureAchatJsonldFactureAchatCreateFactureAchatWrite)
            const localVarPath = `/achats/facture_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatCreateFactureAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFactureAchatFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieFaStatsFactureAchatCollection: async (xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/categorie_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (codesRejets !== undefined) {
                localVarQueryParameter['codesRejets'] = codesRejets;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoderejetFaStatsFactureAchatCollection: async (xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat/coderejet_stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (categories !== undefined) {
                localVarQueryParameter['categories'] = categories;
            }

            if (codesRejets !== undefined) {
                localVarQueryParameter['codesRejets'] = codesRejets;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDossiersFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}/dossiers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetFactureAchatCodeRejetItem: async (facture: number, codeRejet: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facture' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetFactureAchatCodeRejetItem', 'facture', facture)
            // verify required parameter 'codeRejet' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetFactureAchatCodeRejetItem', 'codeRejet', codeRejet)
            const localVarPath = `/achats/facture_achats/{facture}/code_rejets/{codeRejet}/etat`
                .replace(`{${"facture"}}`, encodeURIComponent(String(facture)))
                .replace(`{${"codeRejet"}}`, encodeURIComponent(String(codeRejet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodesRejetsFactureAchatCodeRejetCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_code_rejets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (factureId !== undefined) {
                localVarQueryParameter['facture.id'] = factureId;
            }

            if (factureId2) {
                localVarQueryParameter['facture.id[]'] = factureId2;
            }

            if (idDossier !== undefined) {
                localVarQueryParameter['idDossier'] = idDossier;
            }

            if (idDossier2) {
                localVarQueryParameter['idDossier[]'] = idDossier2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatFactureAchatItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatFactureAchatItem', 'id', id)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsCodesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [syncG3] 
         * @param {boolean} [deleted] 
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [libelleFacturante] 
         * @param {string} [lignesNumeroDossier] 
         * @param {string} [lignesNumeroPrestation] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [lignesIdPrestation] 
         * @param {Array<number>} [lignesIdPrestation2] 
         * @param {string} [numeroFacture] 
         * @param {string} [codesRejetsCodeRejetCategorie] 
         * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
         * @param {string} [codesRejetsCodeRejetCode] 
         * @param {Array<string>} [codesRejetsCodeRejetCode2] 
         * @param {string} [recherche] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsFactureAchatCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (existsCodesRejets !== undefined) {
                localVarQueryParameter['exists[codesRejets]'] = existsCodesRejets;
            }

            if (cloture !== undefined) {
                localVarQueryParameter['cloture'] = cloture;
            }

            if (syncG3 !== undefined) {
                localVarQueryParameter['syncG3'] = syncG3;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (dateFactureBefore !== undefined) {
                localVarQueryParameter['dateFacture[before]'] = dateFactureBefore;
            }

            if (dateFactureStrictlyBefore !== undefined) {
                localVarQueryParameter['dateFacture[strictly_before]'] = dateFactureStrictlyBefore;
            }

            if (dateFactureAfter !== undefined) {
                localVarQueryParameter['dateFacture[after]'] = dateFactureAfter;
            }

            if (dateFactureStrictlyAfter !== undefined) {
                localVarQueryParameter['dateFacture[strictly_after]'] = dateFactureStrictlyAfter;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (libelleFacturante !== undefined) {
                localVarQueryParameter['libelleFacturante'] = libelleFacturante;
            }

            if (lignesNumeroDossier !== undefined) {
                localVarQueryParameter['lignes.numeroDossier'] = lignesNumeroDossier;
            }

            if (lignesNumeroPrestation !== undefined) {
                localVarQueryParameter['lignes.numeroPrestation'] = lignesNumeroPrestation;
            }

            if (updatedBy !== undefined) {
                localVarQueryParameter['updatedBy'] = updatedBy;
            }

            if (updatedBy2) {
                localVarQueryParameter['updatedBy[]'] = updatedBy2;
            }

            if (idEntiteFacturante !== undefined) {
                localVarQueryParameter['idEntiteFacturante'] = idEntiteFacturante;
            }

            if (idEntiteFacturante2) {
                localVarQueryParameter['idEntiteFacturante[]'] = idEntiteFacturante2;
            }

            if (lignesIdDossier !== undefined) {
                localVarQueryParameter['lignes.idDossier'] = lignesIdDossier;
            }

            if (lignesIdDossier2) {
                localVarQueryParameter['lignes.idDossier[]'] = lignesIdDossier2;
            }

            if (lignesIdPrestation !== undefined) {
                localVarQueryParameter['lignes.idPrestation'] = lignesIdPrestation;
            }

            if (lignesIdPrestation2) {
                localVarQueryParameter['lignes.idPrestation[]'] = lignesIdPrestation2;
            }

            if (numeroFacture !== undefined) {
                localVarQueryParameter['numeroFacture'] = numeroFacture;
            }

            if (codesRejetsCodeRejetCategorie !== undefined) {
                localVarQueryParameter['codesRejets.codeRejet.categorie'] = codesRejetsCodeRejetCategorie;
            }

            if (codesRejetsCodeRejetCategorie2) {
                localVarQueryParameter['codesRejets.codeRejet.categorie[]'] = codesRejetsCodeRejetCategorie2;
            }

            if (codesRejetsCodeRejetCode !== undefined) {
                localVarQueryParameter['codesRejets.codeRejet.code'] = codesRejetsCodeRejetCode;
            }

            if (codesRejetsCodeRejetCode2) {
                localVarQueryParameter['codesRejets.codeRejet.code[]'] = codesRejetsCodeRejetCode2;
            }

            if (recherche !== undefined) {
                localVarQueryParameter['recherche'] = recherche;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderUpdatedAt !== undefined) {
                localVarQueryParameter['order[updatedAt]'] = orderUpdatedAt;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncG3FactureAchatFactureAchatItem: async (id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('syncG3FactureAchatFactureAchatItem', 'id', id)
            // verify required parameter 'factureAchatJsonldFactureAchatSyncG3' is not null or undefined
            assertParamExists('syncG3FactureAchatFactureAchatItem', 'factureAchatJsonldFactureAchatSyncG3', factureAchatJsonldFactureAchatSyncG3)
            const localVarPath = `/achats/facture_achats/{id}/sync_g3`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatSyncG3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem: async (facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facture' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'facture', facture)
            // verify required parameter 'codeRejet' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'codeRejet', codeRejet)
            // verify required parameter 'factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat' is not null or undefined
            assertParamExists('updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem', 'factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat', factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat)
            const localVarPath = `/achats/facture_achats/{facture}/code_rejets/{codeRejet}/etat`
                .replace(`{${"facture"}}`, encodeURIComponent(String(facture)))
                .replace(`{${"codeRejet"}}`, encodeURIComponent(String(codeRejet)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatFactureAchatItem: async (id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatFactureAchatItem', 'id', id)
            // verify required parameter 'factureAchatJsonldFactureAchatWrite' is not null or undefined
            assertParamExists('updateFactureAchatFactureAchatItem', 'factureAchatJsonldFactureAchatWrite', factureAchatJsonldFactureAchatWrite)
            const localVarPath = `/achats/facture_achats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJsonldFactureAchatWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatApi - functional programming interface
 * @export
 */
export const FactureAchatApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFactureAchatFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategorieFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategorieFaStatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategorieFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoderejetFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCoderejetFaStatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoderejetFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDossiersFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadDossier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDossiersFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatFactureAchatItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsCodesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [syncG3] 
         * @param {boolean} [deleted] 
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [libelleFacturante] 
         * @param {string} [lignesNumeroDossier] 
         * @param {string} [lignesNumeroPrestation] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [lignesIdPrestation] 
         * @param {Array<number>} [lignesIdPrestation2] 
         * @param {string} [numeroFacture] 
         * @param {string} [codesRejetsCodeRejetCategorie] 
         * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
         * @param {string} [codesRejetsCodeRejetCode] 
         * @param {Array<string>} [codesRejetsCodeRejetCode2] 
         * @param {string} [recherche] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacturesAchatsFactureAchatCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFacturesAchatsFactureAchatCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacturesAchatsFactureAchatCollection(xIdSociete, page, itemsPerPage, existsCodesRejets, cloture, syncG3, deleted, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, libelleFacturante, lignesNumeroDossier, lignesNumeroPrestation, updatedBy, updatedBy2, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, lignesIdPrestation, lignesIdPrestation2, numeroFacture, codesRejetsCodeRejetCategorie, codesRejetsCodeRejetCategorie2, codesRejetsCodeRejetCode, codesRejetsCodeRejetCode2, recherche, orderId, orderUpdatedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncG3FactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncG3FactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatSyncG3, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatApi - factory interface
 * @export
 */
export const FactureAchatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatApiFp(configuration)
    return {
        /**
         * Créer un facture d\'achat
         * @summary Créer un facture d\'achat
         * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup> {
            return localVarFp.createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une facture d\'achat
         * @summary Supprimer une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFactureAchatFactureAchatItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des stats des categories des factures d\'achats
         * @summary Récupérer la liste des stats des categories des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategorieFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: any): AxiosPromise<GetCategorieFaStatsFactureAchatCollection200Response> {
            return localVarFp.getCategorieFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des stats des codes rejets des factures d\'achats
         * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {string} [categories] 
         * @param {string} [codesRejets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoderejetFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: any): AxiosPromise<GetCoderejetFaStatsFactureAchatCollection200Response> {
            return localVarFp.getCoderejetFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des dossiers associés à une facture d\'achat
         * @summary Récupérer la liste des dossiers associés à une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersFactureAchatItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJsonldFactureAchatReadDossier> {
            return localVarFp.getDossiersFactureAchatItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: any): AxiosPromise<GetFactureAchatCodesRejetsFactureAchatCodeRejetCollection200Response> {
            return localVarFp.getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture d\'achat
         * @summary Récupérer les informations d\'une facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJsonldFactureAchatReadFactureAchatReadItemTimestampableBlameambleGroup> {
            return localVarFp.getFactureAchatFactureAchatItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des factures d\'achats
         * @summary Récupérer la liste des factures d\'achats
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [existsCodesRejets] 
         * @param {boolean} [cloture] 
         * @param {boolean} [syncG3] 
         * @param {boolean} [deleted] 
         * @param {string} [dateFactureBefore] 
         * @param {string} [dateFactureStrictlyBefore] 
         * @param {string} [dateFactureAfter] 
         * @param {string} [dateFactureStrictlyAfter] 
         * @param {string} [createdAtBefore] 
         * @param {string} [createdAtStrictlyBefore] 
         * @param {string} [createdAtAfter] 
         * @param {string} [createdAtStrictlyAfter] 
         * @param {string} [libelleFacturante] 
         * @param {string} [lignesNumeroDossier] 
         * @param {string} [lignesNumeroPrestation] 
         * @param {string} [updatedBy] 
         * @param {Array<string>} [updatedBy2] 
         * @param {number} [idEntiteFacturante] 
         * @param {Array<number>} [idEntiteFacturante2] 
         * @param {number} [lignesIdDossier] 
         * @param {Array<number>} [lignesIdDossier2] 
         * @param {number} [lignesIdPrestation] 
         * @param {Array<number>} [lignesIdPrestation2] 
         * @param {string} [numeroFacture] 
         * @param {string} [codesRejetsCodeRejetCategorie] 
         * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
         * @param {string} [codesRejetsCodeRejetCode] 
         * @param {Array<string>} [codesRejetsCodeRejetCode2] 
         * @param {string} [recherche] 
         * @param {'asc' | 'desc'} [orderId] 
         * @param {'asc' | 'desc'} [orderUpdatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsFactureAchatCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options?: any): AxiosPromise<GetFacturesAchatsFactureAchatCollection200Response> {
            return localVarFp.getFacturesAchatsFactureAchatCollection(xIdSociete, page, itemsPerPage, existsCodesRejets, cloture, syncG3, deleted, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, libelleFacturante, lignesNumeroDossier, lignesNumeroPrestation, updatedBy, updatedBy2, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, lignesIdPrestation, lignesIdPrestation2, numeroFacture, codesRejetsCodeRejetCategorie, codesRejetsCodeRejetCategorie2, codesRejetsCodeRejetCode, codesRejetsCodeRejetCode2, recherche, orderId, orderUpdatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Insérer une facture d\'achat dans G3
         * @summary Insérer une facture d\'achat dans G3
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncG3FactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup> {
            return localVarFp.syncG3FactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatSyncG3, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
         * @param {number} facture Identifiant de la facture d\&#39;achat
         * @param {string} codeRejet Identifiant du code rejet
         * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une facture d\'achat
         * @summary Mettre à jour une facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJsonldFactureAchatReadTimestampableBlameambleGroup> {
            return localVarFp.updateFactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatWrite, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FactureAchatApi - object-oriented interface
 * @export
 * @class FactureAchatApi
 * @extends {BaseAPI}
 */
export class FactureAchatApi extends BaseAPI {
    /**
     * Créer un facture d\'achat
     * @summary Créer un facture d\'achat
     * @param {FactureAchatJsonldFactureAchatCreateFactureAchatWrite} factureAchatJsonldFactureAchatCreateFactureAchatWrite The new FactureAchat resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite: FactureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).createFactureAchatFactureAchatCollection(factureAchatJsonldFactureAchatCreateFactureAchatWrite, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une facture d\'achat
     * @summary Supprimer une facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public deleteFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).deleteFactureAchatFactureAchatItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des stats des categories des factures d\'achats
     * @summary Récupérer la liste des stats des categories des factures d\'achats
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {string} [dateFactureBefore] 
     * @param {string} [dateFactureStrictlyBefore] 
     * @param {string} [dateFactureAfter] 
     * @param {string} [dateFactureStrictlyAfter] 
     * @param {string} [createdAtBefore] 
     * @param {string} [createdAtStrictlyBefore] 
     * @param {string} [createdAtAfter] 
     * @param {string} [createdAtStrictlyAfter] 
     * @param {number} [idEntiteFacturante] 
     * @param {Array<number>} [idEntiteFacturante2] 
     * @param {string} [categories] 
     * @param {string} [codesRejets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getCategorieFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getCategorieFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des stats des codes rejets des factures d\'achats
     * @summary Récupérer la liste des stats des codes rejets des factures d\'achats
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {string} [dateFactureBefore] 
     * @param {string} [dateFactureStrictlyBefore] 
     * @param {string} [dateFactureAfter] 
     * @param {string} [dateFactureStrictlyAfter] 
     * @param {string} [createdAtBefore] 
     * @param {string} [createdAtStrictlyBefore] 
     * @param {string} [createdAtAfter] 
     * @param {string} [createdAtStrictlyAfter] 
     * @param {number} [idEntiteFacturante] 
     * @param {Array<number>} [idEntiteFacturante2] 
     * @param {string} [categories] 
     * @param {string} [codesRejets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getCoderejetFaStatsFactureAchatCollection(xIdSociete?: number, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, categories?: string, codesRejets?: string, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getCoderejetFaStatsFactureAchatCollection(xIdSociete, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, idEntiteFacturante, idEntiteFacturante2, categories, codesRejets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des dossiers associés à une facture d\'achat
     * @summary Récupérer la liste des dossiers associés à une facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getDossiersFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getDossiersFactureAchatItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @param {number} facture Identifiant de la facture d\&#39;achat
     * @param {string} codeRejet Identifiant du code rejet
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @summary Récupérer les informations d\'un code rejet associé à une facture d\'achat
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [factureId] 
     * @param {Array<number>} [factureId2] 
     * @param {number} [idDossier] 
     * @param {Array<number>} [idDossier2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatCodesRejetsFactureAchatCodeRejetCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture d\'achat
     * @summary Récupérer les informations d\'une facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFactureAchatFactureAchatItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFactureAchatFactureAchatItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des factures d\'achats
     * @summary Récupérer la liste des factures d\'achats
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [existsCodesRejets] 
     * @param {boolean} [cloture] 
     * @param {boolean} [syncG3] 
     * @param {boolean} [deleted] 
     * @param {string} [dateFactureBefore] 
     * @param {string} [dateFactureStrictlyBefore] 
     * @param {string} [dateFactureAfter] 
     * @param {string} [dateFactureStrictlyAfter] 
     * @param {string} [createdAtBefore] 
     * @param {string} [createdAtStrictlyBefore] 
     * @param {string} [createdAtAfter] 
     * @param {string} [createdAtStrictlyAfter] 
     * @param {string} [libelleFacturante] 
     * @param {string} [lignesNumeroDossier] 
     * @param {string} [lignesNumeroPrestation] 
     * @param {string} [updatedBy] 
     * @param {Array<string>} [updatedBy2] 
     * @param {number} [idEntiteFacturante] 
     * @param {Array<number>} [idEntiteFacturante2] 
     * @param {number} [lignesIdDossier] 
     * @param {Array<number>} [lignesIdDossier2] 
     * @param {number} [lignesIdPrestation] 
     * @param {Array<number>} [lignesIdPrestation2] 
     * @param {string} [numeroFacture] 
     * @param {string} [codesRejetsCodeRejetCategorie] 
     * @param {Array<string>} [codesRejetsCodeRejetCategorie2] 
     * @param {string} [codesRejetsCodeRejetCode] 
     * @param {Array<string>} [codesRejetsCodeRejetCode2] 
     * @param {string} [recherche] 
     * @param {'asc' | 'desc'} [orderId] 
     * @param {'asc' | 'desc'} [orderUpdatedAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public getFacturesAchatsFactureAchatCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, existsCodesRejets?: boolean, cloture?: boolean, syncG3?: boolean, deleted?: boolean, dateFactureBefore?: string, dateFactureStrictlyBefore?: string, dateFactureAfter?: string, dateFactureStrictlyAfter?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, libelleFacturante?: string, lignesNumeroDossier?: string, lignesNumeroPrestation?: string, updatedBy?: string, updatedBy2?: Array<string>, idEntiteFacturante?: number, idEntiteFacturante2?: Array<number>, lignesIdDossier?: number, lignesIdDossier2?: Array<number>, lignesIdPrestation?: number, lignesIdPrestation2?: Array<number>, numeroFacture?: string, codesRejetsCodeRejetCategorie?: string, codesRejetsCodeRejetCategorie2?: Array<string>, codesRejetsCodeRejetCode?: string, codesRejetsCodeRejetCode2?: Array<string>, recherche?: string, orderId?: 'asc' | 'desc', orderUpdatedAt?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).getFacturesAchatsFactureAchatCollection(xIdSociete, page, itemsPerPage, existsCodesRejets, cloture, syncG3, deleted, dateFactureBefore, dateFactureStrictlyBefore, dateFactureAfter, dateFactureStrictlyAfter, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, libelleFacturante, lignesNumeroDossier, lignesNumeroPrestation, updatedBy, updatedBy2, idEntiteFacturante, idEntiteFacturante2, lignesIdDossier, lignesIdDossier2, lignesIdPrestation, lignesIdPrestation2, numeroFacture, codesRejetsCodeRejetCategorie, codesRejetsCodeRejetCategorie2, codesRejetsCodeRejetCode, codesRejetsCodeRejetCode2, recherche, orderId, orderUpdatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insérer une facture d\'achat dans G3
     * @summary Insérer une facture d\'achat dans G3
     * @param {string} id Resource identifier
     * @param {FactureAchatJsonldFactureAchatSyncG3} factureAchatJsonldFactureAchatSyncG3 The updated FactureAchat resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public syncG3FactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatSyncG3: FactureAchatJsonldFactureAchatSyncG3, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).syncG3FactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatSyncG3, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
     * @summary Mettre à jour l\'état d\'un code rejet associé à une facture d\'achat
     * @param {number} facture Identifiant de la facture d\&#39;achat
     * @param {string} codeRejet Identifiant du code rejet
     * @param {FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat} factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat The updated FactureAchatCodeRejet resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture: number, codeRejet: string, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat: FactureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).updateEtatFactureAchatCodeRejetFactureAchatCodeRejetItem(facture, codeRejet, factureAchatCodeRejetJsonldFactureAchatCodeRejetUpdateEtat, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une facture d\'achat
     * @summary Mettre à jour une facture d\'achat
     * @param {string} id Resource identifier
     * @param {FactureAchatJsonldFactureAchatWrite} factureAchatJsonldFactureAchatWrite The updated FactureAchat resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatApi
     */
    public updateFactureAchatFactureAchatItem(id: string, factureAchatJsonldFactureAchatWrite: FactureAchatJsonldFactureAchatWrite, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatApiFp(this.configuration).updateFactureAchatFactureAchatItem(id, factureAchatJsonldFactureAchatWrite, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatCodeRejetApi - axios parameter creator
 * @export
 */
export const FactureAchatCodeRejetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatCodeRejetItem', 'id', id)
            const localVarPath = `/achats/facture_achat_code_rejets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatCodeRejetApi - functional programming interface
 * @export
 */
export const FactureAchatCodeRejetApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatCodeRejetApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatCodeRejetItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatCodeRejetItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatCodeRejetApi - factory interface
 * @export
 */
export const FactureAchatCodeRejetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatCodeRejetApiFp(configuration)
    return {
        /**
         * Retrieves a FactureAchatCodeRejet resource.
         * @summary Retrieves a FactureAchatCodeRejet resource.
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatCodeRejetItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatCodeRejetJsonldFactureAchatCodeRejetReadTimestampableBlameambleGroup> {
            return localVarFp.getFactureAchatCodeRejetItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FactureAchatCodeRejetApi - object-oriented interface
 * @export
 * @class FactureAchatCodeRejetApi
 * @extends {BaseAPI}
 */
export class FactureAchatCodeRejetApi extends BaseAPI {
    /**
     * Retrieves a FactureAchatCodeRejet resource.
     * @summary Retrieves a FactureAchatCodeRejet resource.
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatCodeRejetApi
     */
    public getFactureAchatCodeRejetItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatCodeRejetApiFp(this.configuration).getFactureAchatCodeRejetItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatJalApi - axios parameter creator
 * @export
 */
export const FactureAchatJalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer un facture d\'achat jal
         * @summary Créer un facture d\'achat jal
         * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatJalFactureAchatJalCollection: async (factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatJalJsonldFactureAchatJalWrite' is not null or undefined
            assertParamExists('createFactureAchatJalFactureAchatJalCollection', 'factureAchatJalJsonldFactureAchatJalWrite', factureAchatJalJsonldFactureAchatJalWrite)
            const localVarPath = `/achats/facture_achat_jals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatJalJsonldFactureAchatJalWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatJalFactureAchatJalItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatJalFactureAchatJalItem', 'id', id)
            const localVarPath = `/achats/facture_achat_jals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsJalsFactureAchatJalCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_jals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatJalApi - functional programming interface
 * @export
 */
export const FactureAchatJalApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatJalApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer un facture d\'achat jal
         * @summary Créer un facture d\'achat jal
         * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatJalFactureAchatJalItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatJalFactureAchatJalItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFacturesAchatsJalsFactureAchatJalCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatJalApi - factory interface
 * @export
 */
export const FactureAchatJalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatJalApiFp(configuration)
    return {
        /**
         * Créer un facture d\'achat jal
         * @summary Créer un facture d\'achat jal
         * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead> {
            return localVarFp.createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture d\'achat jal
         * @summary Récupérer les informations d\'une facture d\'achat jal
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatJalFactureAchatJalItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatJalJsonldFactureAchatJalRead> {
            return localVarFp.getFactureAchatJalFactureAchatJalItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer la liste des factures d\'achats jal
         * @summary Récupérer la liste des factures d\'achats jal
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<GetFacturesAchatsJalsFactureAchatJalCollection200Response> {
            return localVarFp.getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FactureAchatJalApi - object-oriented interface
 * @export
 * @class FactureAchatJalApi
 * @extends {BaseAPI}
 */
export class FactureAchatJalApi extends BaseAPI {
    /**
     * Créer un facture d\'achat jal
     * @summary Créer un facture d\'achat jal
     * @param {FactureAchatJalJsonldFactureAchatJalWrite} factureAchatJalJsonldFactureAchatJalWrite The new FactureAchatJal resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite: FactureAchatJalJsonldFactureAchatJalWrite, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).createFactureAchatJalFactureAchatJalCollection(factureAchatJalJsonldFactureAchatJalWrite, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture d\'achat jal
     * @summary Récupérer les informations d\'une facture d\'achat jal
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public getFactureAchatJalFactureAchatJalItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).getFactureAchatJalFactureAchatJalItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer la liste des factures d\'achats jal
     * @summary Récupérer la liste des factures d\'achats jal
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatJalApi
     */
    public getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return FactureAchatJalApiFp(this.configuration).getFacturesAchatsJalsFactureAchatJalCollection(xIdSociete, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatLigneApi - axios parameter creator
 * @export
 */
export const FactureAchatLigneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatLigneFactureAchatLigneCollection: async (factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate' is not null or undefined
            assertParamExists('createFactureAchatLigneFactureAchatLigneCollection', 'factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate', factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate)
            const localVarPath = `/achats/facture_achat_lignes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatLigneFactureAchatLigneItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFactureAchatLigneFactureAchatLigneItem', 'id', id)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatLigneFactureAchatLigneItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatLigneFactureAchatLigneItem', 'id', id)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLignesFactureAchatFactureAchatLigneCollection: async (xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/facture_achat_lignes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (factureId !== undefined) {
                localVarQueryParameter['facture.id'] = factureId;
            }

            if (factureId2) {
                localVarQueryParameter['facture.id[]'] = factureId2;
            }

            if (idDossier !== undefined) {
                localVarQueryParameter['idDossier'] = idDossier;
            }

            if (idDossier2) {
                localVarQueryParameter['idDossier[]'] = idDossier2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatLigneFactureAchatLigneItem: async (id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFactureAchatLigneFactureAchatLigneItem', 'id', id)
            // verify required parameter 'factureAchatLigneJsonldFactureAchatLigneWrite' is not null or undefined
            assertParamExists('updateFactureAchatLigneFactureAchatLigneItem', 'factureAchatLigneJsonldFactureAchatLigneWrite', factureAchatLigneJsonldFactureAchatLigneWrite)
            const localVarPath = `/achats/facture_achat_lignes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(factureAchatLigneJsonldFactureAchatLigneWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatLigneApi - functional programming interface
 * @export
 */
export const FactureAchatLigneApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatLigneApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLignesFactureAchatFactureAchatLigneCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLignesFactureAchatFactureAchatLigneCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLignesFactureAchatFactureAchatLigneCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFactureAchatLigneFactureAchatLigneItem(id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatLigneJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFactureAchatLigneFactureAchatLigneItem(id, factureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatLigneApi - factory interface
 * @export
 */
export const FactureAchatLigneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatLigneApiFp(configuration)
    return {
        /**
         * Créer une ligne de facture d\'achat
         * @summary Créer une ligne de facture d\'achat
         * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une ligne facture d\'achat
         * @summary Supprimer une ligne facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer une ligne de facture d\'achat
         * @summary Récupérer une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.getFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les lignes d\'une facture d\'achat
         * @summary Récupérer les lignes d\'une facture d\'achat
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [factureId] 
         * @param {Array<number>} [factureId2] 
         * @param {number} [idDossier] 
         * @param {Array<number>} [idDossier2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLignesFactureAchatFactureAchatLigneCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: any): AxiosPromise<GetLignesFactureAchatFactureAchatLigneCollection200Response> {
            return localVarFp.getLignesFactureAchatFactureAchatLigneCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une ligne de facture d\'achat
         * @summary Mettre à jour une ligne de facture d\'achat
         * @param {string} id Resource identifier
         * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFactureAchatLigneFactureAchatLigneItem(id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatLigneJsonldFactureAchatRead> {
            return localVarFp.updateFactureAchatLigneFactureAchatLigneItem(id, factureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FactureAchatLigneApi - object-oriented interface
 * @export
 * @class FactureAchatLigneApi
 * @extends {BaseAPI}
 */
export class FactureAchatLigneApi extends BaseAPI {
    /**
     * Créer une ligne de facture d\'achat
     * @summary Créer une ligne de facture d\'achat
     * @param {FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate} factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate The new FactureAchatLigne resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: FactureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).createFactureAchatLigneFactureAchatLigneCollection(factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une ligne facture d\'achat
     * @summary Supprimer une ligne facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public deleteFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).deleteFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer une ligne de facture d\'achat
     * @summary Récupérer une ligne de facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public getFactureAchatLigneFactureAchatLigneItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).getFactureAchatLigneFactureAchatLigneItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les lignes d\'une facture d\'achat
     * @summary Récupérer les lignes d\'une facture d\'achat
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [factureId] 
     * @param {Array<number>} [factureId2] 
     * @param {number} [idDossier] 
     * @param {Array<number>} [idDossier2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public getLignesFactureAchatFactureAchatLigneCollection(xIdSociete?: number, page?: number, itemsPerPage?: number, factureId?: number, factureId2?: Array<number>, idDossier?: number, idDossier2?: Array<number>, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).getLignesFactureAchatFactureAchatLigneCollection(xIdSociete, page, itemsPerPage, factureId, factureId2, idDossier, idDossier2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une ligne de facture d\'achat
     * @summary Mettre à jour une ligne de facture d\'achat
     * @param {string} id Resource identifier
     * @param {FactureAchatLigneJsonldFactureAchatLigneWrite} factureAchatLigneJsonldFactureAchatLigneWrite The updated FactureAchatLigne resource
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatLigneApi
     */
    public updateFactureAchatLigneFactureAchatLigneItem(id: string, factureAchatLigneJsonldFactureAchatLigneWrite: FactureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatLigneApiFp(this.configuration).updateFactureAchatLigneFactureAchatLigneItem(id, factureAchatLigneJsonldFactureAchatLigneWrite, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FactureAchatNoteApi - axios parameter creator
 * @export
 */
export const FactureAchatNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatNoteFactureAchatNoteItem: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFactureAchatNoteFactureAchatNoteItem', 'id', id)
            const localVarPath = `/achats/facture_achat_notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureAchatNoteApi - functional programming interface
 * @export
 */
export const FactureAchatNoteApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureAchatNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFactureAchatNoteFactureAchatNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureAchatNoteJsonldFactureAchatRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFactureAchatNoteFactureAchatNoteItem(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureAchatNoteApi - factory interface
 * @export
 */
export const FactureAchatNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureAchatNoteApiFp(configuration)
    return {
        /**
         * Récupérer une note de facture d\'achat
         * @summary Récupérer une note de facture d\'achat
         * @param {string} id Resource identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFactureAchatNoteFactureAchatNoteItem(id: string, xIdSociete?: number, options?: any): AxiosPromise<FactureAchatNoteJsonldFactureAchatRead> {
            return localVarFp.getFactureAchatNoteFactureAchatNoteItem(id, xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FactureAchatNoteApi - object-oriented interface
 * @export
 * @class FactureAchatNoteApi
 * @extends {BaseAPI}
 */
export class FactureAchatNoteApi extends BaseAPI {
    /**
     * Récupérer une note de facture d\'achat
     * @summary Récupérer une note de facture d\'achat
     * @param {string} id Resource identifier
     * @param {number} [xIdSociete] Identifiant de la société
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureAchatNoteApi
     */
    public getFactureAchatNoteFactureAchatNoteItem(id: string, xIdSociete?: number, options?: AxiosRequestConfig) {
        return FactureAchatNoteApiFp(this.configuration).getFactureAchatNoteFactureAchatNoteItem(id, xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaObjectApi - axios parameter creator
 * @export
 */
export const MediaObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMediaObjectItem', 'id', id)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMediaObjectItem', 'id', id)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediaObjectMediaObjectCollection: async (page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/media_objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (createdBy2) {
                localVarQueryParameter['createdBy[]'] = createdBy2;
            }

            if (existsFacturesAchat !== undefined) {
                localVarQueryParameter['exists[facturesAchat]'] = existsFacturesAchat;
            }

            if (dateDebutTraitementBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[before]'] = dateDebutTraitementBefore;
            }

            if (dateDebutTraitementStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_before]'] = dateDebutTraitementStrictlyBefore;
            }

            if (dateDebutTraitementAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[after]'] = dateDebutTraitementAfter;
            }

            if (dateDebutTraitementStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_after]'] = dateDebutTraitementStrictlyAfter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyMediaObjectMediaObjectCollection: async (page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/media_objects/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (createdBy2) {
                localVarQueryParameter['createdBy[]'] = createdBy2;
            }

            if (existsFacturesAchat !== undefined) {
                localVarQueryParameter['exists[facturesAchat]'] = existsFacturesAchat;
            }

            if (dateDebutTraitementBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[before]'] = dateDebutTraitementBefore;
            }

            if (dateDebutTraitementStrictlyBefore !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_before]'] = dateDebutTraitementStrictlyBefore;
            }

            if (dateDebutTraitementAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[after]'] = dateDebutTraitementAfter;
            }

            if (dateDebutTraitementStrictlyAfter !== undefined) {
                localVarQueryParameter['dateDebutTraitement[strictly_after]'] = dateDebutTraitementStrictlyAfter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {string} id Resource identifier
         * @param {MediaObject} mediaObject The updated MediaObject resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMediaObjectItem: async (id: string, mediaObject: MediaObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchMediaObjectItem', 'id', id)
            // verify required parameter 'mediaObject' is not null or undefined
            assertParamExists('patchMediaObjectItem', 'mediaObject', mediaObject)
            const localVarPath = `/achats/media_objects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(mediaObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMediaObjectCollection: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/achats/media_objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaObjectApi - functional programming interface
 * @export
 */
export const MediaObjectApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMediaObjectItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMediaObjectItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaObjectItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMediaObjectItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMediaObjectMediaObjectCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMediaObjectMediaObjectCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {string} id Resource identifier
         * @param {MediaObject} mediaObject The updated MediaObject resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMediaObjectItem(id: string, mediaObject: MediaObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldDateDebutTraitement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMediaObjectItem(id, mediaObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMediaObjectCollection(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMediaObjectCollection(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaObjectApi - factory interface
 * @export
 */
export const MediaObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaObjectApiFp(configuration)
    return {
        /**
         * Supprimer un document
         * @summary Supprimer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMediaObjectItem(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMediaObjectItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer un document
         * @summary Récupérer un document
         * @param {string} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaObjectItem(id: string, options?: any): AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup> {
            return localVarFp.getMediaObjectItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: any): AxiosPromise<ListMediaObjectMediaObjectCollection200Response> {
            return localVarFp.listMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of MediaObject resources.
         * @summary Retrieves the collection of MediaObject resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [createdBy] 
         * @param {Array<string>} [createdBy2] 
         * @param {boolean} [existsFacturesAchat] 
         * @param {string} [dateDebutTraitementBefore] 
         * @param {string} [dateDebutTraitementStrictlyBefore] 
         * @param {string} [dateDebutTraitementAfter] 
         * @param {string} [dateDebutTraitementStrictlyAfter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: any): AxiosPromise<ListMediaObjectMediaObjectCollection200Response> {
            return localVarFp.listMyMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier un document
         * @summary Modifier un document
         * @param {string} id Resource identifier
         * @param {MediaObject} mediaObject The updated MediaObject resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMediaObjectItem(id: string, mediaObject: MediaObject, options?: any): AxiosPromise<MediaObjectJsonldDateDebutTraitement> {
            return localVarFp.patchMediaObjectItem(id, mediaObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Stocker un document
         * @summary Stocker un document
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMediaObjectCollection(file?: File, options?: any): AxiosPromise<MediaObjectJsonldMediaObjectReadTimestampableBlameambleGroup> {
            return localVarFp.postMediaObjectCollection(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaObjectApi - object-oriented interface
 * @export
 * @class MediaObjectApi
 * @extends {BaseAPI}
 */
export class MediaObjectApi extends BaseAPI {
    /**
     * Supprimer un document
     * @summary Supprimer un document
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public deleteMediaObjectItem(id: string, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).deleteMediaObjectItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer un document
     * @summary Récupérer un document
     * @param {string} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public getMediaObjectItem(id: string, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).getMediaObjectItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of MediaObject resources.
     * @summary Retrieves the collection of MediaObject resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [createdBy] 
     * @param {Array<string>} [createdBy2] 
     * @param {boolean} [existsFacturesAchat] 
     * @param {string} [dateDebutTraitementBefore] 
     * @param {string} [dateDebutTraitementStrictlyBefore] 
     * @param {string} [dateDebutTraitementAfter] 
     * @param {string} [dateDebutTraitementStrictlyAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public listMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).listMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of MediaObject resources.
     * @summary Retrieves the collection of MediaObject resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {string} [createdBy] 
     * @param {Array<string>} [createdBy2] 
     * @param {boolean} [existsFacturesAchat] 
     * @param {string} [dateDebutTraitementBefore] 
     * @param {string} [dateDebutTraitementStrictlyBefore] 
     * @param {string} [dateDebutTraitementAfter] 
     * @param {string} [dateDebutTraitementStrictlyAfter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public listMyMediaObjectMediaObjectCollection(page?: number, itemsPerPage?: number, createdBy?: string, createdBy2?: Array<string>, existsFacturesAchat?: boolean, dateDebutTraitementBefore?: string, dateDebutTraitementStrictlyBefore?: string, dateDebutTraitementAfter?: string, dateDebutTraitementStrictlyAfter?: string, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).listMyMediaObjectMediaObjectCollection(page, itemsPerPage, createdBy, createdBy2, existsFacturesAchat, dateDebutTraitementBefore, dateDebutTraitementStrictlyBefore, dateDebutTraitementAfter, dateDebutTraitementStrictlyAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier un document
     * @summary Modifier un document
     * @param {string} id Resource identifier
     * @param {MediaObject} mediaObject The updated MediaObject resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public patchMediaObjectItem(id: string, mediaObject: MediaObject, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).patchMediaObjectItem(id, mediaObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stocker un document
     * @summary Stocker un document
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaObjectApi
     */
    public postMediaObjectCollection(file?: File, options?: AxiosRequestConfig) {
        return MediaObjectApiFp(this.configuration).postMediaObjectCollection(file, options).then((request) => request(this.axios, this.basePath));
    }
}


