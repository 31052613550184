import { useTheme } from '@emotion/react';
import { BaseTextFieldProps, FormHelperText, lighten } from '@mui/material';
import React from 'react';
import Select, { ControlProps, GroupBase, defaultTheme, Theme } from 'react-select';

export type FlexySelectProps = React.ComponentProps<typeof Select> & {
    error?: BaseTextFieldProps['error'];
    helperText?: BaseTextFieldProps['helperText'];
};
/*
 * FLexySelect permet de selectionner une valeur pré-défini dans une liste déroulante.
 */
const FlexySelect: React.FunctionComponent<FlexySelectProps> = function ({
    ...props
}: FlexySelectProps) {
    const theme: any = useTheme();

    const getBorderColor = () => {
        const errorColor = theme.palette.error.main;
        const colorFromPalette = theme.palette.mode === 'dark' ? '#454545 ' : '#dee3e9 '; // Todo use theme variable instead
        return `${props.error ? errorColor : colorFromPalette}`;
    };

    const overidesStyle = function (state: ControlProps<unknown, boolean, GroupBase<unknown>>) {
        const base = {
            borderWidth: '1px',
            borderRadius: '5px',
            background: 'none',
            borderColor: getBorderColor(),
        };
        if (state.isFocused) {
            return {
                ...base,
                borderWidth: '2px',
            };
        }
        if (state.isDisabled) {
            return {
                ...base,
                color: '#767e89',
                opacity: '1',
                backgroundColor: `${
                    theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.12) ' : '#f8f9fb '
                }`,
            };
        }
        return base;
    };

    const getTheme = function (): Theme {
        return {
            ...defaultTheme,
            borderRadius: 5,
            colors: {
                ...defaultTheme.colors,
                primary: theme.palette.primary.main,
                primary75: theme.palette.primary.light,
                primary50: lighten(theme.palette.primary.main, 0.5),
                primary25: lighten(theme.palette.primary.main, 0.25),

                danger: theme.palette.danger.main,
                dangerLight: theme.palette.danger.light,
            },
        };
    };

    return (
        <>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        ...overidesStyle(state),
                    }),
                }}
                theme={getTheme()}
                {...props}
                // Fix Z-index issue
                menuPortalTarget={document.body}
                menuPosition="fixed"
            />
            <FormHelperText
                sx={{
                    margin: '4px 14px 0px 14px',
                }}
                error={props.error}
            >
                {props.helperText}
            </FormHelperText>
        </>
    );
};

// const FlexySelect = withTheme(FlexySelectRaw);

export default FlexySelect;
