import { prescripteursApi as prescriberApi } from '@b2d/redux/RootStore';
import { Notes } from '@europrocurement/flexy-components';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import * as React from 'react';

type NoteRawProps = {
    prescriber: Prescripteur;
    reloadPrescripteur: () => void;
};

const NoteRow: React.FC<NoteRawProps> = (props) => {
    const { prescriber, reloadPrescripteur } = props;
    const { note } = prescriber;
    const prescriberId = prescriber.id;

    const onEdit = async (noteContent: string) => {
        if (prescriberId) {
            await prescriberApi.updateNoteTiersTiersItem(prescriberId.toString(), {
                note: noteContent,
            });
        }
        reloadPrescripteur();
    };

    return (
        <Grid item xs={12}>
            <Notes title="Notes du prescripteur" noteRawContent={note} onEdit={onEdit} />
        </Grid>
    );
};

export default NoteRow;
