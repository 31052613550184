import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';

type ExistingPrescribersModalProps = {
    onSubmit: () => {};
    isSubmitting?: boolean;
};

const CustomButtons: React.FC<ExistingPrescribersModalProps> = function (props) {
    const { onSubmit, isSubmitting } = props;

    const navigate = useNavigate();

    const redirectToList = () => {
        navigate('/prescripteurs/liste');
    };

    return (
        <Box display="flex" justifyContent="start">
            <Box display="flex" sx={{ marginTop: '25px' }}>
                <Button variant={'outlined'} onClick={redirectToList} sx={{ marginRight: '15px' }}>
                    Annuler
                </Button>
                <Button variant={'contained'} onClick={onSubmit} disabled={isSubmitting}>
                    Créer prescripteur
                </Button>
            </Box>
        </Box>
    );
};

export default CustomButtons;
