import * as React from 'react';

import { Box, Button, CardActions, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import ImageSpecifications, { ImageSpecificationsType } from './ImageSpecifications';
import ActionModal from '../../forms/ActionModal';
import { useSnackbar } from 'notistack';

type PartnerLogoProps = {
    isActive: boolean;
    imageSpecifications: ImageSpecificationsType;
    base64StringLogo: string;
    modalFunctions: any;
    updateLogo: any;
};

const PartnerLogo: React.FC<PartnerLogoProps> = (props) => {
    const { isActive, base64StringLogo, modalFunctions, updateLogo } = props;

    const fallbackSpecifications = {
        width: 800,
        height: 600,
        maxSize: 2,
        allowedTypes: ['jpg, jpeg, png'],
    };

    const imageSpecifications = props.imageSpecifications || fallbackSpecifications;

    const { allowedTypes } = imageSpecifications;
    const { enqueueSnackbar } = useSnackbar();

    const { setModalContent, closeModal, openModal } = modalFunctions;

    const callModal = (modalContent: React.ReactElement) => {
        setModalContent(modalContent);
        openModal();
    };

    // 1 - Input / File methods

    const fileInput = React.useRef<HTMLInputElement>(null);

    const triggerFileInput = () => {
        if (!fileInput.current) {
            return;
        }
        fileInput.current.value = '';
        fileInput.current?.click();
    };

    const precheckFile = (mimeType: string) => {
        const fileType = mimeType.split('/')[0] ?? null;
        const fileExtension = mimeType.split('/')[1] ?? null;

        if (fileType !== 'image') {
            return 'Le fichier doit être une image';
        }

        if (allowedTypes.includes(fileExtension) === false) {
            return `Seul les fichiers images (${allowedTypes.join('/')}) sont supportés`;
        }

        return '';
    };

    const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        const file = event.target.files[0];
        const incorrectFileError = precheckFile(file.type);

        if (incorrectFileError !== '') {
            enqueueSnackbar(incorrectFileError, { variant: 'error' });
            return;
        } else {
            const base64File = await convertFiletoBase64(file);
            updateLogo(base64File);
        }
    };

    // 2 - File format

    const convertFiletoBase64: any = (file: File) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    // 2 - Image related data

    const imageSize = '220px';

    const imageStyle = {
        margin: '20px',
        minHeight: imageSize,
        minWidth: imageSize,
        maxHeight: imageSize,
        maxWidth: imageSize,
    };

    const customGrey = '#8b8b8b';

    const removeLogo = () => {
        callModal(
            <ActionModal
                onClose={closeModal}
                asyncAction={() => {
                    updateLogo('');
                }}
                title={"Suppression d'un logo"}
                actionText={'Voulez-vous supprimer ce logo ?'}
                actionLabel={'Confirmer suppression'}
                callback={closeModal}
            />,
        );
    };

    const getAllowedExtensions = () => {
        const extensions = allowedTypes.map((extension) => `.${extension}`);
        return extensions.join(',');
    };

    const ImageActions = () => {
        return (
            <CardActions sx={{ marginTop: '20px' }}>
                {isActive && base64StringLogo ? (
                    <>
                        <Tooltip title="Modifier logo">
                            <Button
                                variant="contained"
                                onClick={triggerFileInput}
                                aria-label="edit logo"
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Supprimer logo">
                            <Button
                                variant="contained"
                                onClick={removeLogo}
                                aria-label="delete logo"
                            >
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Ajouter un logo">
                        <Button
                            variant="contained"
                            onClick={triggerFileInput}
                            aria-label="add logo"
                            disabled={isActive === false}
                        >
                            <AddIcon />
                        </Button>
                    </Tooltip>
                )}
            </CardActions>
        );
    };

    return (
        <Box display={'flex'} justifyContent={'center'} height={'100%'} marginTop={'20px'}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                {isActive && base64StringLogo ? (
                    <Box>
                        <img
                            src={base64StringLogo}
                            alt="Logo formalité"
                            style={{
                                ...imageStyle,
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        style={{
                            ...imageStyle,
                            backgroundColor: 'lightgrey',
                            opacity: '0.1',
                            border: `5px solid ${customGrey}`,
                            borderRadius: '300px',
                        }}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            height={'100%'}
                        >
                            <Typography
                                fontWeight={'bold'}
                                fontSize={`${parseInt(imageSize) / 5}px`}
                                color={customGrey}
                                letterSpacing={'6px'}
                            >
                                LOGO
                            </Typography>
                        </Box>
                    </Box>
                )}

                <ImageActions />

                <Box display={'flex'} margin={'20px 10px 10px'} justifyContent={'space-between'}>
                    <ImageSpecifications
                        isActive={isActive}
                        imageSpecifications={imageSpecifications}
                    />
                </Box>
            </Box>
            <input
                type="file"
                accept={getAllowedExtensions()}
                ref={fileInput}
                onChange={handleFileInput}
                hidden
            />
        </Box>
    );
};

export default PartnerLogo;
