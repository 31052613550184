import React from 'react';

import { RubriqueFacturation } from '@europrocurement/l2d-domain';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useSelector } from 'react-redux';
import { FlexyAccordeon } from '@europrocurement/flexy-components';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { RubriqueFacturationSelector } from '../../../../redux/RootStore';
import { findRubFactById, organiserProduits } from '../forms/functions/produitFunctions';
import DisplayLigneDossier from './DisplayLigneDossier';
import { RSFRubFac } from '../forms/types';
import CodeRejetsChipList, { CodeRejetsChipType } from '../widgets/CodeRejetsChipList';

export type DossierLinesAccordeonType = {
    displayName: string;
    lines: FactureAchatLigneJsonldFactureAchatRead[];
    rejets?: CodeRejetsChipType[];
};



export const reduceLines = function(lines : FactureAchatLigneJsonldFactureAchatRead[]){

    return lines.reduce((acc : FactureAchatLigneJsonldFactureAchatRead[] ,curr: FactureAchatLigneJsonldFactureAchatRead ,index : number ,lines : FactureAchatLigneJsonldFactureAchatRead[])=>{
        if(curr.details && curr.details[0].idRubriqueFacturation === 99 && acc.length>=1){
            acc.splice(1,0,curr);
        }else{
            if(acc.length === 1 && acc[0].details && acc[0].details[0] && acc[0].details[0].idRubriqueFacturation === 99 ){
                acc.splice(0,0,curr);
            }else{
                acc.push(curr);
            }
        }
        return acc;
    },[]);
}

export const DossierLinesAccordeon = function ({
    displayName,
    lines,
    rejets,
}: DossierLinesAccordeonType) {
    let rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const renderRejetByDossier = function (
        iddossier?: number | null,
        rejets?: FactureAchatLigneJsonldFactureAchatRead[],
    ) {
        if (!rejets || !iddossier) return null;

        return (
            <CodeRejetsChipList
                rejets={rejets.filter((rejet) => rejet.idDossier === iddossier)}
                full={true}
            />
        );
    };

    const summary =
        lines.length === 1 && lines[0].details && lines[0].details.length > 0 ? (
            <>
                Dossier {displayName}:
                <small>{`
                ${
                    findRubFactById(
                        rubFactsDataSource.data,
                        lines[0].details[0]?.idRubriqueFacturation,
                    )?.code
                } - HT
                ${
                    lines[0].details[0] && parseFloat(lines[0].details[0].ht || '0').toFixed(2)
                } €`}</small>
            </>
        ) : lines[0].details ? (
            <>
                Dossier {displayName}:<small>{`${lines.length} lignes`}</small>
            </>
        ) : (
            ''
        );

    return (
        <>
            <FlexyAccordeon
                variante="section"
                accordionSummary={summary}
                defaultExpanded
                accordionDetails={
                    <>
                        {renderRejetByDossier(lines[0].idDossier, rejets)}
                        <Typography>
                            <b>Numero d'annonce : </b> {lines[0].numeroPrestation}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="lignes de la facture d'achat">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Rubrique</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>HT (€)</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>TVA (€)</strong>
                                        </TableCell>
                                        <TableCell align="right">
                                            <strong>TTC (€)</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reduceLines(lines).map((ligne) => (
                                        <DisplayLigneDossier
                                            key={`${ligne.numeroDossier}-${ligne.id}-${ligne.libelleAnnonceur}`}
                                            ligne={ligne}
                                            rubriques={[...rubFactsDataSource.data, RSFRubFac]}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            />
            <Divider />
        </>
    );
};

export default DossierLinesAccordeon;
