import * as React from 'react';
import { FlexyHeaderForm, Modalizer } from '@europrocurement/flexy-components';
import { Box, IconButton, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { PRESCRIBER_LIST } from '@b2d/pages/Prescripteurs/PrescripteurRouter';
import { useTheme } from '@emotion/react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type ExistingPrescribersModalProps = {
    existingPrescribers: any;
    modalStatus: any;
    onModalClose: any;
    redirectToPrescriberDetail: (prescriberId: number) => void;
};

const ExistingPrescribersModal: React.FC<ExistingPrescribersModalProps> = function (props) {
    const { existingPrescribers, modalStatus, onModalClose, redirectToPrescriberDetail } = props;
    const { isOpen } = modalStatus;

    const numberOfExistingsPrescribers = existingPrescribers.length;

    const theme: any = useTheme();

    return (
        <Modalizer open={isOpen} onClose={onModalClose}>
            <Box>
                <>
                    <FlexyHeaderForm
                        label={`Il existe ${numberOfExistingsPrescribers} prescripteur${
                            numberOfExistingsPrescribers > 1 ? 's' : ''
                        } pour ce numéro de
                    Siret :`}
                        outlined
                    />
                    <Box sx={{ margin: '25px 0px' }}>
                        {existingPrescribers.map((prescriber: any, index: number) => {
                            return (
                                <a
                                    href={`${PRESCRIBER_LIST}/${prescriber.id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        sx={{
                                            backgroundColor: '#f6f6f6',
                                            width: '100%',
                                            marginBottom: '18px',
                                            borderRadius: '20px',
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.light,
                                            },
                                            transition: 'background-color 400ms',
                                        }}
                                    >
                                        <Box marginX={2} display="flex" alignItems={'center'}>
                                            <Box marginX={2}>
                                                <PersonIcon />
                                            </Box>

                                            <Box marginX={2}>
                                                <p>{prescriber.raisonSociale}</p>
                                            </Box>
                                        </Box>

                                        <Box display={'flex'}>
                                            <Tooltip title={'Consulter la fiche prescripteur'}>
                                                <Box marginX={2} justifySelf={'end'}>
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>

                                            <Tooltip title={'Aller à la fiche prescripteur'}>
                                                <Box marginX={2} justifySelf={'end'}>
                                                    <IconButton
                                                        onClick={() =>
                                                            redirectToPrescriberDetail(
                                                                prescriber.id,
                                                            )
                                                        }
                                                    >
                                                        <ArrowForwardIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </a>
                            );
                        })}
                    </Box>
                </>
            </Box>
        </Modalizer>
    );
};

export default ExistingPrescribersModal;
