import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router';
import generateB2DPath from '@b2d/utils/generateB2DPath';
import { useMultiAnnonceContext } from '../../../SaisieFacturesMultiple';
import { useDispatch } from 'react-redux';
import { COMMANDES_SLICE_NAME, DOSSIERS_SLICE_NAME, FACTURE_VENTE_SLICE_NAME } from '@europrocurement/l2d-domain';

export const NextEditDossierSaisieMultiple = function () {
    const { factureachatid,dossiernum } = useParams();
    const { mediaObject } = useMultiAnnonceContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }


    useEffect(() => {

        dispatch({
            type: `${DOSSIERS_SLICE_NAME}/deletemainSelected`,
        });
        dispatch({
            type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
        });
        dispatch({
            type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
        });

        const res = generateB2DPath('formmultieditdossier', {
            factureAchat: { id: Number(factureachatid) },
            dossiernum,
            mediaObject,
        });
        console.log("here");
        if (res.status === 'OK') {
            navigate(res.path);
        } else {
            // TODO
        }
    }, [factureachatid, mediaObject, navigate]);

    return null;
};

export default NextEditDossierSaisieMultiple;
