import * as React from 'react';
import { FlexyForm, FormStructure, FormObject } from '@europrocurement/flexy-form';
import Person from '@mui/icons-material/Person';
import {
    AdressesAdresseJsonldAdresseCreate,
    AdressesAdresseJsonldAdresseUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { adressesApi, prescripteursApi } from '@b2d/redux/RootStore';
import { COUNTRY_CODES_ISO_2 } from '@b2d/validation/validation';
import { SubmitHandler } from 'react-hook-form';
import { PrescriberFormProps } from './common';
import { POST_CODE_VALIDATION_REGEX } from '@b2d/validation/regex';
import { Box, Switch, Typography } from '@mui/material';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import useToaster from '@b2d/hooks/useToaster';

export type AddressFormProps = PrescriberFormProps & {
    entity?: any;
};

const AddressForm: React.FC<AddressFormProps> = (props) => {
    const { afterSubmit, entity = {}, isCreate = false, prescriber } = props;

    const { id: prescripteurId, adresses: addresses } = prescriber;

    const { handleApiResponse } = useToaster();

    const AddressFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Informations Adresse
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'customItem',
            name: 'defaut',
            xs: 12,
            md: 6,
            renderField: (formContext, props) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    formContext.setValue('defaut', value);
                    setIsMainAddress(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">{'Adresse principale'}</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isMainAddress}
                            checked={isMainAddress}
                            onChange={onChange}
                            disabled={isMainAddressEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'customItem',
            name: 'facturation',
            xs: 12,
            md: 6,
            sx: {
                display: 'flex',
                flexDirection: 'row',
            },
            renderField: (formContext, props) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    formContext.setValue('facturation', value);
                    setIsBillingAddress(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">{'Adresse de facturation'}</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isBillingAddress}
                            checked={isBillingAddress}
                            onChange={onChange}
                            disabled={isBillingAddressEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'text',
            name: 'adresse1',
            inputlabel: 'Addresse 1',
            xs: 12,
            rules: {
                required: 'Vous devez renseigner une adresse',
            },
            placeholder: 'Numéro, rue ...',
        },
        {
            type: 'text',
            name: 'adresse2',
            inputlabel: 'Adresse 2',
            xs: 12,
            placeholder: 'Appartement, batiment, entrée ...',
        },
        {
            type: 'text',
            name: 'adresse3',
            inputlabel: 'Adresse 3',
            xs: 12,
            placeholder: "Complément d'adresse ...",
        },
        {
            type: 'text',
            name: 'adresse4',
            inputlabel: 'Adresse 4',
            xs: 12,
            placeholder: 'cedex ? Boite postale ...',
        },
        {
            type: 'text',
            name: 'codePostal',
            inputlabel: 'Code Postal',
            xs: 12,
            md: 6,
            rules: {
                required: 'Vous devez renseigner le code postal',
                pattern: {
                    value: POST_CODE_VALIDATION_REGEX,
                    message: 'Le code postal doit être constitué de 5 chiffres',
                },
            },
        },
        {
            type: 'text',
            name: 'ville',
            inputlabel: 'Ville',
            xs: 12,
            md: 6,
            rules: {
                required: 'Vous devez renseigner une ville',
            },
        },
        {
            type: 'text',
            name: 'codePays',
            inputlabel: 'code Pays',
            rules: {
                required: 'Vous devez renseigner un code pays',
                validate: {
                    countryCodeIso2: (value) =>
                        COUNTRY_CODES_ISO_2.includes(value) ||
                        'Le code pays doit être au format ISO 3166-1 alpha-2',
                },
            },
            defaultValue: 'fr'.toUpperCase(),
        },
    ];
    const { defaut: isDefault, facturation: isBilling } = entity;

    const [isMainAddressEditable] = React.useState<boolean>(isCreate ? true : isDefault === false);
    const [isBillingAddressEditable] = React.useState<boolean>(
        isCreate ? true : isBilling === false,
    );
    const [isMainAddress, setIsMainAddress] = React.useState<boolean>(isCreate ? false : isDefault);
    const [isBillingAddress, setIsBillingAddress] = React.useState<boolean>(
        isCreate ? false : isBilling,
    );

    const createAddress: SubmitHandler<FormObject> = async (address: unknown) => {
        const addressToPost = address as AdressesAdresseJsonldAdresseCreate;

        if (prescriber && prescriber.id) {
            const createRequest = prescripteursApi.createPrescripteurAdresseAdressesCollection(
                prescriber.id,
                addressToPost,
            );

            await handleApiResponse(createRequest, afterSubmit);
        }
    };

    const updateAddress = async (address: unknown) => {
        const addressUpdated = address as AdressesAdresseJsonldAdresseUpdate & { id: string }; //TODO Fix apiGenerated types (missing id in AdressesAdresseJsonldAdresseUpdate)

        if (prescripteurId && addressUpdated.id) {
            const updateRequest = adressesApi.updateAdresseAdressesItem(
                addressUpdated.id,
                addressUpdated,
            );

            await handleApiResponse(updateRequest, afterSubmit);
        }
    };

    return (
        <FlexyForm
            formObject={isCreate ? { codePays: 'FR' } : entity}
            formStructure={AddressFormStructure}
            onSubmit={isCreate ? createAddress : updateAddress}
        />
    );
};

export default AddressForm;
