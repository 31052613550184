import * as React from 'react';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export type ImageSpecificationsType = {
    height: number;
    width: number;
    maxSize: number;
    allowedTypes: Array<string>;
};

type ImageSpecificationsProps = {
    isActive: boolean;
    imageSpecifications: ImageSpecificationsType;
};
const ImageSpecifications: React.FC<ImageSpecificationsProps> = (props) => {
    const { isActive, imageSpecifications } = props;
    const { height, width, maxSize, allowedTypes } = imageSpecifications;

    return (
        <Box
            border={'1px solid #d3d3d3'}
            borderRadius={'15px'}
            padding={'12px'}
            fontSize={'15px'}
            lineHeight={'16px'}
            bgcolor={'#f9f9f9'}
            display={'flex'}
            sx={{ opacity: isActive ? '1' : '0.2' }}
        >
            <InfoIcon sx={{ margin: '15px 30px 15px 15px', opacity: '0.2', fontSize: '35px' }} />
            <Box marginRight={'25px'}>
                <p>
                    Hauteur et largeur maximale de l'image : {height} x {width} px
                </p>
                <p>
                    Taille maximale de l'image : <span>{maxSize} Mo</span>
                </p>
                <p>
                    Types de fichiers autorisés :{' '}
                    {allowedTypes.map((type: string, index: number) => (
                        <span>{index === 0 ? `${type} ` : `/ ${type}`}</span>
                    ))}
                </p>
            </Box>
        </Box>
    );
};

export default ImageSpecifications;
