import {
    ConfigurationParameters,
    Configuration,
    FournisseurFournisseurFournisseurRead,
    FournisseursApi,
} from '../../../openApi/ApiTiers';
import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
    filtersToElasticQuery,
} from '@europrocurement/l2d-redux-utils';
import { TIERS_REDUCER_NAME } from '../tiersReducer';

// import keycloak from '../../../../keycloak';

// export { interface ConfigurationParameters} from '../../../openApi/ApiTiers';

export const FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME = 'autocompleteFactures';

export const additionalFournisseursDataSources: Array<string> = [
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
];

export const FOURNISSEUR_SLICE_NAME = 'fournisseur';

export { type ConfigurationParameters };

export type FournisseurApi = FournisseurFournisseurFournisseurRead & Record<string, unknown>;

export type Fournisseur = FournisseurApi & {};

const mapperFournisseur = function (fournisseur: FournisseurApi) {
    return {
        nomDentreprise: fournisseur.raisonSociale,
        ...fournisseur,
    } as Fournisseur;
};

export function createFournisseurSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FournisseursApi(conf);

    const fetchFournisseursCollection: FetchCollectionData<FournisseurApi> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let xIdUser = undefined;
        let page = pagination.page + 1;
        let itemsPerPage = 20;
        let deleted = undefined;
        let siren = undefined;
        let libelle = undefined;
        // let recherche = undefined;
        let societeId = xIdSociete;
        let societeId2 = undefined;
        let options = undefined;

        if (filters.deleted === false) {
            deleted = false;
        }
        if (filters.deleted === true) {
            deleted = true;
        }

        return api
            .getListTiersFournisseurCollection(
                xIdSociete,
                xIdUser,
                page,
                itemsPerPage,
                deleted,
                siren,
                libelle,
                search,
                societeId,
                societeId2,
                options,
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<FournisseurApi>>;
    };

    const fetchFournisseursCollectionBySiren: FetchCollectionData<FournisseurApi> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let deleted = undefined;

        if (filters.deleted === false) {
            deleted = false;
        }
        if (filters.deleted === true) {
            deleted = true;
        }

        return api
            .getListTiersFournisseurCollection(
                xIdSociete,
                undefined,
                pagination.page + 1,
                20,
                deleted,
                search,
                undefined,
                undefined,
                undefined,
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<FournisseurApi>>;
    };

    const fetchFournisseursItem: FetchItemDataType<FournisseurApi> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
        options,
    }) =>
        api
            .getFournisseurFournisseurItem(
                String(idItem), // id: string,
                xIdSociete, // xIdSociete?: number | undefined,
                undefined, // xIdUser?: number | undefined,
                options, // options?: AxiosRequestConfig<any> | undefined
            )
            .then((res) => res.data) as Promise<FournisseurApi>;

    const { slice: fournisseurSlice, dataSourcesThunks: fournisseurDataSourcesThunks } =
        SliceFactory.createSlice<FournisseurApi, Fournisseur>(
            FOURNISSEUR_SLICE_NAME,
            TIERS_REDUCER_NAME,
            additionalFournisseursDataSources,
            fetchFournisseursCollection,
            fetchFournisseursItem,
            mapperFournisseur,
            {
                deleted: false,
            },
            {
                data: {
                    fetchBySiren: fetchFournisseursCollectionBySiren,
                },
            },
        );

    return { fournisseurSlice, fournisseurDataSourcesThunks };
}
