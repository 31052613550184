import React, { ClipboardEvent, useEffect, useState } from 'react';
import {
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    AutocompleteRenderOptionState,
    CircularProgress,
    SxProps,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    getDataThunkType,
    type ApiCollectionResponse,
    type DataSource,
} from '@europrocurement/l2d-redux-utils';
import { type AsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { SliceName } from '@europrocurement/l2d-domain';
import {
    FlexyFormLabel,
    FlexyTextField,
    FlexyTextFieldProps,
} from '@europrocurement/flexy-components';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import ReactJson from 'react-json-view';
// import { ACTIONS } from 'applications/back-to-digital/src/redux/FactureFormReducer';

export type AutocompleteOnChange<T> = (
    event: React.SyntheticEvent<Element, Event>,
    value: T,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>,
) => void;

export type AutocompleteOnInputChange<T> = (
    event: React.SyntheticEvent<Element, Event>,
    value: T,
    reason: AutocompleteInputChangeReason,
) => void;

export type AutocompleteStoreProps<T extends Record<string, unknown>> = {
    // Source de donnée ou récuperer les options
    dataSource: DataSource<T>;
    // Fonction a appeler pour fetch les données
    fetchData: getDataThunkType<T>;
    // Champ a rendre dans l'input
    renderField: keyof T;
    // Label de l'input
    inputlabel: string;
    // propriétée idantifiant l'objet ( pour verifier l'équité entre deux objets )
    idProperty: Extract<keyof T, string>;
    // booléen qui définis si le champ est requis
    required?: boolean;
    // Fonction qui permet de rendre une option, il faut rendre un element <li> pour un affichage correcte. par défaut item[renderField]
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        item: T,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    // Fonction simplifiée de renderOption, se charge de faire l'élément <li>
    simpleRenderOption?: (item: T) => React.ReactNode;
    // Fonction qui rend l'item dans l'inpunt, par défaut item[renderField]
    getOptionLabel?: (item: T) => string;
    // callback appelé au changement
    onChange?: AutocompleteOnChange<T>;
    // callback appelé au changement de l'input
    onInputChange?: AutocompleteOnInputChange<T>;
    // valeur par défaut a l'initalisation
    defaultValue?: T; // Defined like 'any' in MUI. It's not me sorry...
    defaultInputValue?: string; // Defined like 'any' in MUI. It's not me sorry...
    // Propriétés sx
    sx?: SxProps;
    // Props fournies a l'input
    inputProps?: Omit<FlexyTextFieldProps, 'defaultValue'>;
    // Props fournies a l'input
    // preload?: boolean;
    searchability?: (terms: string) => boolean;
    noOptionsTextFn?: (reson: 'noinput' | 'unserachable' | 'nooptions') => React.ReactNode;
    reset?: boolean;
};

export const AutocompleteStore = function <T extends Record<string, unknown>>({
    dataSource,
    fetchData,
    renderField,
    inputlabel,
    idProperty,
    required,
    renderOption,
    simpleRenderOption,
    getOptionLabel,
    onChange,
    onInputChange,
    defaultValue,
    defaultInputValue,
    sx,
    inputProps,
    searchability,
    noOptionsTextFn = (reason: 'noinput' | 'unserachable' | 'nooptions') => {
        switch (reason) {
            case 'noinput':
                return 'merci de compléter le champ';
            case 'unserachable':
                return 'La saisie est invalide';
            case 'nooptions':
                return 'Aucune options disponible pour cette saisie';
        }
    },
    reset,
}: AutocompleteStoreProps<T>) {
    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState<string>(defaultInputValue || '');
    const [value, setValue] = useState<T | null>(defaultValue || null);
    const [noOptions, setNoOptions] = useState<React.ReactNode>('merci de completer le champ');

    const [debouncedSearchTerm, controls] = useDebounce(inputValue, 500);

    /**
     * Efface totalement les données du champs lors
     * du passage à true de reset.
     * (bien s'assurrer que le changement d'état soit temporaire)
     */
    useEffect(() => {
        if (reset) {
            setInputValue('');
            setValue(null);
            dispatch({
                type: `${dataSource.slicename}/clear${dataSource.name}Data`,
            });
        }
    }, [reset]);

    useEffect(() => {
        setInputValue(defaultInputValue || '');
        if (inputValue === '') {
            dispatch({
                type: `${dataSource.slicename}/clear${dataSource.name}Data`,
            });
        }
    }, [defaultInputValue]);

    useEffect(() => {
        if (
            dataSource.data.length === 0 &&
            (searchability ? searchability(debouncedSearchTerm) : true) &&
            debouncedSearchTerm !== ''
        ) {
            setNoOptions(noOptionsTextFn('nooptions'));
        }
    }, [dataSource.status]);

    useEffect(() => {
        if (debouncedSearchTerm !== '') {
            // on ne change qu'en cas de clear ou de saisie user
            const searchable = searchability ? searchability(debouncedSearchTerm) : true;
            if (!searchability || searchable) {
                dispatch({
                    type: `${dataSource.slicename}/set${dataSource.name}Search`,
                    payload: { search: debouncedSearchTerm },
                });
                dispatch(fetchData({}));
            } else if (!searchable) {
                setNoOptions(noOptionsTextFn('unserachable'));
            }
        } else {
            setNoOptions(noOptionsTextFn('noinput'));
        }
    }, [debouncedSearchTerm]);

    const handleInputChange = (
        e: React.SyntheticEvent,
        _value: string,
        reason: AutocompleteInputChangeReason,
    ) => {
        if (reason !== 'reset') controls.cancel();
        if (reason === 'reset' && !_value && inputValue) return; // empêche de supprimer le premier caractère de l'input

        setInputValue(_value);
    };

    // Conditionne l'input après selection
    const getOptionLablFn = (option: T) => {
        if (!option) {
            return '';
        }
        if (getOptionLabel) {
            return getOptionLabel(option);
        }

        return option[renderField] as string;
    };

    const renderOptionFn: (
        props: React.HTMLAttributes<HTMLLIElement>,
        item: T,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode = (props, item, state) => {
        if (renderOption) {
            return renderOption(props, item, state);
        } else if (simpleRenderOption) {
            return (
                <li {...props} key={`acstore-item-${item[idProperty]}`}>
                    <Typography>{simpleRenderOption(item)}</Typography>
                </li>
            );
        } else {
            return (
                <li {...props} key={`acstore-item-${item[idProperty]}`}>
                    <Typography>{getOptionLablFn(item)}</Typography>
                </li>
            );
        }
    };

    const handleValueChange: AutocompleteOnChange<T> = (event, value, reason, details) => {
        switch (reason) {
            case 'blur':
                // Si j'ai une recherche ( inputValue ) et que j'ai de la data dans mon state et j'ai une seul donnée
                if (inputValue === '' || dataSource.data.length !== 1) {
                    dispatch({
                        type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                    });
                    setValue(null);
                } else {
                    dispatch({
                        type: `${dataSource.slicename}/set${dataSource.name}Selected`,
                        payload: dataSource.data[0],
                    });
                    setValue(dataSource.data[0]);
                }
                break;
            case 'selectOption':
                dispatch({
                    type: `${dataSource.slicename}/set${dataSource.name}Selected`,
                    payload: value,
                });
                setValue(value);
                // event.stopPropagation(); marche pas
                break;
            case 'clear':
                dispatch({
                    type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
                });
                setInputValue('');
                setValue(null);
                break;
            default:
                break;
        }

        if (onChange) {
            onChange(event, value, reason, details);
        }
    };

    // const handleValueInputChange : AutocompleteOnInputChange<string> = ( event, value, reason )  => {
    //     switch (reason) {
    //         case 'input':
    //             dispatch({
    //                 type: `${dataSource.slicename}/set${dataSource.name}Selected`,
    //                 payload: value,
    //             });
    //             setValue(value);
    //             // event.stopPropagation(); marche pas
    //             break;
    //         case 'reset':
    //         case 'clear':
    //             dispatch({
    //                 type: `${dataSource.slicename}/delete${dataSource.name}Selected`,
    //             });
    //             setInputValue('');
    //             setValue(null);
    //             break;
    //         default:
    //             break;
    //     }

    //     if(onInputChange){
    //         onInputChange( event, value, reason );
    //     }

    // }

    /**
     * Nouvel autocomplete :
     *  ajout de :
     *      clearOnEscape
     *      clearOnBlur
     *      autoSelect
     *      autoHighlight
     *
     *  a tester :
     *      - le bon fonctionnement des event finaux
     *      - le select a la main
     *      - le 'tab'
     *      - le 'enter'
     *      - le clear a la main
     *      - le je m'envais sans avoir selectionné
     *
     *  les plus :
     *
     *  les moins :
     */

    return (
        <>
            <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                <Typography component="span">
                    {inputlabel}
                    {required ? (
                        <Tooltip title="Le champ est requis">
                            <Typography component="span" color="danger.main">
                                &nbsp;&nbsp;*
                            </Typography>
                        </Tooltip>
                    ) : null}
                </Typography>
            </FlexyFormLabel>
            <Autocomplete
                clearOnEscape
                // blurOnSelect // Provoque un bug A utiliser avec parcimonie
                clearOnBlur
                autoSelect
                autoHighlight
                value={value}
                inputValue={inputValue}
                onChange={handleValueChange}
                onInputChange={handleInputChange}
                options={dataSource.data}
                getOptionLabel={getOptionLablFn}
                sx={{ ...sx }}
                noOptionsText={noOptions}
                loadingText={<CircularProgress sx={{ margin: 'auto' }} />}
                loading={dataSource.status === 'loading'}
                renderOption={renderOptionFn}
                renderInput={(params) => (
                    <FlexyTextField variant="outlined" {...params} {...inputProps} />
                )}
                isOptionEqualToValue={(option: T, value: T) => {
                    return option[idProperty] === value[idProperty];
                }}
                onPaste={(event: ClipboardEvent<HTMLInputElement>) => {
                    if (event.clipboardData) {
                        setInputValue(event.clipboardData.getData('text').trim());
                        event.preventDefault();
                    }
                }}
            />
        </>
    );
};

AutocompleteStore.defaultProps = {
    inputProps: {
        fullWidth: true,
    },
    sx: {
        width: '100%',
    }, //,
    // preload: false
};
