import * as React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { CenterCircularProgress } from '@europrocurement/flexy-components';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { useRoutes } from 'react-router-dom';
import Router from './router/router';
import { ThemeSettings } from '@europrocurement/flexy-components/src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    CivilitiesSelector,
    CspOptionsSelector,
    PaysSelector,
    WalletOptionsSelector,
    getCivilities,
    getCspOptions,
    getPays,
    getWalletOptions,
} from './redux/RootStore';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Pays } from '@europrocurement/l2d-domain';

const App = function () {
    const routing = useRoutes(Router);
    const theme = ThemeSettings();
    const kc = UseKeycloakService();

    // let paysDatasource: DataSource<Pays> = useSelector(PaysSelector).main;
    // let walletOptionsDatasource: DataSource<any> = useSelector(WalletOptionsSelector).main;
    // let cspOptionsDatasource: DataSource<any> = useSelector(CspOptionsSelector).main;
    // let civilitiesDatasource: DataSource<any> = useSelector(CivilitiesSelector).main;

    // const optionsToLoadAtStartUp = [
    //     { dataSource: paysDatasource, action: getPays },
    //     { dataSource: walletOptionsDatasource, action: getWalletOptions },
    //     { dataSource: cspOptionsDatasource, action: getCspOptions },
    //     { dataSource: civilitiesDatasource, action: getCivilities },
    // ];

    // const dispatch = useDispatch();

    // const loadInitialOptions = () => {
    //     // optionsToLoadAtStartUp.forEach(({ dataSource, action }, index) => {
    //     //     const { status } = dataSource;
    //     //     if (status === 'idle') {
    //     //         dispatch(action({}));
    //     //     }
    //     // });
    // };

    // const optionsLoadingStatus = optionsToLoadAtStartUp.map(({ dataSource }) => {
    //     const { status } = dataSource;

    //     return status;
    // });

    // const allOptionsAreLoaded = optionsLoadingStatus.every((status) => status === 'succeeded');

    React.useEffect(() => {
        if (kc.isInitialized()) {
            // loadInitialOptions();
        }
    }, [kc]);

    const isApplicationReady = () => kc.isInitialized() //&& allOptionsAreLoaded;

    return (
        <ThemeProvider theme={theme}>
            {isApplicationReady() ? routing : <CenterCircularProgress />}
            <CssBaseline />
        </ThemeProvider>
    );
};

export default App;
