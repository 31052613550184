import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Route } from '../../../entities/route/route';

const initialState = [] as Route[];

const routingSlice = createSlice({
    name: 'routing',
    initialState,
    reducers: {
        addRoute(state: Route[], action: PayloadAction<Route>) {
            state.push(action.payload);
        },
    },
});

export const { addRoute } = routingSlice.actions;

export default routingSlice.reducer;
