import * as React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import {
    Drawer,
    Box,
    List,
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItemButton,
    Icon,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { LogoIcon, type LogoIconProps } from '../../atoms';
import Scrollbar from '../../molecules/Scrollbar';
import { SidebarWidth } from '../../../assets/global/Theme-variable';
import type { MenuEntry } from './MenuEntry';
import { FlexyLayoutState } from '../../../redux/storeConfig/store';

import './ribbon.css';
import { useSelector } from 'react-redux';

export type SidebarProps = {
    isSidebarOpen?: boolean;
    isMobileSidebarOpen?: boolean;
    onSidebarClose?: (
        event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLAnchorElement>,
    ) => void;
    menuEntries: MenuEntry[];
    logoSrc?: LogoIconProps['src'];
    logoRedirect?: string;
};

/**
 * Barre de menu qui s'ouvre et se ferme lors de l'appui sur un element HTML "Div" ou "Anchor"
 */
const Sidebar: React.FunctionComponent<SidebarProps> = function ({
    isSidebarOpen = true,
    isMobileSidebarOpen = false,
    onSidebarClose,
    menuEntries,
    logoSrc,
    logoRedirect,
}: SidebarProps) {
    const [open, setOpen] = React.useState(true);
    const [collapsed, setCollapsed] = React.useState(-1);

    const env = useSelector((state: FlexyLayoutState) => state.customizer.env);

    const { pathname } = useLocation();
    const pathDirect = pathname;
    const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));

    // const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const lgUp = true;

    const handleColapse = (index: number) => {
        if (index === collapsed) {
            setOpen((value) => !value);
        } else {
            setOpen(true);
            setCollapsed(index);
        }
    };

    const kc = UseKeycloakService();

    const SidebarContent = (
        <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
            <Box data-testid="test-id-Sidebar" sx={{ overflow: 'hidden' }}>
                {env !== 'prod' ? (
                    <Box id="env-ribbon" className={env}>
                        {env}
                    </Box>
                ) : null}
                <Box marginTop={1} sx={{ scale: '0.8', marginTop: '30px' }}>
                    <LogoIcon src={logoSrc} redirect={logoRedirect} />
                </Box>
                <Box sx={{ px: 2 }}>
                    <Icon about="Mail" />
                    <List>
                        {menuEntries.map((item: MenuEntry, index) => {
                            if (item.role) {
                                if (!kc.checkRole(item.role)) return null;
                            }
                            if (item.subheader) {
                                return (
                                    <li key={item.subheader}>
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            color="text.primary"
                                            sx={{ opacity: 0.4 }}
                                        >
                                            {item.subheader.replace(/^[a-z]/, (char) =>
                                                char.toUpperCase(),
                                            )}
                                        </Typography>
                                    </li>
                                );
                            }
                            if (item.children) {
                                return (
                                    <React.Fragment key={item.title}>
                                        <ListItem
                                            button
                                            component="li"
                                            onClick={() => handleColapse(index)}
                                            selected={pathWithoutLastPart === item.href}
                                            sx={{
                                                paddingRight: 1,
                                                mb: 1,
                                                ...(pathWithoutLastPart === item.href && {
                                                    color: 'white',
                                                    backgroundColor: (theme) =>
                                                        `${theme.palette.primary.main}!important`,
                                                }),
                                            }}
                                        >
                                            {item.icon ? (
                                                <ListItemIcon
                                                    sx={{
                                                        ...(pathWithoutLastPart === item.href && {
                                                            color: 'white',
                                                        }),
                                                    }}
                                                >
                                                    <Box width="20" height="20">
                                                        {item.icon}
                                                    </Box>
                                                </ListItemIcon>
                                            ) : null}
                                            <ListItemText>
                                                {item.title?.replace(/^[a-z]/, (char) =>
                                                    char.toUpperCase(),
                                                )}
                                            </ListItemText>
                                            {index === collapsed ||
                                            pathWithoutLastPart === item.href ? (
                                                <ExpandMore />
                                            ) : (
                                                <ChevronRight />
                                            )}
                                        </ListItem>
                                        <Collapse
                                            in={index === collapsed && open}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List component="li" disablePadding>
                                                {item.children.map((child) => {
                                                    if (child.role) {
                                                        if (!kc.checkRole(child.role)) return null;
                                                    }
                                                    return (
                                                        <ListItemButton
                                                            key={child.title}
                                                            component={NavLink}
                                                            to={child.href}
                                                            onClick={onSidebarClose}
                                                            selected={pathDirect === child.href}
                                                            sx={{
                                                                paddingRight: 1,
                                                                mb: 1,
                                                                ...(pathDirect === child.href && {
                                                                    color: 'primary.main',
                                                                    backgroundColor:
                                                                        'transparent!important',
                                                                }),
                                                            }}
                                                        >
                                                            {child.icon ? (
                                                                <ListItemIcon
                                                                    sx={{
                                                                        svg: {
                                                                            width: '14px',
                                                                            marginLeft: '3px',
                                                                        },
                                                                        ...(pathDirect ===
                                                                            child.href && {
                                                                            color: 'primary.main',
                                                                        }),
                                                                    }}
                                                                >
                                                                    <Box width="20" height="20">
                                                                        {child.icon}
                                                                    </Box>
                                                                </ListItemIcon>
                                                            ) : null}
                                                            <ListItemText>
                                                                {child.title?.replace(
                                                                    /^[a-z]/,
                                                                    (char) => char.toUpperCase(),
                                                                )}
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    );
                                                })}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                );
                                // {/********If Sub No Menu**********/}
                            }
                            return (
                                <List component="li" disablePadding key={item.title}>
                                    <ListItemButton
                                        component={NavLink}
                                        to={item.href}
                                        selected={
                                            pathDirect === item.href ||
                                            pathWithoutLastPart === item.href
                                        }
                                        sx={{
                                            paddingRight: 1,
                                            mb: 1,
                                            ...((pathDirect === item.href ||
                                                pathWithoutLastPart === item.href) && {
                                                color: 'white',
                                                backgroundColor: (theme) =>
                                                    `${theme.palette.primary.main}!important`,
                                            }),
                                        }}
                                    >
                                        {item.icon ? (
                                            <ListItemIcon
                                                sx={{
                                                    ...((pathDirect === item.href ||
                                                        pathWithoutLastPart === item.href) && {
                                                        color: 'white',
                                                    }),
                                                }}
                                            >
                                                <Box width="20" height="20">
                                                    {item.icon}
                                                </Box>
                                            </ListItemIcon>
                                        ) : null}
                                        <ListItemText onClick={onSidebarClose}>
                                            {item.title?.replace(/^[a-z]/, (char) =>
                                                char.toUpperCase(),
                                            )}
                                        </ListItemText>
                                    </ListItemButton>
                                </List>
                            );
                        })}
                    </List>
                </Box>
            </Box>
        </Scrollbar>
    );
    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        border: '0 !important',
                        boxShadow: '0px 7px 30px 0px rgb(113 122 131 / 11%)',
                    },
                }}
            >
                {SidebarContent}
            </Drawer>
        );
    }
    return (
        <Drawer
            anchor="left"
            open={isMobileSidebarOpen}
            onClose={onSidebarClose}
            PaperProps={{
                sx: {
                    width: SidebarWidth,
                    border: '0 !important',
                },
            }}
            variant="temporary"
        >
            {SidebarContent}
        </Drawer>
    );
};

Sidebar.defaultProps = {
    isSidebarOpen: true,
    isMobileSidebarOpen: false,
    onSidebarClose: undefined,
};

export default Sidebar;
