import * as React from 'react';
import { type AccordionProps, Typography, styled, AccordionSummaryProps } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const SectionAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const SectionAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        sx={{ minHeight: '40px' }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: `0 0 0 ${theme.spacing(1)}`,
    },
}));

const SectionAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export type FlexyAccordeonProps = Pick<
    AccordionProps,
    Exclude<keyof AccordionProps, 'children'>
> & {
    variante?: 'data' | 'section';
    accordionSummary?: React.ReactNode;
    accordionDetails?: React.ReactNode;
    children?: React.ReactNode;
};

const FlexyAccordeon: React.FunctionComponent<FlexyAccordeonProps> = function ({
    variante,
    accordionSummary,
    accordionDetails,
    ...props
}: FlexyAccordeonProps) {
    if (variante === 'section')
        return (
            <SectionAccordion {...props}>
                <SectionAccordionSummary>
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        display="flex"
                        alignItems="center"
                        gap="10px"
                        width="100%"
                    >
                        {accordionSummary}
                    </Typography>
                </SectionAccordionSummary>
                <SectionAccordionDetails>{accordionDetails}</SectionAccordionDetails>
            </SectionAccordion>
        );

    return (
        <Accordion
            style={{
                borderRadius: '20px',
                overflow: 'hidden',
                boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11) ',
            }}
            {...props}
        >
            <AccordionSummary>
                <Typography variant="h4" fontWeight="bold">
                    {accordionSummary}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{accordionDetails}</AccordionDetails>
        </Accordion>
    );
};

export default FlexyAccordeon;
