import {
    APIENV,
    APISENV,
    APIS_ENUM,
    ENV,
    ENV_API,
} from '../../constants/env';
import type { Reducer } from '@reduxjs/toolkit';
import {
    THEME_COLOR,
    DARK_THEME,
    SET_ID_SOCIETE,
    SET_ENV,
    type THEME_LIST,
    SET_ENV_API,
} from '../../constants';
import { defaultEnv } from './defaultEnv';

export type CustomizerReducerActiveModeType = 'light' | 'dark';
export type CustomizerReducerActiveThemeType = THEME_LIST;

export type CustomizerReducetApiType = {
    achats: ENV_API;
    tiers: ENV_API;
    dossiers: ENV_API;
};

export type CustomizerReducerType = {
    activeMode: CustomizerReducerActiveModeType; // This can be light or dark
    activeTheme: CustomizerReducerActiveThemeType; // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME
    xIdSociete: 2 | 3 | 5;
    sideBarState: 'open' | 'close';
    env: ENV;
    api: CustomizerReducetApiType;
};

const customizerState = localStorage.getItem('customizerState');

export let INIT_STATE: CustomizerReducerType;


INIT_STATE = {
    activeMode: 'light', // This can be light or dark
    activeTheme: 'L2D_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME, L2D_THEME
    xIdSociete: 2,
    sideBarState: 'open',
    env: defaultEnv,
    api: {
        achats: defaultEnv,
        tiers: defaultEnv,
        dossiers: defaultEnv,
    },
};

if (customizerState && customizerState !== '') {
    try {
        INIT_STATE = {
            ...INIT_STATE,
            ...JSON.parse(customizerState),
        };

        if (localStorage.getItem('customizerState') !== JSON.stringify(INIT_STATE)) {
            localStorage.setItem('customizerState', JSON.stringify(INIT_STATE));
            location.reload();
        }
    } catch (e) {
        localStorage.setItem('customizerState', JSON.stringify(INIT_STATE));
        location.reload();
    }
}

// const INIT_STATE: CustomizerReducerType = {
//     activeMode: 'light', // This can be light or dark
//     activeTheme: 'L2D_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME, L2D_THEME
//     xIdSociete: '2',
// };

export const CustomizerReducer: Reducer<CustomizerReducerType> = (state = INIT_STATE, action) => {
    let newState;
    let mustReload = false;
    switch (action.type) {
        case DARK_THEME:
            newState = {
                ...state,
                activeMode: action.payload,
            };
            break;
        case THEME_COLOR:
            newState = {
                ...state,
                activeTheme: action.payload,
            };
            break;
        case SET_ID_SOCIETE:
            newState = {
                ...state,
                xIdSociete: action.payload,
            };
            break;
        case SET_ENV:
            newState = {
                ...state,
                env: action.payload,
            };
            mustReload = true;
            break;

        case SET_ENV_API:
            newState = {
                ...state,
                api: { ...state.api },
            };

            if (action.payload.api && action.payload.env) {
                const overide: any = {};
                overide[action.payload.api as APIS_ENUM] = action.payload.env as ENV_API;
                newState.api = {
                    ...newState.api,
                    ...overide,
                };
            }
            mustReload = true;
            break;
        default:
            newState = { ...state };
            break;
    }

    localStorage.setItem('customizerState', JSON.stringify(newState));

    if (mustReload) {
        location.reload();
    }

    return newState;
};

export default CustomizerReducer;
