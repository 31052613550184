//React imports
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPrescripteur,
    PrescripteursSelector,
    customizerSelector,
} from '../../../../redux/RootStore';

//Components imports
import { Avatar, Box, Typography } from '@mui/material';
import PrescripteurInformationsCard from './PrescripteurInformationsCard';
import {
    CenterCircularProgress,
    LazyBreadcrumb,
    Modalizer,
} from '@europrocurement/flexy-components';

//Data fetching / utils / custom hooks
import { Prescripteur } from '@europrocurement/l2d-domain';
import { prescripteursApi, contactsApi } from '../../../../redux/RootStore';
import useModal from '../../../../hooks/useModal';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';

export type ModalFunctions = {
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    setModalContent: (element: React.ReactElement | null) => void;
};

const PrescripteurFiche: React.FunctionComponent = function () {
    const dispatch = useDispatch();

    const { idPrescripteur } = useParams();
    const { xIdSociete } = useSelector(customizerSelector);
    const prescripteur: Prescripteur = useSelector(PrescripteursSelector).main.selected;
    const isLoading = !prescripteur;

    //Modal functions / state
    const [isModalOpen, toggleModal, closeModal, openModal] = useModal();
    const [modalContent, setModalContent] = React.useState<React.ReactElement | null>(null);

    const modalFunctions = {
        toggleModal,
        closeModal,
        openModal,
        setModalContent,
    };

    useEffect(() => {
        const { signal } = new AbortController();
        if (idPrescripteur)
            dispatch(selectPrescripteur({ id: +idPrescripteur, options: { signal } }));
        return () => {
            dispatch({ type: 'prescripteur/deletemainSelected' });
        };
    }, [dispatch, idPrescripteur]);

    if (isLoading) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    const formatTitle = () => {
        const { raisonSociale: socialReason, relationsSocietes: companyRelation } = prescripteur;

        const relation = companyRelation.find((item) => item.societe?.id === xIdSociete);

        const customerCode = relation?.codeClient;
        const separator = ' - ';

        return customerCode ? socialReason + separator + customerCode : socialReason;
    };

    const reloadPrescriber = () =>
        idPrescripteur && dispatch(selectPrescripteur({ id: +idPrescripteur }));

    const title = formatTitle();

    return (
        <>
            {idPrescripteur && prescripteur && (
                <Box
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <LazyBreadcrumb
                        path={[
                            { path: 'Prescripteurs', link: '/prescripteurs/liste' },
                            "Details d'un prescripteur",
                            '',
                            // title,
                        ]}
                    />

                    <Box display={'flex'}>
                        <Avatar sx={{ width: 50, height: 50, margin: '0px 15px' }}>
                            {prescripteur.personnePhysique ? <PersonIcon /> : <ApartmentIcon />}
                        </Avatar>

                        <Typography variant="h1">{title}</Typography>
                        {prescripteur.demo && (
                            <Avatar
                                sx={{ bgcolor: 'red', width: 50, height: 50, margin: '0px 15px' }}
                            >
                                <Typography fontSize={'50'}>Demo</Typography>
                            </Avatar>
                        )}
                    </Box>

                    <PrescripteurInformationsCard
                        prescripteur={prescripteur}
                        prescripteursApi={prescripteursApi}
                        contactsApi={contactsApi}
                        idSociete={xIdSociete}
                        reloadPrescripteur={reloadPrescriber}
                        modalFunctions={modalFunctions}
                    />
                </Box>
            )}

            <Modalizer open={isModalOpen} onClose={() => closeModal()} maxWidth={'lg'}>
                <Box>{modalContent}</Box>
            </Modalizer>
        </>
    );
};

export default React.memo(PrescripteurFiche);
