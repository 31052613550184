import * as React from 'react';
import { styled, Switch as MuiSwitch, type SwitchProps as MuiSwitchProps } from '@mui/material';

export type SwitchProps = MuiSwitchProps & {
    value?: boolean;
    defaultValue?: boolean;
    // color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
};

const StyledSwitch: React.FunctionComponent<SwitchProps> = styled((props: SwitchProps) => (
    <MuiSwitch {...(props as MuiSwitchProps)} />
))(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: 'primary',
            },
        },
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                backgroundColor: '-50%',
                opacity: 0.3,
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: '-50%',
            },
        },
    },
}));

const Switch: React.FunctionComponent<SwitchProps> = function ({ color, ...props }: SwitchProps) {
    let checked = false;

    if (props.checked !== undefined) {
        checked = props.checked;
    } else if (props.value !== undefined) {
        checked = props.value;
    } else if (props.defaultValue) {
        checked = props.defaultValue;
    }

    return color ? (
        <MuiSwitch role="switch" color={color} {...(props as MuiSwitchProps)} checked={checked} />
    ) : (
        <StyledSwitch role="switch" {...props} checked={checked} />
    );
};

export default Switch;
