import { Components } from '@mui/material/styles';

const components: Components = {
    MuiCssBaseline: {
        styleOverrides: {
            '*': {
                boxSizing: 'border-box',
            },
            html: {
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                margin: 0,
                padding: 0,
            },
            '#root': {
                height: '100%',
            },
            "*[dir='rtl'] .buyNowImg": {
                transform: 'scaleX(-1)',
            },

            '.buyNowImg': {
                position: 'absolute',
                right: '-44px',
                top: '-18px',
                width: '143px',
                height: '175px',
            },
            '.MuiCardHeader-action': {
                alignSelf: 'center !important',
            },
            '.scrollbar-container': {
                borderRight: '0 !important',
            },
            "*[dir='rtl'] .welcomebg:before": {
                backgroundPosition: 'top -24px left -9px !important',
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                paddingLeft: '0 !important',
                paddingRight: '0 !important',
                maxWidth: '100vw',
            },
        },
    },

    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                boxShadow: 'none',
                fontSize: '15px',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
        },
    },

    MuiListItem: {
        styleOverrides: {
            root: {
                borderRadius: '9px',
            },
        },
    },

    MuiListItemButton: {
        styleOverrides: {
            root: {
                borderRadius: '9px',
            },
        },
    },

    // MuiPaper: {
    //     styleOverrides: {
    //         root: {
    //             padding: '20px',
    //         },
    //     },
    // },

    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: '20px',
                padding: '20px',
                margin: '15px',
                boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
            },
        },
    },
    MuiCardHeader: {
        defaultProps: {},
        styleOverrides: {
            root: {
                padding: '0px',
                fontWeight: 'bold !important',
            },
        },
    },
    MuiCardContent: {
        defaultProps: {},
        styleOverrides: {
            root: {
                padding: '16px 0px',
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: '40px',
            },
        },
    },
    MuiLinearProgress: {
        styleOverrides: {
            root: {
                backgroundColor: '#ecf0f2',
                borderRadius: '6px',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                borderRadius: '0',
            },
        },
    },
    MuiChip: {
        defaultProps: {
            size: 'small',
        },
        styleOverrides: {
            root: {
                fontWeight: '400',
                fontSize: '0.625rem',
                borderRadius: '50px',
            },
        },
    },
};

export default components;
