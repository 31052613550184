import * as React from 'react';
import { Box, SxProps } from '@mui/system';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaceIcon from '@mui/icons-material/Place';

export type ActionsBarProps = {
    deleteAction?: React.MouseEventHandler<SVGSVGElement>;
    editAction?: React.MouseEventHandler<SVGSVGElement>;
    localisationAction?: React.MouseEventHandler<SVGSVGElement>;
    sx?: SxProps;
};

const ActionsBar: React.FunctionComponent<ActionsBarProps> = function ({
    deleteAction,
    editAction,
    localisationAction,
    sx,
}) {
    return (
        <Box
            role="listbox"
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                color: 'text.primary',
                gap: '10px',
                ...sx,
            }}
        >
            {editAction ? (
                <DriveFileRenameOutlineIcon onClick={editAction} sx={{ cursor: 'pointer' }} />
            ) : null}
            {deleteAction ? (
                <DeleteOutlineIcon onClick={deleteAction} sx={{ cursor: 'pointer' }} />
            ) : null}
            {localisationAction ? (
                <PlaceIcon onClick={localisationAction} sx={{ cursor: 'pointer' }} />
            ) : null}
        </Box>
    );
};

export default ActionsBar;
