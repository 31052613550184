import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FolderIcon from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import { FormObject, FormStructure, onChangeInputType } from '@europrocurement/flexy-form';
import {
    Dossier,
    Fournisseur,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import { DataSource, getDataThunkType } from '@europrocurement/l2d-redux-utils';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { RSFRubFac, ligneProduit, tableauProduits } from './types';
import { calculatorProduit, compareProduits, updateFormTotaux } from './functions/calculsProduits';
import { HeaderSwitch } from '@europrocurement/flexy-components';
import { UseFormReturn } from 'react-hook-form';
import { fillProduits } from './functions/produitFunctions';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import { useDispatch, useSelector } from 'react-redux';
import { FactureFormSelector } from '../../../../redux/RootStore';

export const getFournisseurStructure = function (
    sirenField: FormStructure,
    fournisseurAdornment: React.ReactNode | null = null,
) {
    const fournisseurStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <BusinessIcon sx={{ marginRight: '5px' }} />
                    Fournisseur
                </>
            ),
            name: 'supplier_header',
        },
        sirenField,
        {
            type: 'text',
            name: 'raison_sociale_fournisseur',
            inputlabel: 'Raison sociale',
            disabled: true,
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6,
        },
        {
            type: 'text',
            name: 'type_remise_fournisseur',
            inputlabel: 'Type remise',
            disabled: true,
            InputProps: {
                endAdornment: fournisseurAdornment,
            },
            xs: 6,
            sm: 6,
            md: 6,
            lg: 6,
        },
    ];

    return fournisseurStructure;
};

export const enteteFactureStructure: (
    onUpdateAvoir: () => void,
    disabled?: boolean,
    headerChildren?: React.ReactNode,
) => FormStructure[] = (onUpdateAvoir, disabled = false, headerChildren = null) => [
    {
        type: 'header',
        label: (
            <>
                <ReceiptIcon sx={{ marginRight: '5px' }} />
                Facture
                {headerChildren}
            </>
        ),
        sx: {
            marginTop: '0px',
        },
        name: 'invoice_header',
    },
    {
        type: 'text',
        name: 'numero_facture',
        inputlabel: 'Numero',
        placeholder: 'F000000000',
        disabled,
        rules: {
            required: 'Vous devez renseigner le numero de facture',
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 5,
    },
    {
        type: 'date',
        name: 'date_facture',
        inputlabel: 'Date',
        disabled,
        rules: {
            required: 'Vous devez renseigner la date de facturation',
            validate: {
                between: (value: Date) => {
                    if (value < new Date(2000, 0, 1)) {
                        return 'Vérifier la date (Année inférieure à 2000)';
                    }
                    let current = new Date();  
                    if (value > new Date(current.getFullYear()+1, current.getMonth(), current.getDate())) {
                        return `Vérifier la date (Impossibilité de saisir une facture antidatée de plus d'un an)`;
                    }
                    return true;
                },
            },
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 5,
    },
    {
        type: 'boolean',
        name: 'avoir',
        inputlabel: "Facture d'avoir",
        disabled,
        verticalSwitch: true,
        onChangeInput: () => {
            onUpdateAvoir();
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 2,
    },
];

export const totauxStructure: (
    disabled: boolean,
    changeDisabled: (disabled: boolean) => void,
    isMulti?: boolean,
    displaySwitch?: boolean,
) => FormStructure[] = (disabled, changeDisabled, isMulti = false, displaySwitch = true) => {
    const header: FormStructure = {
        type: 'header',
        label: (
            <>
                <CheckCircleOutlineIcon sx={{ marginRight: '5px' }} />
                {isMulti ? 'Sous-totaux' : 'Totaux'}
            </>
        ),
        name: 'totals_header',
    };

    if (displaySwitch === true) {
        header.switchs = [
            {
                label: 'Débloquer',
                checked: !disabled,
                onChange(event, checked) {
                    changeDisabled(!checked);
                },
            },
        ];
    }

    return [
        header,
        {
            type: 'number',
            name: 'total_ht',
            inputlabel: isMulti ? 'Sous-total HT' : 'Total HT',
            disabled,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
        },
        {
            type: 'number',
            name: 'total_tva',
            inputlabel: isMulti ? 'Sous-total TVA' : 'Total TVA',
            disabled,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
        },
        {
            type: 'number',
            name: 'total_ttc',
            inputlabel: isMulti ? 'Sous-total TTC' : 'Total TTC',
            disabled,
            xs: 12,
            sm: 12,
            md: 4,
            lg: 4,
        },
    ];
};

export const getDossierStructure: (
    disabled: boolean,
    dossierField: FormStructure,
    prestaField: FormStructure,
    headerChildren?: React.ReactNode,
) => FormStructure[] = (disabled, dossierField, prestaField, headerChildren = null) => {
    return [
        {
            type: 'header',
            label: (
                <>
                    <FolderIcon sx={{ marginRight: '5px' }} />
                    Dossier
                    {headerChildren}
                </>
            ),
            name: 'file_header',
        },
        dossierField,
        prestaField,
        {
            type: 'text',
            name: 'nom_journal',
            inputlabel: 'Journal',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
        {
            type: 'text',
            name: 'date_parution',
            inputlabel: 'Date de parution',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
        {
            type: 'text',
            name: 'nom_annonceur',
            inputlabel: 'Annonceur',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
    ];
};

export const getDossierAcStructure = function (
    dataSource: DataSource<Dossier>,
    fetchData: getDataThunkType<Dossier>,
    defaultInputValue?: string,
    onChangeDossier?: onChangeInputType,
    editMode?: boolean,
): FormStructure {
    editMode = editMode ? editMode : false;

    let acDossier: FormStructure = {
        type: 'text',
        name: 'numero_dossier',
        inputlabel: 'Numéro de dossier',
        disabled: editMode,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };

    if (editMode) return acDossier;

    acDossier = {
        type: 'autocompletestore',
        name: 'numero_dossier',
        inputlabel: 'Recherche par numéro de dossier',
        dataSource: dataSource,
        fetchData: fetchData,
        renderField: 'numero',
        defaultInputValue: defaultInputValue,
        idProperty: 'id',
        rules: {
            required: 'Vous devez renseigner le numero de dossier',
        },
        getOptionLabel: (item: Dossier) => '' + item.numero || '', // Se rends dans l'input
        simpleRenderOption: (item: Dossier) =>
            `${item.numero} - ${item.prestations && item.prestations[0]?.jal?.nom} - ${
                item.annonceur
            }`, // Se rends dans les options
        onChangeInput: onChangeDossier,
        searchability: (terms) => /^\d+$/.test(terms),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };

    return acDossier;
};

export const getPrestaAcStructure = function (
    dataSource: DataSource<Dossier>,
    fetchData: getDataThunkType<Dossier>,
    defaultInputValue?: string,
    onChangeDossier?: onChangeInputType,
    editMode?: boolean,
): FormStructure {
    editMode = editMode ? editMode : false;

    let acPresta: FormStructure = {
        type: 'text',
        name: 'numero_annonce',
        inputlabel: "Numéro d'annonce",
        disabled: editMode,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };

    if (editMode) return acPresta;

    acPresta = {
        type: 'autocompletestore',
        name: 'numero_annonce',
        inputlabel: "Recherche par numéro d'annonce",
        dataSource: dataSource,
        fetchData: fetchData,
        renderField: 'prestations.0.numero',
        defaultInputValue: defaultInputValue,
        idProperty: 'id',
        rules: {
            required: "Vous devez renseigner le numero d'annonce",
        },
        getOptionLabel: (item: Dossier) =>
            '' + (item.prestations && item.prestations[0]?.numero) || '',
        // Se rends dans les options
        simpleRenderOption: (item: Dossier) =>
            `${item.prestations && item.prestations[0]?.numero} - ${
                item.prestations && item.prestations[0]?.jal?.nom
            } - ${item.annonceur}`,
        onChangeInput: onChangeDossier,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };

    return acPresta;
};

export const getAcDossierStructure: (
    onClickModal: () => void,
    dataSource: DataSource<Dossier>,
    dossierFetchData: getDataThunkType<Dossier>,
    prestaFetchData: getDataThunkType<Dossier>,
    dossierDefaultValue: string,
    prestaDefaultValue: string,
    editMode?: boolean,
) => FormStructure[] = (
    onClickModal,
    dataSource,
    dossierFetchData,
    prestaFetchData,
    dossierDefaultValue,
    prestaDefaultValue,
    editMode = false,
) => {
    return getDossierStructure(
        true,
        getDossierAcStructure(
            dataSource,
            dossierFetchData,
            dossierDefaultValue,
            undefined,
            editMode,
        ),
        getPrestaAcStructure(dataSource, prestaFetchData, prestaDefaultValue, undefined, editMode),
        !editMode ? (
            <Button
                onClick={() => onClickModal()}
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ marginLeft: 'auto' }}
            >
                Recherche avancée
            </Button>
        ) : null,
    );
};

export const getFournisseurAcStructure = function (
    dataSource: DataSource<Fournisseur>,
    fetchData: getDataThunkType<Fournisseur>,
    defaultInputValue?: string,
    editionMode?: true,
): FormStructure {
    const acSiren: FormStructure = {
        type: 'autocompletestore',
        name: 'siren_fournisseur',
        inputlabel: 'Recherche (siren, nom)',
        dataSource,
        fetchData,
        renderField: 'siren',
        idProperty: 'id',
        defaultInputValue,
        rules: {
            required: !editionMode ? 'Vous devez renseigner le siren' : undefined,
            validate: (data: Fournisseur) => {
                if (!data.societeReference || data.societeReference === 0) {
                    return true;
                }
                return 'Vous ne pouvez pas intégrer une facture du Groupe';
            },
        },
        getOptionLabel: (item: Fournisseur) => `${item.siren} (${item.libelle})` || '',
        simpleRenderOption: (item: Fournisseur) => `${item.siren} (${item.libelle})`,
        onChangeInput: (event, input, formContext) => {},
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
    };

    return acSiren;
};

export const useGetProduitsStructure = function () {
    const dispatch = useDispatch();
    const state = useSelector(FactureFormSelector);

    return function (
        formContext: UseFormReturn<FormObject>,
        rubFacts: RubriqueFacturation[],
        headerSwitch?: HeaderSwitch,
        headerChildren?: React.ReactNode,
        blockTotaux?: boolean,
        hasBlured?: boolean,
        openModal?: () => void,
        produitsVente?: tableauProduits,
        afficherLigneVente?: boolean,
    ): FormStructure[] {
        return [
            {
                type: 'header',
                name: 'products_header',
                label: (
                    <>
                        <ListIcon sx={{ marginRight: '5px' }} />
                        Rubriques
                        {headerChildren}
                    </>
                ),
                // switchs: headerSwitch
                //     ? [
                //           {
                //               onChange(
                //                   event: React.ChangeEvent,
                //                   checked: React.SetStateAction<boolean>,
                //               ) {
                //                   dispatch({
                //                       type: ACTIONS.AFFICHER_LIGNE_VENTE,
                //                       payload: checked,
                //                   });
                //               },
                //               checked: state.afficherLigneVente,
                //               ...headerSwitch,
                //           },
                //       ]
                //     : [],
            },
            {
                type: 'subformarray',
                name: 'produits',
                isArray: true,
                addBtnLabel: 'Ajouter un produit',
                addBtnTooltip: 'Ajouter un produit',
                minLength: 1,
                maxLength: 10,
                removeBtnTooltip: 'Supprimer le produit',
                displayAddButton: true,
                gridProps: {
                    rowSpacing: 0,
                    columnSpacing: 2,
                    columns: 10,
                },
                events: {
                    onRemove() {
                        updateFormTotaux(formContext, !blockTotaux);
                    },
                    beforeAppend(item: Partial<ligneProduit>) {
                        item.txtva = 20;
                    },
                    onAppend(item, formContext, fieldArray) {
                        const produits: Partial<ligneProduit>[] = formContext.getValue('produits');
                        if (produits) {
                            produits.sort(compareProduits).map((produit) => {
                                return {
                                    ...produit,
                                    ...calculatorProduit(produit),
                                };
                            });
                            formContext.setValue('produits', produits);
                        }
                    },
                },
                structure: [
                    {
                        type: 'hidden',
                        name: 'idLigneVente',
                    },
                    {
                        type: 'select',
                        name: 'rubriqueFacturation',
                        inputlabel: 'Rubrique',
                        options: [...rubFacts, RSFRubFac],
                        getOptionValue: (item: RubriqueFacturation) => `${item.id || 0}`,
                        getOptionLabel: (item: RubriqueFacturation) => {
                            if (item.id === 99) {
                                return 'RSF';
                            } else {
                                return (
                                    rubFacts.find((rubFact: RubriqueFacturationApiObject) => {
                                        return item.id === rubFact.id;
                                    })?.code || ''
                                );
                            }
                        },
                        rules: {
                            required: 'Rubrique manquante',
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'ht',
                        inputlabel: 'HT',
                        placeholder: '0',
                        inputProps: {
                            step: 1,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            const produit = formContext.getValue(
                                input.objectPath,
                            ) as Partial<ligneProduit>;
                            fillProduits(formContext, produit, +input.base.replace(/^\D+/g, ''));
                            updateFormTotaux(formContext, !blockTotaux);
                        },
                        onBlurInput: (event, input, formContext) => {
                            if (!hasBlured) {
                                const htRub = formContext.getValue(
                                    `${input.base}rubriqueFacturation`,
                                );
                                const inputValue = formContext.getValue(input.path);
                                const typeRemise = formContext.getValue('type_remise_fournisseur');

                                if (
                                    openModal &&
                                    htRub &&
                                    htRub.id === 1 &&
                                    inputValue &&
                                    typeRemise === 'RSF Inclus (Remise sur facture)'
                                ) {
                                    openModal();
                                }
                            }
                        },
                        rules: {
                            required: 'Prix HT manquant',
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'txtva',
                        inputlabel: 'Taux TVA',
                        placeholder: '0',
                        InputProps: {
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                        defaultValue: 20,
                        inputProps: {
                            step: 1,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            const produit = formContext.getValue(
                                input.objectPath,
                            ) as Partial<ligneProduit>;
                            fillProduits(formContext, produit, +input.base.replace(/^\D+/g, ''));
                            updateFormTotaux(formContext, !blockTotaux);
                        },
                        onPaste: (event: React.ClipboardEvent) => {
                            event.clipboardData.getData('text').match(/\D/g) &&
                                event.preventDefault();
                        },
                        onKeyDown: (event: React.KeyboardEvent) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                        },
                        rules: {
                            required: 'Taux TVA manquant',
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'tva',
                        inputlabel: 'TVA',
                        placeholder: '0',
                        disabled: true,
                        inputProps: {
                            step: 0.01,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            updateFormTotaux(formContext, !blockTotaux);
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'number',
                        name: 'ttc',
                        inputlabel: 'TTC',
                        placeholder: '0',
                        disabled: true,
                        inputProps: {
                            step: 0.01,
                            min: 0,
                        },
                        onChangeInput: (event, input, formContext) => {
                            updateFormTotaux(formContext, !blockTotaux);
                        },
                        xs: 10,
                        sm: 2,
                        md: 2,
                        lg: 2,
                    },
                    {
                        type: 'customItem',
                        name: 'Informations : ',
                        renderField: (formContext, props) => {
                            const subform = formContext.getValue(props.objectPath);

                            if (!subform || !produitsVente) {
                                console.log("here null")
                                return null;
                            }
                            
                            const ligneVenteTrouvee = produitsVente.find(
                                (produitVente: any) =>
                                produitVente.idLigneVente === subform.idLigneVente,
                            );
                            console.log("here pa null",ligneVenteTrouvee, produitsVente, subform)
                            // TODO: Verifier la condition, il trouve des lignes qu'il ne devrait pas trouver
                            return afficherLigneVente ? (
                                <Box sx={{ height: '20px' }}>
                                    {ligneVenteTrouvee ? (
                                        <Typography
                                            sx={{
                                                fontStyle: 'italic',
                                                color: 'text.disabled',
                                            }}
                                        >
                                            <>
                                                vente :{' '}
                                                {ligneVenteTrouvee.rubriqueFacturation?.code} -{' '}
                                                {ligneVenteTrouvee.libelle}
                                                &nbsp;| ht: {ligneVenteTrouvee.ht}
                                                &nbsp;| tva: {ligneVenteTrouvee.tva}
                                                &nbsp;| ttc: {ligneVenteTrouvee.ttc}
                                            </>
                                        </Typography>
                                    ) : null}
                                </Box>
                            ) : null;
                        },
                    },
                ],
            },
        ];
    };
};
