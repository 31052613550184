import React, { useCallback } from 'react';
import {
    DatePicker,
    DatePickerProps,
    DateTimePicker,
    DateTimePickerProps,
    LocalizationProvider,
    MobileDatePicker,
    MobileDatePickerProps,
    MobileDateTimePicker,
    MobileDateTimePickerProps,
    MobileTimePicker,
    MobileTimePickerProps,
    TimePicker,
    TimePickerProps,
} from '@mui/x-date-pickers';
import { BaseTextFieldProps, Box, FormHelperText } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { DateTime } from 'luxon';

import FlexyTextField from '../FlexyTextField';

export const typeDateList: string[] = [
    'date',
    'datetime',
    'time',
    'mobiledate',
    'mobiledatetime',
    'mobiletime',
];
export const isDateType = (type: string): boolean => typeDateList.includes(type);

export type FlexyDateProps = Omit<
    DatePickerProps<Date, Date>,
    'renderInput' | 'value' | 'onChange' | 'onError' | 'label'
> & {
    type?: string;
    value?: Date | null;
    onChange?: (date: unknown, keyboardInputValue?: string | undefined) => void;
    inputRef?: React.ForwardedRef<HTMLInputElement>;
    error?: BaseTextFieldProps['error'];
    helperText?: BaseTextFieldProps['helperText'];
    size?: 'small' | 'medium';
};

const FlexyDate: React.FunctionComponent<FlexyDateProps> = function ({
    type,
    ...props
}: FlexyDateProps) {
    const renderInput = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ ...inputProps }: FlexyDateProps) => (
            <FlexyTextField
                fullWidth
                variant="outlined"
                size={props.size}
                {...inputProps}
                error={props.error}
                helperText={props.helperText}
            />
        ),
        [props.error, props.helperText, props.size],
    );

    const RenderPicker = useCallback(() => {
        const handleChangeDateTime = (newValue: DateTime) => {
            if (props.onChange && newValue !== null) {
                props.onChange(newValue.toJSDate());
            }
        };
        const handleChangeDate = (newValue: Date) => {
            if (props.onChange && newValue !== null) {
                props.onChange(newValue);
            }
        };
        const { ...propsChildren } = { ...props, renderInput };
        switch (type) {
            case 'Datetime':
                return (
                    <DateTimePicker
                        {...(propsChildren as DateTimePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
            case 'time':
                return (
                    <TimePicker
                        {...(propsChildren as TimePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
            case 'mobiledate':
                return (
                    <MobileDatePicker
                        {...(propsChildren as MobileDatePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'mobiledatetime':
                return (
                    <MobileDateTimePicker
                        {...(propsChildren as MobileDateTimePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'mobiletime':
                return (
                    <MobileTimePicker
                        {...(propsChildren as MobileTimePickerProps<Date, Date>)}
                        onChange={handleChangeDate}
                    />
                );
            case 'date':
            default:
                return (
                    <DatePicker
                        {...(propsChildren as DatePickerProps<Date, DateTime>)}
                        onChange={handleChangeDateTime}
                    />
                );
        }
    }, [props, renderInput, type]);

    return (
        <LocalizationProvider
            adapterLocale="fr-FR"
            dateAdapter={AdapterLuxon}
            // utils={DayJsUtils}
        >
            <Box data-testid="FlexyDate">{RenderPicker()}</Box>
        </LocalizationProvider>
    );
};

FlexyDate.defaultProps = {
    type: 'date',
    value: null,
    onChange: undefined,
    inputRef: undefined,
};

export default FlexyDate;
