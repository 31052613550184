import * as React from 'react';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';

const Accueil = function () {
    return (
        <>
            <LazyBreadcrumb path={[{ path: 'Accueil', link: '/accueil' }, 'Dashboard']} />
        </>
    );
};
export default Accueil;
