import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useForm, SubmitErrorHandler, FieldErrors } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Fournisseur,
    Dossier,
    RubriqueFacturationApiObject,
    Commande,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    MediaObject,
} from '@europrocurement/l2d-domain';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { Box, Button, FormControlLabel, InputAdornment, Switch, Typography } from '@mui/material';

import {
    dossierDataSourcesThunks,
    FactureVenteSelector,
    selectFactureVente,
    selectFournisseurAc,
    FactureFormSelector,
    RubriqueFacturationSelector,
    RootStateType,
    getFournisseurAc,
    CommandeSelector,
    MediaObjectSelector,
    DossierSelector,
    FournisseurSelector,
    factureAchatApi,
    factureAchatLigneApi,
} from '../../../../redux/RootStore';
import { FactureVente } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';

import { updateFormTotaux, arrondi } from './functions/calculsProduits';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { ACTIONS } from '../../../../redux/FactureFormReducer';
import {
    enteteFactureStructure,
    totauxStructure,
    getFournisseurStructure,
    getFournisseurAcStructure,
    getAcDossierStructure,
    useGetProduitsStructure,
} from './formElements';
import { factureAchatCreate, factureFormObject, ligneAchat, ligneProduit, ligneWrite, tableauProduits } from './types';
import { Modalizer } from '@europrocurement/flexy-components';
import { toISOString } from '../../../../utils/toISOString';
import SearchDossierModal from '../modals/SearchDossierModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import generateB2DPath from '../../../../utils/generateB2DPath';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { updateFormWithFournisseur } from './functions/fournisseurFunctions';
import { useUpdateFormWithDossier } from './functions/dossierFonctions';
import {
    ajouterLigneRSF,
    defaultProduits,
    replaceProduits,
    removeEmptyProduits,
    useActionOnChangeTauxRemise,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
} from './functions/produitFunctions';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { useResetAllForm, useResetFactureAchatState } from './functions/generalFunctions';
import { fillProduits } from './functions/produitFunctions';
import { formProduitsToTableauAchat } from './functions/dataTransformers';
import { multiAnnonceModalMessages, removeProductsModalMessages } from '../modals/modalSpec';
import { useUpdateDomaine } from './functions/venteFunctions';

export type FactureFormProps = {
    mediaObject: MediaObject;
    facture: factureFormObject | Partial<factureFormObject>;
    onSubmit: (data: factureAchatCreate, goToNext?: boolean) => void;
    onCancel: () => void;
};

const FactureForm: React.FunctionComponent<FactureFormProps> = function ({
    mediaObject,
    facture,
    onSubmit,
    onCancel,
}) {
    const kc = UseKeycloakService();

    // Comportement normal ( check droits keycloak )
    const isInterne = kc.checkRole('realm:interne');

    const [hasBlured, setHasBlured] = useState<boolean>(false);
    const [modalDossierOpen, setModalDossierOpen] = useState<boolean>(false);
    const [modalRemiseOpen, setModalRemiseOpen] = useState<boolean>(false);
    const [modalConfirmationOpen, setModalConfirmationOpen] = useState<boolean>(false);
    const [modalRemoveProduitsOpen, setModalRemoveProduitsOpen] = useState<boolean>(false);
    // const [actionOnValidation, setActionOnValidation] = useState<() => void>(undefined);
    const [blockTotaux, setBlockTotaux] = useState<boolean>(true);
    const [rsfInclusTxremise, setRsfInclusTxremise] = useState<string>();
    const [lastVenteUpdate, setLastVenteUpdate] = useState<number|null>(null);

    const [hasSetRsf, setHasSetRsf] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    /**
     * DataSources
     */
    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
        shallowEqual,
    );

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
        shallowEqual,
    );

    const rubFactsDataSource: DataSource<RubriqueFacturationApiObject> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;

    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;

    const commandeSelected: Commande | undefined = useSelector(CommandeSelector).main.selected;
    const venteSelected: FactureVente | undefined = useSelector(FactureVenteSelector).main.selected;
    const dossierSelected: Dossier | undefined =
        useSelector(DossierSelector).autocompleteDossiers.selected;
    const fournisseurSelected: Fournisseur | undefined =
        useSelector(FournisseurSelector).autocompleteFactures.selected;

    const searchByNumeroPresta =
        dossierDataSourcesThunks.autocompleteDossiers.data.searchByNumeroPresta;

    // Reducer instanciation (replace useStates)
    const dispatch = useDispatch();
    const state = useSelector(FactureFormSelector);

    // Initialisation du formulaire
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: facture,
    });

    const navigate = useNavigate();

    // const resetStates = useResetFactureAchatState();
    const resetAllForm = useResetAllForm();
    const updateFormWithDossier = useUpdateFormWithDossier();
    const updateDomaine = useUpdateDomaine();
    const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
    const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
    const getProduitsStructure = useGetProduitsStructure();

    const acSiren: FormStructure = useMemo(() => {
        return getFournisseurAcStructure(
            fournisseursDataSource,
            getFournisseurAc,
            state.defaultSiren,
        );
    }, [fournisseursDataSource, state.defaultSiren]);

    /**
     * DEB - useEffects
     */

    /**
     * Infos facture de vente par N° de dossier / d'annonce
     *
     * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
     * Enregistrer le fournisseur correspondant.
     *
     * @see dossierSelected?.facture
     * @see dispatch
     * @see isInterne
     */
    useEffect(() => {
        updateFormWithVentesAndEvents(
            formContext,
            dossierSelected || null,
            fournisseurSelected || null,
            venteSelected || null,
            ventesDataSource.status,
        );
    }, [
        formContext,
        updateFormWithVentesAndEvents,
        dossierSelected,
        fournisseurSelected,
        venteSelected,
        ventesDataSource.status,
        commandeSelected,
    ]);

    /**
     * S'il n'y plus de dossier ou de fournisseur sélectionné,
     * alors on remplace le tableau de produits par la valeur par défaut.
     */
    useEffect(() => {
        if (!dossierSelected && !fournisseurSelected) {
            replaceProduits(formContext, defaultProduits(1, false));
        }
    }, [dossierSelected, formContext, fournisseurSelected])

    /**
     * Infos fournisseur par N° de dossier / d'annonce
     *
     * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
     * Enregistrer le fournisseur correspondant.
     *
     * @see dossierSelected?.prestations
     * @see dispatch
     * @see isInterne
     */
    useEffect(() => {
        const presta =
            dossierSelected?.prestations &&
            dossierSelected?.prestations.length > 0 &&
            dossierSelected?.prestations[0];

        if (presta) {
            dispatch({ type: ACTIONS.SET_NUM_PRESTA, payload: presta.numero });
            formContext.trigger('numero_annonce');
        }
        const idFacturante = presta ? presta.idEntiteFacturante : null;
        if (idFacturante) {
            dispatch(selectFournisseurAc({ id: idFacturante }));
        }
    }, [dossierSelected?.prestations, dispatch, formContext]);

    const resetStates = useResetFactureAchatState();

    /**
     * Infos dossier par N° de dossier / d'annonce
     *
     * Si un dossier est sélectionné, met à jour les informations.
     */
    useEffect(() => {
        if (
            dossierSelected &&
            dossierSelected.numero &&
            `${dossierSelected.numero}` !== state.defaultNumeroDossier
        ) {
            updateFormWithDossier(formContext, dossierSelected);
        } else if (!dossierSelected) {
            resetStates(formContext);
        }
    }, [
        formContext,
        dossierSelected,
        state.defaultNumeroDossier,
        updateFormWithDossier,
        resetStates,
    ]);

    /**
     * Ajout des RubFacts généraux
     *
     * @see dispatch
     * @see rubFacts.data
     */
    useEffect(() => {
        const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

        dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
    }, [dispatch, rubFactsDataSource.data]);

    /**
     * Filtre sur les rubriques de facturation
     *
     * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
     *
     * @see state.defDomaine
     * @see state.rubFacts
     */
    useEffect(() => {
        dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

        if (state.defDomaine !== 0) {
            dispatch({
                type: ACTIONS.FILTER_RUB_FACTS,
                payload: state.defDomaine,
            });
        }
    }, [dispatch, rubFactsDataSource.data, state.defDomaine]);

    /**
     * Si on a une commande on met le domaine de la commande
     */
    useEffect(() => {
        if (commandeSelected?.idDomaine) {
            dispatch({
                type: ACTIONS.SET_DEF_DOMAINE,
                payload: commandeSelected?.idDomaine,
            });
        }
    }, [commandeSelected?.idDomaine, dispatch]);

    /**
     * Met à jours le domaine en fonction  de l'achat
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

        const factureVente = venteSelected;

        updateDomaine(factureVente);
    }, [venteSelected, updateDomaine]);

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomaine);

    /**
     * Select la facture de vente de la commande,
     * si le dossier n'a pas lui-même de facture de vente.
     *
     * On cherche dans la commande le dossier qui correspond au dossier sélectionné
     * pour s'assurer que la facture soit la bonne.
     *
     * E.g. :
     *     - Dossier sans facture : 1272353
     *     - Dossier avec facture : 802308
     */
    useEffect(() => {
        if (
            commandeSelected?.idFacture &&
            dossierSelected &&
            dossierSelected.id &&
            !dossierSelected.facture
        ) {
            const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                if (!dossierSelected.id) return false;
                return dossier.includes(dossierSelected.id.toString());
            });

            if (
                matchedDossier &&
                ventesDataSource.status !== 'loading' &&
                (!venteSelected || commandeSelected?.idFacture !== venteSelected.id)
            ) {
                dispatch(selectFactureVente({ id: commandeSelected?.idFacture }));
            } else {
                // console.log('Aucun dossier de la commande ne correspond au dossier sélectionné.');
                if (domaine !== commandeSelected.idDomaine) {
                    dispatch({
                        type: ACTIONS.SET_DEF_DOMAINE,
                        payload: commandeSelected.idDomaine,
                    });
                }
            }
        }
    }, [
        commandeSelected,
        dossierSelected,
        ventesDataSource.status,
        dispatch,
        venteSelected,
        domaine,
    ]);

    /**
     * Ajout des lignes d'achat correspondant à la vente
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-54
     *
     * Si une facture de vente est trouvée :
     * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
     * la propriété 'affiche' est à false.
     *
     * @see venteSelected
     * @see formContext
     * @see addProduit
     * @see state.rubFacts
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect


        if( venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate ){
            setLastVenteUpdate(venteSelected.id);
            syncAchatProduitsWithVente(venteSelected, formContext, state.rubFacts, dossierSelected);
        }
        // console.log('FIN DE LA SYNCHRONISATION ENTRE LES LIGNES D\'ACHATS ET LES VENTES', formContext.getValues('produits'));
    }, [lastVenteUpdate, dossierSelected, formContext, state.rubFacts, syncAchatProduitsWithVente, venteSelected]);


    
    // TODO: Double la ligne RSF (BUG). A voir plus tard si vraiment inutile.
    // useEffect(() => {
    //     if (domaine && !venteSelected) {
    //         replaceProduits(formContext, defaultProduits(domaine, true));
    //     }
    // }, [domaine, formContext, venteSelected])

    /**
     * Afficher infos fournisseur
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-53
     *
     * Si le N° de dossier / annonce change :
     * Remplir les champs Siren, Raison sociale, Type remise
     * Ajouter une ligne de produit : Remise
     *
     * @see fournisseurSelected
     * @see updateRSFFournisseur
     */
    useEffect(() => {
        const fournisseur = fournisseurSelected;
        if (fournisseur !== undefined) {
            formContext.setValue('fournisseur', fournisseur);
            formContext.setValue('siren_fournisseur', fournisseur);
            formContext.trigger('siren_fournisseur');
        }

        updateFormWithFournisseur(formContext, fournisseur, {
            typeRemise: (type: TypeRemiseFournisseurRead) => {
                if (type.code === 'RSF' || type.code === 'RSF Inclus') {
                    ajouterLigneRSF(formContext, fournisseurSelected);
                }
            },
            siren: (siren: string) => {
                if (siren !== state.defaultSiren) {
                    dispatch({ type: ACTIONS.SET_SIREN, payload: siren });
                }
            },
        });
    }, [dispatch, formContext, fournisseurSelected, state.defaultSiren, state.rubFacts]);

    /**
     * FIN - useEffects
     */

    /**
     * FormStructure
     */

    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();
    /**
     * Fragment fournisseur du formulaire
     *
     * Affiche les champs Siren, Raison sociale, Type remise
     *
     * @param fournisseursDataSource
     * @param fournisseurDataSourcesThunks.autocompleteFactures.getData
     * @param state.defaultSiren
     */
    const fournisseurStructure: FormStructure[] = useMemo(() => {
        return getFournisseurStructure(
            acSiren,
            fournisseurSelected?.typeRemise?.code === 'RSF' ? (
                <InputAdornment position="end">
                    <Button
                        onClick={() => {
                            actionOnChangeTauxRemise(
                                formContext,
                                () => {
                                    setModalRemiseOpen(true);
                                },
                                (message) => {
                                    enqueueSnackbar(<Typography>{message}</Typography>, {
                                        variant: 'error',
                                    });
                                },
                            );
                        }}
                        disabled={false}
                        variant="text"
                        size="small"
                        style={{ marginTop: '16px', marginBottom: '16px' }}
                    >
                        Remise intégrée
                    </Button>
                </InputAdornment>
            ) : null,
        );
    }, [
        acSiren,
        actionOnChangeTauxRemise,
        enqueueSnackbar,
        formContext,
        fournisseurSelected?.typeRemise?.code,
    ]);

    /**
     * Fragment dossier du formulaire
     *
     * Affiche les champs Recherche par numero de dossier, Recherche par numero d'annonce, Journal, Date de parution, Annonceur
     *
     * @param aCDossier
     * @param aCPresta
     */
    const dossierStructure: FormStructure[] = useMemo(
        () =>
            getAcDossierStructure(
                () => setModalDossierOpen(true),
                dossiersDataSource,
                dossierDataSourcesThunks.autocompleteDossiers.getData,
                searchByNumeroPresta,
                state.defaultNumeroDossier,
                state.defaultNumeroPresta,
            ),
        [
            dossiersDataSource,
            searchByNumeroPresta,
            state.defaultNumeroDossier,
            state.defaultNumeroPresta,
        ],
    );

    /**
     * Fragment produits du formulaire
     *
     * Affiche les lignes produits (Rubrique, HT, Taux TVA, TVA, TTC), Ajouter un produit, Afficher les informations de vente
     *
     * @param ACTIONS.AFFICHER_LIGNE_VENTE
     * @param state.rubFacts
     * @param state.produitsVente
     * @param state.afficherLigneVente
     */
    const produitsStructure: FormStructure[] = useMemo(
        () =>
            getProduitsStructure(
                formContext,
                state.rubFacts,
                undefined,
                state.switchLigneVente ? (<Box
                    sx={{
                        display: 'flex',
                        // width: '-webkit-fill-available',
                        marginRight: '-8px',
                        marginLeft: 'auto'
                    }}
                >
                    { state.switchLigneVente && isInterne ? (<FormControlLabel
                        sx={{
                            marginLeft: 'auto',
                        }}
                        label={state.switchLigneVente.label}
                        key={`formHeaderbtn-${state.switchLigneVente.label}`}
                        control={
                            <Switch
                                onChange={(
                                    event: React.ChangeEvent,
                                    checked: React.SetStateAction<boolean>
                                ) => {
                                    dispatch({
                                        type: ACTIONS.AFFICHER_LIGNE_VENTE,
                                        payload: checked,
                                    });
                                }}
                                checked={state.afficherLigneVente}
                                size="small"
                                aria-label="switch"
                                {...state.switchLigneVente}
                            />
                        }
                    />) : null}
                </Box>) : null,
                blockTotaux,
                hasBlured,
                () => {
                    setModalRemiseOpen(true);
                },
                state.produitsVente,
                isInterne ? state.afficherLigneVente : false,
            ),
        [
            blockTotaux,
            formContext,
            getProduitsStructure,
            hasBlured,
            state.afficherLigneVente,
            state.produitsVente,
            state.rubFacts,
            state.switchLigneVente,
        ],
    );

    const navigateToMultiAnnonces = (
        mediaObject: MediaObject,
        navigate: (path: string) => void,
        onError: (message: string) => void,
    ) => {
        const response = generateB2DPath('formmultimediaobject', {
            mediaObject: mediaObject,
        });

        resetAllForm(formContext);

        if (response.status === 'OK') {
            navigate(response.path);
        } else if (response.status === 'KO') {
            onError(response.message);
        }
    };

    const factureFormStructure: FormStructure[] = useMemo(() => {
        // Ordre et affichage des sections de formulaire
        const btnMultiAnnonces: ReactNode = (
            <Button
                onClick={() => {
                    setModalConfirmationOpen(true);
                }} // URL vers page de formulaire multi-annonces
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ marginLeft: 'auto' }}
            >
                Facture multi-annonces
            </Button>
        );

        const factureFormStructure: FormStructure[] = [];


        factureFormStructure.push(
            ...enteteFactureStructure(
                () => {
                    updateFormTotaux(formContext, blockTotaux);
                },
                false,
                btnMultiAnnonces,
            ),
            ...dossierStructure,
            ...fournisseurStructure,
        );
       

        // console.log("iamhere")
        // TODO: Switch disabled ne change pas d'état
        // (les champs eux peuvent changer une fois)
        factureFormStructure.push(
            ...produitsStructure,
            ...totauxStructure(blockTotaux, (disabled: boolean) => {
                // console.log("here",disabled);
                setBlockTotaux(disabled);
                if (disabled) updateFormTotaux(formContext, blockTotaux);
            }),
        );

        return factureFormStructure;
    }, [
        blockTotaux,
        dossierStructure,
        formContext,
        fournisseurStructure,
        produitsStructure,
    ]);

    /**
     * Actions lors de l'envoi du formulaire
     *
     * Si le formulaire est rempli correctement et le bouton d'envoi est appuyé :
     * Formaliser les données du formulaire de façon à les rendres compatible avec l'API achats
     *
     * @param data
     * @see onSubmit
     *
     */
    const handleSubmit: SubmitHandler<factureFormObject> = useCallback(
        async (data: factureFormObject) => {
            // Formalisme des données du formulaire de création de facture d'achat
            let factureAchatFormated: factureAchatCreate = {
                cloture: true,
                libelleAnnonceur: data.nom_annonceur,
                avoir: data.avoir,
                hasSetRsf: hasSetRsf,
                idJal: +data.dossier.prestations[0].jal.id,
                dateFacture: toISOString(data.date_facture),
                numeroFacture: data.numero_facture,
                idEntiteFacturante: +data.fournisseur.id,
                libelleFacturante: data.fournisseur.libelle,
                idSociete: +data.dossier.prestations[0].idSocieteFacture,
                codesRejets: [],
                notes: [
                    {
                        note: data.dossier.noteAchats ? data.dossier.noteAchats : 'Pas de note',
                    },
                ],
                lignes: formProduitsToTableauAchat(data),
                pdfFacture: mediaObject['@id'],
            };
            // Si les totaux ne sont pas bloqués on set les totaux manuellement
            if (!blockTotaux) {
                factureAchatFormated.ht = parseFloat(String(data.total_ht)).toFixed(2);
                factureAchatFormated.tva = parseFloat(String(data.total_tva)).toFixed(2);
                factureAchatFormated.ttc = parseFloat(String(data.total_ttc)).toFixed(2);
            }
            if (data.goToNext) {
                onSubmit(factureAchatFormated, true);
            } else {
                onSubmit(factureAchatFormated);
            }
        },
        [blockTotaux, hasSetRsf, onSubmit],
    );

    const handleInvalid: SubmitErrorHandler<factureFormObject> = useCallback(
        (errors: FieldErrors<factureFormObject>) => {
            // Formalisme des données du formulaire de création de facture d'achat
            if (Object.keys(errors).length === 1 && errors.produits) {
                // console.log(errors);
                let oneProduitOk = false;
                if (errors.produits.length) {
                    for (let index = 0; index < errors.produits.length; index++) {
                        if (errors.produits[index] === undefined) {
                            oneProduitOk = true;
                        }
                    }

                    if (oneProduitOk) {
                        setModalRemoveProduitsOpen(true);
                    }
                }
            }
        },
        [],
    );

    useEffect(() => {
        formContext.setValue('rsf_inclus_txremise', rsfInclusTxremise);
    }, [formContext, rsfInclusTxremise]);

    const modalFactureFormStructure: FormStructure[] = useMemo(() => {
        return [
            {
                type: 'number',
                name: 'facture-form-modal-txremise',
                inputlabel: 'Taux de remise',
                autoFocus: true,
                placeholder: '0',
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },
                defaultValue: '20',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChangeInput: (event, input, formContext) => {
                    const value: string = formContext.getValue(input.path);
                    setRsfInclusTxremise(value);
                },
                onPaste: (event: React.ClipboardEvent) => {
                    event.clipboardData.getData('text').match(/\D/g) && event.preventDefault();
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                },
                rules: {
                    required: 'Taux de remise manquant',
                },
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
            },
        ];
    }, []);

    const actionOnRSF: any | undefined = (
        tableauProduits: tableauProduits,
        actionIfRSF: (produitRSF?: Partial<ligneProduit> | undefined) => any | undefined,
        actionNoRSF: () => any | undefined,
    ) => {
        const produits = tableauProduits;
        const produitRSF = produits.find((item) => item.rubriqueFacturation?.id === 99);

        if (produitRSF && actionIfRSF !== undefined) {
            actionIfRSF(produitRSF);
        }

        if (!produitRSF && actionNoRSF !== undefined) {
            actionNoRSF();
        }
    };

    const updateProduitsOnRemise = useCallback(
        (txRemise: string, pubLineNumber: number) => {
            const fournisseur = fournisseurSelected;

            // Sert pour relever les fournisseurs dont la RSF a été modifiée manuellement.
            setHasSetRsf(true);
            ajouterLigneRSF(formContext, fournisseur);

            const produits = formContext.getValues(`produits`) as tableauProduits;

            if (fournisseur && parseFloat(txRemise) > 0 && pubLineNumber !== undefined) {
                // Calcul de la ligne de PUB
                const pubHt = formContext.getValues(`produits.${pubLineNumber}.ht`);

                if (pubHt !== undefined) {
                    const newPubHt = arrondi(pubHt / (1 - parseFloat(txRemise) / 100)) as number;
                    const newRsfHt = arrondi(newPubHt - pubHt);

                    const txtva = formContext.getValues(`produits.${pubLineNumber}.txtva`);

                    formContext.setValue(`produits.${pubLineNumber}.ht`, newPubHt);

                    fillProduits(
                        formContext,
                        {
                            idLigneVente: formContext.getValues(
                                `produits.${pubLineNumber}.idLigneVente`,
                            ),
                            ht: newPubHt,
                            txtva: formContext.getValues(`produits.${pubLineNumber}.txtva`),
                        },
                        pubLineNumber,
                    );
                    // console.log("j'ai a mettre dans rsf ca :",newRsfHt);

                    // On cherche la ligne de rsf
                    actionOnRSF(
                        produits,
                        (produitRSF: Partial<ligneProduit>) => {
                            // console.log("j'agit sur ma ligne");
                            const index = produits.indexOf(produitRSF);

                            produits[index].ht = newRsfHt;
                            produits[index].txtva = txtva;

                            formContext.setValue('produits', produits);
                        },
                        undefined,
                    );

                    const produitsAfterAddRsf = formContext.getValues(
                        `produits`,
                    ) as tableauProduits;

                    const RSF = produitsAfterAddRsf.find(
                        (item) => item.rubriqueFacturation?.id === 99,
                    );

                    if (RSF) {
                        fillProduits(formContext, RSF, produitsAfterAddRsf.indexOf(RSF));
                        formContext.trigger(`produits.${produitsAfterAddRsf.indexOf(RSF)}.ht`);
                    }

                    updateFormTotaux(formContext, blockTotaux);
                }
            }
        },
        [blockTotaux, formContext, fournisseurSelected],
    );

    /**
     * Déclenché lors de la soumission du formulaire de la modal.
     * Siren exemple RSF Inclus : 987654321
     */
    const handleModalSubmit: SubmitHandler<{
        'facture-form-modal-txremise': string;
    }> = useCallback(
        (data: { 'facture-form-modal-txremise': string }) => {
            actionOnChangeTauxRemise(
                formContext,
                (produitConcernedByRsf) => {
                    const txRemise = data['facture-form-modal-txremise'];
                    updateProduitsOnRemise(txRemise, produitConcernedByRsf);
                    setHasBlured(true);
                    setModalRemiseOpen(false);
                },
                (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'error' });
                },
            );
        },
        [actionOnChangeTauxRemise, enqueueSnackbar, formContext, updateProduitsOnRemise],
    );

    return (
        <>
            {/* Modal Multi annonce */}
            <ConfirmationModal
                isModalOpen={modalConfirmationOpen}
                closeModal={() => {
                    setModalConfirmationOpen(false);
                }}
                messages={multiAnnonceModalMessages}
                actionOnValidation={() => {
                    mediaObjectDataSource.selected
                        ? navigateToMultiAnnonces(mediaObjectDataSource.selected, navigate, () => {
                              resetAllForm(formContext);
                          })
                        : enqueueSnackbar('PDF manquant !', { variant: 'error' });

                    setModalConfirmationOpen(false);
                }}
                actionOnCancellation={() => {
                    setModalConfirmationOpen(false);
                }}
            />

            {/* Modal produits */}
            <ConfirmationModal
                isModalOpen={modalRemoveProduitsOpen}
                closeModal={() => {
                    setModalRemoveProduitsOpen(false);
                }}
                messages={removeProductsModalMessages}
                actionOnValidation={() => {
                    removeEmptyProduits(formContext);
                    formContext.handleSubmit(
                        (d) => {
                            handleSubmit(d as factureFormObject);
                        },
                        (e) => console.log(e),
                    )();
                    setModalRemoveProduitsOpen(false);
                }}
                actionOnCancellation={() => {
                    setModalRemoveProduitsOpen(false);
                }}
            />

            <Modalizer
                open={modalRemiseOpen}
                onClose={() => {
                    setModalRemiseOpen(false);
                }}
            >
                <Box>
                    <FlexyForm
                        formObject={{
                            'facture-form-modal-txremise': rsfInclusTxremise,
                        }}
                        formStructure={modalFactureFormStructure}
                        onSubmit={handleModalSubmit}
                        submitButton={{
                            render: ({ isSubmitting }, innerHandleSubmit: () => void) => (
                                <Box display="flex" flexDirection="row" justifyContent="end">
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        style={{ marginTop: '16px', marginBottom: '16px' }}
                                    >
                                        Appliquer la remise
                                    </Button>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Modalizer>

            <SearchDossierModal
                isModalOpen={modalDossierOpen}
                isDraggable={true}
                closeModal={(dossier: Dossier | null) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                    setModalDossierOpen(false);
                }}
            ></SearchDossierModal>

            <FlexyForm
                formObject={facture}
                formStructure={factureFormStructure}
                onSubmit={handleSubmit}
                onInvalid={handleInvalid}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitting }) => (
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Button
                                color="error"
                                disabled={isSubmitting}
                                variant="contained"
                                onClick={onCancel}
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            >
                                Annuler la saisie
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                variant="contained"
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            >
                                Terminer la saisie
                            </Button>
                            <Button
                                disabled={isSubmitting}
                                variant="contained"
                                onClick={formContext.handleSubmit((d) => {
                                    d.goToNext = true;
                                    handleSubmit(d as factureFormObject);
                                }, handleInvalid)}
                                style={{ marginTop: '16px', marginBottom: '16px' }}
                            >
                                Facture Suivante
                            </Button>
                        </Box>
                    ),
                }}
            />
        </>
    );
};

export default FactureForm;
