import * as React from 'react';

import { MenuEntry, BreadcrumbsOpts } from '@europrocurement/flexy-components';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TaskIcon from '@mui/icons-material/Task';
import PieChartIcon from '@mui/icons-material/PieChart';
import PauseIcon from '@mui/icons-material/PauseCircle';

import MediaObjectList from './components/lists/MediaObjectList';
import MediaObjectAdd from './MediaObjectAdd';
import SaisieFactures from './SaisieFactures';
import ModifierFactures from './ModifierFactures';

import AchatsRoot from './AchatsRoot';
import Stats from './components/Stats';
import { modulePathResolver } from '../../utils/moduleResolver';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';
import MainFactureAchatList from './components/lists/MainFactureAchatList';
import SaisieFacturesMultiple from './SaisieFacturesMultiple';
import EnteteSaisieMultiple from './components/forms/saisieMultiple/EnteteSaisieMultiple';
import EditDossierSaisieMultiple from './components/forms/saisieMultiple/EditDossierSaisieMultiple';
import DossierSaisieMultiple from './components/forms/saisieMultiple/DossierSaisieMultiple';
import RecapSaisieMultiple from './components/forms/saisieMultiple/RecapSaisieMultiple';
import { FactureachatSelector, mediaObjectApi, selectFactureAchat } from '../../redux/RootStore';
import generateB2DPath from '../../utils/generateB2DPath';
import { useNavigate, useParams } from 'react-router';
import { toISOString } from '../../utils/toISOString';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { FactureAchat, MediaObject } from '@europrocurement/l2d-domain';
import NextDossierSaisieMultiple from './components/forms/saisieMultiple/NextDossierSaisieMultiple';
import ConsulterMultiAnnonce from './ConsulterMultiAnnonce';
import PauseFactureAchatList from './components/lists/PauseFactureAchatList';
import NextEditDossierSaisieMultiple from './components/forms/saisieMultiple/NextEditDossierSaisieMultiple';

export const AchatsBreadcrumbMapper: Array<BreadcrumbsOpts> = [];

export const achatsBasePath = 'achats';

export const saisirFacturePath = 'saisir/:mediaobjectid';
export const saisirMultiFacturePath = saisirFacturePath + '/multi';

export const saisirEnteteMultiFacturePath = 'entete';
export const saisirDossierMultiFacturePath = ':factureachatid/dossier';
export const nextDossierMultiFacturePath = ':factureachatid/next';
export const nextEditDossierMultiFacturePath = saisirDossierMultiFacturePath + '/next/:dossiernum';
export const saisirEditDossierMultiFacturePath= saisirDossierMultiFacturePath + '/:dossiernum';
export const recapMultiFacturePath = ':factureachatid/recap';

export const modifierFacturePath = 'modifier/:factureachatid/:mediaobjectid';
export const consulterMultiFacturePath = 'recap/:factureachatid/:mediaobjectid';
export const listePath = 'liste';
export const traitePath = 'fait';
export const pausePath = 'pause';
export const downloadPath = 'telecharger/:mediaobjectid';
export const uploadPath = 'import';
export const statsPath = 'stats';

export type achatsSuportedViewPathResolver =
    | 'formmediaobject'
    | 'formmultimediaobject'
    | 'formfactureachat'
    | 'listmediaobject'
    | 'listfacture'
    | 'listfacturepause'
    | 'downloadMediaObject'
    | 'uploadMediaObject'
    | 'achatsstats'
    | 'formmultiadddossier'
    | 'formmultirecap'
    | 'formmultinextdossier'
    | 'nextEditDossierMultiFacturePath'
    | 'consulterMultiFacture'
    | 'formmultieditdossier';

export const baseMulti = [modulePathResolver('achats'), listePath, saisirMultiFacturePath].join(
    '/',
);

export const achatsViewPathResolver = function (view: achatsSuportedViewPathResolver) {
    const baseAchats = modulePathResolver('achats');

    switch (view) {
        case 'listmediaobject':
            return [baseAchats, listePath].join('/');
        case 'formmediaobject':
            return [baseAchats, listePath, saisirFacturePath].join('/');
        case 'formmultimediaobject':
            return [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEnteteMultiFacturePath,
            ].join('/');
        case 'formmultiadddossier':
            return [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirDossierMultiFacturePath,
            ].join('/');
        case 'formmultinextdossier':
            return [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                nextDossierMultiFacturePath,
            ].join('/');
            
        case 'formmultieditdossier':
            return [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEditDossierMultiFacturePath,
            ].join('/');
        case 'nextEditDossierMultiFacturePath':
            return [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                nextEditDossierMultiFacturePath,
            ].join('/');
        case 'formmultirecap':
            return [baseAchats, listePath, saisirMultiFacturePath, recapMultiFacturePath].join('/');
        case 'formfactureachat':
            return [baseAchats, listePath, modifierFacturePath].join('/');
        case 'consulterMultiFacture':
            return [baseAchats, listePath, consulterMultiFacturePath].join('/');
        case 'listfacture':
            return [baseAchats, listePath, traitePath].join('/');
        case 'listfacturepause':
            return [baseAchats, listePath, pausePath].join('/');
        case 'downloadMediaObject':
            return [baseAchats, listePath, downloadPath].join('/');
        case 'uploadMediaObject':
            return [baseAchats, uploadPath].join('/');
        case 'achatsstats':
            return [baseAchats, statsPath].join('/');
        default:
            throw new Error('unimplemented path');
    }
};

/**
 * Envoi vers la liste des MediaObjects
 *
 * @returns () => void
 */
export const useNavigateToMediaObjectsList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listmediaobject');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la liste des Factures d'achat
 *
 * @returns () => void
 */
export const useNavigateToFactureAchatsList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listfacture');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la liste des Factures d'achat en pause
 *
 * @returns () => void
 */
export const useNavigateToPausedFactureAchatsList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listfacturepause');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToFactureAchatRecap = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat, mediaObject: MediaObject) => {
        const response = generateB2DPath('formmultirecap', {
            factureAchat,
            mediaObject,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToFactureAchatNextDossier = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat, mediaObject: MediaObject) => {
        const response = generateB2DPath('formmultinextdossier', {
            factureAchat,
            mediaObject,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la saisie d'un prochain dossier (multi-dossiers)
 *
 * @returns () => void
 */
export const useNavigateToDernierDossier = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    return (factureAchat: FactureAchat) => {
        const response = generateB2DPath('formmultiadddossier', {
            factureAchat: factureAchat,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        } else if (response.status === 'KO') {
            enqueueSnackbar(response.message, {
                variant: 'error',
            });
        }
    };
};

/**
 * Annule les modifications apportées au MediaObject
 *
 * @param mediaObjectId
 */
export const cancelEditMediaObject = async function (mediaObjectId: string | undefined) {
    if (mediaObjectId) {
        await mediaObjectApi.patchMediaObjectItem(mediaObjectId, {
            dateDebutTraitement: toISOString(
                new Date(new Date().setDate(new Date().getDate() - 1)) as Date,
            ),
        });
    }
};

/**
 * Envoi vers la liste des MediaObjects et
 * annule les modifications apportées au MediaObject
 *
 * @returns () => Promise<void>
 */
export const useBackFromMediaObjectEdit = () => {
    const { mediaobjectid } = useParams();
    const navigateToListe = useNavigateToMediaObjectsList();

    return async () => {
        if (mediaobjectid) {
            await cancelEditMediaObject(`${mediaobjectid}`);
        }
        navigateToListe();
    };
};

const AchatsRouter = {
    path: achatsBasePath,
    element: <AchatsRoot />,
    children: [
        {
            path: listePath,
            children: [
                // La liste des factures a traiter
                {
                    path: '',
                    index: true,
                    element: <MediaObjectList />,
                },
                // Télécharge le fichier PDF
                {
                    path: downloadPath,
                    index: true,
                    element: (
                        <>
                            <br />
                            **Téléchargement du PDF... ** <br />
                            <br /> la page doit se fermée directement et laisser le navigateur web
                            gérer le téléchargement.
                        </>
                    ),
                },
                // Saisie d'une nouvelle facture liée à un PDF
                {
                    path: saisirFacturePath,
                    index: true,
                    element: <SaisieFactures />,
                },
                // Saisie d'une nouvelle facture multi-annonces liée à un PDF
                {
                    path: saisirMultiFacturePath,
                    abstract: true,
                    // index: true,
                    element: <SaisieFacturesMultiple />,
                    children: [
                        {
                            path: saisirEnteteMultiFacturePath,
                            index: true,
                            element: <EnteteSaisieMultiple />,
                        },
                        {
                            path: saisirDossierMultiFacturePath,
                            element: <DossierSaisieMultiple />,
                        },
                        {
                            path: nextDossierMultiFacturePath,
                            element: <NextDossierSaisieMultiple />,
                        },
                        {
                            path: saisirEditDossierMultiFacturePath,
                            element: <EditDossierSaisieMultiple />,
                        },
                        {
                            path: nextEditDossierMultiFacturePath,
                            element: <NextEditDossierSaisieMultiple />,
                        },
                        {
                            path: recapMultiFacturePath,
                            element: <RecapSaisieMultiple />,
                        },
                    ],
                },
                // Modification
                {
                    path: modifierFacturePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <ModifierFactures type="formfactureachat" />
                        </KeycloakRoute>
                    ),
                },
                {
                    path: consulterMultiFacturePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <ConsulterMultiAnnonce type="formfactureachat" />
                        </KeycloakRoute>
                    ),
                },
                // La liste des factures traitées
                {
                    path: traitePath,
                    index: true,
                    element: (
                        <KeycloakRoute roles="realm:interne">
                            <MainFactureAchatList />
                        </KeycloakRoute>
                    ),
                },
                // La liste des factures en pause
                {
                    path: pausePath,
                    index: true,
                    element: <PauseFactureAchatList />
                },
            ],
        },
        {
            path: uploadPath,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <MediaObjectAdd />
                </KeycloakRoute>
            ),
        },
        {
            path: statsPath,
            element: (
                <KeycloakRoute roles="realm:interne">
                    <Stats />
                </KeycloakRoute>
            ),
        },
    ],
};

export const AchatsMenuEntries: MenuEntry[] = [
    {
        navlabel: true,
        subheader: 'Portail Achats',
        href: '/comptabilite',
    },
    {
        title: 'Import',
        icon: <SaveAltIcon />,
        href: achatsViewPathResolver('uploadMediaObject'),
        role: 'realm:interne',
    },
    {
        title: 'Factures',
        icon: <ReceiptIcon />,
        href: achatsViewPathResolver('listmediaobject'),
        collapse: true,
        children: [
            {
                title: 'À traiter',
                icon: <PictureAsPdfIcon />,
                href: achatsViewPathResolver('listmediaobject'),
            },
            {
                title: 'Traitées',
                icon: <TaskIcon />,
                role: 'realm:interne',
                href: achatsViewPathResolver('listfacture'),
            },
            {
                title: 'En pause',
                icon: <PauseIcon />,
                href: achatsViewPathResolver('listfacturepause'),
            },
        ],
    },
    {
        title: 'Gestion des erreurs',
        role: 'realm:interne',
        icon: <PieChartIcon />,
        href: achatsViewPathResolver('achatsstats'),
    },
];

export default AchatsRouter;
