import * as React from 'react';
import { useSelector } from 'react-redux';
import FormalitiesNotifications from './FormalitiesNotifications';
import FormalitiesSettings from './FormalitiesSettings';
import { PrescripteursApi } from '@europrocurement/l2d-domain';
import { customizerSelector } from '../../../../../../redux/RootStore';
import { ModalFunctions } from '../../PrescripteurDetail';

import _ from 'lodash';
import VerticalTabs from '../../../../../../components/VerticalTabs';

type FormalitiesComponentPropsType = {
    prescriberId: number;
    prescribersApi: PrescripteursApi;
    modalFunctions: ModalFunctions;
};

export type FormalitiesTabProps = {
    companyId: number;
    modalFunctions: ModalFunctions;
    prescribersApi: PrescripteursApi;
    prescriberId: number;
};

const Formalities: React.FunctionComponent<FormalitiesComponentPropsType> = (props) => {
    const { modalFunctions } = props;
    // 1 - Requests params & data promises (GET)

    const { prescriberId, prescribersApi } = props;
    const { xIdSociete: companyId } = useSelector(customizerSelector);

    const tabProps = {
        companyId,
        modalFunctions,
        prescribersApi,
        prescriberId,
    };

    const SETTINGS_TAB_INDEX = 0;
    const NOTIFICATION_TAB_INDEX = 1;

    const TABS = [
        {
            index: SETTINGS_TAB_INDEX,
            label: 'Paramétrage',
            tabContent: <FormalitiesSettings {...tabProps} />,
        },
        {
            index: NOTIFICATION_TAB_INDEX,
            label: 'Notifications',
            tabContent: <FormalitiesNotifications {...tabProps} />,
        },
    ];

    return <VerticalTabs tabs={TABS} />;
};

export default Formalities;
