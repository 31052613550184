import { SubmitHandler, useForm, UseFormReturn, FieldValues } from 'react-hook-form';
import {
    ExtandedFormContext,
    FlexyForm,
    FormStructure,
    FormObject,
} from '@europrocurement/flexy-form';
import { Fournisseur } from '@europrocurement/l2d-domain';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

/**
 * Met à jour le champs "Recherche par siren / nom" de la section "Fournisseur"
 *
 * @param formContext
 * @param siren
 */
export const updateSirenFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    siren: string,
) {
    formContext.setValue('siren_fournisseur', siren);
};

/**
 * Met à jour le champs "Raison sociale" de la section "Fournisseur"
 *
 * @param formContext
 * @param raisonSociale
 */
export const updateRaisonSocialeFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    raisonSociale: string,
) {
    formContext.setValue('raison_sociale_fournisseur', raisonSociale);
};

/**
 * Met à jour le champs "Type remise" de la section "Fournisseur"
 *
 * @param formContext
 * @param typeRemise
 */
export const updateRemiseFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    typeRemise: string,
) {
    formContext.setValue('type_remise_fournisseur', typeRemise);
};

/**
 * Vide les champs de la section "Fournisseur"
 *
 * @param formContext
 */
export const resetFournisseursFields = function (formContext: UseFormReturn<FormObject>) {
    updateRaisonSocialeFournisseur(formContext, '');
    updateSirenFournisseur(formContext, '');
    updateRemiseFournisseur(formContext, '');
};

/**
 * Complète le formulaire grâce au fournisseur.
 *
 * @param formContext
 * @param fournisseur
 * @param callbacks appelés en fonction de certains champs
 *      raisonSociale => Si le fournisseur est présent
 *      remise => Si le fournisseur est présent
 *      siren => Si le fournisseur est présent
 *      typeRemise => Si le fournisseur a un type de remise
 * @returns
 */
export const updateFormWithFournisseur = function (
    formContext: UseFormReturn<FormObject>,
    fournisseur?: Fournisseur,
    callbacks?: {
        raisonSociale?: (value: string) => void;
        remise?: (value: string) => void;
        siren?: (value: string) => void;
        typeRemise?: (value: TypeRemiseFournisseurRead) => void;
    },
) {
    if (!fournisseur) {
        updateRaisonSocialeFournisseur(formContext, '');
        updateSirenFournisseur(formContext, '');
        updateRemiseFournisseur(formContext, '');
        return;
    }

    if (callbacks?.raisonSociale) {
        callbacks?.raisonSociale(fournisseur.libelle || '');
    }
    updateRaisonSocialeFournisseur(formContext, fournisseur.libelle || '');

    if (callbacks?.siren) {
        callbacks?.siren(fournisseur.siren || '');
    }
    updateSirenFournisseur(formContext, fournisseur.siren || '');

    if (fournisseur.typeRemise) {
        let codeTypeRemise = fournisseur.typeRemise.code;

        if (fournisseur.rsfInclus) {
            codeTypeRemise = 'RSF Inclus';
        }

        const remiseString = `${codeTypeRemise} (${fournisseur.typeRemise.libelle})`;

        if (callbacks?.remise) {
            callbacks?.remise(remiseString);
        }
        if (callbacks?.typeRemise) {
            callbacks?.typeRemise(fournisseur.typeRemise);
        }
        updateRemiseFournisseur(formContext, remiseString);
    } else {
        updateRemiseFournisseur(formContext, `Pas de remise`);
    }
};
