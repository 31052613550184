import * as React from 'react';
import { Helmet } from 'react-helmet';

export type PageContainerType = {
    title: string;
    description: string;
    children: React.ReactNode;
};

export const PageContainer = function ({ title, description, children }: PageContainerType) {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {children}
        </div>
    );
};

export default PageContainer;
