import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import { ColorsPaletteVariant } from '../../../assets';
import { ActionsBar } from '../../molecules';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

export type NotesProps = {
    title: string;
    subtitle?: string;
    noteRawContent: string | undefined;
    color?: ColorsPaletteVariant;
    sx?: SxProps;
    onEdit?: (noteContent: string) => void;
};

const Notes: React.FunctionComponent<NotesProps> = function (props) {
    const { title, subtitle, noteRawContent, color, sx, onEdit } = props;

    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: { note: noteRawContent },
    });

    const noteField = formContext.register('note');

    const splitStringWithAuthorPattern = (rawNote: string) => {
        // Author pattern (date dash 2 words space colon)
        const regex = /(\d{2}\/\d{2}\/\d{4} - [a-zA-Z&-9]* [a-zA-Z&-9]*\s*:*)/g;
        const splitSymbol = '/cutNotesHere/';

        const formattedNotes = rawNote.trim().replace(regex, `${splitSymbol} $1 ${splitSymbol}`);

        const splittedNotes = formattedNotes.split(splitSymbol);
        if (splittedNotes[0] === '') {
            // Normal case, Unshift first split
            splittedNotes.shift();
        } else {
            // If note start without author pattern, add blank author
            splittedNotes.unshift('');
        }

        return splittedNotes;
    };

    const groupNotesByAuthor = (splittedNotes: Array<string>) => {
        const authors: Array<string> = [];
        const notes: Array<string> = [];

        splittedNotes.forEach((element: string, index: number) => {
            const item = element.trim();
            if (index % 2 === 0) {
                authors.push(item);
            } else {
                notes.push(item);
            }
        });

        const groupedNotes = authors.map((author: string, index: number) => ({
            author,
            note: notes[index] ?? '',
        }));

        return groupedNotes;
    };

    const formatRawNote = (rawNote: string) => {
        if (!rawNote) {
            return [];
        }

        const splittedString = splitStringWithAuthorPattern(rawNote);
        const notesGroupedByAuthor = groupNotesByAuthor(splittedString);
        return notesGroupedByAuthor;
    };

    const groupedNotes = formatRawNote(noteRawContent ?? '');

    const setCaretPosition = (introLength: number) => {
        const textAreas = document.getElementsByTagName('textarea');
        const noteTextArea = textAreas.namedItem('note_prescripteur_textarea');

        if (noteTextArea) {
            noteTextArea.focus();
            noteTextArea.setSelectionRange(introLength - 1, introLength); // Place caret at new line break
        }
    };

    const setNoteIntroduction = (introduction: string) => {
        formContext.setValue('note', introduction + (noteRawContent ?? ''));
    };

    const [editionMode, setEditionMode] = React.useState<boolean>(false);
    const [noteHasAlreadyBeenFocused, setNoteHasAlreadyBeenFocused] =
        React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const keycloakService = UseKeycloakService();
    const currentUserName = keycloakService.getName();

    const todayDate = new Date().toLocaleDateString();

    const onNoteFocus = () => {
        const noteIntroduction = `${todayDate} - ${currentUserName} : \n\n`;

        if (!noteHasAlreadyBeenFocused) {
            setNoteIntroduction(noteIntroduction);
            setCaretPosition(noteIntroduction.length - 1);
            setNoteHasAlreadyBeenFocused(true);
        }
    };

    const toggleEditionMode = () => {
        setEditionMode(!editionMode);
    };

    const updateNote = () => {
        if (onEdit) {
            setIsSubmitting(true);
            const noteContent = formContext.getValues('note');
            onEdit(noteContent ?? '');
            setIsSubmitting(false);
            setNoteHasAlreadyBeenFocused(false);
            setEditionMode(false);
        }
    };

    return (
        <Card
            role="textbox"
            sx={{ backgroundColor: `${color}.main`, color: `${color}.contrastText`, ...sx }}
            {...props}
        >
            <CardHeader
                role="heading"
                title={<Typography fontWeight="bold">{title}</Typography>}
                subheader={subtitle}
                subheaderTypographyProps={{
                    variant: 'subtitle1',
                    color: `${color}.contrastText`,
                }}
                action={<ActionsBar editAction={() => toggleEditionMode()} />}
            />

            <CardContent>
                <Typography component="div" variant="body1" style={{ whiteSpace: 'break-spaces' }}>
                    {editionMode ? (
                        <>
                            <TextField
                                id="note_prescripteur_textarea"
                                sx={{ marginTop: 2 }}
                                variant="outlined"
                                onFocus={onNoteFocus}
                                fullWidth
                                multiline
                                minRows={15}
                                placeholder="Notes"
                                {...noteField}
                            />

                            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                <Button
                                    type="submit"
                                    onClick={updateNote}
                                    disabled={isSubmitting}
                                    variant="contained"
                                    style={{ marginTop: '2rem', marginBottom: '16px' }}
                                >
                                    Mettre à jour la note
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <div>
                            {groupedNotes &&
                                groupedNotes.map(
                                    ({ author, note }: { author: string; note: string }) => (
                                        <>
                                            <Typography variant="h5" fontWeight="bold">
                                                {author}
                                            </Typography>
                                            <span>{note}</span>
                                        </>
                                    ),
                                )}
                        </div>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

Notes.defaultProps = {
    color: undefined,
};

export default Notes;
