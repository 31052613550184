import { combineReducers } from '@reduxjs/toolkit';
import { ConfigurationParameters } from '../../openApi/ApiAchats';
import { createMediaObjectSlice } from './slices/mediaObjectsSlice';
import { createFactureAchatSlice } from './slices/factureAchatSlice';
import { createCategorieStatsSlice } from './slices/categorieStatsSlice';
import { createCodeRejetStatsSlice } from './slices/codeRejetStatsSlice';

export const ACHATS_REDUCER_NAME = 'achats';

export function createMediaobjectReducer(configuration: ConfigurationParameters) {
    const { mediaobjectSlice, mediaobjectDataSourcesThunks } =
        createMediaObjectSlice(configuration);

    return { mediaobjectSlice, mediaobjectDataSourcesThunks };
}

export function createFactureAchatReducer(configuration: ConfigurationParameters) {
    const { factureAchatSlice, factureAchatDataSourcesThunks } =
        createFactureAchatSlice(configuration);

    return { factureAchatSlice, factureAchatDataSourcesThunks };
}

export function createCategorieStatsReducer(configuration: ConfigurationParameters) {
    const { categorieStatsSlice, categorieStatsDataSourcesThunks } =
        createCategorieStatsSlice(configuration);

    return { categorieStatsSlice, categorieStatsDataSourcesThunks };
}

export function createCodeRejetStatsReducer(configuration: ConfigurationParameters) {
    const { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks } =
        createCodeRejetStatsSlice(configuration);

    return { codeRejetStatsSlice, codeRejetStatsDataSourcesThunks };
}
