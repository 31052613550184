import * as React from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

export const NavigateNotFound = function () {
    const location = useLocation();
    return <Navigate to="/errors/404" state={{ from: location, notFoundError: true }} />;
};

export default NavigateNotFound;
