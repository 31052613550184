import React from 'react';
import { FlexyForm, FormStructure, FormObject } from '@europrocurement/flexy-form';
import { PrescriberFormProps } from './common';
import { EMAIL_VALIDATION_REGEX, PHONE_NUMBER_VALIDATION_REGEX } from '@b2d/validation/regex';
import Person from '@mui/icons-material/Person';
import { civilitiesOptions, isMobilePhoneNumber } from './utils';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { contactsApi, prescripteursApi } from '@b2d/redux/RootStore';

import { SubmitHandler, useForm } from 'react-hook-form';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { FlexyFormLabel, FlexyHeaderForm, Switch } from '@europrocurement/flexy-components';
import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';
import { ContactsContactJsonldContactRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

export type ContactFormProps = PrescriberFormProps & {
    entity?: any; // todo
};

const ContactForm: React.FC<ContactFormProps> = (props) => {
    type ContactForm = {
        IsExit?: boolean;
        administrateur: boolean;
        civilite: number; //old ContactJsonldTiersReadCiviliteEnum
        comptabilite: boolean;
        emailsContact: { email: string; defaut: boolean; factureEmail: boolean }[];
        fonction: string;
        nomContact: string;
        note: string;
        prenomContact: string;
        principal: boolean;
        telephonesContact: { telephone: string; defaut: boolean }[];
        mobilesContact: { mobile: string; defaut: boolean }[];
        faxsContact: never[]; //old []
        factureParMail: boolean;
    };

    const contactFormStructure: FormStructure[] = [
        {
            type: 'header',
            label: (
                <>
                    <Person sx={{ marginRight: '5px' }} />
                    Informations contact
                </>
            ),
            sx: {
                marginTop: '0px',
            },
            name: 'info',
        },
        {
            type: 'customItem',
            name: 'principal',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            renderField: (formContext, props) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    formContext.setValue('principal', value);
                    setIsMainContact(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">{'Contact principal'}</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isMainContact}
                            onChange={onChange}
                            disabled={isMainContactEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'customItem',
            name: 'comptabilite',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            renderField: (formContext, props) => {
                const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
                    formContext.setValue('comptabilite', value);
                    setIsBillingContact(value);
                };

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <FlexyFormLabel data-testid="test-id-FlexyInput-label">
                            <Typography component="span">{'Comptabilité'}</Typography>
                        </FlexyFormLabel>
                        <Switch
                            type="checkbox"
                            value={isBillingContact}
                            onChange={onChange}
                            disabled={isBillingContactEditable === false}
                        />
                    </Box>
                );
            },
        },
        {
            type: 'boolean',
            defaultValue: false,
            name: 'IsExit',
            inputlabel: 'A Quitté',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'boolean',
            name: 'administrateur',
            defaultValue: false,
            inputlabel: 'Administrateur',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'select',
            inputlabel: 'Civilité',
            name: 'civilite',
            rules: {
                required: 'Vous devez renseigner la civilité',
            },
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            options: civilitiesOptions,
        },
        {
            type: 'text',
            name: 'nomContact',
            inputlabel: 'Nom',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le nom',
            },
        },
        {
            type: 'text',
            name: 'prenomContact',
            inputlabel: 'Prenom',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
            rules: {
                required: 'Vous devez renseigner le prenom',
            },
        },
        {
            type: 'text',
            name: 'fonction',
            inputlabel: 'Fonction',
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
        },
        {
            type: 'header',
            label: (
                <>
                    <ContactMailIcon sx={{ marginRight: '5px' }} />
                    Coordonnées
                </>
            ),
            name: 'coord',
        },
        {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 6,
            type: 'subformarray',
            name: 'telephonesContact',
            defaultLength: 1,
            minLength: 1,
            maxLength: 10,
            structure: [
                {
                    type: 'text',
                    name: 'telephone',
                    inputlabel: 'Téléphone',
                    rules: {
                        required: 'Vous devez renseigner le numero de téléphone',
                        pattern: {
                            value: PHONE_NUMBER_VALIDATION_REGEX,
                            message: 'Le numero de téléphone est invalide',
                        },
                        validate: {
                            unique: (fieldValue) => {
                                const phones = formContext.getValues('telephonesContact');
                                const phonesNumber = phones.map(
                                    ({ telephone: number }: { telephone: string }) => number,
                                );
                                return (
                                    phonesNumber.filter((value: string) => value === fieldValue)
                                        .length === 1 || 'Ce numéro de téléphone est déja utilisé'
                                );
                            },
                            phoneDefaultConstraint: (fieldValue) => {
                                if (isMobilePhoneNumber(fieldValue)) {
                                    return true;
                                }

                                const phones = formContext
                                    .getValues('telephonesContact')
                                    .filter(
                                        ({ telephone: number }: { telephone: string }) =>
                                            isMobilePhoneNumber(number) === false,
                                    );
                                const mainPhones = phones.filter(
                                    ({ defaut: isMain }: { defaut: boolean }) => isMain,
                                );

                                return (
                                    mainPhones.length === 1 ||
                                    'Veuillez selectionner un numéro de téléphone principal'
                                );
                            },
                            mobileDefaultConstraint: (fieldValue) => {
                                if (!isMobilePhoneNumber(fieldValue)) {
                                    return true;
                                }

                                const phones = formContext
                                    .getValues('telephonesContact')
                                    .filter(({ telephone: number }: { telephone: string }) =>
                                        isMobilePhoneNumber(number),
                                    );
                                const mainMobiles = phones.filter(
                                    ({ defaut: isMain }: { defaut: boolean }) => isMain,
                                );

                                return (
                                    mainMobiles.length === 1 ||
                                    'Veuillez selectionner un numéro de mobile principal'
                                );
                            },
                        },
                    },
                },
                {
                    type: 'boolean',
                    name: 'defaut',
                    inputlabel: 'Par défaut',
                    defaultChecked: false,
                    xs: 12,
                    onClick: () => formContext.trigger('telephonesContact'),
                },
            ],
        },
        {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 6,
            type: 'subformarray',
            name: 'emailsContact',
            defaultLength: 1,
            minLength: 1,
            maxLength: 10,
            structure: [
                {
                    type: 'text',
                    name: 'email',
                    inputlabel: 'Email',
                    rules: {
                        required: "Vous devez renseigner l'email",
                        pattern: {
                            value: EMAIL_VALIDATION_REGEX,
                            message: "L'Email est invalide",
                        },
                        validate: {
                            unique: (fieldValue) => {
                                const formEmailsContact = formContext.getValues('emailsContact');
                                const emails = formEmailsContact.map(
                                    ({ email }: { email: string }) => email,
                                );
                                return (
                                    emails.filter((value: string) => value === fieldValue)
                                        .length === 1 || 'Cet email est déja utilisé'
                                );
                            },
                            hasOneMainEmail: (fieldValue) => {
                                const formEmailsContact = formContext.getValues('emailsContact');
                                const emailsDefautValues = formEmailsContact.map(
                                    ({ defaut: isMain }: { defaut: boolean }) => isMain,
                                );

                                const hasOneTrue = (array: Array<boolean>) => {
                                    let counter = 0;
                                    array.forEach((booleanValue) =>
                                        booleanValue ? counter++ : null,
                                    );
                                    return counter === 1;
                                };

                                return (
                                    hasOneTrue(emailsDefautValues) ||
                                    'Veuillez choisir un email par defaut'
                                );
                            },
                        },
                    },
                },
                {
                    type: 'boolean',
                    name: 'defaut',
                    inputlabel: 'Email par defaut',
                    onClick: () => formContext.trigger('emailsContact'),
                },
                {
                    type: 'boolean',
                    name: 'factureEmail',
                    inputlabel: 'Facturation par Email',
                    defaultChecked: false,
                },
            ],
        },
    ];

    const { afterSubmit, entity = {}, isCreate = false, prescriber } = props;

    const { enqueueSnackbar } = useSnackbar();

    const defaultFormValues = {
        administrateur: false,
        civilite: null,
        comptabilite: false,
        emailsContact: [{ email: '', defaut: true, factureEmail: false }],
        fonction: '',
        nomContact: '',
        note: isCreate ? '' : entity.note,
        prenomContact: '',
        principal: false,
        telephonesContact: isCreate
            ? [{ telephone: '', defaut: true }]
            : entity.telephonesContact.concat(
                  entity.mobilesContact.map((mobileContact: any) => {
                      return {
                          type: 'telephone',
                          telephone: mobileContact.mobile,
                      };
                  }),
              ),
        faxsContact: [],
    };

    const getEntity = () => {
        const { civilite } = entity;

        const entityCivilityOption = civilitiesOptions.find(({ value }) => value === civilite);

        return {
            ...entity,
            civilite: entityCivilityOption,

            // Mobile and phone numbers are merged and sended in one array (formStructure take on type of phone input)
            // Info: Mobile / phone types differentiation is API side

            telephonesContact: entity.telephonesContact.concat(
                entity.mobilesContact.map((mobileContact: any) => {
                    return {
                        type: 'telephone',
                        telephone: mobileContact.mobile,
                        defaut: mobileContact.defaut,
                    };
                }),
            ),
        };
    };

    //FormContext (Access formState from parent)
    const formContext = useForm({
        mode: 'onTouched',
        defaultValues: isCreate ? defaultFormValues : getEntity(),
    });

    // FormState
    const [formContactObject] = React.useState<Partial<ContactForm>>(isCreate ? {} : entity);
    const [isMainContactEditable] = React.useState<boolean>(
        isCreate ? true : entity.principal === false,
    );
    const [isBillingContactEditable] = React.useState<boolean>(
        isCreate ? true : entity.comptabilite === false,
    );
    const [isMainContact, setIsMainContact] = React.useState<boolean>(
        isCreate ? false : entity.principal,
    );
    const [isBillingContact, setIsBillingContact] = React.useState<boolean>(
        isCreate ? false : entity.comptabilite,
    );
    const [noteHasAlreadyBeenFocused, setNoteHasAlreadyBeenFocused] =
        React.useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const { id: prescriberId, adresses: addresses } = prescriber;

    const keycloakService = UseKeycloakService();
    const currentUserName = keycloakService.getName();
    const todayDate = new Date().toLocaleDateString();

    const noteField = formContext.register('note');

    const setCaretPosition = (introLength: number) => {
        const textAreas = document.getElementsByTagName('textarea');
        const noteTextArea = textAreas.namedItem('note_textarea');

        if (noteTextArea) {
            noteTextArea.focus();
            noteTextArea.setSelectionRange(introLength - 1, introLength); // Place caret at new line break
        }
    };

    const setNoteIntroduction = (introduction: string) => {
        formContext.setValue('note', introduction + (formContactObject.note ?? ''));
    };

    const onNoteFocus = () => {
        const noteIntroduction = `${todayDate} - ${currentUserName} : \n\n`;

        if (!noteHasAlreadyBeenFocused) {
            setNoteIntroduction(noteIntroduction);
            setCaretPosition(noteIntroduction.length - 1);
            setNoteHasAlreadyBeenFocused(true);
        }
    };

    const reformatedContactData = (contact: any) => {
        const { note } = formContext.getValues();

        return {
            ...contact,
            note,
            civilite: contact.civilite.value,
        };
    };

    const createContact: SubmitHandler<FormObject> = async (contact: unknown) => {
        //Todo type param

        if (prescriber && prescriberId) {
            await prescripteursApi.createPrescripteurContactContactsCollection(
                prescriberId,
                reformatedContactData(contact),
            );
            afterSubmit();
            formContext.reset();
        }
    };

    const toasterFeedBack = (
        results: PromiseSettledResult<AxiosResponse<ContactsContactJsonldContactRead, any>>[],
    ) => {
        const numberOfPromises = results.length;

        const errorsIndexes = results.reduce((accumulator: number[], currentValue, index) => {
            if (currentValue.status === 'rejected') {
                accumulator.push(index);
            }
            return accumulator;
        }, []);

        const hasError = errorsIndexes.length > 0;
        const totallyRejected = errorsIndexes.length === numberOfPromises;

        const getErrorLabel = (index: number) => {
            switch (index) {
                case 0:
                    return 'des informations de contact';
                case 1:
                    return 'du téléphone';
                case 2:
                    return "de l'email";
            }
        };

        if (totallyRejected) {
            enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
        } else if (hasError) {
            const errorToasts = errorsIndexes.map(
                (errorIndex) => `Echec de la mise à jour ${getErrorLabel(errorIndex)}`,
            );

            errorToasts.forEach((errorToast) => {
                enqueueSnackbar(errorToast, { variant: 'error' });
            });
        } else {
            enqueueSnackbar('Modifications effectuées avec succès', { variant: 'success' });
        }
    };

    const proceedUpdate = async (prescriber: any, contact: any) => {
        // Prepare requests
        const { telephonesContact, emailsContact } = contact;

        const updatePromises = [
            // Contact main informations (base model without relations)
            contactsApi.updateContactContactsItem(contact.id, reformatedContactData(contact)),
            // Relations - Contact phones (phone & mobile)
            contactsApi.updateTelephonesContactPrescripteurContactsItem(
                prescriber.id.toString(),
                contact.id,
                { telephonesContact },
            ),
            // Contact email
            contactsApi.updateEmailsContactPrescripteurContactsItem(
                prescriber.id.toString(),
                contact.id,
                { emailsContact },
            ),
        ];

        // Proceed update
        setIsSubmitting(true);
        Promise.allSettled(updatePromises).then((results) => {
            toasterFeedBack(results);
            setIsSubmitting(false);
            afterSubmit();
            formContext.reset();
        });
    };

    const updateContact: SubmitHandler<FormObject> = async (contact: any) => {
        //Todo type param
        if (prescriber && prescriber.id) {
            proceedUpdate(prescriber, contact);
        }
    };

    return (
        <Grid container spacing={3}>
            {/* contact form */}
            <Grid item lg={6}>
                <FlexyForm
                    formContext={formContext}
                    formObject={isCreate ? defaultFormValues : getEntity()}
                    formStructure={contactFormStructure}
                    onSubmit={isCreate ? createContact : updateContact}
                    isSubmitting={isSubmitting}
                />
            </Grid>
            {/* note form */}
            <Grid item lg={6}>
                <FlexyHeaderForm label="Notes" outlined sx={{ margin: 0 }} />
                <TextField
                    id={'note_textarea'}
                    sx={{ marginTop: 2 }}
                    variant="outlined"
                    onFocus={() => onNoteFocus()}
                    fullWidth
                    multiline
                    minRows={15}
                    maxRows={25}
                    placeholder="Notes"
                    {...noteField}
                />
            </Grid>
        </Grid>
    );
};

export default ContactForm;
