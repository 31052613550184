/**
 * Formate la date pour la norme ISO, en prenant en compte le décalage horaire.
 *
 * @param date La date à convertir
 * @param startOfDay Force l'heure à '00:00:00' pour true sinon '23:59:59'
 * @returns string
 */
export const toISOString = function (date: Date, startOfDay?: boolean): string {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number) {
            return (num < 10 ? '0' : '') + num;
        };

    const dateChunk =
        date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());

    let timeChunk = '00:00:00';

    if (startOfDay === undefined) {
        timeChunk =
            pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
    } else if (!startOfDay) {
        timeChunk = '23:59:59';
    }

    const offset = dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60);

    return dateChunk + 'T' + timeChunk + offset;
};
