import * as React from 'react';
import { SpeedDial, SpeedDialAction, Tooltip } from '@mui/material';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import BrushIcon from '@mui/icons-material/Brush';
import Customizer from '../Customizer/Customizer';

export type ContextActionType = {
    icon: React.ReactNode;
    name: string;
    action: () => unknown;
};

type ContextActionsMenuProps = {
    actions?: ContextActionType[];
    mainIcon?: React.ReactNode;
};

const ContextActionsMenu: React.FunctionComponent<ContextActionsMenuProps> = function (props) {
    const { actions: providedActions = [], mainIcon = <SettingsIcon /> } = props;

    const [open, setOpen] = React.useState(false);
    const [isCustomizerDrawerOpen, setCustomizerDrawerOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const toggleCustomizerDrawer = () => setCustomizerDrawerOpen(!isCustomizerDrawerOpen);

    const defaultActions: ContextActionType[] = [
        {
            name: 'Thème',
            icon: <BrushIcon />,
            action: toggleCustomizerDrawer,
        },
    ];

    const actions = [...providedActions, ...defaultActions];

    return (
        <>
            <SpeedDial
                onOpen={() => null}
                onClick={handleOpen}
                open={open}
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 1600,
                    // Children theme override / custom style
                    '& .MuiButtonBase-root': {
                        backgroundColor: 'white',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: '#e0e0e0',
                    },
                }}
                icon={
                    <Tooltip title={`${actions.length} action(s) disponibles`}>
                        <div>{mainIcon}</div>
                    </Tooltip>
                }
            >
                {actions.map((actionItem: ContextActionType) => {
                    const { action, name, icon } = actionItem;
                    return (
                        <SpeedDialAction
                            key={name}
                            icon={icon}
                            tooltipTitle={name}
                            onClick={action}
                        />
                    );
                })}
            </SpeedDial>
            <Customizer
                showDrawer={isCustomizerDrawerOpen}
                setShowDrawer={setCustomizerDrawerOpen}
                showButton={false}
            />
        </>
    );
};
export default ContextActionsMenu;
