import * as React from 'react';

import {
    SidePanelCard,
    TopbarHeight,
    breadcrumbHeight,
    footerHeight,
} from '@europrocurement/flexy-components';
import FlexyDatatableFilters from '../FlexyDatatableFilters/FlexyDatatableFilters';
import FlexyDatatableTable, {
    FlexyDatatableProps,
} from '../FlexyDatatableTable/FlexyDatatableTable';

export const FlexyDatatableMaxHeight = `calc(100vh - ${footerHeight}px - ${TopbarHeight}px - ${breadcrumbHeight}px )`;

export const FlexyDatatable = function <T extends Record<string, unknown>>({
    sx,
    ...props
}: FlexyDatatableProps<T>) {
    return props.searchOpts && props.searchOpts.filtersOpts ? (
        <SidePanelCard
            data-testid="FlexyDatatable"
            rightMenu={<FlexyDatatableFilters filtersOpts={props.searchOpts.filtersOpts} />}
            children={<FlexyDatatableTable {...props} />}
            sx={{
                width: '100%',
                height: FlexyDatatableMaxHeight,
                ...sx,
            }}
        />
    ) : (
        <FlexyDatatableTable
            sx={{
                width: '100%',
                height: FlexyDatatableMaxHeight,
                ...sx,
            }}
            {...props}
        />
    );
};

FlexyDatatable.defaultProps = {
    columnLabelVariant: 'h5',
    textCellVariant: 'h5',
    pagination: undefined,
    searchOpts: undefined,
    setColumns: undefined,
    handleExport: undefined,
};

export default FlexyDatatable;
