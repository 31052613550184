import React, { type FunctionComponent } from 'react';
import { ActiveFilterBadge, FlexyInput } from '@europrocurement/flexy-components';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatatableHeaderProps, DisplayOpts, FiltersOpts, SearchOpts } from '../DatatableHeader';
import FlexyFiltersList from '../FlexyFiltersList/FlexyFiltersList';
import FlexyHandleExport from '../FlexyHandleExport/FlexyHandleExport';
import FlexySearch from '../FlexySearch/FlexySearch';

export type FlexyFiltersProps = {
    searchOpts?: SearchOpts;
    displayOpts?: DisplayOpts;
    handleExport?: DatatableHeaderProps['handleExport'];
    toggleRightMenu?: () => void;
    isSearch?: boolean;
    isExport?: boolean;
    isFilters?: boolean;
};

const FlexyFilters: FunctionComponent<FlexyFiltersProps> = function ({
    searchOpts,
    displayOpts,
    handleExport,
    toggleRightMenu,
    isSearch,
    isExport,
    isFilters,
}: FlexyFiltersProps) {
    return searchOpts || displayOpts || handleExport ? (
        <>
            {isSearch ? (
                <FlexySearch searchOpts={searchOpts} toggleRightMenu={toggleRightMenu} />
            ) : null}
            {isExport ? <FlexyHandleExport handleExport={handleExport} /> : null}
            {isFilters ? <FlexyFiltersList filtersOpts={searchOpts?.filtersOpts} /> : null}
        </>
    ) : null;
};

FlexyFilters.defaultProps = {
    searchOpts: undefined,
    displayOpts: undefined,
    isSearch: true,
    isExport: false,
    isFilters: true,
};

export default FlexyFilters;
