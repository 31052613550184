import React from 'react';
import { Grid, Typography } from '@mui/material';
import { DataCard } from '@europrocurement/flexy-components';
import CheckIcon from '@mui/icons-material/Check';

const RienAAfficher: React.FunctionComponent = function ({}) {
    return (
        <Grid container spacing={2}>
            <Grid key="cards-grid" item xs={12}>
                <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <DataCard
                            title="Rien à afficher"
                            icon={<CheckIcon />}
                            color="primary"
                            sx={{
                                minHeight: '90%',
                                borderStyle: 'dashed',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'center',
                                }}
                            >
                                <strong>Rien à afficher avec ces parametres</strong>
                            </Typography>
                        </DataCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RienAAfficher;
