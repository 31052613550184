import { PayloadAction } from '@reduxjs/toolkit';
import type {
    ApiCollectionResponse,
    EuroprocStore,
    EuroprocApiResponseStatus,
    Pagination,
    DataSource,
} from './EuroprocurementApiType';

export const initialPagination: Pagination = {
    itemsPerPage: 10,
    page: 0,
    total: undefined,
    rowsPerPageOptions: [10, 20, 50, 100],
};

export const initialDataSource: DataSource<unknown> = {
    name: 'main',
    data: [],
    status: 'idle',
    pagination: initialPagination,
    filters: {},
    search: undefined,
    orders: [],
    slicename: '',
};

export const EuroprocurementApiInitialState: EuroprocStore<unknown> = {
    main: initialDataSource,
};

export const europrocurementApiFormatData = <T, U>(
    state: EuroprocStore<U>,
    payload: ApiCollectionResponse<T>,
    dataSourceName: string,
    mapper: (item: T) => U,
) => ({
    ...state,
    [dataSourceName]: {
        ...state[dataSourceName],
        data: payload['hydra:member'].map(mapper) || [],
        status: 'succeeded' as EuroprocApiResponseStatus,
        pagination: {
            ...state[dataSourceName].pagination,
            total: payload['hydra:totalItems'],
        },
    },
});

export const europrocurementApiFormatSelected = <T, U>(
    state: EuroprocStore<U>,
    { payload }: PayloadAction<T>,
    dataSourceName: string,
    mapper: (item: T) => U,
) => ({
    ...state,
    [dataSourceName]: {
        ...state[dataSourceName],
        selected: mapper(payload),
        status: 'succeeded' as EuroprocApiResponseStatus,
    },
});
