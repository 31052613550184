import { Box } from '@mui/material';
import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { OrdersOpts } from '../DatatableHeader';
import { ColumnDatatable } from '../ColumnDatatable';

export type SortDirection = 'asc' | 'desc' | undefined;

type ColumnHeaderItemSortabledProps = {
    onOrdersChange: OrdersOpts['onOrdersChange'];
    field: ColumnDatatable['field'];
    defaultValue: SortDirection;
};

const ColumnHeaderItemSortabled: React.FunctionComponent<ColumnHeaderItemSortabledProps> =
    function ({ onOrdersChange, field, defaultValue }: ColumnHeaderItemSortabledProps) {
        const [sortDirection, setSortDirection] = React.useState<SortDirection>(defaultValue);

        const handleClick = () => {
            let newDirection: SortDirection;

            // solution 1
            if (!sortDirection) newDirection = 'asc';
            if (sortDirection === 'asc') newDirection = 'desc';
            if (sortDirection === 'desc') newDirection = undefined;

            setSortDirection(newDirection);
        };

        React.useEffect(() => {
            console.log(field);
            if (field && field !== '') {
                onOrdersChange(`${field}`, sortDirection);
            } else {
                console.error('colump miss configured for order, please set field ');
            }
        }, [field, onOrdersChange, sortDirection]);

        const renderChevron = () => {
            switch (sortDirection) {
                case 'asc':
                    return <KeyboardArrowDownIcon />;
                case 'desc':
                    return <KeyboardArrowUpIcon />;
                case undefined:
                default:
                    return <UnfoldMore />;
            }
        };

        return (
            <Box
                onClick={handleClick}
                sx={{ padding: 0, margin: 0, width: '24px', height: '24px' }}
            >
                {renderChevron()}
            </Box>
        );
    };

export default ColumnHeaderItemSortabled;
